import PSA from './psa';
import globalWdgRegister from './widgets/wdgregister';

/**
 * initializes the "window.pisasales" object
 * @returns {Boolean} true if everything is ok; false if we must stop this session right now
 */
function _init_pisasales() {
	// get the PSA singleton
	const psa = PSA.getInst();
	// and publish it as global "pisasales" object
	Object.defineProperty(window, 'pisasales', {
		value: psa,
		writable: false,
		configurable: false,
		enumerable: false
	});
	// and let it complete its initialization
	return psa.completeInitialization();
}

/**
 * basic PISA Sales Web Client Initialization
 */
export default function PISA_WebClientInit() {
	console.debug(`+++ PisaSales Web Client Initialization - BEGIN +++`);
	// initialize "window.pisasales"
	if ( _init_pisasales() ) {
		// register all custom widgets
		globalWdgRegister().registerAll();
		console.debug(`+++ PisaSales Web Client Initialization - DONE +++`);
	} else {
		// incompatible browser! force a redirect to a static HTML page
		console.warn("ERROR! Cannot go on!");
		let tgt = top.window.location.href;
		let pos = tgt.lastIndexOf('/');
		if ( pos !== -1 ) {
			tgt = tgt.substring(0, pos);
		}
		tgt += '/theme/unsupported.html';
		top.window.location.href = tgt;
	}
}

console.debug('init.js loaded.');