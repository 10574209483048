import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class XRowItemHeightAdjustmentExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get height() {
		const modelItem = this.item;
		if ( !Validator.isObject( modelItem ) ) {
			return void 0;
		}
		return Validator.isFunction( modelItem.getHeight ) ? modelItem.getHeight() :
			"height" in modelItem ? modelItem.height : void 0;
	}

	set height( newValue ) {
		let newHeightValue = newValue;
		if ( Validator.isString( newHeightValue ) ) {
			newHeightValue = Number( newHeightValue );
		}
		if ( !Validator.isPositiveNumber( newHeightValue ) ) {
			return;
		}
		const modelItem = this.item;
		if ( !Validator.isObject( modelItem ) || !( "height" in modelItem ) ) {
			return;
		}
		modelItem.height = newHeightValue;
	}

}
