import Base from '../../../base/base';

/**
 * XTW cell properties
 */
export default class CellProp extends Base {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( json ) {
		super();
		this.font = json.font || null;
		this.txc = json.txc || null;
		this.bgc = json.bgc || null;
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		delete this.font;
		delete this.txc;
		delete this.bgc
	}
}

