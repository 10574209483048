import LoggingBase from "../base/loggingbase";
import EventListenerManager from './EventListenerManager';
import ListenerMgr from "./ListenerMgr";

/**
 * generic UI item class with general listener support
 */
export default class ListenerItem extends LoggingBase {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'utils.ListenerItem');
        this.lsrManager = new ListenerMgr(ln);
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        this.lsrManager.destroy();
        super.doDestroy();
    }

    /**
     * adds an event listener with a special name prefix to this item's HTML element, if such exists
     * @param {String} eventName the event name to add a listener for; required
     * @param {String} functionName name of callback method, required
     * @param {String} prefix callback prefix
     * @param {HTMLElement} element optional target DOM element
     * @param {Boolean} useCapture optional "capture phase" flag
     * @returns {Boolean} true if successful; false otherwise
     */
     addPrefixListener(eventName, functionName, prefix, element = void 0, useCapture = false) {
        if ( !this.lsrManager.hasListener(eventName, prefix) ) {
            const successfullyAdded = EventListenerManager.addListener( {
                instance: this,
                eventName: eventName,
                functionName: functionName,
                callBackPrefix: prefix,
                element: element,
                useCapture: useCapture,
                manager: this.lsrManager
            } );
            if ( !successfullyAdded ) {
                this.warn(`Could not add a "${ eventName }" event listener to the ListenerItem's element.`);
            }
            return successfullyAdded;
        } else {
            // already added!
            return false;
        }
    }

	/**
	 * adds an event listener to this item's HTML element, if such exists
     * @param {String} eventName the event name to add a listener for; required
     * @param {String} functionName name of callback method, required
     * @param {HTMLElement} element optional target DOM element
     * @param {Boolean} useCapture optional "capture phase" flag
     * @returns {Boolean} true if successful; false otherwise
	 */
    addListener( eventName, functionName, element = void 0, useCapture = false ) {
        return this.addPrefixListener(eventName, functionName, null, element, useCapture);
	}

    /**
	 * removes an event listener from this item's HTML element if the
	 * element is valid/rendered and the event listener exists (was previously
	 * added)
     * @param {String} eventName the event name to add a listener for; required
     * @param {String} prefix callback prefix
     * @param {HTMLElement} element optional target DOM element
     * @returns {Boolean} true if successful; false otherwise
	 */
    removePrefixListener(eventName, prefix, element = void 0) {
        if ( this.lsrManager.hasListener(eventName, prefix) ) {
            const successfullyRemoved = EventListenerManager.removeListener( this, eventName, element, prefix, this.lsrManager );
            if ( !successfullyRemoved ) {
                this.log(`Could not remove the "${ eventName }" event listener from the ListenerItem's element.`);
            }
            return successfullyRemoved;
        }
        return false;
    }

    /**
	 * removes an event listener from this item's HTML element if the
	 * element is valid/rendered and the event listener exists (was previously
	 * added)
     * @param {String} eventName the event name to add a listener for; required
     * @param {HTMLElement} element optional target DOM element
     * @returns {Boolean} true if successful; false otherwise
	 */
    removeListener( eventName, element = void 0 ) {
        return this.removePrefixListener(eventName, null, element);
	}

    /**
     * removes all registered listeners
     */
	removeAllListeners() {
		if ( this.alive ) {
            this.lsrManager.removeAllListeners();
		}
	}

}