/**
 * [Esc] handler interface
 */
export default class EscHandler {

    /**
     * handles [Esc] key strokes
     * @param {KeyboardEvent} e the keyboard event
     * @returns {Boolean} true if the event has been handled and should not be propagated any further; false for common event handling
     */
    handleEsc(e) {
        // abstract!
        return false;
    }
}
