import Validator from "./Validator";
import EventListenerManager from "./EventListenerManager";
import IListenerMgr from "../internal/IListenerMgr";

/**
 * helper class holding listener properties
 */
 class LProps {

    /**
     * constructs a new instance
     * @param {String} key the object key
	 * @param {Object} instance the object providing the listener callback method
     * @param {String} name the event name
     * @param {String} prefix the callback prefix
     * @param {HTMLElement} element the target DOM element
     */
    constructor(key, instance, name, prefix, element) {
        this._key = key;
        this._instance = instance;
        this._name = name;
        this._prefix = Validator.isString(prefix) ? prefix : null;
        this._element = element instanceof HTMLElement ? element : null;
        Object.freeze(this);
    }

    /**
     * @returns {String} the object key
     */
    get key() {
        return this._key;
    }

    /**
     * @returns {Object} the object providing the listener callback method
     */
    get instance() {
        return this._instance;
    }

    /**
     * @returns {String} the event name
     */
    get eventName() {
        return this._name;
    }

    /**
     * @returns {String} the callback prefix
     */
    get prefix() {
        return this._prefix;
    }

    /**
     * @returns {HTMLElement} the target DOM element
     */
    get element() {
        return this._element;
    }
}


/**
 * helper class that holds event listeners;
 * works together with ./EventListenerManager
 */
export default class ListenerMgr extends IListenerMgr {
    
    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'utils.ListenerMgr');
        this.listeners = new Map();
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        this.removeAllListeners();
        super.doDestroy();
    }
    
    /**
     * generates the map key
     * @param {String} eventName event name
     * @param {String} prefix callback prefix
     * @returns the map key
     */
    _getKey(eventName, prefix) {
        if ( !Validator.isString(eventName) ) {
            throw new Error('No event name specified!');
        }
        return Validator.isString(prefix) ? eventName + '@' + prefix : eventName;
    }

    /**
     * @inheritdoc
     * @override
     * @param {String} eventName 
     * @param {String} prefix 
     * @returns {Boolean}
     */
    hasListener(eventName, prefix) {
        return this.listeners.has(this._getKey(eventName, prefix));
    }

    /**
     * @inheritdoc
     * @override
     * @param {Object} instance 
     * @param {String} eventName 
     * @param {String} prefix 
     * @param {HTMLElement} element 
     */
    registerListener(instance, eventName, prefix, element = null) {
        const key = this._getKey(eventName, prefix);
        const props = new LProps(key, instance, eventName, prefix, element);
        this.listeners.set(key, props);
    }

    /**
     * @inheritdoc
     * @override
     * @param {String} eventName 
     * @param {String} prefix 
     */
    unregisterListener(eventName, prefix) {
        const key = this._getKey(eventName, prefix);
        if ( this.listeners.has(key) ) {
            this.listeners.delete(key);
        }
    }

    /**
     * @inheritdoc
     * @override
     */
    removeAllListeners() {
        if ( this.alive && (this.listeners.size > 0) ) {
            try {
                this.listeners.forEach((props) => EventListenerManager.removeListener(props.instance, props.eventName, props.element, props.prefix, null));
            } finally {
                this.listeners.clear();
            }
        }
    }

}