import XtwModel from "./XtwModel";

/**
 * column focus listener interface
 */
 export default class ColumnFocusLsr {

    /**
     * called if the column focus has changed
     * @param {XtwModel} model the data model
     * @param {Number} idc ID of the currently focused column
     * @param {Number} prev ID of the previously focused column
     */
    onColumnFocus(model, idc, prev) {
        // abstract
    }
}
