import BscMgr from '../../gui/BscMgr';
import MenuHandler from '../../gui/menu/MenuHandler';
import MnuObj from '../../gui/menu/MnuObj';
import PSA from '../../psa';
import Utils from '../../utils/Utils';

/**
 * class MnuPopWdg - client side implementation of popup menu support widget
 */
export default class MnuPopWdg extends MenuHandler {
	
	/**
	 * constructs a new instance
	 * @param {Object} properties 
	 */
	constructor(properties) {
		super('widgets.menu.MnuPopWdg');
		// bind RAP related methods
		Utils.bindAll(this, [ "onReady", "onRender" ]);
		this.ready = false;
		// initialize the widget
		this.parent = rap.getObject(properties.parent);
		const cwd = this.parent.getData("pisasales.CSTPRP.CWD");
		this.refWdg = cwd.ref || null;
		this.mnuDsc = cwd.mnu || null;
		this.orgRct = cwd.rct || null;
		this.popMnu = null;
		// activate "render" event
		rap.on("render", this.onRender);
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		super.destroy();
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	doDestroy() {
		this.ready = false;
		if ( this.popMnu ) {
			const mnu = this.popMnu;
			this.popMnu = null;
			mnu.destroy();
		}
		delete this.popMnu;
		delete this.refWdg;
		delete this.mnuDsc;
		delete this.parent;
		super.doDestroy();
	}

	onRender() {
		rap.off("render", this.onRender);			// just once!
		this.onReady();
	}

	onReady() {
		this.ready = true;
		if ( this.refWdg && this.mnuDsc ) {
			// try to show the menu
			const wdg = rwt.remote.ObjectRegistry.getObject(this.refWdg);
			if ( wdg && wdg._element ) {
				const elm = wdg._element;
				const dsc = this.mnuDsc;
				if ( dsc && dsc.id && dsc.items && dsc.items.length && (dsc.items.length > 0) ) {
					// that's (hopefully) a menu structure
					const idm = dsc.id || 0;
					const items = dsc.items;
					const mmg = PSA.getInst().getMnuMgr();
					this.popMnu = mmg.createMenu(idm, items);
					if ( this.popMnu ) {
						// show the menu immediately
						const or = this.orgRct;
						const rect = or ? new DOMRect(or.x, or.y, or.w, or.h) : null;
						mmg.showMenu(this.popMnu, { element: elm, rect: rect }, this, true, false);
					}
				}
			}
		}
		if ( !this.popMnu ) {
			// failed to create the menu...
			this.onMenuClose();
		}
	}

	/**
	 * @param {Number} id the ID of the menu item that was clicked
	 * @param {MnuObj} menu the menu that contains this item
	 * @param {Number} what an indicator which part was clicked:
	 * @inheritdoc
	 * @override
	 */
	onMenuItem(id, menu, what) {
		if ( this.ready ) {
			const par = {};
			par.id = id || '';
			BscMgr.getInstance().setBscRqu();
			this._nfySrv("mnuItm", par);
		}
	}

	/**
     * @param {MnuObj} menu the menu that was closed
	 * @inheritdoc
	 * @override
	 */
	onMenuClose(menu) {
		if ( this.ready ) {
			const par = {};
			this._nfySrv("mnuCls", par);
		}
	}

	/**
	 * called to update a menu item
	 * @param {Object} args arguments
	 */
	updMnu(args) {
		if ( this.popMnu ) {
			const id = args.id || 0;
			if ( id ) {
				// update the menu item
				this.popMnu.updMnuItm(id, args);
			}
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv(code, par) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify("MNU_POP_NFY", param);
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget MnuPopWdg.');
		rap.registerTypeHandler("psawidget.MnuPopWdg", {
			factory : function(properties) {
				return new MnuPopWdg(properties);
			},
			destructor: "destroy",
			properties: [  ],
			methods: [ "updMnu" ],
			events: [ "MNU_POP_NFY"]
		});
	}
}

console.debug('widgets/menu/MnuPopWdg.js loaded.');