import BscMgr from "../BscMgr";
import FocusHolder from "../FocusHolder";

const DEFAULT_CSTTMO = 3000;

/**
 * floating message support class
 */
export default class FloMsgMgr {

	/**
	 * @returns {FloMsgMgr} the block screen manager instance
	 */
	 static getInstance() {
		return this.instance;
	}

	/**
	 * constructs a new instance
	 */
	constructor() {
		FloMsgMgr.instance = this;
		this.messageStack = [];
		this.garbageStack = [];
		this.blockAnim = false;
		this.timInv = null;
	 	// create host DIV
		const fmh = document.createElement('div');
		fmh.className = 'floatMessageHost';
		fmh.id = 'PSA.floatMessageHost';
		document.body.appendChild(fmh);
		this.fmhDiv = fmh;
	}

	/**
	 * destructor method
	 */
	destroy() {
		if ( this.fmhDiv ) {
			 let fmh = this.fmhDiv;
			 this.fmhDiv = null;
			 fmh.parentNode.removeChild(fmh);
		}
		this.timInv = null;
		delete this.messageStack;
		delete this.garbageStack;
	}

	/**
	 * shows a floating message
	 * @param {Object} args argument object that provides:
	 * @member args.id: message ID
	 * @member args.ttl: title of the message
	 * @member args.txt body text of the message
	 * @member args.ico icon of the message
	 */
	 shwMsg(args) {
		if ( this.fmhDiv ) {
			if ( this.messageStack.length >= 10 ){
				let shiftMsg = this.messageStack.shift();
				if ( shiftMsg ){
					this.removeMessage(shiftMsg);
				}
			}
			args.actPan = this.messageStack.length + 1;
			const message = this.shwMsgWorker(args);
			if ( message instanceof HTMLElement ) {
				this.messageStack.push( message );
			}
		}
	}

	/**
	 * Creates the actual DOM Element
	 * @param  {Object} args [includes the text and the icon for the message]
	 * @return {HTMLElement} the DOM element
	 */
	shwMsgWorker(args) {
		if ( args ){
			const bsc = BscMgr.getInstance();
			const fh = bsc.focusHolder;
			if ( fh instanceof FocusHolder ) {
				fh.lock();
			}
			try {
				const txt = args.txt;
				if ( txt != null ) {
					const ico = args.ico;
					const fntico = args.fntico;
					const self = this;
					const message = document.createElement("div");
					message.className = "floatMessageContainer";
					message.style.bottom = (args.actPan ? 2.8 * args.actPan + "em" : "2.8em");
					const messageText = document.createElement("div");
					messageText.className = "floatMessageText";
	
					const iconcontainer = document.createElement("div");
					iconcontainer.className = "icocontainer";
					if ( ico ){
						const msgIco = document.createElement("img");
						msgIco.src = ico;
						msgIco.className = "messageIco";
						iconcontainer.appendChild(msgIco);
					}
					if ( fntico ){
						const msgIco = document.createElement("div");
						msgIco.className = "messageFntIco";
						msgIco.innerHTML = fntico;
						iconcontainer.appendChild(msgIco);
					}
					messageText.appendChild(iconcontainer);
	
					const itlIco = document.createElement("i");
					itlIco.className = "close fa fa-times";
					messageText.appendChild(itlIco);
	
					const prf = document.createElement("p");
					prf.innerHTML = pisasales.escHtml(txt);
					messageText.appendChild(prf);
	
					message.appendChild(messageText);
					this.fmhDiv.appendChild( message );
					// inline onclick handling
					itlIco.onclick = function(){
						self.removeMessage(message);
					};
					// self destruction initiated!
					self.startcstTimer( self, message, DEFAULT_CSTTMO );
					message.onmouseover = function(){
						self.blockAnim = true;
					};
					message.onmouseout = function(){
						self.blockAnim = false;
						self.startcstTimer(self, message, 250);
					};
					return message;
				}
			} finally {
				if ( fh instanceof FocusHolder ) {
					fh.release();
				}
			}
		}
		return null;
	}

	/**
	 * Starts a custom timer function which initiates the self-destruction of the message.
	 * @param {FloMsgMgr} self a reference to 'this'
	 * @param {HTMLElement} message the message DOM element
	 * @param {Number} delay the delay time
	 * @return {Number} return a timer ID.
	 */
	startcstTimer(self, message, delay){
		const dly = delay ? delay : DEFAULT_CSTTMO;
		const tmr = setTimeout( () => {
			if ( message ){
				if ( !self.blockAnim ){
					self.startDellusion(self, message);
					self.cleanGarbage(self);
				} else {
					self.garbageStack.push(message);
					self.runGCInterval(self);
				}
			}
		}, dly);
		return tmr;
	}

	/**
	 * A throttled garbage collection function.
	 * @param {FloMsgMgr} self the message manager
	 */
	runGCInterval(self){
		if ( !self.blockAnim ){
			self.cleanGarbage(self);
		} else if ( !self.timInv ) {
			const that = self;
			that.timInv = setInterval( () => {
				that.runGCInterval(that);
			}, 2000);
		}
	}

	/**
	 * Cleans all the old messages from the garbage stack.
	 * @param  {FloMsgMgr} self instance of the FloMsgMgr
	 */
	cleanGarbage(self){
		while ( self.garbageStack.length > 0 ){
			self.startDellusion(self, self.garbageStack.shift());
		}
		if ( self.timInv ){
			clearInterval(self.timInv);
			self.timInv = null;
		}
	}

	/**
	* Toggles the animation and removes the message from the stack after a short delay.
	* @param {FloMsgMgr} self the manager object
	* @param {HTMLElement} message the DOM message object
	*/
	startDellusion(self, message){
		const msg = message;
		if ( msg ){
			msg.classList.add("dilute");
			setTimeout( () => {
				self.removeMessage(msg);
			}, 1000);
		}
	}

	/**
	 * removes the message from the DOM and from the message stack.
	 * @param {HTMLElement} message the DOM message object
	 */
	removeMessage(message){
		if ( message != null ) {
			this.messageStack = this.messageStack.filter(function(msg){ return msg !== message; });
			message.remove();
			this.correctMSPosition();
		}
	}

	/**
	 * Corrects the position of all the messages in the stack.
	 */
	correctMSPosition(){
		this.messageStack.map(function(ele, index) {
			ele.style.bottom = (index + 1) * 2.8 + "em";
		});
	}
}

console.debug('gui/misc/FloMsgMgr.js loaded.');