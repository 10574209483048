import PSA from '../../psa';
import ObjReg from '../../utils/ObjReg';

// global object registry for select button groups
const GRP_REG = new ObjReg();

/**
 * select button group composite
 */
export default class SelBtnGrp {

	constructor(properties) {
		PSA.getInst().bindAll(this, [ 'onReady', 'onRender' ]);
		this.btnReg = new ObjReg();
		this.ready = false;
		this.dead = false;
		this.domElm = null;
		this.wdgId = properties.parent || '';
		this.parent = rap.getObject(this.wdgId);
		this.focBtn = null;
		// activate "render" event
		rap.on('render', this.onRender);
		// add us to the global group registry
		GRP_REG.addObj(this.getID(), this);
	}

	/**
	 * destructor method
	 */
	destroy() {
		// remove us from the global group registry
		GRP_REG.rmvObj(this.getID());
		// clean-up
		this.ready = false;
		delete this.focBtn;
		this.btnReg.clear();					// the radio buttons are destroyed by RAP
		delete this.btnReg;
		delete this.domElm;
		delete this.parent;
		delete this.wdgId;
		delete this.ready;
		this.dead = true;
	}

	/**
	 * returns the select button group with the specified ID
	 * @param {String} id the group ID
	 * @returns {SelBtnGrp} the button group or null if not found
	 */
	static getSelBtnGrp(id) {
		return GRP_REG.getObj(id);
	}

	/**
	 * returns the widget ID
	 * @returns {String} the widget ID as string
	 */
	getID() {
		return this.wdgId;
	}

	/**
	 * marks this instance as fully initialized and rendered
	 */
	onReady() {
		this.domElm = this.parent.$el.get(0);
		this.ready = !!this.domElm;
		if ( this.ready ) {
			// publish key event handler
			this.domElm._keyEvtHdl = this;
			// and allow child items to get mouse events
			this.domElm.style.pointerEvents = 'none';
		}
	}

	/**
	 * called if the widget hast been rendered
	 */
	onRender() {
		if ( this.parent ) {
			rap.off('render', this.onRender);
			this.onReady();
		}
	}

	/**
	 * adds a select button to this button group
	 * @param {SelBtn} btn the button to be added
	 */
	addBtn(btn) {
		this.btnReg.addObj(btn.getID(), btn);
		if ( !this.focBtn && btn.isFocused() ) {
			this.focBtn = btn;
		}
	}

	/**
	 * removes a select button from this button group
	 * @param {SelBtn} btn 
	 */
	rmvBtn(btn) {
		if ( this.dead ) {
			return null;
		}
		if ( this.focBtn === btn ) {
			this.focBtn = null;
		}
		return this.btnReg.rmvObj(btn.getID());
	}

	onBtnFocus(btn) {
		if ( btn.isFocused() ) {
			this.focBtn = btn;
		}
	}

	/**
	 * called if a button of this group was selected
	 * @param {SelBtn} btn the button that was selected
	 */
	onBtnSel(btn) {
		this.btnReg.forEach((b) => {
			if ( b !== btn ) {
				b.forceUnselected();
			}
		})
	}

	/**
	 * moves the selection
	 * @param {SelBtn} btn the button that caused the call
	 * @param {Boolean} dir direction flag
	 */
	moveSel(btn, dir) {
		const btns = this.btnReg.getValues();
		if ( btns.length > 1 ) {
			const idx = btns.indexOf(btn);
			if ( idx !== -1 ) {
				const sel = btn.isSelected();
				let go = true;
				let nxt_btn = null;
				let nxt_idx = idx;
				while ( go && !nxt_btn ) {
					nxt_idx = nxt_idx + (dir ? 1 : -1);
					if ( nxt_idx < 0 ) {
						nxt_idx = btns.length - 1;
					} else if ( nxt_idx >= btns.length ) {
						nxt_idx = 0;
					}
					nxt_btn = btns[nxt_idx];
					if ( !nxt_btn.canSelect() ) {
						nxt_btn = null;
					}
					go = nxt_idx !== idx;
				}
				// activate next avaibale button
				if ( nxt_btn ) {
					nxt_btn.activate(sel);
				}
			}
		}
	}

	/**
	 * handles keyboard events
	 * @param {KeyboardEvent} ke the keyboard event
	 * @param {HTMLElement} tgt the real target element
	 * @returns {Boolean} true if the keyboard event was handled
	 */
	hdlKeyEvt(ke, tgt) {
		if ( this.focBtn && this.focBtn.isFocused() && this.focBtn.isEnabled() ) {
			// forward to focused buton
			return this.focBtn.hdlKeyEvt(ke, tgt);
		}
		return false;
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget SelBtnGrp.');
		rap.registerTypeHandler('psawidget.SelBtnGrp', {
			factory : function(properties) {
				return new SelBtnGrp(properties);
			},
			destructor: 'destroy',
			properties: [  ],
			methods: [ ],
			events: [ 'SELBTNGRP_NFY' ]
		});
	}
}

console.debug('widgets/selbtn/SelBtnGrp.js loaded.');