import LoggingBase from '../../../../base/loggingbase';
import EditableElement from './EditableElement';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';

export default class CharactersCounterExtension extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {EditableElement} editable the editable element
	 */
	constructor( editable ) {
		super('widgets.xtw.editing.CharactersCounterExtension');
		this._editable = editable;
	}

	/**
	 * @returns {EditableElement} the editable element
	 */
	 get editable() {
		return this._editable;
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
	}

	/**
	 * @returns {HTMLElement} the DOM element representing the characters counter
	 */
	get newCharactersCounter() {
		const counterContainer = window.document.createElement( "div" );
		counterContainer.classList.add( "rtp-character-count" );
		return counterContainer;
	}

	/**
	 * @returns {Number} the maximal allowed number of characters
	 */
	get maxCharacterCount() {
		const column = this.editable.column;
		if ( !Validator.isObject( column ) || !( "maxCharacterCount" in column ) ) {
			return 0;
		}
		return column.maxCharacterCount;
	}

	/**
	 * @returns {Number} the current content length
	 */
	get currentContentLength() {
		const inputValue = this.editable.inputValue;
		const inputValueLength = Validator.isString( inputValue ) ? inputValue.length : 0;
		const contentEditableElement = this.editable.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return inputValueLength;
		}
		const editableElementTextLength = contentEditableElement.textLength;
		if ( Validator.isPositiveInteger( editableElementTextLength ) ) {
			return editableElementTextLength;
		}
		return inputValueLength;
	}

	/**
	 * @returns {Number} the still available capacity
	 */
	get remainingContentLength() {
		const currentContentLength = this.currentContentLength;
		if ( !Validator.isPositiveInteger( currentContentLength ) ) {
			return 0;
		}
		const maxCharacterCount = this.maxCharacterCount;
		if ( !Validator.isPositiveInteger( maxCharacterCount, false ) ) {
			return 0;
		}
		const remainingCharactersAmount = maxCharacterCount - currentContentLength;
		return Validator.isPositiveInteger( remainingCharactersAmount ) ? remainingCharactersAmount : 0;
	}

	/**
	 * @returns {Number} the length of the currently selected content
	 */
	get selectedContentLength() {
		const contentEditableElement = this.editable.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return 0;
		}
		const selectionStart = contentEditableElement.selectionStart;
		if ( !Validator.isPositiveInteger( selectionStart ) ) {
			return 0;
		}
		const selectionEnd = contentEditableElement.selectionEnd;
		if ( !Validator.isPositiveInteger( selectionEnd ) ) {
			return 0;
		}
		return Math.abs( selectionEnd - selectionStart );
	}

	/**
	 * @returns {Boolean} true if no new characters can be added to the current content
	 */
	get maximumCharacterCountReached() {
		return this.allowedInsertionLength <= 0;
	}

	/**
	 * @returns {Number} the maximal number of characters tha can be inserted by a block operation such as "paste"
	 */
	get allowedInsertionLength() {
		return this.remainingContentLength + this.selectedContentLength;
	}

	/**
	 * checks wether a DOM event would cause the current input length to be exceeded
	 * @param {Event} domEvent the DOM event
	 * @returns {Boolean} true if the event would lead to the current input length to be exceeded
	 */
	eventSurpassesMaximumCharacterCount( domEvent ) {
		return this.maximumCharacterCountReached && ( DomEventHelper.isContentLengthIncreasingKey( domEvent ) || DomEventHelper.isPasteEvent( domEvent ) );
	}

	/**
	 * creates the content for the character count indicator
	 * @param {Number} currentCharacterCount 
	 * @returns the content string
	 */
	getCounterContent( currentCharacterCount ) {
		const maxCharacterCount = this.maxCharacterCount;
		const currentCharactersAmountIsValid = Validator.isPositiveInteger( Number( currentCharacterCount ) );
		if ( !Validator.isPositiveInteger( maxCharacterCount ) ) {
			return currentCharactersAmountIsValid ? String( currentCharacterCount ) : "";
		}
		return !currentCharactersAmountIsValid ? String( maxCharacterCount ) : `${ currentCharacterCount } / ${ maxCharacterCount }`;
	}

}
