import Validator from '../Validator';
import Condition from './Condition';
import Expression from './Expression';

const IF_OPERATOR = "?";
const ELSE_OPERATOR = ":";
const AND_OPERATOR = "&&";
const OR_OPERATOR = "||";
const NEGATION_OPERATOR = "!";
const OPENING_ROUND_BRACKET = "(";
const CLOSING_ROUND_BRACKET = ")";
const BOOLEAN_EXPRESSION_DELIMITERS = [ "===", "!==", "==", "!=", "<=", ">=", "<", ">" ];

export default class ConditionInterpreter {

	static interpret( conditionExpression ) {
		if ( !Validator.isString( conditionExpression ) ) return void 0;
		return ConditionInterpreter._interpret( conditionExpression );
	}

	static _interpret( conditionExpression ) {
		// TODO
	}

	static cloneBase( conditionalObject ) {
		if ( !Validator.isObject( conditionalObject ) ||
			!Validator.isString( conditionalObject.expression ) ) return void 0;
		return ConditionInterpreter.splitExpression( conditionalObject.expression );
	}

	static getFromColumnDescriptor( columnDescriptor ) {
		if ( !Validator.isObject( columnDescriptor ) ||
			!Validator.isString( columnDescriptor.rtpDscCndMode ) ) return void 0;
		return columnDescriptor.rtpDscCndMode;
	}

	static getFromParameters( parameters ) {
		if ( !Validator.isObject( parameters ) ) return void 0;
		return ConditionInterpreter.getFromColumnDescriptor( parameters.rtpCol );
	}

	static splitExpression( conditionExpression ) {
		if ( !Validator.isString( conditionExpression ) ) return void 0;
		// if ( !Validator.isString( conditionExpression ) )
		// 	conditionExpression = "!((x > 1) && !(x < 100) && x-2>8 || ( x =='sss' ) ) || (x == 300)?a:b";
		const execution = ConditionInterpreter.getSplittedExecution( conditionExpression );
		const condition = ConditionInterpreter.getSplittedCondition( conditionExpression );
		return {
			condition: condition,
			execution: execution,
			expression: conditionExpression
		}
	}

	static getExecution( conditionExpression ) {
		if ( !Validator.isString( conditionExpression ) ) return void 0;
		let lastIndex = conditionExpression.lastIndexOf( IF_OPERATOR );
		if ( !Validator.isPositiveInteger( lastIndex ) ||
			lastIndex + 1 > conditionExpression.length ) return void 0;
		return conditionExpression.substring( conditionExpression.lastIndexOf( IF_OPERATOR ) + 1 );
	}

	static splitExecution( execution ) {
		if ( !Validator.isString( execution ) ) return void 0;
		let lastIndex = execution.lastIndexOf( ELSE_OPERATOR );
		if ( !Validator.isPositiveInteger( lastIndex ) ||
			lastIndex + 1 > execution.length ) return void 0;
		let trueConsequence = execution.substring( 0, lastIndex );
		let falseConsequence = execution.substring( lastIndex + 1 );
		return { ifTrue: trueConsequence, ifFalse: falseConsequence };
	}

	static getSplittedExecution( conditionExpression ) {
		let execution = ConditionInterpreter.getExecution( conditionExpression );
		return ConditionInterpreter.splitExecution( execution );
	}

	static getCondition( conditionExpression ) {
		if ( !Validator.isString( conditionExpression ) ) return void 0;
		let lastIndex = conditionExpression.lastIndexOf( IF_OPERATOR );
		if ( !Validator.isPositiveInteger( lastIndex ) ||
			lastIndex + 1 > conditionExpression.length ) return void 0;
		return conditionExpression.substring( 0, lastIndex );
	}

	static getSplittedCondition( conditionExpression ) {
		let condition = ConditionInterpreter.getCondition( conditionExpression );
		return ConditionInterpreter.splitCondition( condition );
	}

	static splitCondition( condition ) {
		if ( !Validator.isString( condition ) ) return void 0;
		condition = condition.trimStart().trimEnd();

		let conditionCharacters = Array.from( condition );
		let mainCondition = new Condition( { expression: condition } );
		let openParantheses = 0;

		for ( let characterIndex = 0; characterIndex < conditionCharacters.length; characterIndex++ ) {
			const character = conditionCharacters[ characterIndex ];

			// handle last character
			if ( characterIndex == conditionCharacters.length - 1 ) {
				let subCondition = condition.substring( 0 );
				mainCondition.push( subCondition );
				break;
			}

			if ( character == OPENING_ROUND_BRACKET ) {
				openParantheses++;
				continue;
			}
			if ( character == CLOSING_ROUND_BRACKET ) {
				if ( openParantheses > 0 ) openParantheses--;
				continue;
			}

			const nextCharacter = characterIndex < conditionCharacters.length - 1 ?
				conditionCharacters[ characterIndex + 1 ] : "";

			if ( character == NEGATION_OPERATOR &&
				nextCharacter == OPENING_ROUND_BRACKET ) {
				openParantheses++;
				characterIndex++;
				continue;
			}

			if ( openParantheses > 0 ) continue;

			const nextTwoCharacters = character + nextCharacter;

			if ( [ AND_OPERATOR, OR_OPERATOR ].indexOf( nextTwoCharacters ) >= 0 ) {
				mainCondition.push( condition.substring( 0, characterIndex ) );
				mainCondition.operator = nextTwoCharacters;
				mainCondition.callback = ConditionInterpreter
					.getCallbackBasedOnConditionalOperator( nextTwoCharacters );
				mainCondition.push( OPENING_ROUND_BRACKET +
					condition.substring( characterIndex + 2 ) + CLOSING_ROUND_BRACKET );
				break;
			}
		}

		mainCondition._conditionChildren();

		if ( !Validator.isFunction( mainCondition.callback ) &&
			mainCondition.operands.length == 1 &&
			( Validator.is( mainCondition.operands[ 0 ], "Condition" ) ||
				Validator.is( mainCondition.operands[ 0 ], "Expression" ) ) )
			mainCondition = mainCondition.operands[ 0 ];

		return mainCondition;
	}

	static toCondition( operand ) {
		if ( !Validator.isString( operand ) ) return operand;

		operand = operand.trimStart().trimEnd();
		let expression = String( operand );

		if ( !operand.startsWith( OPENING_ROUND_BRACKET ) &&
			!operand.startsWith( NEGATION_OPERATOR + OPENING_ROUND_BRACKET ) )
			return ConditionInterpreter.toExpression( operand );

		const isNegation = operand.startsWith( NEGATION_OPERATOR + OPENING_ROUND_BRACKET );

		operand = ConditionInterpreter.splitCondition(
			operand.substring( ( isNegation ? 2 : 1 ), operand.length - 1 ) );

		if ( !Validator.isFunction( operand.callback ) &&
			operand.operands.length == 1 ) {
			if ( Validator.is( operand.operands[ 0 ], "Condition" ) ||
				Validator.is( operand.operands[ 0 ], "Expression" ) )
				operand = operand.operands[ 0 ];
			else if ( Validator.isString( operand.operands[ 0 ] ) ) {
				operand = ConditionInterpreter.toExpression( operand.operands[ 0 ] );
			}
		}

		if ( isNegation ) operand = new Condition( {
			expression: expression,
			operands: [ operand ],
			operator: NEGATION_OPERATOR,
			callback: ConditionInterpreter
				.getCallbackBasedOnConditionalOperator( NEGATION_OPERATOR )
		} );

		return operand;
	}

	static toExpression( operand ) {
		if ( !Validator.isString( operand ) ) return operand;
		operand = operand.trimStart().trimEnd();
		let expression = new Expression( { expression: operand } );
		for ( let operator of BOOLEAN_EXPRESSION_DELIMITERS ) {
			if ( operand.indexOf( operator ) < 0 ) continue;
			expression.callback = ConditionInterpreter.getCallbackBasedOnOperator( operator );
			expression.operands = operand.split( operator );
			expression.operator = operator;
			break;
		}
		for ( let operandIndex = 0; operandIndex < expression.operands.length; operandIndex++ ) {
			let expressionOperand = expression.operands[ operandIndex ];
			expressionOperand = expressionOperand.trimStart().trimEnd();
			expression.operands[ operandIndex ] = expressionOperand;
		}
		return expression;
	}

	static getCallbackBasedOnOperator( operator ) {
		if ( operator === "===" ) return ( operandsList ) => {
			return operandsList[ 0 ] === operandsList[ 1 ];
		}
		if ( operator === "!==" ) return ( operandsList ) => {
			return operandsList[ 0 ] !== operandsList[ 1 ];
		}
		if ( operator === "==" ) return ( operandsList ) => {
			return operandsList[ 0 ] == operandsList[ 1 ];
		}
		if ( operator === ">=" ) return ( operandsList ) => {
			return Number( operandsList[ 0 ] ) >= Number( operandsList[ 1 ] );
			return operandsList[ 0 ] >= operandsList[ 1 ];
		}
		if ( operator === "<=" ) return ( operandsList ) => {
			return Number( operandsList[ 0 ] ) <= Number( operandsList[ 1 ] );
			return operandsList[ 0 ] <= operandsList[ 1 ];
		}
		if ( operator === "!=" ) return ( operandsList ) => {
			return operandsList[ 0 ] != operandsList[ 1 ];
		}
		if ( operator === "<" ) return ( operandsList ) => {
			return Number( operandsList[ 0 ] ) < Number( operandsList[ 1 ] );
			return operandsList[ 0 ] < operandsList[ 1 ];
		}
		if ( operator === ">" ) return ( operandsList ) => {
			return Number( operandsList[ 0 ] ) > Number( operandsList[ 1 ] );
			return operandsList[ 0 ] > operandsList[ 1 ];
		}
		return void 0;
	}

	static getCallbackBasedOnConditionalOperator( conditionalOperator ) {
		if ( conditionalOperator == AND_OPERATOR ) return ( conditionList ) => {
			let allTrue = true;
			for ( let condition of conditionList ) {
				if ( condition ) continue;
				allTrue = false;
				break;
			}
			return allTrue;
		}
		if ( conditionalOperator == OR_OPERATOR ) return ( conditionList ) => {
			let anyIsTrue = false;
			for ( let condition of conditionList ) {
				if ( !condition ) continue;
				anyIsTrue = true;
				break;
			}
			return anyIsTrue;
		}
		if ( conditionalOperator == NEGATION_OPERATOR ) return ( conditionList ) => {
			return !conditionList[ 0 ];
		}
		return void 0;
	}

}

console.debug( 'utils/condition/ConditionInterpreter.js loaded.' );
