import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class TxtDiv - client side part of custom text label widget
 */
export default class TxtDiv {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		this.element = null;
		this.itmMgr = ItmMgr.getInst();
		this.parent.addListener("Resize", this.layout);
		this.ready = false;
		this.pndChk = false;
		this.fixLin = 0;
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			const txt = cwd["txt"] || '';
			if ( this._psa.isStr(txt) ) {
				this.element = this.itmMgr.creTxt(this.parent, txt, null, null, null, cwd["cssCls"]);
				const clr = cwd["fgrClr"];
				if ( clr ) {
					this.itmMgr.setFgrClr(this.element, clr);
				}
				const fxl = cwd["fixLin"];
				if ( typeof fxl === "number" ) {
					this.fixLin = fxl;
				}
				const fnt = cwd["txtFnt"];
				if ( fnt ) {
					const ffm = fnt["ffm"];
					const siz = fnt["siz"];
					const wgt = fnt["wgt"];
					const stl = fnt["stl"];
					const tdc = fnt["tdc"];
					if ( ffm ) {
						this.element.style.fontFamily = ffm;
					}
					if ( siz ) {
						this.element.style.fontSize = '' + siz + 'px';
					}
					if ( wgt ) {
						this.element.style.fontWeight = wgt;
					}
					if ( stl ) {
						this.element.style.fontStyle = stl;
					}
					if ( tdc ) {
						this.element.style.textDecoration = tdc;
					}
				}
			}
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	onReady() {
		this.ready = true;
		// update the layout
		this.layout();
	}
	
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
		}
	}
	
	onSend() {
		// do nothing so far...
	}
	
	destroy() {
		this._psa.rmvDomElm(this.element);
		this.itmMgr = null;
	}
	
	layout() {
		if ( this.ready && this.element && this.pndChk ) {
			this.chkTxtSiz(null);
		}
	}
	
	setFgrClr(args) {
		if ( this.element ) {
			this.itmMgr.setFgrClr(this.element, args["fgrClr"]);
		}
	}
	
	chkTxtSiz(args) {
		if ( this.ready ) {
			this.pndChk = false;
			const res = this.itmMgr.measureText(this.element, (this.fixLin > 0), null, false);
			this._nfySrv("txtSiz", res);
			this._adjParDiv();
		} else {
			this.pndChk = true;
		}
	}
	
	setCssCls(args) {
		if ( this.element ) {
			const css = args["cssCls"];
			if ( css ) {
				this.element.className = css;
				this._adjParDiv();
			}
		}
	}
	
	_nfySrv(code, par) {
		if ( this.ready ) {
			const param = {};
			param["cod"] = code;
			param["par"] = par;
			rap.getRemoteObject(this).notify("TXTDIV_NFY", param);
		}
	}
	
	_adjParDiv() {
		if ( this.ready && (this.fixLin > 0) ) {
			const par = this.element.parentElement;
			if ( par ) {
				const res = this.itmMgr.measureText(this.element, false, null, false);
				const txs = res.sts;
				const hgt = (this.fixLin * txs.cy) + 1;
				par.style.height = '' + hgt + 'px';
			}
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget TxtDiv.');
		rap.registerTypeHandler("psawidget.TxtDiv", {
			factory : function(properties) {
				return new TxtDiv(properties);
			},
			destructor : "destroy",
			methods : [ "setFgrClr", "setCssCls", "chkTxtSiz" ],
			events : [ "TXTDIV_NFY" ]
		} );
	}
}

console.debug('widgets/csswdg/TxtDiv.js loaded.');