import Validator from '../../../utils/Validator';
import Base from '../../../base/base';
import CellProp from './CellProp';
import Utils from '../../../utils/Utils';

/** empty content constant */
const EMPTY_CTT = Object.freeze( { html: false, rawText: '', text: '', prop: {} } );

/**
 * XTW cell content
 */
export default class CellCtt extends Base {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( json ) {
		super();
		this.text = Utils.dropCR(json.text || '');
		this.rawText = Utils.dropCR(json.rawText || this.text);
		this.html = !!json.html;
		this._fullLength = !!json.fullLength;
		// default cell access mode is writable (3)
		const cellAccessMode = Number( json.acc );
		this.acc = Validator.isPositiveInteger( cellAccessMode ) ? cellAccessMode : 3;
		this.prop = new CellProp( json.prop || {} );
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		this.prop.destroy();
		delete this.rawText;
		delete this.text;
		delete this.html;
		delete this.prop;
		delete this.acc;
	}

	/**
	 * @returns {String} the content as plain text
	 */
	get plainText() {
		if ( this.html && Validator.isString(this.rawText) ) {
			return this.rawText;
		} else {
			return Validator.isString(this.text) ? this.text : '';
		}
	}

	/**
	 * @returns {Boolean} the "full length" flag
	 */
	get fullLength() {
		return this._fullLength;
	}

	/**
	 * returns a new empty instance
	 * @returns {CellCtt} a new empty instance
	 */
	static get EMPTY_CONTENT() {
		return Object.assign( {}, EMPTY_CTT );
	}

	/**
	 * 
	 * @param {Object} json JSON data describing cell content
	 * @returns {CellCtt} a new instance initialized by given JSON data
	 */
	static newInstance(json) {
		return json ? new CellCtt(json) : CellCtt.EMPTY_CONTENT;
	}
}

export { EMPTY_CTT };
