import PSA from '../psa';

/**
 * WdgInfo - a widget info class
 */
export default class WdgInfo {

    /**
     * constructs a new instance
     * @param {String} idw widget ID
     * @param {HTMLElement} element the target element
     */
    constructor(idw, element) {
        if ( !PSA.getInst().isStr(idw) ) {
            throw new Error('No widget ID sepcified!');
        }
        if ( !(element instanceof HTMLElement) ) {
            throw new Error('No target DOM element specified!');
        }
        Object.defineProperty(this, '_idw', { value: idw, writable: false, enumerable: true } );
        Object.defineProperty(this, '_element', { value: element, writable: false, enumerable: true } );
        this._bounds = null;
    }

    /**
     * @returns {String} the widget ID
     */
    get idw() {
        return this._idw;
    }

    /**
     * @returns {HTMLElement} the target element
     */
    get element() {
        return this._element;
    }

    /**
     * @returns {DOMRectReadOnly} the current bounding box; may be null
     */
    get bounds() {
        return this._bounds;
    }

    /**
     * updates the bound box
     * @param {DOMRectReadOnly} bounds new bounding box
     */
    setBounds(bounds) {
        if ( bounds instanceof DOMRectReadOnly ) {
            this._bounds = bounds;
        }
    }
}
