import PSA from '../../psa';
import JsPoint from '../../utils/JsPoint';

const DRAG_BORDER = '1px dotted #000000';

/**
 * creates a dragger element
 * @param {Boolean} vert vertical flag
 * @returns {HTMLElement} the dragger element
 */
function createDragger(vert) {
	const dragger = document.createElement('div');
	if ( vert ) {
		dragger.id = 'psawebcli-dragger-vert';
		dragger.style.width = '1px';
		dragger.style.cursor = 'col-resize';
		dragger.style.borderRight = DRAG_BORDER;
	} else {
		dragger.id = 'psawebcli-dragger-horz';
		dragger.style.height = '1px';
		dragger.style.cursor = 'row-resize';
		dragger.style.borderBottom = DRAG_BORDER;
	}
	// styles common to all draggers
	dragger.style.zIndex = 100000000;
	dragger.style.position = 'absolute';
	// initially invisible
	dragger.style.display = 'none';
	// add it to the DOM
	document.body.appendChild(dragger);
	return dragger;
}

const DRAG_VERT = createDragger(true);
const DRAG_HORZ = createDragger(false);

/**
 * the drag widget class
 */
export default class DragWdg {
	
	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this._psa.bindAll(this, [ 'layout', 'onReady', 'onRender' ]);
		this.ready = false;
		this.vert = false;
		this.btop = false;
		this.bclr = null;
		this.parent = rap.getObject(properties.parent);
		this.element = document.createElement('div');
		this.element.className = 'dragwidget';
		this.element.style.position = 'absolute';
		this.parent.append(this.element);
		this._init(properties);
		this.parent.addListener('Resize', this.layout);
		// bind mouse listeners
		this.lsrMdn = this._psa.bind(this, this._onMouseDown);
		this.lsrMup = this._psa.bind(this, this._onMouseUp);
		this.lsrMov = this._psa.bind(this, this._onMouseMove);
		this.lsrMlv = this._psa.bind(this, this._onMouseLeave);
		// remaining initialization
		this.attached = false;
		this.dragger = null;
		this.curPos = new JsPoint(-1, -1);
		// activate "render" event
		rap.on('render', this.onRender);
	}
	
	/**
	 * destructor method
	 */
	destroy() {
		this._cleanup();
		delete this.curPos;
		delete this.dragger;
		delete this.attached;
		delete this.lsrMdn;
		delete this.lsrMup;
		delete this.lsrMov;
		delete this.lsrMlv;
		delete this.vert;
		delete this.btop;
		delete this.bclr;
		delete this.ready;
		delete this.parent;
	}

	/**
	 * marks this instance as fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
		this.element.addEventListener('mousedown', this.lsrMdn, true);
		this._apply();
	}

	/**
	 * called if the widget hast been rendered
	 */
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off('render', this.onRender);
			this.onReady();
			this.layout();
		}
	}
	
	/**
	 * updates the layout
	 */
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const wdt = area[2];
			const hgt = area[3];
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = wdt + 'px';
			this.element.style.height = hgt + 'px';
		}
	}
	
	/**
	 * changes the orientation
	 * @param {Boolean} args new orientation flag
	 */
	setVert(args) {
		const value = !!args;
		if ( this.vert !== value ) {
			this._release();
			this.vert = value;
			this._setCursor();
			if ( this.ready ) {
				this._apply();
			}
		}
	}
	
	/**
	 * sets the border color
	 * @param {*} args border color
	 */
	setBclr(args) {
		const clr = (this._psa.isStr(args) ? args : this._psa.UIUtil.getCssRgb(args || null)) || null;
		if ( this.bclr !== clr ) {
			this.bclr = clr;
			if ( this.ready ) {
				this._apply();
			}
		}
	}
	
	/**
	 * sets the border top/left flag
	 * @param {Boolean} args new border top/left flag
	 */
	setBtop(args) {
		const value = !!args;
		if ( this.btop !== value ) {
			this.btop = value;
			if ( this.ready ) {
				this._apply();
			}
		}
	}
	
	/**
	 * first initialization
	 * @param {*} args ignored
	 */
	_init(args) {
		this._setCursor();
	}
	
	/**
	 * applies UI style changes
	 */
	_apply() {
		if ( this.element ) {
			const elm = this.element;
			elm.style.border = '';
			if ( this.bclr ) {
				const brd = '1px solid ' + this.bclr;
				if ( this.vert ) {
					if ( this.btop ) {
						elm.style.borderLeft = brd;
					} else {
						elm.style.borderRight = brd;
					}
				} else {
					if ( this.btop ) {
						elm.style.borderTop = brd;
					} else {
						elm.style.borderBottom = brd;
					}
				}
			}
		}
	}

	/**
	 * final cleanup before destruction
	 */
	_cleanup() {
		this.ready = false;
		this._release();
		if ( this.element ) {
			const elm = this.element;
			delete this.element;
			elm.removeEventListener('mousedown', this.lsrMdn, true);
			this._psa.rmvDomElm(elm);
		}
	}
	
	/**
	 * sets the mouse cursor style according to the orientation
	 */
	_setCursor() {
		if ( this.element ) {
			this.element.style.cursor = (this.vert ? 'col-resize' : 'row-resize');
		}
	}
	
	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv(code, par) {
		if ( this.ready ) {
			const param = {};
			param.cod = code;
			param.par = par;
			rap.getRemoteObject(this).notify('DRAGWDG_NFY', param);
		}
	}

	/**
	 * stops further propagation of a DOM event
	 * @param {Event} evt 
	 */
	_stopEvt(evt) {
		evt.stopPropagation();
		evt.preventDefault();
	}

	/**
	 * "mousedown" event listener
	 * @param {MouseEvent} evt event
	 */
	_onMouseDown(evt) {
		this._stopEvt(evt);
		if ( evt.button === 0 ) {
			this._attach();
			this.curPos.x = evt.pageX;
			this.curPos.y = evt.pageY;
		}
	}

	/**
	 * "mouseup" event listener
	 * @param {MouseEvent} evt event
	 */
	_onMouseUp(evt) {
		this._stopEvt(evt);
		const cps = new JsPoint(this.curPos);
		const pos = new JsPoint(evt.pageX, evt.pageY);
		this._release();
		if ( (cps.x !== -1) && (cps.y !== -1) ) {
			const dist = pos.offs(cps);
			const par = {};
			par.dist = dist;
			this._nfySrv('nfyDrag', par);
		}
	}

	/**
	 * "mousemove" event listener
	 * @param {MouseEvent} evt event
	 */
	_onMouseMove(evt) {
		this._stopEvt(evt);
		if ( this.dragger ) {
			if ( this.vert ) {
				this.dragger.style.left = evt.pageX + 'px';
			} else {
				this.dragger.style.top = evt.pageY + 'px';
			}
		}
	}

	/**
	 * "mouseleave" event listener
	 * @param {MouseEvent} evt event
	 */
	_onMouseLeave(evt) {
		this._stopEvt(evt);
		if ( evt.target === document.body ) {
			this._release();
		}
	}

	/**
	 * attaches mouse listeners and places and shows the dragger
	 */
	_attach() {
		if ( this.ready && !this.attached ) {
			const body = document.body;
			body.addEventListener('mouseup', this.lsrMup, true);
			body.addEventListener('mousemove', this.lsrMov, true);
			body.addEventListener('mouseleave', this.lsrMlv, true);
			// setup dragger
			const dragger = this.vert ? DRAG_VERT : DRAG_HORZ;
			const brc = this.element.getBoundingClientRect();
			dragger.style.left = brc.left + 'px';
			dragger.style.top = brc.top + 'px';
			if ( this.vert ) {
				dragger.style.height = brc.height + 'px';
			} else {
				dragger.style.width = brc.width + 'px';
			}
			dragger.style.display = '';
			this.dragger = dragger;
			this.attached = true;
		}
	}

	/**
	 * hides and releases the dragger, removes mouse listeners
	 */
	_release() {
		if ( this.attached ) {
			if ( this.dragger ) {
				const dragger = this.dragger;
				this.dragger = null;
				dragger.style.display = 'none';
			}
			this.curPos.x = -1;
			this.curPos.y = -1;
			const body = document.body;
			body.removeEventListener('mouseup', this.lsrMup, true);
			body.removeEventListener('mousemove', this.lsrMov, true);
			body.removeEventListener('mouseleave', this.lsrMlv, true);
			this.attached = false;
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget DragWdg.');
		/** register custom widget type */
		rap.registerTypeHandler('psawidget.DragWdg', {
			factory : function(properties) {
				return new DragWdg(properties);
			},
			destructor: 'destroy',
			properties: [ 'vert', 'bclr', 'btop' ],
			methods: [ ],
			events: [ 'DRAGWDG_NFY' ]
		});
	}
}

console.debug('widgets/dragwdg/DragWdg.js loaded.');