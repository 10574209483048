/**
 * desktop notification support
 */

let DN_DISABLED = false;		// disabled by user or not available in the current browser
let DN_ADMOFF = false;			// turned off by administration

// check general availability
if ( !('Notification' in window) ) {
	// not supported by this browser!
	DN_DISABLED = true;
	console.info('Feature "Desktop notification" is not supported by this browser.');
} else {
	// browser supports this, but it may be disabled
	if ( Notification.permission === 'denied' ) {
		// user has disabled this permanently :-(
		DN_DISABLED = true;
		console.info('Feature "Desktop notification" is disabled in this browser.');
	} else {
		console.debug('Feature "Desktop notification" is generally available int this browser.');
	}
}

/**
 * desktop notification helper class
 */
export default class DskNfy {
	
	/**
	 * constructs a new instance
	 * @param {Boolean} off "turned off by administration
	 */
	constructor(off) {
		DN_ADMOFF = !!off;
		this.granted = DskNfy.isDskNfyAvl() && (Notification.permission === 'granted');
	}

	/**
	 * indicates whether desktop notification is generally available
	 * @returns {Boolean} true if desktop notification is generally available; false otherwise
	 */
	static isDskNfyAvl() {
		return !DN_DISABLED && !DN_ADMOFF;
	}

	/**
	 * destructor method
	 */
	destroy() {
		// do nothing so far...
	}
	
	/**
	 * @returns {Boolean} true if permission to show a desktop notification is granted; false otherwise
	 */
	isGranted() {
		return this.granted;
	}

	/**
	 * requests permission to show a desktop notification
	 * @param {Function} cbk callback function
	 */
	rquPermission(cbk) {
		if ( DskNfy.isDskNfyAvl() ) {
			const self = this;
			if ( !this.isGranted() ) {
				// ask for permission
				Notification.requestPermission( (permission) => {
					// accepted by the user
					if ( permission === 'granted' ) {
						self.granted = true;
					}
					if ( typeof cbk === 'function' ) {
						cbk(self.isGranted());
					}
				});				
			} else {
				// already granted
				if ( typeof cbk === 'function' ) {
					cbk(true);
				}
			}
		} else {
			// not available or denied by the user
			if ( typeof cbk === 'function' ) {
				cbk(false);
			}
		}
	}
	
	/**
	 * shows a desktop notification
	 * @param {String} id notification ID, passed to the callback function
	 * @param {String} ttl title of the notification
	 * @param {String} txt body text of the notification
	 * @param {String} ico URL of icon for the notification
	 * @param {Function} cbk callback function that is called if the user clicks on the notification; it is called like this: f(id, notification);
	 */
	showNfy(id, ttl, txt, ico, cbk) {
		if ( pisasales.isDskNfyAvl() ) {
			const self = this;
			top.window.focus();
			this.rquPermission( (grt) => {
				if ( grt ) {
					self._doShowNfy(id, ttl, txt, ico, cbk);
				}
			});
		}
	}
	
	/**
	 * worker method; effectively shows the desktop notification
	 * @see DskNfy#showNfy
	 * @param {String} id notification ID, passed to the callback function
	 * @param {String} ttl title of the notification
	 * @param {String} txt body text of the notification
	 * @param {String} ico URL of icon for the notification
	 * @param {Function} cbk callback function that is called if the user clicks on the notification; it is called like this: f(id, notification);
	 */
	_doShowNfy(id, ttl, txt, ico, cbk) {
		const opt = {};
		if ( txt ) {
			opt.body = txt;
		}
		if ( ico ) {
			opt.icon = ico;
		}
		const nfy = new Notification(ttl, opt);
		if ( typeof cbk === 'function' ) {
			nfy.onclick = function() {
				cbk(id, nfy);
			}
		}
	}
}

console.debug('ssn/DskNfy.js loaded.');