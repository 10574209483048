import MnuObj from '../../../../gui/menu/MnuObj';
// import { BTN_HGT } from '../../../../gui/menu/MnuObj';
import { SEP_HGT } from '../../../../gui/menu/MnuItm';
import Color from '../../../../utils/Color';
import DomEventHelper from '../../../../utils/DomEventHelper';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import SearchInputMenuItem from './SearchInputMenuItem';
import { FIXED_AREA_SEPARATOR_COLOR, FIXED_AREA_SEPARATOR_HEIGHT } from './SearchInputMenuItem';

export default class SearchableMenuObject extends MnuObj {

	/**
	 * constructs a new instance
	 * @param {MnuMgr} menuManager the menu manager
	 * @param {Number} menuId menu ID
	 * @param {Array} menuItemsDescriptors the menu item descriptors
	 */
	constructor( menuManager, menuId, menuItemsDescriptors ) {
		super( menuManager, null, menuId, menuItemsDescriptors );
		this.attachInputDisplay();
	}

	get searchDisplayText() {
		if ( !Validator.isObject( this.searchInputMenuItem ) ||
			!this.searchInputMenuItem.isRendered ) {
			return "";
		}
		return this.searchInputMenuItem.value;
	}

	set searchDisplayText( newText ) {
		if ( !Validator.isObject( this.searchInputMenuItem ) ||
			!this.searchInputMenuItem.isRendered ) {
			return;
		}
		this.searchInputMenuItem.value = newText;
	}

	get searchString() {
		const searchString = this.searchDisplayText;
		return !Validator.isString( searchString ) ? "" :
			searchString.trimStart().replace( /\.+/g, "." )
			.replace( /\s+/g, " " ).toLowerCase();
	}

	get searchInputContainerElement() {
		if ( !Validator.isObject( this.searchInputMenuItem ) ||
			!this.searchInputMenuItem.isRendered ) {
			return void 0;
		}
		return this.searchInputMenuItem.container;
	}

	get itemHostRect() {
		if ( !( this.itmHost instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.itmHost.getBoundingClientRect();
	}

	get itemHostHeight() {
		const itemHostRect = this.itemHostRect;
		if ( !Validator.isObject( itemHostRect ) ) {
			return void 0;
		}
		return itemHostRect.height;
	}

	get documentBodyRect() {
		return window.document.body.getBoundingClientRect();
	}

	get documentBodyHeight() {
		return this.documentBodyRect.height;
	}

	get searchInputContainerElementRect() {
		const searchInputContainerElement = this.searchInputContainerElement;
		if ( !( searchInputContainerElement instanceof HTMLElement ) ) {
			return void 0;
		}
		return searchInputContainerElement.getBoundingClientRect();
	}

	get searchInputContainerElementHeight() {
		const searchInputContainerElementRect = this.searchInputContainerElementRect;
		if ( !Validator.isObject( searchInputContainerElementRect ) ) {
			return void 0;
		}
		return searchInputContainerElementRect.height;
	}

	get noScrollMenuHeight() {
		const itemHostHeight = this.itemHostHeight;
		if ( !Validator.isPositiveNumber( itemHostHeight ) ) {
			return void 0;
		}
		const searchInputContainerElementHeight =
			this.searchInputContainerElementHeight || 0;
		return itemHostHeight + searchInputContainerElementHeight;
	}

	/**
	 * @override
	 */
	show() {
		const originalReturnValue = super.show();
		if ( this.btnDwn instanceof HTMLElement ) {
			this.btnDwn.style.borderTop = `${ FIXED_AREA_SEPARATOR_HEIGHT }px solid` +
				` ${ FIXED_AREA_SEPARATOR_COLOR }`;;
		}
		return originalReturnValue;
	}

	refreshInputPlaceholder() {
		return Validator.isFunctionPath( this.searchInputMenuItem,
				"searchInputMenuItem.refreshInputPlaceholder" ) ?
			this.searchInputMenuItem.refreshInputPlaceholder() : void 0;
	}

	addToSearchDisplayText( additionText ) {
		if ( !Validator.isString( additionText ) ) {
			return false;
		}
		this.searchDisplayText = this.searchDisplayText + additionText;
		return true;
	}

	removeLastCharacterFromSearchDisplayText() {
		const currentSearchDisplayText = this.searchDisplayText;
		if ( !Validator.isString( currentSearchDisplayText ) ) {
			return false;
		}
		this.searchDisplayText = currentSearchDisplayText
			.substring( 0, currentSearchDisplayText.length - 1 );
		return true;
	}

	/**
	 * @override
	 */
	setVisHgt( visibleHeightInPixels ) {
		const returnValue = super.setVisHgt( visibleHeightInPixels );
		if ( !Validator.isObject( this.searchInputMenuItem ) ) {
			return returnValue;
		}
		const requiredHeightInPixels = this.getMnuHgt();
		requiredHeightInPixels <= visibleHeightInPixels ?
			this.searchInputMenuItem.hide() : this.searchInputMenuItem.show();
		return returnValue;
	}

	get searchInputMenuItemIsVisible() {
		return !Validator.isObject( this.searchInputMenuItem ) ? false :
			this.searchInputMenuItem.isVisible;
	}

	/**
	 * destructor method
	 * @override
	 */
	doDestroy() {
		this.discardInputUi();
		super.doDestroy();
	}

	attachInputDisplay() {
		this.discardInputUi();
		if ( !( this.mnuDiv instanceof HTMLElement ) ||
			!( this.scrHost instanceof HTMLElement ) ) {
			return false;
		}
		new SearchInputMenuItem( this );
		if ( !this.searchInputMenuItem.isRendered ) {
			return false;
		}
		const searchContainer = this.searchInputMenuItem.container;
		this.mnuDiv.insertBefore( searchContainer, this.scrHost );
		this.removeButtonBorder();
		return true;
	}

	removeButtonBorder() {
		if ( !( this.btnUp instanceof HTMLElement ) ) {
			return false;
		}
		this.btnUp.style.borderBottom = "0px solid rgba(255,255,255,0)";
		return true;
	}

	discardInputUi() {
		if ( Validator.isFunctionPath(
				this.searchInputMenuItem, "searchInputMenuItem.removeSelf" ) ) {
			return this.searchInputMenuItem.removeSelf();
		}
		return false;
	}

	get searchInColumnsMenuOptionTitle() {
		return Validator.isObjectPath( this.mnuMgr, "mnuMgr.curHdl" ) &&
			Validator.isString( this.mnuMgr.curHdl.searchInColumnsMenuOptionTitle ) ?
			this.mnuMgr.curHdl.searchInColumnsMenuOptionTitle : void 0;
	}

	get functionalMenuItems() {
		if ( !Validator.isObject( this.mnuItems ) ||
			!Validator.isMap( this.mnuItems._objReg ) ) {
			return void 0;
		}
		return [ ...this.mnuItems._objReg.values() ].filter( item =>
			Validator.is( item, "MnuItm" ) && item.alive && item.enabled &&
			!item.inactive && !item.sep && Validator.isPositiveInteger( item.id ) );
	}

	get visibleMenuItems() {
		if ( !Validator.isObject( this.mnuItems ) ||
			!Validator.isMap( this.mnuItems._objReg ) ) {
			return void 0;
		}
		return [ ...this.mnuItems._objReg.values() ].filter( item =>
			Validator.is( item, "MnuItm" ) && !item.inactive && !item.sep &&
			item.element instanceof HTMLElement &&
			!item.element.classList.contains( "invisible" ) );
	}

	hasSearchString( menuItem, startsWithPoint, searchString ) {
		if ( !Validator.isObject( menuItem ) ||
			!( menuItem.spanText instanceof HTMLElement ) ) {
			return true;
		}
		const menuItemTitle = menuItem.spanText.innerHTML.toLowerCase().trim();
		return !!startsWithPoint ? menuItemTitle.indexOf( searchString ) >= 0 :
			menuItemTitle.startsWith( searchString ) ||
			menuItemTitle.indexOf( "." + searchString ) >= 0;
	}

	focusHeaderContextMenuSearchInput() {
		return Validator.isFunctionPath(
				this.searchInputMenuItem, "searchInputMenuItem.focus" ) ?
			this.searchInputMenuItem.focus() : false;
	}

	reactToInputChange() {
		// TODO kind of redundant code
		const textMenuSearched = this.searchContextMenu();
		const menuUiAdjusted = this.adjustMenuUiAfterSearch();
		return textMenuSearched && menuUiAdjusted;
	}

	onHeaderContextMenuPrintableKey( domEvent ) {
		if ( !Validator.isObject( domEvent ) || !this.searchInputMenuItemIsVisible ) {
			return false;
		}
		const isBackspace = DomEventHelper.keyIs( domEvent, "Backspace" );
		if ( this.allMenuItemsAreHidden && !isBackspace ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		this.focusHeaderContextMenuSearchInput();
		const displayTextChanged = isBackspace ?
			this.removeLastCharacterFromSearchDisplayText() :
			this.addToSearchDisplayText( domEvent.key );
		const textSearchedAndMenuAdjusted = this.reactToInputChange();
		return displayTextChanged && textSearchedAndMenuAdjusted;
	}

	searchContextMenu() {
		const functionalMenuItems = this.functionalMenuItems;
		if ( !Validator.isIterable( functionalMenuItems ) ) {
			return false;
		}
		const searchString = this.searchString;
		const searchStringStartsWithPoint = searchString.startsWith( "." );
		this.allMenuItemsAreHidden = true;
		for ( let menuItem of functionalMenuItems ) {
			const showItem = this.hasSearchString(
				menuItem, searchStringStartsWithPoint, searchString );
			if ( !showItem ) {
				this.temporaryHideMenuItem( menuItem );
				continue;
			}
			this.unhideMenuItem( menuItem );
			this.allMenuItemsAreHidden = false;
		}
		return true;
	}

	adjustMenuUiAfterSearch() {
		const necessaryHeight = this.noScrollMenuHeight;
		if ( !Validator.isPositiveNumber( necessaryHeight ) ) {
			return false;
		}
		const bodyHeight = this.documentBodyHeight;
		this._adjustMenuUiAfterSearch( necessaryHeight > bodyHeight );
		return true;
	}

	_adjustMenuUiAfterSearch( needsScrolling = true ) {
		this.canScroll = !!needsScrolling;
		if ( this.mnuDiv instanceof HTMLElement ) {
			this.mnuDiv.style.top = `0px`;
			!!needsScrolling ? this.mnuDiv.classList.remove( "searched" ) :
				this.mnuDiv.classList.add( "searched" );
		}
		if ( this.btnUp instanceof HTMLElement ) {
			this.btnUp.style.display = !!needsScrolling ? "flex" : "none";
		}
		if ( this.btnDwn instanceof HTMLElement ) {
			this.btnDwn.style.display = !!needsScrolling ? "flex" : "none";
		}
		if ( this.itmHost instanceof HTMLElement ) {
			this.itmHost.style.top = "0px";
		}
		return !!needsScrolling ? true : this._adjustMnuTopAfterSearch();
	}

	_adjustMnuTopAfterSearch() {
		if ( !Validator.isObject( this.mnuMgr ) ||
			!( this.mnuDiv instanceof HTMLElement ) ||
			!( this.mnuMgr.originElement instanceof HTMLElement ) ) {
			return false;
		}
		const necessaryHeight = this.noScrollMenuHeight;
		if ( !Validator.isPositiveNumber( necessaryHeight ) ) {
			return false;
		}
		const originElementRect = this.mnuMgr.originElement.getBoundingClientRect();
		const originElementBottom = originElementRect.top + originElementRect.height;
		const bodyHeight = this.documentBodyHeight;
		const availableBottomSpace = bodyHeight - originElementBottom;
		const menuTop = necessaryHeight < availableBottomSpace ?
			originElementBottom : bodyHeight - necessaryHeight;
		this.mnuDiv.style.top = `${ menuTop }px`;
		return true;
	}

	_setMenuItemSearchTemporaryVisibility( menuItem, setVisible = true ) {
		if ( !Validator.isObject( menuItem ) ||
			!( menuItem.element instanceof HTMLElement ) ) {
			return false;
		}
		menuItem.temporaryHidden = !!setVisible;
		menuItem.element.classList.add( "menu-item" );
		!!setVisible ? menuItem.element.classList.remove( "invisible" ) :
			menuItem.element.classList.add( "invisible" );
		return true;
	}

	temporaryHideMenuItem( menuItem ) {
		return this._setMenuItemSearchTemporaryVisibility( menuItem, false );
	}

	unhideMenuItem( menuItem ) {
		// the name "showMenuItem" might be misinterpreted
		return this._setMenuItemSearchTemporaryVisibility( menuItem, true );
	}

}
