import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

const NO_BORDER = 'none';

/**
 * class BorderCmp - a stylable composite
 */
export default class BorderCmp {

    /**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		this.ready = false;
        this.element = null;
        this.style = null;
        this.width = 0;
        this.color = null;
        this.radius = 0;
        this._pending = false;
		this.parent = rap.getObject(properties.parent);
		// activate "render" event
        this._trigger();
		rap.on("render", this.onRender);
    }

	destroy() {
        rap.off("render", this.onRender);
        delete this.ready;
        delete this.parent;
	}

	onReady() {
		this.ready = true;
        // get the DOM element
        this.element = this.parent.$el.get(0);
		// update the layout
		this.layout();
	}
	
	onRender() {
        if ( !this.ready ) {
            this.onReady();
        }
        this._apply();
	}
	
	onSend() {
		// do nothing so far...
	}
	
	layout() {
        // we've got nothing to do...
	}

    /**
     * sets a new border style
     * @param {String} style new border style
     */
    setStyle(style) {
        this.style = style || '';
        this._trigger();
    }

    /**
     * sets a new border width
     * @param {Number} width new border width
     */
    setWidth(width) {
        this.width = width || 0;
        this._trigger();
    }

    /**
     * sets a new border color
     * @param {*} color new border color
     */
    setColor(color) {
        this.color = color || null;
        this._trigger();
    }

    /**
     * sets a new border radius
     * @param {Number} radius new border radius
     */
    setRadius(radius) {
        this.radius = radius || 0;
        this._trigger();
    }

    /**
     * triggers an UI update
     */
    _trigger() {
        if ( !this._pending ) {
            // activate "render" event
            this._pending = true;
            rap.on('render', this.onRender);
        }
    }

    /**
     * applies the border style
     */
    _apply() {
        try {
            if ( this.element ) {
                const css = this.element.style;
                const has_border = this._psa.isStr(this.style) && (NO_BORDER !== this.style);
                if ( has_border ) {
                    css.borderStyle = this.style;
                    css.borderWidth = '' + (this.width || 0) + 'px';
                    css.borderColor = ItmMgr.getInst().getRgb(this.color);
                    css.borderRadius = '' + (this.radius || 0) + 'px';
                } else {
                    css.borderStyle = NO_BORDER;
                    css.borderWidth = '0px';
                    css.borderColor = '';
                    css.borderRadius = '0px';
                }
            }
        }
        finally {
            this._pending = false;
            rap.off('render', this.onRender);
        }
    }
	
	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget BorderCmp.');
		rap.registerTypeHandler("psawidget.BorderCmp", {
			factory : function(properties) {
				return new BorderCmp(properties);
			},
			destructor : "destroy",
			properties: [ 'style', 'width', 'color', 'radius' ],
			methods : [  ],
			events : [ 'BORDERCMP_NFY' ]
		} );
	}
}

console.debug('widgets/csswdg/BorderCmp.js loaded.');