import Utils from './Utils';
import Validator from './Validator';
import IListenerMgr from '../internal/IListenerMgr';

export default class EventListenerManager {

	/**
	 * creates a callback function name
	 * @param {String} eventName the event name
	 * @param {String} callBackPrefix optional prefix
	 * @returns {String} the callback function name
	 */
	static createCallbackFunctionName( eventName, callBackPrefix = "" ) {
		if ( !Validator.isString( eventName ) ) {
			return void 0;
		}
		return "on" + eventName.charAt( 0 ).toUpperCase() + eventName.slice( 1 ) + ( Validator.isString( callBackPrefix ) ? callBackPrefix : "" ) + "Callback";
	}

	/**
	 * adds an event listener
	 * @param {Object} args parameters
	 * @param {Object} args.instance the object providing the listener callback method
	 * @param {String} args.eventName the event name
	 * @param {String} args.functionName the name of the listener callback method
	 * @param {HTMLElement} args.element the target DOM element to add the listener to; if not specified then args.instance must provide the DOM element
	 * @param {Number} args.debounceTimeout if greater than zero then a "debounced" listener method is created
	 * @param {Boolean} args.overrideCallback flag whether to override an existing callback method
	 * @param {String} args.callBackPrefix optional prefix for the callback method
	 * @param {Boolean} args.useCapture flag whether to add the listener for the "capture" phase
	 * @param {IListenerMgr} args.manager optional listener manager instance
	 * @returns {Boolean} true if successful; false otherwise
	 */
	static addListener( {
		instance,
		eventName,
		functionName,
		element = void 0,
		debounceTimeout = -1,
		overrideCallback = true,
		callBackPrefix = "",
		useCapture = false,
		manager = null
	} ) {
		if ( !Validator.isString( eventName ) || eventName.length < 2 ||
			 !Validator.isObject( instance ) ||
			 !Validator.isString( functionName ) ||
			 !Validator.isFunction( instance[ functionName ] ) ) {
				return false;
		}
		if ( !( element instanceof HTMLElement ) ) {
			if ( !instance.isRendered ) {
				return false;
			}
			element = instance.element;
		}
		if ( !(element instanceof HTMLElement) ) {
			return false;
		}
		const callbackFunctionName = EventListenerManager.createCallbackFunctionName( eventName, Validator.ensureString(callBackPrefix) );
		if ( !overrideCallback && Validator.isFunction( instance[ callbackFunctionName ] ) ) {
			return false;
		}
		if ( Validator.isPositiveInteger( debounceTimeout, false ) ) {
			instance[ callbackFunctionName ] = Utils.debounce( instance[ callbackFunctionName ], debounceTimeout );
		} else {
			instance[ callbackFunctionName ] = Utils.bind( instance, instance[ functionName ] );
		}
		element.addEventListener( eventName, instance[ callbackFunctionName ], !!useCapture );
		if ( manager instanceof IListenerMgr ) {
			manager.registerListener(instance, eventName, callBackPrefix, element);
		}
		return true;
	}

	/**
	 * removes an event listener
	 * @param {*} instance the host instance providing the listener callback method
	 * @param {String} eventName the event name
	 * @param {HTMLElement} element the target DOM element
	 * @param {String} callBackPrefix the callback prefix
	 * @param {IListenerMgr} manager optional listener manager instance
	 * @returns {Boolean} true if successful; false otherwise
	 */
	static removeListener( instance, eventName, element = void 0, callBackPrefix = "", manager = null ) {
		if ( !Validator.isObject( instance ) ) {
			return false;
		}
		if ( !( element instanceof HTMLElement ) ) {
			if ( !instance.isRendered ) {
				return false;
			}
			element = instance.element;
		}
		const callbackFunctionName = EventListenerManager.createCallbackFunctionName( eventName, Validator.ensureString(callBackPrefix) );
		if ( !Validator.isString( callbackFunctionName ) || !Validator.isFunction( instance[ callbackFunctionName ] ) ) {
			return false;
		}
		element.removeEventListener( eventName, instance[ callbackFunctionName ] );
		instance[ callbackFunctionName ] = void 0;
		delete instance[ callbackFunctionName ];
		if ( manager instanceof IListenerMgr ) {
			manager.unregisterListener(eventName, callBackPrefix);
		}
		return true;
	}

}

console.debug( 'utils/EventListenerManager.js loaded.' );
