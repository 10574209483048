import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import HtmHelper from '../../../../utils/HtmHelper';

export default class ColumnWidthManager extends AttachmentObject {

	constructor( hostObject ) {
		super( hostObject );
		Validator.unmodifiableGetter( {
			hostObject: hostObject,
			getterName: "xtdTbl",
			getCallback: () => {
				return Validator.isObjectPath( hostObject.xthWdg, "xthWdg.xtdTbl" ) ?
					hostObject.xthWdg.xtdTbl : void 0;
			}
		} );
		Validator.unmodifiableGetter( {
			hostObject: hostObject,
			getterName: "columnDragElement",
			getCallback: () => {
				const tableWidget = hostObject.xtdTbl;
				return Validator.isObject( tableWidget ) ? tableWidget.cdrElm : void 0;
			}
		} );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		hostObject.constructor = void 0;
		delete hostObject.constructor;
	}

	setWidth( width ) {
		if ( !Validator.isPositiveNumber( width ) ) {
			return false;
		}
		const widthDifference = width - this.width;
		this.width = width;
		this.update();
		if ( !this.visible ) {
			return false;
		}
		if ( !Validator.isObject( this.xthWdg ) ||
			!Validator.isFunction( this.xthWdg._renderAllCols ) ) {
			return false;
		}
		// let the table head instance render all the columns
		this.xthWdg._renderAllCols();
		if ( Validator.isObject( this.xthWdg.xtwBody ) ) {
			// notify the table body instance
			this.xthWdg.xtwBody.onColumnWidth( this, width, widthDifference );
		}
		return true;
	}

	setTemporaryWidthProperties( domEvent, returnValue ) {
		this.columnWidthOnWidthAdjustmentSpanMouseDown = Number( this.width );
		if ( domEvent instanceof Event ) {
			this.timestampOnWidthAdjustmentSpanMouseDown = Number( domEvent.timeStamp );
		}
		this.dateNowOnWidthAdjustmentSpanMouseDown = Number( Date.now() );
		return returnValue;
	}

	removeTemporaryWidthProperties( returnValue ) {
		this.columnWidthOnWidthAdjustmentSpanMouseDown = void 0;
		delete this.columnWidthOnWidthAdjustmentSpanMouseDown;
		this.timestampOnWidthAdjustmentSpanMouseDown = void 0;
		delete this.timestampOnWidthAdjustmentSpanMouseDown;
		this.dateNowOnWidthAdjustmentSpanMouseDown = void 0;
		delete this.dateNowOnWidthAdjustmentSpanMouseDown;
		return returnValue;
	}

	columnWidthChanged() {
		return this.columnWidthOnWidthAdjustmentSpanMouseDown != this.width;
	}

	logTimePassed() {
		const now = Number( Date.now() );
		return !Validator.isPositiveNumber( this.dateNowOnWidthAdjustmentSpanMouseDown ) ||
			now - this.dateNowOnWidthAdjustmentSpanMouseDown > 400;
	}

	addColumnDragWidgetMouseUpListener() {
		const columnDragElement = this.columnDragElement;
		if ( !( columnDragElement instanceof HTMLElement ) ) {
			return false;
		}
		return Validator.isObject(this.spans) && this.addPrefixListener("mouseup", "onColumnDragWidgetMouseUp", "autoWidth", columnDragElement,  false);
	}

	removeColumnDragWidgetMouseUpListener() {
		const columnDragElement = this.columnDragElement;
		if ( !( columnDragElement instanceof HTMLElement ) ) {
			return false;
		}
		const successfullyRemoved = EventListenerManager.removeListener( this, "mouseup", columnDragElement, "autoWidth" );
		return successfullyRemoved;
	}

	addWidthAdjustmentSpanMouseDownListener() {
		return Validator.isObject(this.spans) && this.addPrefixListener("mousedown", "onWaSpanMouseDown", "autoWidth", this.spans.wa, false);
	}

	removeWidthAdjustmentSpanMouseDownListener() {
		const successfullyRemoved = Validator.isObject( this.spans ) &&
			EventListenerManager.removeListener( this, "mousedown", this.spans.wa, "autoWidth" );
		return successfullyRemoved;
	}

	addWidthAdjustmentSpanDoubleClickListener() {
		return Validator.isObject(this.spans) && this.addPrefixListener("dblclick", "onWidthAdjustmentSpanDoubleClick", "autoWidth", this.spans.wa, false);
	}

	removeWidthAdjustmentSpanDoubleClickListener() {
		const successfullyRemoved = Validator.isObject( this.spans ) &&
			EventListenerManager.removeListener( this, "dblclick", this.spans.wa, "autoWidth" );
		return successfullyRemoved;
	}

	onWidthAdjustmentSpanDoubleClick( domEvent ) {
		this.removeColumnDragWidgetMouseUpListener();
		this.removeTemporaryWidthProperties();
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const minimalRequiredWidth = this._getMinimalRequiredWidth();
		return this.setWidth( minimalRequiredWidth );
	}

	onWaSpanMouseDown( domEvent ) {
		const listenerAdded = this.addColumnDragWidgetMouseUpListener();
		if ( listenerAdded ) {
			this.setTemporaryWidthProperties( domEvent );
		}
		Warner.traceIf( !listenerAdded, `Could not add a "mouseup" event` +
			` listener to the table column drag widget for auto-width adjustment` +
			` purposes.` );
		return listenerAdded;
	}

	onColumnDragWidgetMouseUp( domEvent ) {
		this.removeColumnDragWidgetMouseUpListener();
		if ( !this.isColumnDragWidgetOverThisWidthSpan() ) {
			return this.removeTemporaryWidthProperties( false );
		}
		if ( this.columnWidthChanged() || this.logTimePassed() ) {
			return this.removeTemporaryWidthProperties( false );
		}
		this.removeTemporaryWidthProperties();
		// in this case we do not want the DOM event to be stopped from
		// propagation, so we won't provide the method with it
		return this.onWidthAdjustmentSpanDoubleClick();
	}

	_getMinimalRequiredWidth() {
		if ( !this.available || !this.visible || !this.isRendered ) {
			return void 0;
		}
		const requiredWidth = this
			.getRequiredMinimalWidthToDisplayAllContent( this.element );
		if ( !Validator.isObject( this.spans ) ) {
			// 4px is the "padding-left" of the first span, another 4px is the width
			// of the "width-adjustment" span, both are indicated in the stylesheet
			return !Validator.isPositiveNumber( requiredWidth, false ) ? void 0 :
				requiredWidth + 8;
		}
		let additionalWidth = 0;
		for ( let spanEntry of [ ...Object.entries( this.spans ) ] ) {
			if ( !Validator.isArray( spanEntry ) || spanEntry.length < 2 ) {
				continue;
			}
			if ( spanEntry[ 0 ] == "st" ) {
				// we do not want to include the "text span" into this calculation
				continue;
			}
			const width = HtmHelper.getNumericAttributePixelWidth( spanEntry[ 1 ] );
			if ( !Validator.isPositiveNumber( width, false ) ) {
				continue;
			}
			additionalWidth += width;
		}
		const minimalRequiredWidth = !Validator.isPositiveNumber( requiredWidth ) ?
			additionalWidth : additionalWidth + requiredWidth;
		// 4px is the "padding-left" of the first span, another 4px is the width
		// of the "width-adjustment" span, both are indicated in the stylesheet
		return !Validator.isPositiveNumber( minimalRequiredWidth, false ) ?
			void 0 : minimalRequiredWidth + 8;
	}

	adjustToMinimalRequiredWidth() {
		const minimalRequiredWidth = this._getMinimalRequiredWidth();
		if ( !Validator.isPositiveNumber( minimalRequiredWidth, false ) ) {
			return false;
		}
		this.width = minimalRequiredWidth;
		return true;
	}

	getRequiredMinimalWidthToDisplayAllContent() {
		if ( !Validator.isObject( this.spans ) ) {
			return void 0;
		}
		// const element = this.element;
		const element = this.spans.st;
		if ( !( element instanceof HTMLElement ) ||
			!Validator.isObject( window.pisasales ) ||
			!Validator.isFunction( window.pisasales.getItmMgr ) ) {
			return void 0;
		}
		const itemManager = window.pisasales.getItmMgr();
		if ( !Validator.isObject( itemManager ) ||
			!Validator.isFunction( itemManager.measureText ) ) {
			return void 0;
		}
		const measuredText = itemManager.measureText( element );
		if ( !Validator.isObjectPath( measuredText, "measuredText.sts" ) ||
			!Validator.isPositiveNumber( measuredText.sts.cx ) ) {
			return void 0;
		}
		return Number( measuredText.sts.cx );
	}

	isColumnDragWidgetOverThisWidthSpan() {
		const columnDragElement = this.columnDragElement;
		if ( !( columnDragElement instanceof HTMLElement ) ) {
			return false;
		}
		const widthAdjusmentSpan = Validator.isObject( this.spans ) ?
			this.spans.wa : void 0;
		if ( !( widthAdjusmentSpan instanceof HTMLElement ) ) {
			return false;
		}
		const spanRect = widthAdjusmentSpan.getBoundingClientRect();
		const regionStartX = spanRect.x;
		const regionEndX = spanRect.x + spanRect.width;
		const dragElementRect = columnDragElement.getBoundingClientRect();
		return regionStartX <= dragElementRect.x && dragElementRect.x <= regionEndX;
	}

}
