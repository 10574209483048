import LoggingBase from '../../base/loggingbase';
import PSA from '../../psa';

const DIV_ID = 'psadimanchor';
const LNK_ID = 'psadimhlink';
const IFR_ID = 'psadimiframe';
const ITO_SHORT = 42;
const ITO_LONG = 147;

/**
 * "Plugin" widget, connects PiSA sales Desktop Integration
 */
export default class PgiWdg extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {Object} properties initialization parameters
	 */
	constructor(properties) {
		super('widgets.pgiwdg.PgiWdg');
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ "layout", "onReady", "onRender" ]);
		this.ready = false;
		this.iniPar = null;
		this.wscUrl = '';
		this.rquCre = false;
		this.dimElm = null;
		this.element = null;
		this.parent = rap.getObject(properties.parent);
		// activate "render" event
		rap.on("render", this.onRender);
	}

	/**
	 * destructor
	 */
	destroy() {
		this.ready = false;
		this._psa.rmvDomElm(this.element);
		delete this.element;
		delete this.iniPar;
		delete this.rquCre;
		delete this.wscUrl;
		delete this.dimElm;
	}

	/**
	 * onReady method; called once if the plugin object is rendered the first time
	 */
	onReady() {
		this.ready = true;
		this.log("PGIWDG got ready...");
		if ( this.iniPar ) {
			// we've got a connect call before we became "ready"
			const ini = this.iniPar;
			this.iniPar = null;
			this._doConnect(ini);
		}
	}

	/**
	 * called if the plugin object is rendered
	 */
	onRender() {
		this.onReady();
		rap.off("render", this.onRender);
	}

	/**
	 * layouts the element; almost a dummy here
	 */
	layout() {
		if ( this.ready && this.element ) {
			this.element.style.left = "0px";
			this.element.style.top = "0px";
			this.element.style.width = "0px";
			this.element.style.height = "0px";
		}
	}
	
	/**
	 * called by web server to initiate the desktop side connection
	 */
	connect(args) {
		const ini = args.ini || {};
		if ( this.ready ) {
			// go on!
			this._doConnect(ini);
		} else {
			// not yet "ready" --> store initialization parameters
			this.log("PGIWDG not yet ready, deferring connect attempt...");
			this.iniPar = ini;
		}
	}

	/**
	 * releases the link object or the iframe, respectively
	 */
	release() {
		if ( this.dimElm ) {
			const elm = this.dimElm;
			this.dimElm = null;
			if ( elm.parentElement ) {
				elm.parentElement.innerHTML = '';
			}
		}
	}
	
	/**
	 * creates the target URL
	 * @param {Object} ini initialization parameters
	 * @returns {String} the target URL as string
	 */
	_creTgtUrl(ini) {
		let url = '';
		if ( ini && this._psa.isStr(ini.sid) && this._psa.isStr(ini.url) ) {
			let pfx = '';
			let ptl = '';
			if ( !!ini.tls ) {
				pfx = 'wss';
				ptl = 'psadims';
			} else {
				if ( top.window.location.href.startsWith('https') ) {
					pfx = 'wss';
					ptl = 'psadims';
				} else {
					pfx = 'ws'
					ptl = 'psadim';
				}
			}
			let wsc_url = ini.url;
			let pos = wsc_url.indexOf(':');
			if ( pos !== -1 ) {
				wsc_url = wsc_url.replace(/\:/g, '!');
			}
			wsc_url = wsc_url.replace(/\//g, '$');
			url = ptl + '://' + ini.sid + '@' + pfx + '~' + wsc_url;
		}
		return url;
	}

	/**
	 * returns the initialization timeout
	 * @returns {Number} the initialization timeout in milliseconds
	 */
	_getIniTmo() {
		let tmo = ITO_SHORT;
		if ( this._isReload() ) {
			this.log('PGIWDG: page was reloaded, increasing timeout...');
			tmo = ITO_LONG;
		}
		return tmo;
	}

	/**
	 * connects the desktop integration using a hyperlink
	 * @param {Object} parent RAP parent
	 * @param {Object} ini initialization parameters
	 * @param {Function} cbk callback method
	 * @returns {HTMLElement} the DOM element containing the hyperlink
	 */
	_connectLink(parent, ini, cbk) {
		this.log("PGIWDG creating a hyperlink to trigger the connection...");
		let cre = false;
		const div = document.createElement('div');
		div.id = DIV_ID;
		if ( ini && this._psa.isStr(ini.sid) && this._psa.isStr(ini.url) ) {
			try {
				const src = this._creTgtUrl(ini);
				const lnk = document.createElement('a');
				lnk.id = LNK_ID + ini.sid;
				lnk.href = src;
				lnk.target = '';
				lnk.innerHTML = LNK_ID + ini.sid;
				lnk.style.display = 'none';
				div.appendChild(lnk);
				const dsp = rwt.widgets.Display.getCurrent();
				const cnf = dsp ? (dsp._exitConfirmation || null) : null;
				if ( dsp ) {
					dsp._exitConfirmation = null;
				}
				const self = this;
				const tmo = this._getIniTmo();
				setTimeout(() => {
					try {
						if ( lnk.dispatchEvent ) {
							lnk.addEventListener('click', (e) => {
								const tgt = e.dimurl || '<undefined>';
								self.log('PGIWDG: "click" event received: ' + tgt);
							}, false);
							const event = new MouseEvent('click', {
								button: 0,
								buttons: 1,
								bubbles: true,
								cancelable: false,
								view: window,
							});
							event.dimurl = src;
							self.log('PGIWDG: Dispatching "click" event.');
							lnk.dispatchEvent(event);
						} else if ( lnk.click ) {
							self.log('PGIWDG: calling link.click().')
							lnk.click();
						}
					} finally {
						if ( dsp ) {
							dsp._exitConfirmation = cnf;
						}
					}
					self._iniTmo(ini);
					cbk.apply(self);
				}, tmo);
				this.wscUrl = src;
				this.dimElm = lnk;
				cre = true;
			} catch (e) {
				this.error(e);
				cre = false;
			}
		} else {
			cbk.apply(this);
		}
		this.rquCre = cre;
		parent.append(div);
		div.style.visibility = 'hidden';
		return div;
	}

	/**
	 * connects the desktop integration using an iframe element
	 * @param {Object} parent RAP parent
	 * @param {Object} ini initialization parameters
	 * @param {Function} cbk callback method
	 * @returns {HTMLElement} the DOM element containing the hyperlink
	 */
	_connectIframe(parent, ini, cbk) {
		this.log("PGIWDG creating an iframe to trigger the connection...");
		let cre = false;
		const div = document.createElement('div');
		div.id = DIV_ID;
		if ( ini && this._psa.isStr(ini.sid) && this._psa.isStr(ini.url) ) {
			const	url = this._creTgtUrl(ini);
			const tmo = this._getIniTmo();
			setTimeout(() => {
				const ifr = document.createElement('iframe');
				ifr.id = IFR_ID + ini.sid;
				ifr.src = url;
				this.wscUrl = url;
				this.dimElm = ifr;
				div.appendChild(ifr);
				this._iniTmo(ini);
				cbk.apply(this);
			}, tmo);
			cre = true;
		} else {
			this.rquCre = false;
			cbk.apply(this);
		}
		this.rquCre = cre;
		parent.append(div);
		return div;
	}

	/**
	 * checks whether the Web Client's main page is considered to be "reloaded"
	 * @returns {Boolean} true if a page reload is assumed; false for common page navigation
	 */
	_isReload() {
		let rl = false;
		const pe = performance.getEntriesByType('navigation');
		for ( let i=0 ; !rl && (i < pe.length) ; ++i ) {
			const p = pe[i];
			rl = (p.type !== 'navigate');
		}
		return rl;
	}

	/**
	 * starts the timeout check
	 * @param {Object} ini initialization parameters
	 */
	_iniTmo(ini) {
		const self = this;
		let tmo = 10;
		if ( ini.tmo && (typeof ini.tmo === 'number') ) {
			tmo = ini.tmo;
		}
		setTimeout(() => {
			self._nfySrv("pgitmo", tmo);
		}, tmo*1000);
	}
	
	/**
	 * notifies the web server that the initialization has been done
	 */
	_nfyWdgIni() {
		if ( this.ready ) {
			const param = {};
			param.init = this.rquCre;
			this._nfySrv("init", param);
		}
	}
	
	/**
	 * notifies the web server
	 * @param cod notification code {String}
	 * @param par parameters
	 */
	_nfySrv(cod, par) {
		if ( this.ready && (rwt.remote.ObjectRegistry.getId(this) !== null) ) {
			const param = {};
			param.cod = cod;
			param.par = par;
			rap.getRemoteObject(this).notify("nfyPgi", param);
		}
	}
	
	/**
	 * performs initialization check and notifies the web server 
	 */
	_postInit() {
		let url = 'NO WS URL!';
		let ok = false;
		if ( this._psa.isStr(this.wscUrl) && this.element && this.rquCre ) {
			url = this.wscUrl;
			ok = true;
		}
		if ( ok ) {
			this.log("PGIWDG finally initialized - WS URL=" + url);
		}
		else {
			this.warn("PGIWDG iframe creation failed!");
		}
		this.layout();
		this._nfyWdgIni();
	}
	
	/**
	 * connects the desktop integration
	 * @param {Object} ini initialization parameters
	 */
	_doConnectNow(ini) {
		this.log("PGIWDG connecting *now*...");
		const lnk = !!ini.lnk;
		if ( lnk ) {
			this.element = this._connectLink(this.parent, ini, this._postInit);
		} else {
			this.element = this._connectIframe(this.parent, ini, this._postInit);
		}
	}

	/**
	 * connects the desktop integration
	 * @param {Object} ini initialization parameters
	 */
	_doConnect(ini) {
		const self = this;
		setTimeout(function() {
			self._doConnectNow(ini);
		}, ITO_SHORT);
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget PgiWdg.');
		rap.registerTypeHandler("psawidget.PgiWdg", {
			factory : function(properties) {
				return new PgiWdg(properties);
			},
			destructor : "destroy",
			methods : [ "connect", "release" ],
			events : [ "nfyPgi" ]
		} );
	}
}

console.debug('widgets/pgiwdg/PgiWdg.js loaded.');