import BaseRect from './BaseRect';
import JsPoint from './JsPoint';
import JsSize from './JsSize';

/**
 * class JsRect - a simple rectangle
 */
export default class JsRect extends BaseRect{

	/**
	 * constructs a new instance
	 * @param {Object} args arguments
	 */
	constructor(args) {
		super(typeof args === 'object' ? args : arguments);
	}

	getSize() {
		return new JsSize(this.width, this.height);
	}
	
	intersectRect(rct) {
		const ptl = new JsPoint(rct.x, rct.y);
		const pbr = new JsPoint(rct.x + rct.width, rct.y + rct.height);
		return this.containsPt(ptl) || this.containsPt(pbr);
	}
	
	toString() {
		let x2 = this.x + this.width;
		let y2 = this.y + this.height;
		return "{JsRect (" + this.x + "," + this.y +"), (" + x2 + "," + y2 +") - (" + this.width + "x" + this.height + ")}";
	}
}

console.debug('utils/JsRect.js loaded.');