import BaseSize from './BaseSize';
import BasePoint from './BasePoint';

/**
 * class JsSize - a simple size
 */
export default class JsSize extends BaseSize {

	/**
	 * construcs a new instance
	 * @param {*} args arguments
	 */
	constructor(args) {
		super(typeof args === 'object' ? args : arguments);
	}

	/**
	 * moves the specified point by the distances of this instance
	 * @param {BasePoint} pt the source point
	 * @returns {BasePoint} the new point
	 */
	movePt(pt) {
		return pt.move(this.cx, this.cy);
	}

	toString() {
		return "{JsSize (" + this.cx + "," + this.cy + ")}";
	}
}

console.debug('utils/JsSize.js loaded.');