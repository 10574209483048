export default class BaseRect {

   	/**
	 * constructs a new instance
	 * @param {Object} args arguments
	 */
	constructor(args) {
		let x = 0;
		let y = 0;
		let w = 0;
		let h = 0;
		if ( args.length == 4 ) {
			x = args[0];
			y = args[1];
			w = args[2];
			h = args[3];
		} else {
			x = args.x;
			y = args.y;
			w = args.w;
			h = args.h;
		}
		// check types
		if ( (typeof x !== "number") || (typeof y !== "number") ||
				(typeof w !== "number") || (typeof h !== "number") ||
				(w < 0) || (h < 0) ) {
			throw new Error("Invalid arguments");
		}
		// initialize
		this.x = x;
		this.y = y;
		this.width = w;
		this.height = h;
	}

	get top() {
		return this.y;
	}

	get bottom() {
		return this.y + this.height;
	}

	get left() {
		return this.x;
	}

	get right() {
		return this.x + this.width;
	}

	getWidth() {
		return this.width;
	}
	
	getHeight() {
		return this.height;
	}
	
	isEmpty() {
		return this.width == 0 || this.height == 0;
	}
	
	contains(px, py) {
		return (this.x <= px) && (px <= (this.x + this.width)) && (this.y <= py) && (py <= (this.y + this.height));
	}

	containsPt(pt) {
		return this.contains(pt.x, pt.y);
	}

	containsPt(pt) {
		return this.contains(pt.x, pt.y);
	}


}