import EventListenerManager from '../../../utils/EventListenerManager';
import Validator from '../../../utils/Validator';

export default class ViewToggleButton {

	constructor( parentObject, parameters ) {
		this._creationParameters = {
			parentObject: parentObject,
			parameters: parameters
		};
		this.create();
	}

	create() {
		this.renderBlocked = false;
	}

	destroy() {
		this.ruin();
		delete this._creationParameters;
	}

	get currentRect() {
		if ( !this.isRendered ) return void 0;
		return DOMRectReadOnly.fromRect( this.element.getBoundingClientRect() );
	}

	getNewToggleButton( listIcon = false ) {
		let toggleButton = document.createElement( "div" );
		[ "vieTglBtn", "icoDscDiv", "vieTglRgt", "no-print" ]
		.forEach( className => { toggleButton.classList.add( className ); } );
		toggleButton.style.position = "absolute";
		toggleButton.style.right = 0;
		let icon = document.createElement( "i" );
		icon.classList.add( "fa" );
		icon.classList.add( ( !!listIcon ? "fa-list" : "fa-table" ) );
		toggleButton.appendChild( icon );
		return toggleButton;
	}

	getUpdatedToggleButton( listIcon = false ) {
		const toggleButtonContainer = document.createElement( "div" );
		toggleButtonContainer.classList.add( "toggle-button-container" );
		const roundedCornerButton = document.createElement( "div" );
		roundedCornerButton.classList.add( "rounded_corner_button" );
		const iconDescriptor = document.createElement( "div" );
		iconDescriptor.classList.add( "icoDscDiv" );
		const icon = document.createElement( "i" );
		icon.classList.add( "fa" );
		icon.classList.add( ( !!listIcon ? "fa-list" : "fa-table" ) );
		iconDescriptor.appendChild( icon );
		roundedCornerButton.appendChild( iconDescriptor );
		toggleButtonContainer.appendChild( roundedCornerButton );
		return toggleButtonContainer;
	}

	toggleIcon() {
		if ( !this.isRendered ) return;
		const icon = this.icon;
		if ( !( icon instanceof HTMLElement ) ) return;
		icon.classList.contains( "fa-list" ) ? this.changeToTableIcon( icon ) :
			this.changeToListIcon( icon );
	}

	changeToTableIcon( icon = void 0 ) {
		if ( !this.isRendered ) return;
		if ( !( icon instanceof HTMLElement ) ) {
			icon = this.icon;
			if ( !( icon instanceof HTMLElement ) ) return;
		}
		icon.classList.remove( "fa-list" );
		icon.classList.add( "fa-table" );
	}

	changeToListIcon( icon = void 0 ) {
		if ( !this.isRendered ) return;
		if ( !( icon instanceof HTMLElement ) ) {
			icon = this.icon;
			if ( !( icon instanceof HTMLElement ) ) return;
		}
		icon.classList.remove( "fa-table" );
		icon.classList.add( "fa-list" );
	}

	render( force = false ) {
		if ( this.renderBlocked && !force ) return;
		if ( this.isRendered ) {
			if ( !force ) return;
			// because the render is forced, it should proceed even if there already
			// is an element, so the already existing element should be removed first
			this.removeSelf();
		}
		const parentElement = this.parentElement;
		if ( !( parentElement instanceof HTMLElement ) ) return void 0;
		// this.element = this.getNewToggleButton( true );
		this.element = this.getUpdatedToggleButton( true );
		parentElement.appendChild( this.element );
		this.addListeners();
	}

	addListeners() {
		this.addListener( "click", "onClick" );
	}

	removeListeners() {
		this.removeListener( "click" );
	}

	onClick( evt, parameters = void 0 ) {
		if ( !this.isRendered ) return;
		evt.stopPropagation();
		evt.preventDefault();
		this.toggleIcon();
		const parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) {
			return;
		}
		parentObject.iniViewMode( !parentObject.rtpMode, true );
	}

	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	get icon() {
		if ( !this.isRendered ) {
			return void 0;
		}
		let iconsList = this.element.getElementsByTagName( "I" );
		if ( !Validator.isIterable( iconsList ) ) {
			return void 0;
		}
		iconsList = [ ...iconsList ];
		if ( iconsList.length < 1 ) {
			return void 0;
		}
		const icon = iconsList[ 0 ];
		return icon instanceof HTMLElement ? icon : void 0;
	}

	get parameters() {
		if ( !Validator.isObject( this._creationParameters ) ) return void 0;
		return this._creationParameters.parameters;
	}

	get parentObject() {
		if ( !Validator.isObject( this._creationParameters ) ) return void 0;
		return this._creationParameters.parentObject;
	}

	get parentObjectElement() {
		const parentObject = this.parentObject;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		return parentObject.element;
	}

	get parentElement() {
		const parentObjectElement = this.parentObjectElement;
		if ( parentObjectElement instanceof HTMLElement ) return parentObjectElement;
		if ( !this.isRendered ) return void 0;
		return this.element.parentElement instanceof HTMLElement ?
			this.element.parentElement : void 0;
	}

	ruin() {
		this.removeSelf();
	}

	removeSelf() {
		if ( !this.isRendered ) return;

		this.removeListeners();

		for ( let childNode of this.element.childNodes ) {
			// childNode.remove();
			childNode.innerHTML = "";
			this.element.removeChild( childNode );
		}
		this.element.innerHTML = "";

		let element = this.element;
		this.element = void 0;
		delete this.element;
		element.innerHTML = "";
		element.remove();
		element = void 0;
	}

	addListener( eventName, functionName, element = void 0, debounceTimeout ) {
		return EventListenerManager.addListener( {
			instance: this,
			eventName: eventName,
			functionName: functionName,
			element: element,
			debounceTimeout: debounceTimeout
		} );
	}

	removeListener( eventName, element = void 0 ) {
		return EventListenerManager.removeListener( this, eventName, element );
	}

	get blockScreenManager() {
		if ( !Validator.isObject( window.pisasales ) ||
			!Validator.isFunction( pisasales.getBscMgr ) ) return void 0;
		const blockScreenManager = pisasales.getBscMgr();
		return Validator.isObject( blockScreenManager ) ? blockScreenManager : void 0;
	}

	_showOrHideBlockScreen( doShow = true ) {
		const blockScreenManager = this.blockScreenManager;
		if ( !Validator.isObject( blockScreenManager ) ||
			!Validator.isFunction( blockScreenManager.shwBlkScr ) ) return false;
		blockScreenManager.shwBlkScr( { mod: "trn", sbc: !!doShow } );
		return true;
	}

	showBlockScreen() {
		return this._showOrHideBlockScreen( true );
	}

	hideBlockScreen() {
		return this._showOrHideBlockScreen( false );
	}

}

console.debug( 'widgets/xtw/parts/ViewToggleButton.js loaded.' );
