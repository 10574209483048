import Validator from '../../../../utils/Validator';
import LoggingBase from '../../../../base/loggingbase';
import XtwModel from '../../model/XtwModel';
import XtwBody from '../../XtwBody';
import XtwTbl from '../../XtwTbl';
import PSA from '../../../../psa';
import UIRefresh from '../../../../utils/UIRefresh';
import { UI_SCROLL_REQUEST } from '../../XtwBodyConst';

export default class XtwBodyScrollingExtension extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {XtwBody} xtb the XTW body instance
	 */
	constructor( xtb ) {
		super('widgets.xtw.XtwBodyScrollingExtension');
		this._xtwBody = xtb;
		this._hscPos = 0;
		this._vscPos = 0;
		this._rquHsc = 0;
		this._rquVsc = 0;
		this._topIdx = -1;
		this._cbkCount = 0;
		this._inScrUpd = false;
		this._afterUpdateCbk = null;
		// set ID
		Object.defineProperty(this, '_id', {
			value: xtb.wdgId,
			writable: false,
			configurable: true,
			enumerable: false
		});
		// get model instance
		Object.defineProperty(this, '_model', {
			value: xtb.model,
			writable: false,
			configurable: true,
			enumerable: false
		});
		// bind _updateView()
		this._updateViewCallback = PSA.getInst().bind(this, this._updateView);
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
	}

	/**
	 * @returns {XtwBody} the table body widget
	 */
	get xtwBody() {
		return this._xtwBody;
	}

	/**
	 * @returns {XtwTbl} the table widget instance
	 */
	get xtdTbl() {
		return this.xtwBody.xtdTbl;
	}

	/**
	 * @returns {XtwModel} the data model
	 */
	get model() {
		return this._model;
	}

	/**
	 * @returns {String} the instance ID
	 */
	get id() {
		return this._id;
	}

	/**
	 * @returns {Boolean} true if a scrolling operation is in progress; false otherwise
	 */
	get inScrUpd() {
		return this._inScrUpd;
	}

	/**
	 * @returns {Function} the "after update" callback function
	 */
	get afterUpdateCbk() {
		return this._afterUpdateCbk;
	}

	/**
	 * the "after update" callback
	 * @param {Function} func the callback function
	 */
	set afterUpdateCbk(func) {
		if ( typeof func === 'function' || func === null ) {
			this._afterUpdateCbk = func;
		}
	}

	/**
	 * @returns {Number} the current horizontal scrolling position
	 */
	get hscPos() {
		return this._hscPos;
	}

	/**
	 * @param {Number} hsp new horizontal scrolling position
	 */
	set hscPos(hsp) {
		this._hscPos = hsp;
	}

	/**
	 * @returns {Number} the current vertical scrolling position
	 */
	get vscPos() {
		return this._vscPos;
	}

	/**
	 * @param {Number} vsp new vertical scrolling position
	 */
	set vscPos(vsp) {
		this._vscPos = vsp;
	}

	/**
	 * @returns {Number} the required horizontal scrolling position
	 */
	get rquHsc() {
		return this._rquHsc;
	}

	/**
	 * @param {Number} rqu the new required horizontal scrolling position
	 */
	set rquHsc(rqu) {
		this._rquHsc = rqu;
	}

	/**
	 * @returns {Number} the required vertical scrolling position
	 */
	get rquVsc() {
		return this._rquVsc;
	}

	/**
	 * @param {Number} rqu the new required vertical scrolling position
	 */
	set rquVsc(rqu) {
		this._rquVsc = rqu;
	}

	/**
	 * @returns {Number} the current top index
	 */
	get topIdx() {
		return this._getEffTopIdx();
	}

	/**
	 * @param {Number} new top index
	 */
	set topIdx(tix) {
		this._topIdx = tix;
	}
	
	get widgetHorizontalSelection() {
		if ( !Validator.isObject( this.xtdTbl.wdgSlh ) ) {
			return null;
		}
		return this.xtdTbl.wdgSlh;
	}

	/**
	 * @returns {Number} the current visible width
	 */
	get visWdt() {
		return this.xtwBody.visWdt;
	}

	/**
	 * @returns {Array} an array with all row items
	 */
	get rowItems() {
		return this.xtwBody.rowItems;
	}

	/**
	 * resets scrolling settings
	 * @param {Number} h optional horizontal scrolling position
	 * @param {Number} v optional vertical scrolling position
	 */
	reset(h = -1, v = -1) {
		if ( !this.inScrUpd ) {
			this.topIdx = -1;
			this.rquHsc = this.hscPos = ((typeof h === 'number') ? h : -1);
			this.rquVsc = this.vscPos = ((typeof v === 'number') ? v : -1);
		} else {
			this.trace('Reset rejected since a scrolling operation is still in progress!');
		}
	}

	/**
	 * resets the horizontal scrolling position so that full refresh is forced
	 * the next time a horizontal scrolling is triggered
	 */
	resetHScroll() {
		this.rquHsc = this.hscPos = -1;
	}

	/**
	 * triggers a scroll update
	 * @param {Number} sx new horizontal scrolling position
	 * @param {Number} sy new vertical scrolling position
	 */
	triggerScrollUpd( sx, sy ) {
		let hit = false;
		if ( this.rquHsc !== sx ) {
			this.log(`Got horizontal scroll request to position ${sx} (previous=${this.rquHsc}).`);
			this.rquHsc = sx;
			hit = true;
		}
		if ( this.rquVsc !== sy ) {
			this.log(`Got vertical scroll request to position ${sy} (previous=${this.rquVsc}).`);
			this.rquVsc = sy;
			hit = true;
		}
		if ( hit ) {
			if ( !this.inScrUpd ) {
				this._inScrUpd = true;
				this._scrDoUpd();
			} else {
				this.log(`UI refresh request #${this._cbkCount} still pending...`);
			}
		}
	}

	/**
	 * @returns {Number} the effective top index
	 */
	_getEffTopIdx() {
		if ( this.inScrUpd && (this._vscPos !== this._rquVsc) ) {
			return Math.max(this._rquVsc, 0);
		}
		return Math.max(this._topIdx, 0);
	}

	/**
	 * creates a render request ID from a name
	 * @param {String} name the actual request name
	 * @returns the render request ID
	 */
	_getRequestId(name) {
		return this.id + '-' + name;
	}

	/**
	 * finally updates the view according to current scrolling positions
	 */
	_scrDoUpd() {
		const rid = this._getRequestId(UI_SCROLL_REQUEST);
		const count = ++this._cbkCount;
		this.log(`Registering UI refresh request "${rid} #${count}".`);
		UIRefresh.getInstance().addRequest(rid, this._updateViewCallback);
	}

	/**
	 * updates the view according to current scrolling positions
	 * @returns {Boolean} true if the UI was updated due to changed scrolling positions; false if no update was required
	 */
	_updateView() {
		if ( !this.alive || this.dying ) {
			return false;
		}
		let result = false;
		this.log(`Running UI refresh request #${this._cbkCount}...`);
		const rqu_hpos = Math.max(this.rquHsc, 0);
		const rqu_vpos = Math.max(this.rquVsc, 0);
		try {
			if ( rqu_hpos !== this.hscPos ) {
				// we must scroll horizontally
				this.log(`Scrolling horizontally to position ${rqu_hpos}.`);
				this._scrHorz( rqu_hpos);
				result = true;
			}
			if ( rqu_vpos !== this.vscPos ) {
				// we must scroll vertically
				this.log(`Scrolling vertically to position ${rqu_vpos}.`);
				const up = (this.vscPos >= 0) && (rqu_vpos < this.vscPos);
				const model = this.model;
				const cti = Math.max(this._topIdx, 0);
				const tix = Math.max( model.getNextTopIdx( rqu_vpos, cti, up ), 0 );
				if ( tix !== this._topIdx ) {
					// update all row items
					const diff = tix - cti;
					if ( (diff !== 0) && this.logger.isDebugEnabled() ) {
						this.log(`XTW ${this.xtwBody.wdgId} - vertically scrolled by ${diff} rows; new top index = ${tix}.`);
					}
					this.vscPos = rqu_vpos;
					this.topIdx = tix;
					if ( diff !== 0 ) {
						this.xtwBody.scrollBy(diff, tix);
					} else {
						this.xtwBody._updDomElm( this.xtwBody.visHgt );
						this.xtwBody._updAllRowItems();
					}
					result = true;
				}
			}
		} finally {
			this.hscPos = rqu_hpos;
			this.vscPos = rqu_vpos;
			this._inScrUpd = false;
		}
		this.xtwBody.triggerUIRefresh(true);
		try {
			const func = this.afterUpdateCbk;
			this._afterUpdateCbk = null;
			if ( func ) {
				func();
			}
		}
		finally {
			this._afterUpdateCbk = null;
		}
		return result;
	}

	/**
	 * performs horizontal scrolling if required
	 * @param {Number} hsp new horizontal scroll position
	 */
	_scrHorz( hsp ) {
		this.hscPos = hsp;
		if ( this.visWdt > 0 ) {
			let eff_pos = hsp;
			if ( hsp > 0 ) {
				const vwd = this.visWdt;
				const full_wdt = this.xtwBody._getFullWidth();
				if ( ( vwd + hsp ) > full_wdt ) {
					eff_pos -= vwd + hsp - full_wdt;
					this.hscPos = this.rquHsc = eff_pos; // the calling method sets 'this.hscPos' in its finally block, so we must force the correct value!
				}
			}
			this.rowItems.forEach( ( ri ) => {
				ri.onHScroll( eff_pos );
			} );
		}
	}

	addHorizontalScrollAfterModelData( evt, callBackPrefix = "" ) {
		// if ( !Validator.isString( callBackPrefix ) ) {
		// 	callBackPrefix = "";
		// }
		// const callBackId = Validator.generateRandomString( callBackPrefix );
		// const callback = () => {
		// 	this.scrollHorizontallyAfterModelDataCallback( evt, callBackId );
		// }
		// if ( !Validator.isMap( this._afterModelDataCallbacks ) ) {
		// 	this._afterModelDataCallbacks = new Map();
		// }
		// const sameTypeCallbacks = !Validator.isString( callBackPrefix ) ?
		// 	Array.from( [] ) : [ ...this._afterModelDataCallbacks.keys() ]
		// 	.filter( key => key.startsWith( callBackPrefix ) );
		// for ( let callbackKey of sameTypeCallbacks ) {
		// 	this._afterModelDataCallbacks.delete( callbackKey );
		// }
		// this._afterModelDataCallbacks.set( callBackId, callback );
		return true;
	}

	scrollHorizontallyAfterModelDataCallback( evt, callBackId ) {
		if ( !Validator.is( this.xtdTbl, "XtwTbl" ) ) {
			return false;
		}
		this.xtdTbl._onHScroll();
		this._scrHorz( this.hscPos );
		if ( !Validator.isString( callBackId ) ||
			!Validator.isMap( this._afterModelDataCallbacks ) ) {
			return true;
		}
		this._afterModelDataCallbacks.delete( callBackId );
		return true;
	}

	onHorizontalScrollbarHidden() {
		this.rquHsc = 0;
		this._scrHorz( 0 );
		return true;
	}

	onHorizontalScrollbarShown() {
		const widgetHorizontalSelection = this.widgetHorizontalSelection;
		if ( !Validator.isObject( widgetHorizontalSelection ) ||
			!Validator.isValidNumber( widgetHorizontalSelection._selection ) ) {
			return false;
		}
		this.rquHsc = widgetHorizontalSelection._selection;
		this._scrHorz( widgetHorizontalSelection._selection );
		return true;
	}

	onTblMouseWheel( domEvent ) {
		if ( this.isRowTpl ) {
			return true;
		}
		if ( Validator.isObject( this.inputField ) &&
			Validator.isFunction( this.inputField.onInputScroll ) ) {
			return this.inputField.onInputScroll( domEvent );
		}
		return true;
	}

}
