export default class BasePoint {
 
	/**
	 * constructs a new instance
	 * @param {*} args arguments
	 */
	 constructor(args) {
		let x = 0;
		let y = 0;
		if ( args.length == 2 ) {
			x = args[0];
			y = args[1];
		} else {
			x = args.x;
			y = args.y;
		}
		// check types
		if ( (typeof x !== "number") || (typeof y !== "number") ) {
			throw new Error("Invalid arguments");
		}
		// initialize
		this.x = x;
		this.y = y;
	}

	/**
	 * creates new point that has an offset to this instance
	 * @param {Number} dx x-offset
	 * @param {Number} dy y-offset
	 * @returns the new point
	 */
    move(dx, dy) {
        return new BasePoint(this.x+dx, this.y+dy);
    }

}