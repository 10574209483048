import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import JsRect from '../../utils/JsRect';
import JsPoint from '../../utils/JsPoint';

/** width of sort indicator icon */
const SRT_ICO_WDT = 15;
/** right border offset */
const RGT_BRD_OFS = 2;

/**
 * class TblMgr - client side implementation of table manager widget
 */ 
export default class TblMgr {
	
	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */ 
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll( this, ["layout", "onReady", "onSend", "onRender"] );
		this.classname =  "psawidget.TblMgr";
		this.dbgLog =  false;
		this.ready =  false;
		this.itmMgr =  null;
		this.tblCtl =  null;
		this.idwTbl =  null;
		this.tblRct =  null;
		this.scrPos =  null;
		this.curEdt =  null;
		this.edtRct =  null;
		this.hdrBgc =  null;
		this.rszOsv =  null;
		this.fixWdt =  0;
		this.lngIcos =  [];
		this.rdrRqu =  [];
		const parent = rap.getObject( properties.parent );
		this.parent = parent;
		this.element = document.createElement( "div" );
		this.parent.append( this.element );
		this.parent.addListener( "Resize", this.layout );
		this.itmMgr = ItmMgr.getInst();
		const self = this;
		const cwd = parent.getData( "pisasales.CSTPRP.CWD" );
		if ( cwd ) {
			const idt = cwd.tbl;
			if ( idt ) {
				const tbl = rwt.remote.ObjectRegistry.getObject( idt );
				if ( tbl ) {
					this.tblCtl = tbl;
					this.idwTbl = idt;
					this.tblRct = new JsRect( tbl.getLeft(), tbl.getTop(), tbl.getWidth(), tbl.getHeight() );
					this.scrPos = new JsPoint( 0, 0 );
					this._initRC( this.tblCtl.getRowContainer() );
					this.sngSel = !!parent.getData( "pisasales.CSTPRP.SNG" );
					tbl.addEventListener( "mousemove", this._onMouMov, this );
					tbl.addEventListener( "mousedown", this._onMouDow, this );
					tbl.addEventListener( "keydown", this._onKeyDown, this );
					tbl.addEventListener( "scrollLeftChanged", this._onScroll, this );
					tbl.addEventListener( "topItemChanged", this._onScroll, this );
				}	
			}	
		}	
		// activate "render" event
		rap.on( "render", this.onRender );
		if ( window.ResizeObserver ) {
			// create resize observer
			const rso = new ResizeObserver((elms, osv) => {
				self._onColResize(elms, osv);
			});	
			this.rszOsv = rso;
		}	

	}	

	destroy () {
		rap.off( "render", this.onRender );
		if ( this.rszOsv ) {
			this.rszOsv.disconnect();
		}
		this.itmMgr = null;
		if ( this.tblCtl ) {
			const tbl = this.tblCtl;
			this.tblCtl = null;
			this.idwTbl = null;
			this.tblRct = null;
			this.scrPos = null;
			this._doneRC( this.rc );
			this.rc = null;
			tbl.removeEventListener( "mousedown", this._onMouMov, this );
			tbl.removeEventListener( "mousedown", this._onMouDow, this );
			tbl.removeEventListener( "keydown", this._onKeyDown, this );
			tbl.removeEventListener( "scrollLeftChanged", this._onScroll, this );
			tbl.removeEventListener( "topItemChanged", this._onScroll, this );
		}
		if ( this.lngIcos ) {
			for ( let ele in this.lngIcos ) {
				for ( let evt in this.lngIcos[ele].trackedEvents ) {
					this.lngIcos[ele].removeEventListener( evt, this.lngIcos[ele].trackedEvents[evt][0] );
				}
			}
			this.lngIcos = [];
		}
		if ( this.element && this.element.parentNode ) {
			this.element.parentNode.removeChild( this.element );
		}
	}

	_initRC( rc ) {
		if ( rc ) {
			this._hookRC( rc );
		}	
		this.rc = rc;
	}
	
	_hookRC( rc ) {
		if ( rc._container && rc._container.length && ( rc._container.length > 0 ) ) {
			for ( let i = rc._container.length - 1; i >= 0; --i ) {
				this._hookRC( rc._container[i] );
			}	
		} else if ( typeof rc.requestToolTipText === 'function' ) {
			const psa = this._psa;
			rc._org_rquttx = rc.requestToolTipText;
			rc.requestToolTipText = function () {
				const qvm = psa.getQvwMgr();
				if ( qvm ) {
					if ( qvm.isQvwDis() ) {
						// no tooltip, no QickView, nothing!
						return;
					}	
					qvm.sndCurPos();
				}	
				return rc._org_rquttx.call( rc, arguments );
			};	
		}	
	
	}	
	_doneRC( rc ) {
		if ( rc ) {
			this._unhookRC( rc );
		}	
	}
	
	_unhookRC( rc ) {
		if ( rc._container && rc._container.length && ( rc._container.length > 0 ) ) {
			for ( var i = rc._container.length - 1; i >= 0; --i ) {
				this._unhookRC( rc._container[i] );
			}	
		} else if ( typeof rc._org_rquttx === 'function' ) {
			const fnc = rc._org_rquttx;
			rc.requestToolTipText = fnc;
			delete rc._org_rquttx;
		}	
	}
	
	onReady() {
		this.ready = true;
		this.layout();
		this._iniTblWdg();
	}

	onRender() {
		if ( !this.ready && this.element && this.element.parentNode ) {
			this.onReady();
		}	
		if ( this.ready ) {
			this._onRdrRqu();
		}	
	}	
	
	onSend() {
		// do nothing so far...
	}
	
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			this.element.style.left = area[0] + "px";
			this.element.style.top = area[1] + "px";
			this.element.style.width = area[2] + "px";
			this.element.style.height = area[3] + "px";
		}
	}

	/**
	 * Sets the color of a table-column-header.
	 * This method is quite specific for table headers.
	 */
	setHdrColBkgClr( args ) {
		const color = args.bgc || null;
		const idw = args.idw || '';
		if ( color && idw ) {
			this.itmMgr.setHdrColBkgClr( idw, color );
		}
	}
	
	/**
	 * sets the text color of a table column header
	 */
	setHdrColTxtClr( args ) {
		const color = args.txc || null;
		const idw = args.idw || '';
		if ( color && idw ) {
			this.itmMgr.setHdrColTxtClr( idw, color );
		}
	}

	/**
	 * sets the background color of the table header
	 * @param {Object} args
	 */
	setHdrClr( args ) {
		const color = args.bgc || null;
		if ( color ) {
			this.hdrBgc = color;
			this.itmMgr.setQxBgkClr( this.tblCtl._header._dummyColumn, color );
		}
	}
	
	/**
	 * sets the header font
	 */
	setHdrFnt( args ) {
		const fnt = args.hdrfnt || null;
		if ( fnt ) {
			this.tblCtl._header.__inherit$font.__size = fnt.siz + "px";
			this.tblCtl._header.__inherit$font.__family = fnt.ffm;
		}
	}

	/**
	 * sets a HTML/CSS based header icon for single a table column.
	 */
	setHdrIco( args ) {
		const idw = args.idw || ''
		const wdg = rwt.remote.ObjectRegistry.getObject( idw );
		if ( wdg ) {
			const ico = args.ico || null;
			if ( ico ) {
				if ( ico.typ === 'DSC' ) {
					// a font based icon
					const dsc = ico.img || null;
					if ( dsc ) {
						const cls = dsc.cssCls;
						const icn = dsc.icoNam;
						const siz = dsc.icoSiz;
						const clr = dsc.icoClr;
						const fnm = dsc.fntNam;
						if ( ( typeof cls === "string" ) && ( typeof icn === "string" ) && ( typeof siz === "number" ) ) {
							const pos = [];
							pos.x = 3;
							pos.y = Math.round( ( wdg._grid._headerHeight - siz ) / 2 );
							const div = this.itmMgr._creDiv( 'center', siz, pos );
							const icd = this.itmMgr._creDscIco( cls, icn, siz, clr, fnm, null, false );
							div.appendChild( icd );
							const mulcel = wdg._grid._header._getLabelByColumn( wdg );
							mulcel.$el.append( div );
						}
					}
				} else if ( ico.svg && (ico.typ === 'IMG') ) {
					// a SVG image
					const svg = ico.img || null;
					if ( svg ) {
						const siz = 16;								// fixed size!
						const pos = [];
						pos.x = 3;
						pos.y = Math.round( ( wdg._grid._headerHeight - siz ) / 2 );
						const img = this.itmMgr.creSvgImg(svg);
						if ( img ) {
							img.setAttribute('width', '' + siz);		// fixed size!
							img.setAttribute('height', '' + siz);		// fixed size!
							const div = this.itmMgr._creDiv( 'center', siz, pos );
							div.appendChild(img);
							const mulcel = wdg._grid._header._getLabelByColumn( wdg );
							mulcel.$el.append( div );
						}
					}
				}
			}
		}
	}

	/**
	 * Creates a button-like icon to turn on international switch for table columns
	 */
	setLngHdrIco( args ) {
		const idw = args.idw;
		const wdg = rwt.remote.ObjectRegistry.getObject( idw );
		if ( wdg ) {
			const dsc = args.ico;
			if ( dsc ) {
				const cls = dsc.cssCls;
				const icn = dsc.icoNam;
				const siz = dsc.icoSiz;
				const fnm = dsc.fntNam;
				if ( ( typeof cls === "string" ) && ( typeof icn === "string" ) && ( typeof siz === "number" ) ) {
					const pos = {};
					/* adding extra +15 to the size cause of the sort-indicator */
					pos.x = Math.round( wdg.getWidth() - ( siz + SRT_ICO_WDT ) );
					pos.y = Math.max(Math.round( ( wdg._grid._headerHeight - siz - 1) / 2 ), 0);
					const lngIcoDiv = this.itmMgr._creDiv( 'center', siz, pos );
					const ico = this.itmMgr._creDscIco( cls, icn, siz, [255, 255, 255], fnm, null, false );
					ico.onmouseover = function () { ico.style.color = "black"; };
					ico.onmouseout = function () { ico.style.color = "white"; };
					lngIcoDiv.appendChild( ico );
					/* it must be on top of the table header construct */
					lngIcoDiv.style.zIndex = "33333333";
					lngIcoDiv.className = 'tblHdrLngIco';
					/* don't forget: this event handler function is bound to <THIS>*/
					const hdler = this._psa.bind( this, function ( evt ) {
						this._nfySrv( "tblLngChg", this._lngBtnDwn( evt ), true );
						evt.preventDefault();
						evt.stopPropagation();
					} );
					lngIcoDiv.onclick = hdler;
	
					const self = this;
					const mulcel = wdg._grid._header._getLabelByColumn( wdg );
					mulcel.$el.append( lngIcoDiv );
					const hdr_elm = mulcel.$el.get(0);
					if ( hdr_elm && this.rszOsv ) {
						this.rszOsv.observe(hdr_elm);
						wdg.addEventListener('update', () => {
							self._onColUpdate(wdg, hdr_elm, lngIcoDiv)
						});
						this._onColUpdate(wdg, hdr_elm, lngIcoDiv);
					}
				}
			}
		}
	}

	tglLngCol(args) {
		const idw = args.idw || '';
		const cix = args.cix || null;
		const wdg = rwt.remote.ObjectRegistry.getObject( idw );
		if ( wdg ) {
			const mulcel = wdg._grid._header._getLabelByColumn( wdg );
			if ( mulcel ) {
				const div = mulcel.$el.get(0);
				const chl = div.getElementsByClassName('tblHdrLngIco');
				if ( chl && chl.length > 0 ) {
					const ico = chl[0];
					if ( typeof ico.onclick === 'function' ) {
						const evt = {};
						evt.preventDefault = function() { /* do nothing */ }
						evt.stopPropagation = function() { /* do nothing */ }
						evt.cix = cix;
						evt.target = ico.firstElementChild || ico;
						ico.onclick.call(this, evt);
					}
				}
			}
		}
	}

	/**
	 * sets the alignment of the title/header cell
	 */
	setHdrTtlAln( args ) {
		const idw = args.idw;
		const wdg = rwt.remote.ObjectRegistry.getObject( idw );
		if ( wdg ) {
			const aln = args.aln;
			if ( this._psa.isStr( aln ) ) {
				const cel = wdg._grid._header._getLabelByColumn( wdg );
				if ( cel ) {
					const elm = cel.$el.get();
					if ( elm ) {
						let div = null;
						if ( elm.length ) {
							div = elm[0];
						} else {
							div = elm;
						}
						if ( div && div.style ) {
							div.style.textAlign = aln;
							if ( aln === 'center' ) {
								// try to really center things a bit :-)
								this._tryCenElm(div);
							}
						}
					}
				}
			}
		}
	}

	/**
	 * tries to center the content
	 * @param {HTMLElement} div the container element
	 */
	_tryCenElm(div) {
		const elm = [];
		for ( let i=0 ; i < div.childElementCount ; ++i ) {
			const chl = div.children[i];
			const rct = chl.getBoundingClientRect();
			if ( rct.width > 0 && rct.height > 0 ) {
				elm.push(chl);
			}
		}
		if ( elm.length === 1 ) {
			// only exactly one relevant child element
			const bdr = div.getBoundingClientRect();
			const chl = elm[0];
			const rct = chl.getBoundingClientRect();
			if ( rct.width <= bdr.width ) {
				chl.style.width = 'inherit';
			}
			if ( rct.height !== bdr.height ) {
				const top = Math.floor((bdr.height - rct.height) / 2);
				chl.style.top = '' + top + 'px';
			}
			else {
				chl.style.top = '0px';
			}
		}
	}

	/**
	 * informs the client side, if a hyperlink(anchor-tag) or just a DIV-tag has been clicked.
	 */
	clcTxtSiz( evt ) {
		const tgt = evt.getTarget();
		if ( tgt ) {
			const bn = tgt.basename || '';
			const cn = tgt.classname || '';
			if ( (bn === 'Button') || cn.includes('Button') ) {
				// *not* for us! probably a combobox's dropdown button
				return;
			}
		}
		const tag = evt.getDomTarget().tagName;
		let hypLnk = false;
		if ( ( tag == "A" ) || ( tag == "I" ) || ( tag == "IMG" ) ) {
			hypLnk = true;
		}
		if ( evt.getDomTarget().tagName == "DIV" ) {
			hypLnk = false;
		}
		const par = {};
		par["x"] = evt.getClientX();
		par["y"] = evt.getClientY();
		par["hyp"] = hypLnk;
		par["ref"] = evt.getDomTarget().getAttribute( "href" );
		let tmpDiv = evt.getDomTarget();
		while ( hypLnk && !par["ref"] && !!tmpDiv.parentNode && tmpDiv.parentNode != window.document ) {
			tmpDiv = tmpDiv.parentNode;
			par["ref"] = tmpDiv.getAttribute( "href" );
		}
		par["sft"] = evt.isShiftPressed();
		par["ctr"] = evt.isCtrlPressed();
		this._nfySrv( "tblEdrAtv", par, hypLnk );
	}

	/**
	 * extended table widget initialization
	 */
	_iniTblWdg() {
		if ( this.ready ) {
			const hdr = this.tblCtl._header;
			if ( hdr ) {
				const chl = hdr._children;
				if ( chl && chl.length && chl.length > 1 ) {		// index 0 --> "dummy column" (no man's land)
					const cnt = chl.length;
					for ( let i=1 ; i < cnt ; ++i ) {
						const ce = chl[i].$el.get(0);
						const all = i === 1;
						if ( ce ) {
							ce.style.cursor = all ? 'cell' : 'pointer';
						}
					}
				}
			}
			const rc = this.rc;
			if ( rc && rc._container && rc._container.length > 0 ) {
				const cnt = rc._container.length;
				for ( let i=0 ; i < cnt ; ++i ) {
					const c = rc._container[i];
					if ( c._element && c._element.firstChild ) {
						const sc = c._element.firstChild;
						sc.style.overflow = 'hidden';					// this is a work-around for a bug in FF
					}
				}
			}
			this._nfySrv('nfyRendered', { rendered: true }, false);
		}
	}

	/**
	 * Removes the href attribute while shift is pressed so hyperlinks are rendered like standard text
	 */
	_onMouMov( evt ) {
		const tag = evt.getDomTarget().tagName;
		if ( ( tag === "A" ) || ( tag === "I" ) || ( tag === "IMG" ) ) {
			const isDisabled = evt._valueDomTarget.classList.contains( "shiftDisabled" );
			if ( evt.isShiftPressed() && !isDisabled ) {
				evt._valueDomTarget.setAttribute( "dref", evt._valueDomTarget.getAttribute( "href" ) ); // sets "dref" attribute to deactivate but save the link
				evt._valueDomTarget.removeAttribute( "href" );
				evt._valueDomTarget.className += "shiftDisabled";
			} else if ( !evt.isShiftPressed() && isDisabled ) {
				evt._valueDomTarget.setAttribute( "href", evt._valueDomTarget.getAttribute( "dref" ) ); // restores "dref" attribute to href
				evt._valueDomTarget.removeAttribute( "dref" );
				evt._valueDomTarget.classList.remove( "shiftDisabled" );
			}
		}
	}

	_onMouDow( evt ) {
		if ( evt._valueButton === "left" ) {
			if ( this.isOnSelCol( evt ) ) {
				// click on selection column
				if ( this.sngSel ) {
					this._initSingleSelect( evt );
				} else {
					this._initMultiSelect( evt );
				}
			} else {
				// click on any non-selection column
				this.clcTxtSiz( evt );
			}
		}
	}

	_lngBtnDwn( evt ) {
		let col = null;
		let colIdx = -1;
		if ( typeof evt.cix === 'number' ) {
			colIdx = evt.cix;
		}
		if ( (colIdx === -1) && evt.target && evt.target.parentElement && evt.target.parentElement.parentNode ) {
			col = evt.target.parentElement.parentNode.rwtWidget;
			var heaCol = this.tblCtl._header._getColumnByLabel( col );
			if ( heaCol ) {
				colIdx = heaCol._index;
				heaCol = null;
			}
		}
		const par = {
			"colIdx": colIdx,
		};
		return par;
	}

	_onColResize(roes, osv) {
		for ( let roe of roes ) {
			this._lngIcoLayout(roe.target, null);
		}
	}

	_lngIcoLayout(elm, lni) {
		let ico = lni;
		if ( !ico ) {
			const chl = elm.getElementsByClassName('tblHdrLngIco');
			if ( chl && chl.length > 0 ) {
				ico = chl[0];
			}
		}
		if ( ico != null ) {
			const rct = elm.getBoundingClientRect();
			const icr = ico.getBoundingClientRect();
			const chl = elm.children;
			if ( chl.length > 2 ) {
				// more than two elements - sort indicator...
				const cnt = chl.length;
				const wdt_ico = icr.width;
				let wdt_rgt = 0;
				let rgt = true;
				for ( let i=cnt-1 ; rgt && (i >= 0) ; --i ) {
					let ce = chl[i];
					if ( ce === ico ) {
						// flipping to the left side
						rgt = false;
					} else {
						wdt_rgt += ce.getBoundingClientRect().width;
					}
				}
				if ( wdt_rgt == 0 ) {
					wdt_rgt = RGT_BRD_OFS;
				} else {
					wdt_rgt = Math.max(wdt_rgt, SRT_ICO_WDT);
				}
				const lft = Math.max(rct.width - (wdt_ico + wdt_rgt), 0);
				ico.style.left = '' + lft + 'px';
			} else {
				// just the label/icon and the language icon
				const lft = Math.max(rct.width - (icr.width + RGT_BRD_OFS), 0);
				ico.style.left = '' + lft + 'px';
			}
		}
	}

	_onColUpdate(wdg, hde, lni) {
		const rqu = {};
		rqu.wdg = wdg || null;
		rqu.hde = hde || null;
		rqu.lni = lni || null;
		this.rdrRqu.push(rqu);
	}
	
	_onRdrRqu() {
		if ( this.rdrRqu.length > 0 ) {
			const all_rqu = this.rdrRqu;
			this.rdrRqu = [];
			for ( let rqu of all_rqu ) {
				this._lngIcoLayout(rqu.hde, rqu.lni);
			}
		}
	}

	_initSingleSelect( evt ) {
		const tbl = this.tblCtl.getElement();
		let firstSelRow = this.rc.findRowByElement( evt._valueDomTarget );
		if ( !firstSelRow ) {
			// if click was on selector column in header (to select all) return
			return;
		}
		this.firstSelIdx = this.rc.getRowIndex( firstSelRow ) + this._getYScrollOffset(); // index of row where mouse went down
		const lastPageRowCount = this.tblCtl._getLastPageRowCount();
		while ( !firstSelRow._item && this.firstSelIdx > 0 ) {
			// if first selection row index exceeds row count
			this.firstSelIdx--;
			firstSelRow = this.rc._container[0].getRow( this.firstSelIdx - this._getYScrollOffset() );
		}
		if ( !firstSelRow._item ) {
			// no selection can be made -> table is empty -> abandon
			return;
		}
		const par = {
			"sel": [this.firstSelIdx],
			"foc": this.firstSelIdx
		};
		this._nfySrv( "tblRowSel", par, true );
	}

	_initMultiSelect( evt ) {
		const tbl = this.tblCtl.getElement();
		const that = this;
		const shift = evt.isShiftPressed();
		const ctrl = evt.isCtrlPressed();
		let firstSelRow = this.rc.findRowByElement( evt._valueDomTarget );
		if ( !firstSelRow || shift ) {
			// if click was on selector column in header (to select all) return
			return;
		}
		this.firstSelIdx = this.rc.getRowIndex( firstSelRow ) + this._getYScrollOffset(); // index of row where mouse went down
		const lastPageRowCount = this.tblCtl._getLastPageRowCount();
		while ( !firstSelRow._item && this.firstSelIdx > 0 ) {
			// if first selection row index exceeds row count
			this.firstSelIdx--;
			firstSelRow = this.rc._container[0].getRow( this.firstSelIdx - this._getYScrollOffset() );
		}
		if ( !firstSelRow._item ) {
			// no selection can be made -> table is empty -> abandon
			return;
		}
		this.newSel = []; // array of row indices
		this.oldSel = []; // array of row indices
		this.selRange = [this.firstSelIdx, this.firstSelIdx]; // this is the current row selection (selRange[0] the rowIdx at mouseDownPos & selRange[1] the rowIdx at mouseMovePos)
		if ( shift ) {
			// shift-click
			this._initShiftSelect();
		} else if ( ctrl ) {
			// ctrl-click
			this._initCtrlSelect( firstSelRow );
		} else {
			// click without ctrl or shift
			this._initClickSelect( firstSelRow );
		}
		if ( !this.tOverlay ) {
			this.tOverlay = document.createElement( "div" ); // transparent overlay
		}
		this.tOverlay.addEventListener( "mousemove", this.movFunction = function ( evt ) {
			that._onColSelect( evt, shift, ctrl );
		}, this );
		this.tOverlay.addEventListener( "mouseout", this.motFunction = function ( evt ) {
			that._onColSelectFinished( shift, ctrl );
		}, this );
		this.tOverlay.addEventListener( "mouseup", this.mupFunction = function ( evt ) {
			that._onColSelectFinished( shift, ctrl );
		}, this );
		this._createTransparentOverlay();
	}

	_initShiftSelect() {
		const tmpFocIdx = this._getFocRow();
		this.focRow = tmpFocIdx;
		this.arrowIdx = tmpFocIdx === -1 ? 0 : tmpFocIdx;
		this.selRange = [this.arrowIdx, this.firstSelIdx];
		this.deselect = false;
		this._doSelect( true, false );
	}
	
	_initCtrlSelect( firstSelRow ) {
		for ( let i = 0; i < this.tblCtl.getSelection().length; ++i ) {
			this.oldSel[i] = this.tblCtl.getSelection()[i].getFlatIndex();
		}
		this.focRow = this.rc.getRowIndex( firstSelRow ) + this._getYScrollOffset();
		this.deselect = !this.tblCtl.isItemSelected( firstSelRow._item ); // if the click deselected an item -> set deselect-flag to true
	}
	
	_initClickSelect( firstSelRow ) {
		this.tblCtl.deselectAll(); // deselect all rows
		this.focRow = this.rc.getRowIndex( firstSelRow ) + this._getYScrollOffset();
		this.tmpPrvSelRng = this.selRange.slice();
		this._doSelect( false, false );
		this.deselect = false;
	}

	_createTransparentOverlay() {
		this.tOverlay.style.position = "absolute";
		this.tOverlay.style.zIndex = "2147483645";
		this.tOverlay.style.top = 0 + "px";
		this.tOverlay.style.left = 0 + "px";
		this.tOverlay.style.width = window.innerWidth + "px";
		this.tOverlay.style.height = window.innerHeight + "px";
		document.body.appendChild( this.tOverlay );
	}
	
	/**
	 * Returns the focused row's index (the row with the arrow in front). If there is no focused row -1 is returned.
	 */
	_getFocRow() {
		const userData = this.tblCtl.getUserData( "org.eclipse.swt.widgets.Widget#data" );
		if ( userData !== null ) {
			if ( userData["pisasales.CSTPRP.CWD"].FOC_ROW != null ) {
				// property was set by the server in TblColSel#getImage
				return userData["pisasales.CSTPRP.CWD"].FOC_ROW;
			}
		}
		return -1;
	}

	_onKeyDown( evt ) {
		const hdl = this._psa.keyHdl;
		if ( hdl ) {
			const key = evt.getKeyIdentifier();
			switch ( key ) {
				case "Escape":
				case "Enter":
					{
						const dsc = hdl.getEvtKeyDsc( evt );
						if ( dsc && ( dsc.length > 0 ) && ( dsc.charAt( 0 ) === '0' ) ) {
							hdl.stopKeyEvt( evt );
							const par = {};
							par["keyCod"] = dsc;
							this._nfySrv( "keyEvt", par, true );
							return true;
						}
					}
					break;
				default:
					break;
			}
		}
		return false;
	}

	_onScroll() {
		/* sleep well */
		if ( this.ready && this.tblCtl && false ) {
			var tbl = this.tblCtl;
			var cur = new JsPoint( tbl._horzScrollBar.getValue(), tbl._vertScrollBar.getValue() );
			var ofs = cur.offs( this.scrPos );
			this._psa.Log.logObj( tbl, this.idwTbl, "Scrolled by " + ofs, null );
			this._psa.Log.logObj( tbl, this.idwTbl, "Scrolled to " + cur, null );
			if ( this.curEdt ) {
				var edt = this.curEdt;
				if ( ofs.cx !== 0 ) {
					var lft = edt.getLeft();
					edt.setLeft( lft - ofs.cx );
				}
				if ( ofs.cy !== 0 ) {
					var top = edt.getTop();
					edt.setTop( top - ofs.cy );
				}
			}
			this.scrPos = cur;
		}
	}

	_nfySrv( code, par, bsc ) {
		if ( this.ready ) {
			if ( bsc ) {
				this._psa.setBscRqu();
			}
			const param = {};
			param["cod"] = code;
			param["par"] = par;
			rap.getRemoteObject( this ).notify( "TBL_NFY_SRV", param );
		}
	}

	_log( fnc, par ) {
		this._psa.Log.logObj( this, this.idwTbl, fnc, par );
	}

	setEdtVis( args ) {
		const idw = args["edt"] || '';
		const vis = !!args["vis"];
		if ( idw ) {
			const edt = rwt.remote.ObjectRegistry.getObject( idw );
			if ( edt ) {
				if ( vis ) {
					this.curEdt = edt;
					this.edtRct = new JsRect( edt.getLeft(), edt.getTop(), edt.getWidth(), edt.getHeight() );
				}
				else if ( edt === this.curEdt ) {
					this.curEdt = null;
					this.edtRct = null;
				}
				if ( this.dbgLog ) {
					this._psa.Log.logObj( edt, idw, "setEdtVis", vis ? "y" : "n" );
				}
			}
		}
	}

	setLayDat( args ) {
		const fxw = args["fxw"];
		if ( typeof fxw === "number" ) {
			this.fixWdt = fxw;
		}
		const tbr = args["tbr"];
		if ( tbr ) {
			this.tblRct = new JsRect( tbr );
		}
	}
	
	setEdtRct( args ) {
		const par = args["rct"];
		if ( par ) {
			const rct = new JsRect( par );
			if ( rct ) {
				this.edtRct = rct;
			}
		}
	}

	/**
	 * check if x and y are on selector column
	 */
	isOnSelCol( evt ) {
		return rwt.widgets.util.GridUtil.getColumnByPageX( this.tblCtl, evt.getPageX() ) === 0;
	}

	/**
	 * returns the most suitable row at the given point. Will only return selectable rows that are scrolled into view.
	 */
	_getRowIdxByPnt( pageX, pageY ) {
		const row = this.rc._container[0].getRow( 0 ).$el.get()[0];
		const bounds = row.getBoundingClientRect();
		const idx = Math.floor( ( pageY - bounds.top ) / bounds.height );
		if ( idx < 0 ) {
			return this._getYScrollOffset();
		} else if ( idx > this.tblCtl._getLastPageRowCount() + this._getYScrollOffset() ) {
			return this.tblCtl._getLastPageRowCount() + this._getYScrollOffset();
		}
		return idx + this._getYScrollOffset();
	}

	_onColSelectFinished( isShift, isCtrl ) {
		const foc = this.focRow; // the newly focused table item index
		const that = this;
		let sel = []; // the table selection indices
		if ( isCtrl ) {
			if ( this.deselect ) {
				// subtract new deselection from old selection
				this.oldSel.forEach( function ( item, index, array ) {
					if ( that.newSel.indexOf( item ) === -1 ) {
						sel.push( item );
					}
				} );
			} else {
				// add new selection to old selection
				sel = this.newSel.concat( this.oldSel );
			}
		} else {
			// notify server on selection
			sel = this.newSel;
		}
		const par = {
			"sel": sel,
			"foc": foc
		};
		this._nfySrv( "tblRowSel", par, true );
	
		this.tOverlay.removeEventListener( "mouseup", this.mupFunction, this );
		this.tOverlay.removeEventListener( "mouseout", this.motFunction, this );
		this.tOverlay.removeEventListener( "mousemove", this.movFunction, this );
	
		setTimeout( function () {
			// delay is necessary so the dnd-drop is triggered on the transparent overlay (where it doesn't harm anyone) and not on the element beneath
			// dnd should be disabled anyway but keeping this for good measure
			document.body.removeChild( that.tOverlay );
			that.tOverlay = null;
		}, 300 );
	}

	_getYScrollOffset() {
		return this.tblCtl._vertScrollBar ? this.tblCtl._vertScrollBar.getValue() : 0;
	}
	
	_onColSelect( evt, isShift, isCtrl ) {
		// cancel dnd
		rwt.event.DragAndDropHandler.getInstance().globalCancelDrag();
	
		// save previous selection
		this.tmpPrvSelRng = this.selRange.slice();
	
		// prepares the selection range
		this._updateSelRange( evt );
	
		this._doSelect( isShift, isCtrl );
	
		// if the selection range became smaller -> deselect
		this._deselectOld( isShift, isCtrl );
	}

	_doSelect( isShift, isCtrl ) {
		let min = 0;
		let max = 0;
		if ( isShift ) {
			// the rows between the firstly focused row (the one with the arrow) and the now hovered row matter. The rest will remain untouched.
			min = Math.min( this.selRange[1], this.arrowIdx );
			max = Math.max( this.selRange[1], this.arrowIdx );
		} else {
			// Only the rows between the currently hovered rowIdx and the previously hovered rowIdx matter. The rest will remain untouched.
			min = Math.min( this.selRange[1], this.tmpPrvSelRng[1] );
			max = Math.max( this.selRange[1], this.tmpPrvSelRng[1] );
		}
		while ( min <= max ) {
			// update array with current (de)selection (and keep entries unique)
			const arrIdx = this.newSel.indexOf( min );
			if ( arrIdx === -1 ) {
				this.newSel.push( min );
			}
			// render (de)selection, limited to the visible area because table is virtual and getRow(index) will fail for non-displayed rows
			const relIdx = min - this._getYScrollOffset(); // relative index inside displayed table area
			if ( relIdx >= 0 && relIdx < this.rc.getRowCount() ) {
				const row = this.rc._container[0].getRow( min - this._getYScrollOffset() );
				if ( row != null ) {
					const item = this.rc.findItemByRow( row );
					if ( item != null ) {
						if ( isShift ) {
							this.tblCtl._selectItem( item, true );
						} else if ( isCtrl ) {
							this.deselect ? this.tblCtl._deselectItem( item, true ) : this.tblCtl._selectItem( item, true );
						} else {
							this.tblCtl._selectItem( item, true );
						}
					}
				}
			}
			++min;
		}
	}

	_deselectOld( isShift, isCtrl ) {
		const oldMin = Math.min( this.tmpPrvSelRng[0], this.tmpPrvSelRng[1] );
		const oldMax = Math.max( this.tmpPrvSelRng[0], this.tmpPrvSelRng[1] );
		const newMin = Math.min( this.selRange[0], this.selRange[1] );
		const newMax = Math.max( this.selRange[0], this.selRange[1] );
		for ( let i = oldMin; i <= oldMax; ++i ) {
			if ( i < newMin || i > newMax ) {
				const row = this.rc._container[0].getRow( i - this._getYScrollOffset() );
				if ( row != null ) {
					const item = this.rc.findItemByRow( row );
					const newSelIdx = this.newSel.indexOf( i );
					if ( this.oldSel.indexOf( i ) != -1 && this.deselect ) {
						this.tblCtl._selectItem( item, true );
						if ( newSelIdx === -1 ) {
							this.newSel.push( i );
						}
					} else if ( this.oldSel.indexOf( i ) === -1 && !this.deselect ) {
						this.tblCtl._deselectItem( item, true );
						if ( newSelIdx !== -1 ) {
							this.newSel.splice( newSelIdx, 1 );
						}
					}
				}
			}
		}
	}

	/**
	 * Updates the current selection range (selRange[1]) to the current mouse position. Also limits selection to rows that exist and are visible.
	 */
	_updateSelRange( evt ) {
		this.selRange[1] = this._getRowIdxByPnt( evt.pageX, evt.pageY );
	}

	/**
	* add a event to the list of tracked events for a item.
	*/
	_addTrackedListener( ele, type, handler ) {
		if ( !ele.trackedEvents ) {
			ele.trackedEvents = {};
		}
		if ( !ele.trackedEvents[type] ) {
			ele.trackedEvents[type] = [];
			ele[type] = function () {
				for ( let i = 0; i < ele.trackedEvents[type].length; ++i ) {
					ele.trackedEvents[type][i]();
				}
			};
		}
		ele.trackedEvents[type].push( handler );
		this.lngIcos.push( ele );
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget TblMgr.');
		rap.registerTypeHandler( "psawidget.TblMgr", {
		
			factory: function ( properties ) {
				return new TblMgr( properties );
			},	
		
			destructor: "destroy",
			methods: ["clcTxtSiz",
				"setHdrIco",
				"setLngHdrIco",
				"tglLngCol",
				"setHdrTtlAln",
				"setHdrColBkgClr",
				"setHdrColTxtClr",
				"setHdrFnt",
				"setEdtVis",
				"setLayDat",
				"setHdrClr",
				"setTblWdg",
			],	
			properties: ["edtRct"],
			events: ["TBL_NFY_SRV"]
		} );	
	}
}																																					

// override grid functions for CTRL-deselect
( function () {
	console.debug('TBL: Overriding RAP grid method for CTRL-deselect...');
	rwt.widgets.Grid.prototype._delayMultiSelect = function ( event, item ) {
		if ( this._isDragSource() && this.isItemSelected( item ) && event.getType() === "mousedown" ) {
			if ( this.getAppearance() === "table" && rwt.widgets.util.GridUtil.getColumnByPageX( this, event.getPageX() ) === 0 ) {
				return this._delayedSelection;
			}
			this._delayedSelection = true;
		}
		return this._delayedSelection;
	}
}() );

console.debug('widgets/rchtable/TblMgr.js loaded.');