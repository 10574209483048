import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class SepDiv - client side part of custom separator widget
 */
export default class SepDiv {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		this.element = null;
		this.parent.addListener("Resize", this.layout);
		this.ready = false;
		this.horz = false;
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			const mgr = ItmMgr.getInst();
			this.element = mgr.creCmnDiv(this.parent, cwd.cssCls); 
			const sep = this.element;
			const clr = cwd.bkgClr;
			const wdt = Math.max((cwd.size || 0), 1); 
			if ( clr ) {
				mgr.setBkgClr(sep, clr);
			}
			const ori = cwd.ori;
			sep.style.position = 'relative';
			sep.style.border = 'none';
			if ( ori === "horz" ) {
				sep.style.width = 'inherit';
				sep.style.height = '' + wdt + 'px';
				this.horz = true;
			} else {
				// default to vertical alignment
				this.horz = false;
				sep.style.width = '' + wdt + 'px';
				sep.style.height = 'inherit';
				sep.style.marginLeft = 'auto';
				sep.style.marginRight = 'auto';
			}
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	onReady() {
		this.ready = true;
		// update the layout
		this.layout();
	}
	
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
		}
	}
	
	onSend() {
		// do nothing so far...
	}
	
	destroy() {
		this._psa.rmvDomElm(this.element);
	}
	
	layout() {
		if ( this.ready && this.horz ) {
			const area = this.parent.getClientArea();
			const sep = this.element;
			const hgt = area[3]/2;
			sep.style.top = '' + hgt.toFixed() + 'px';
		}
	}
	
	setFgrClr(args) {
		if ( this.element ) {
			ItmMgr.getInst().setBkgClr(this.element, args["fgrClr"], false); /* YES! *always* set the BACKground color! */
		}
	}
	
	setBkgClr(args) {
		if ( this.element ) {
			ItmMgr.getInst().setBkgClr(this.element, args["bkgClr"], false);
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget SepDiv.');
		rap.registerTypeHandler("psawidget.SepDiv", {
			factory : function(properties) {
				return new SepDiv(properties);
			},
			destructor : "destroy",
			methods : [ "setFgrClr", "setBkgClr" ],
			events : [ "SEPDIV_NFY" ]
		} );
	}
}

console.debug('widgets/csswdg/SepDiv.js loaded.');