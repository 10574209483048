import Base from '../../base/base';
import MnuMgr from './MnuMgr';
import MnuObj from './MnuObj';
import ItmMgr from '../ItmMgr';
import Utils from '../../utils/Utils';
import HtmHelper from '../../utils/HtmHelper';
import DomEventHelper from '../../utils/DomEventHelper';
import Validator from '../../utils/Validator';

import { MI_ANY_CLICKED, MI_ICON_CLICKED, MI_TEXT_CLICKED } from './MenuHandler';

/** max. icon size */
const ICO_SIZE = 32;
/** icon element's left padding */
const ICE_PAD_LFT = 8;
/** icon element's left padding */
const ICE_PAD_RGT = 2;
/** the icon element width in pixels */
const ICE_WDT = '32px';
/** height in pixels of separator items */
export const SEP_HGT = 14;
/** sub menu indicator */
const SUB_IND = '<i class="far fa-angle-right" style="font-size:16px;"></i>';
/** sub menu popup time in milliseconds */
const POP_TMO = 140;
/** CSS filter for disabled bitmap images */
const DIS_IMG = 'brightness(1.2) grayscale(100%)';
/** check mark */
const CHK_MRK = '<i class="fa fa-check" style="font-size:12px;"></i>';
/** property "checked" */
const PRP_CHK = 'checked';
/** property "enabled" */
const PRP_ENA = 'enabled';
/** property "image" */
const PRP_IMG = 'image';
/** property "text" */
const PRP_TXT = 'text';


/**
 * a menu item
 */
export default class MnuItm extends Base {

	/**
	 * constructs a new instance
	 * @param {MnuMgr} mgr the menu manager
	 * @param {MnuObj} par parent menu
	 * @param {Object} dsc menu item descriptor
	 */
	constructor( mgr, par, dsc ) {
		super();
		this._im = ItmMgr.getInst();
		this.mnuMgr = mgr;
		this.parMnu = par;
		this.id = dsc.id || 0;
		this.subMnu = null;
		this.subPop = false;
		this.popTmh = null;
		this.element = null;
		this.spanText = null;
		this.spanMainIcon = null;
		this.spanTitleIcon = null;
		this.elmIcon = null;
		this.lsrMse = null;
		this.lsrMlv = null;
		this.lsrClk = null;
		this.itmHgt = mgr.itmHgt;
		this.sep = false;
		this.hotBgc = mgr.mnuHlc;
		this.hotTxc = mgr.mnuStc;
		this.disTxc = mgr.mnuDtc;
		this.enabled = true;
		this.checked = false;
		this.inactive = false;
		this.onPopTimer = null;
		this.rightClkCmd = false;
		this._render( dsc );
	}

	/**
	 * destructor method
	 * @override
	 */
	doDestroy() {
		this._clrPopTimer();
		if ( this.element ) {
			if ( this.lsrClk ) {
				this.element.removeEventListener( 'click', this.lsrClk );
				if ( this.rightClkCmd ) {
					this.element.removeEventListener( 'contextmenu', this.lsrClk );
				}
			}
			if ( this.lsrMse ) {
				this.element.removeEventListener( 'mouseenter', this.lsrMse );
			}
			if ( this.lsrMlv ) {
				this.element.removeEventListener( 'mouseleave', this.lsrMlv );
			}
			HtmHelper.rmvDomElm( this.element );
		}
		if ( this.subMnu ) {
			this.subMnu.destroy();
		}
		delete this.rightClkCmd;
		delete this.lsrClk;
		delete this.lsrMse;
		delete this.lsrMlv;
		delete this.elmIcon;
		delete this.spanTitleIcon;
		delete this.spanMainIcon;
		delete this.spanText;
		delete this.element;
		delete this.hotBgc;
		delete this.hotTxc;
		delete this.disTxc;
		delete this.subPop;
		delete this.popTmh;
		delete this.subMnu;
		delete this.onPopTimer;
		delete this.enabled;
		delete this.checked;
		delete this.id;
		delete this.parMnu;
		delete this.mnuMgr;
		super.doDestroy();
	}

	/**
	 * returns the item ID
	 * @returns {Number} the item ID
	 */
	getId() {
		return this.id;
	}

	/**
	 * @returns {Number} the height in pixels of this item
	 */
	getHeight() {
		return this.itmHgt;
	}

	/**
	 * @returns {Boolean} true if the menu item is enabled; false if it is disabled
	 */
	isEnabled() {
		return this.enabled;
	}

	/**
	 * @returns {Boolean} true if the menu item is inactive; false if it is inactive
	 */
	isInActive() {
		return this.inactive;
	}

	/**
	 * @returns {Boolean} true if this menu item is checked; false otherwise
	 */
	isChecked() {
		return this.checked;
	}

	/**
	 * indicates whether this item is a static separator item
	 * @returns {Boolean} true if this is a static separator item; false otherwise
	 */
	isSep() {
		return this.sep;
	}

	/**
	 * @returns {MnuObj} the parent menu object
	 */
	getParMnu() {
		return this.parMnu;
	}

	/**
	 * set the enabled status
	 * @param {Boolean} ena new enabled status
	 */
	setEnabled( ena ) {
		if ( this.enabled !== ena ) {
			this.enabled = !!ena;
			this._im.setBkgClr( this.element, null, false );
			this._im.setFgrClr( this.element, this.isEnabled() ? null : this.disTxc );
			if ( this.elmIcon ) {
				this.elmIcon.style.filter = this.isEnabled() ? '' : DIS_IMG;
			}
		}
	}

	/**
	 * set the "checked" status
	 * @param {Boolean} chk new checked status
	 */
	setChecked( chk ) {
		const eff_chk = !!chk && !this.inactive;
		if ( this.checked !== eff_chk ) {
			this.checked = eff_chk;
			if ( this.spanMainIcon ) {
				this.spanMainIcon.innerHTML = this.isChecked() ? CHK_MRK : '';
			}
		}
	}

	/**
	 * updates the text of this menu item
	 * @param {String} txt new text
	 */
	setText( txt ) {
		if ( this.spanText ) {
			this.spanText.innerText = txt;
		}
	}

	/**
	 * updates the image of this menu item
	 * @param {Object} img image descriptor; may be null to drop the current image
	 * @param {Boolean} upd update flag
	 */
	setImage( img, upd ) {
		if ( this.spanMainIcon ) {
			if ( upd ) {
				// drop old image
				this.elmIcon = null;
				this.spanMainIcon.innerHTML = '';
			}
			if ( img ) {
				const ime = this._im.creImg( img, 0, false );
				if ( ime ) {
					if ( this.spanTitleIcon ) {
						this.spanTitleIcon.style.display = 'none';
					}
					this.spanMainIcon.appendChild( ime );
					if ( img.typ !== 'DSC' ) {
						if ( !img.svg ) {
							this._im.scaleImageTag( ime, img, Math.min( ICO_SIZE - ICE_PAD_LFT - ICE_PAD_RGT, this.itmHgt - 4 ) );
						}
						this.elmIcon = ime;
					}
				}
			}
		}
	}

	/**
	 * updates this menu item
	 * @param {Object} args new properties
	 */
	updMnuItm( args ) {
		const prp = args.prp || '';
		switch ( prp ) {
			case PRP_CHK:
				this.setChecked( !!args.chk );
				break;
			case PRP_ENA:
				this.setEnabled( !!args.ena );
				break;
			case PRP_IMG:
				this.setImage( args.img || null, true );
				break;
			case PRP_TXT:
				this.setText( args.txt || '' );
				break;
			default:
				break;
		}
	}

	/**
	 * updates a menu item in a sub menu
	 * @param {Number} id menu item ID
	 * @param {Object} args new properties
	 * @returns {Boolean} true if the specified menu items was found and successfully updated; false otherwise
	 */
	updSubItm( id, args ) {
		if ( this.subMnu ) {
			return this.subMnu.updMnuItm( id, args );
		} else {
			// we do not have any sub item
			return false;
		}
	}

	/**
	 * hides the sub-menu, if any
	 */
	hideSub() {
		if ( this.subMnu ) {
			this.subPop = false;
			this.subMnu.hide();
		}
	}

	/**
	 * updates an icon descriptor, drops the text color if set
	 * @param {*} ico icon descriptor
	 */
	_updIcoDsc(ico) {
		if ( (ico.typ === 'DSC') && ico.img.icoClr ) {
			// drop any special color
			ico.img.icoClr = null;
		}
	}

	/**
	 * renders the menu item
	 * @param {Object} dsc menu item descriptor
	 */
	_render( dsc ) {
		const div = document.createElement( 'div' );
		if ( !dsc.stc ) {
			// a regular item
			const hgt = '' + this.itmHgt + 'px';
			div.className = 'psamnuitm';
			div.style.flexBasis = hgt;
			div.style.minHeight = hgt;
			div.style.maxHeight = hgt;
			// menu item icon
			const si = document.createElement( 'span' );
			si.style.flexBasis = ICE_WDT;
			si.style.minWidth = ICE_WDT;
			si.style.maxWidth = ICE_WDT;
			si.style.paddingLeft = '' + ICE_PAD_LFT + 'px';
			si.style.paddingRight = '' + ICE_PAD_RGT + 'px';
			const xsi = document.createElement( 'span' );
			xsi.style.display = 'none';
			xsi.style.paddingRight = '4px';
			xsi.style.overflow = 'hidden';
			this.spanMainIcon = si;
			this.spanTitleIcon = xsi;
			if ( dsc.img ) {
				// regular main icon
				this.setImage( dsc.img, false );
			} else if ( dsc.ttlImg ) {
				// special title icon
				const tti = dsc.ttlImg;
				this._updIcoDsc(tti);
				const xti = this._im.creImg( tti, 0, false );
				if ( xti ) {
					xsi.appendChild( xti );
					xsi.style.maxWidth = '' + (4 + tti.isz.cx) + 'px';	// see padding above!
					xsi.style.display = '';
				}
			}
			// menu item text
			const st = document.createElement( 'span' );
			st.style.whiteSpace = 'nowrap';
			st.style.flexGrow = '100';
			st.style.paddingLeft = '2px';
			st.style.paddingRight = '2px';
			st.innerText = dsc.txt || '';
			this.spanText = st;
			// sub menu indicator
			const smi = document.createElement( 'span' );
			smi.style.textAlign = 'right';
			smi.style.flexBasis = ICE_WDT;
			smi.style.minWidth = ICE_WDT;
			smi.style.maxWidth = ICE_WDT;
			smi.style.paddingLeft = '2px';
			smi.style.paddingRight = '8px';
			// add child items
			div.appendChild( si );
			div.appendChild( xsi );
			div.appendChild( st );
			div.appendChild( smi );
			// active vs. inactive
			const ina = !!dsc.ina;
			this.inactive = ina;
			if ( !ina ) {
				// active item -> check for sub items and attach listeners
				const sub = dsc.items && ( dsc.items.length > 0 )
				if ( sub ) {
					smi.innerHTML = SUB_IND;
					this.subMnu = new MnuObj( this.mnuMgr, this, this.id, dsc.items );
				}
				this.onPopTimer = Utils.bind( this, this._onPopTimer );
				this.lsrClk = Utils.bind( this, this._onClick );
				div.addEventListener( 'click', this.lsrClk, false );
				this.lsrMse = Utils.bind( this, this._onMouseEnter );
				div.addEventListener( 'mouseenter', this.lsrMse, false );
				this.lsrMlv = Utils.bind( this, this._onMouseLeave );
				div.addEventListener( 'mouseleave', this.lsrMlv, false );
				// "right click" setting
				this.rightClkCmd = !!dsc.rgtclk;
				if ( this.rightClkCmd ) {
					div.addEventListener( 'contextmenu', this.lsrClk, false );
				}
			}
		} else {
			// a separator
			this.itmHgt = SEP_HGT;
			this.sep = true;
			this.inactive = true;
			const hgt = '' + SEP_HGT + 'px';
			div.className = 'psamnuseparator';
			div.style.flexBasis = hgt;
			div.style.minHeight = hgt;
			div.style.maxHeight = hgt;
			const sep = document.createElement( 'div' );
			sep.className = 'psamenusepline';
			sep.style.minHeight = '' + ( SEP_HGT / 2 ) + 'px';
			div.appendChild( sep );
		}
		if ( Validator.isString(dsc.dsc) ) {
			div.dataset.testid = dsc.dsc;			
		}
		this.parMnu.getItmElm().appendChild( div );
		this.element = div;
		if ( !this.isSep() ) {
			this.setEnabled( !!dsc.ena );
			this.setChecked( !!dsc.chk );
		}
	}

	_onMouseEnter() {
		if ( this.isEnabled() ) {
			this._im.setBkgClr( this.element, this.hotBgc, false );
			this._im.setFgrClr( this.element, this.hotTxc );
			this._clrPopTimer();
			this.popTmh = setTimeout( this.onPopTimer, POP_TMO );
		}
	}

	_onMouseLeave() {
		if ( this.isEnabled() ) {
			this._im.setBkgClr( this.element, null, false );
			this._im.setFgrClr( this.element, null );
		}
		this._clrPopTimer();
	}

	_onClick( evt ) {
		if ( this.isEnabled() && ( evt instanceof MouseEvent ) ) {
			const left = ( evt.button === 0 );
			const right = ( evt.button === 2 );
			if ( left || right ) {
				DomEventHelper.stopEvent(evt);
			}
			if ( this.subMnu && left && !right ) {
				// show the sub menu
				this._showSub();
			} else if ( left || right ) {
				// trigger the command
				if ( !right ) {
					this._onMouseLeave();
				}
				if ( left || this.rightClkCmd ) {
					this.mnuMgr.onItemClick( this, right, this._getClickTarget(evt) );
				}
			}
		}
	}

	/**
	 * checks what part of the menu item was clicked
	 * @param {Event} evt the click event
	 * @returns one of the MI_XXX constants that indicate the clicked part
	 */
	_getClickTarget(evt) {
		const tgt = evt.target;
		if ( HtmHelper.isSameOrChildOf(tgt, this.spanMainIcon) ) {
			// a click on the main icon
			return MI_ICON_CLICKED;
		}
		if ( HtmHelper.isSameOrChildOf(tgt, this.spanText) || HtmHelper.isSameOrChildOf(tgt, this.spanTitleIcon) ) {
			// a click on the text; the "title" icon is considered to part of the text
			return MI_TEXT_CLICKED;
		}
		// anywhere else clicked
		return MI_ANY_CLICKED;
	}

	_clrPopTimer() {
		if ( this.popTmh ) {
			const tmh = this.popTmh;
			this.popTmh = null;
			clearTimeout( tmh );
		}
	}

	_onPopTimer() {
		this._clrPopTimer();
		if ( this.isEnabled() ) {
			if ( this.subMnu ) {
				this._showSub();
			} else {
				this.parMnu.onSubPop( 0 );
			}
		}
	}

	_showSub() {
		if ( this.subPop ) {
			this.hideSub();
		}
		if ( this.subMnu ) {
			// we need to show and to place the menu
			const mh = this.mnuMgr.getHostElm();
			const mne = this.subMnu.getElm();
			// show the menu
			this.subPop = true;
			this.subMnu.show();
			// place the menu
			const mxr = mh.getBoundingClientRect();
			const itr = this.element.getBoundingClientRect();
			const mnr = mne.getBoundingClientRect();
			// 1. horizontal placement
			const mnu_wdt = Math.min( this.subMnu.getOrgWdt(), mxr.width - 2 );
			let pfr_lft = this.parMnu.isPfrLft();
			let max_wdt = mnu_wdt;
			let lft = pfr_lft ? itr.left - mnu_wdt : itr.right - 1;
			if ( ( lft < mxr.left ) || ( ( lft + mnu_wdt ) > mxr.right ) ) {
				if ( ( itr.left - mxr.left ) > ( mxr.right - ( itr.right - 1 ) ) ) {
					pfr_lft = true;
					max_wdt = itr.left - mxr.left - 1;
					lft = Math.max( itr.left - mnu_wdt + 1, 1 );
				} else {
					pfr_lft = false;
					lft = itr.right - 1;
					max_wdt = mxr.right - lft - 1;
				}
			}
			this.subMnu.setPfrLft( pfr_lft );
			if ( mnr.width > max_wdt ) {
				mne.style.width = '' + max_wdt + 'px';
			}
			// 2. vertical placement
			const mnu_rqh = this.subMnu.getMnuHgt();
			let max_hgt = Math.min( mnu_rqh, mxr.height - 2 );
			let top = itr.top - mxr.top - 1;
			if ( mnu_rqh <= max_hgt ) {
				// it fits
				if ( ( top + mnu_rqh ) > mxr.height ) {
					if ( ( itr.bottom - mxr.top ) >= mnu_rqh ) {
						// upwards
						top = Math.max( itr.bottom - mnu_rqh - mxr.top, 0 );
					} else {
						// set the bottom edge of the menu at the bottom edge of the menu host
						top = mxr.height - mnu_rqh;
					}
				} else {
					// downwards - top is already set
				}
			} else {
				top = 0;
			}
			// set visible height - the menu might make itself scrollable
			this.subMnu.setVisHgt( max_hgt );
			// move the menu into the visible area
			mne.style.left = '' + lft + 'px';
			mne.style.top = '' + top + 'px';
		}
	}
}

console.debug( 'gui/menu/MnuItm.js loaded.' );
