import Checkbox from './Checkbox';
import EventListenerManager from '../../../../utils/EventListenerManager';
import Validator from '../../../../utils/Validator';
import XRowItem from '../../parts/XRowItem';

const NULL_CTT = 'null';

export default class TripleStateCheckbox extends Checkbox {

	constructor( cellObject ) {
		super( cellObject, null );
		cellObject.checkbox = this;
	}

	/**
	 * @override
	 */
	getType() {
		return 'tristate';
	}

	get isTripleState() {
		return true;
	}

	/**
	 * @returns {Boolean} true if the checkbox is in "undefined" state; false otherwise
	 */
	get isUnset() {
		return this._checkedState === null;
	}


	get userValue() {
		return this.isChecked ? true : this.isUnset ? '' : false;
	}

	get initialValue() {
		this.isOriginallyChecked ? true : this.isOriginallyUnset ? "" : false;
	}

	get isOriginallyUnchecked() {
		return Validator.false(this.originalValue);
	}

	get isOriginallyUnset() {
		const originalValue = this.originalValue;
		return originalValue === null || originalValue === 'null' || originalValue === '';
	}

	setCheckedStatus( newCellContent ) {
		return Validator.true(newCellContent) ? this.check() : (Validator.false(newCellContent) ? this.uncheck() : this.unset());
	}

	toggleInput() {
		const new_value = this.isChecked ? this.uncheck() : this.isUnset ? this.check() : this.unset();
		this.nextValue = this._checkedState;
		return new_value;
	}

	unset() {
		this._checkedState = null;
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.innerHTML = "";
		this.input.appendChild( this.newSquare );
		return true;
	}

	resetInput() {
		this.dirty = false;
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		EventListenerManager.removeListener( this, "blur", this.input, "Input" );
		this.isOriginallyChecked ? this.check() : this.isOriginallyUnset ? this.unset() : this.uncheck();
		return true;
	}

	render() {
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			return false;
		}
		this.discardUi();
		cellElement.innerHTML = "";
		this._setInput(this.newInput);
		if ( this.isOriginallyChecked ) {
			this.input.appendChild( this.newCheckMark );
			this._checkedState = true;
		} else if ( this.isOriginallyUnset ) {
			this.input.appendChild( this.newSquare );
			this._checkedState = null;
		} else {
			this._checkedState = false;
		}
		this.container = this.newContainer;
		this.container.style.justifyContent = "center";
		this.container.appendChild( this.input );
		cellElement.appendChild( this.container );
		return true;
	}

	get newSquare() {
		const squareDiv = window.document.createElement( "div" );
		squareDiv.classList.add( "null-value" );
		return squareDiv;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {String} text 
	 */
	applyCtt(text) {
		if ( Validator.isString(text) && (NULL_CTT !== text) ) {
			super.applyCtt(text);
		} else {
			this.unset();
		}
	}

	informAboutContentChange() {
		if ( !this.dirty || !this.editingAllowed ||
			( this.isOriginallyChecked === this.isChecked &&
				this.isOriginallyUnset === this.isUnset ) ) {
			return false;
		}
		this.informAboutSave();
		return true;
	}

	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const row = this.row;
		if ( !(row instanceof XRowItem) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		Object.assign( parameters, {
			idc: this.columnId,
			originalValue: this.isOriginallyChecked ? 'y' : this.isOriginallyUnset ? '' : 'n',
			userValue: this.isChecked ? 'y' : this.isUnset ? '' : 'n',
			inputId: this.inputId
		} );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

}
