import LoggingBase from "../../../base/loggingbase";

/**
 * table body part interface
 */
export default class IBody extends LoggingBase {
    /**
     * constructs a new instance
     * @param {string} ln logger name
     */
    constructor(ln) {
        super(ln);
    }

    /**
     * scrolls the table view
     * @param {boolean} dist scrolling distance: true: a page; false: a single line
     * @param {boolean} dir scrolling direction: true: upwards; false: downwards
     */
    scroll(dist, dir) {
        // abstract
    }

	/**
	 * toggles the "select all" status - selects all data rows if now row is currently selected;
	 * unselects all rows if there's at least one selected data row
	 */
	toggleSelectAll() {
        // abstract
    }

    /**
     * handles relevant "arrow" key events
	 * @param {Boolean} press flag whether the key pressed ("keydown") or released ("keyup")
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     * @param {Boolean} arrow "arrow key" flag
     * @param {Boolean} page "page up/down" flag
     * @param {Boolean} dir direction flag
     */
    onArrowKey(press, e, shift, ctrl, arrow, page, dir) {
        // abstract
    }

    /**
     * handles "[Tab]" key events
	 * @param {Boolean} press flag whether the key pressed ("keydown") or released ("keyup")
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     */
    onTabKey(press, e, shift, ctrl) {
        // abstract
    }

    /**
     * handles "[Space]" key events
	 * @param {Boolean} press flag whether the key pressed ("keydown") or released ("keyup")
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     * @returns {Boolean} true if the keyboard event was handled; false otherwise
     */
    onSpaceKey(press, e, shift, ctrl) {
        // abstract
        return false;
    }

	/**
	 * checks whether a clipboard operation is possible
	 * @returns {Boolean} true if a clipboard operation is possible; false otherwise
	 */
    canHandleClipboard() {
        // abstract
        return false;
    }

   	/**
	 * copies the selected rows to the clipboard
     * @param {Boolean} plain optional flag whether to create plain text clipboard data
	 */
	copyToClipboard(plain = false) {
        // abstract
    }

    /**
     * pastes data from clipboard, if available and applicable
     * @param {Boolean} alt flag whether the [Alt] key is pressed
     * @param {Boolean} plain optional flag whether to treat clipboard data as plain text 
     */
    pasteFromClipboard(alt = false, plain = false) {
        // abstract
    }

}