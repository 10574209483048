import MnuItm from './MnuItm';
import MnuObj from './MnuObj';
import PSA from '../../psa';
import JsPoint from '../../utils/JsPoint';
import JsRect from '../../utils/JsRect';
import Validator from '../../utils/Validator';
import Utils from '../../utils/Utils';
import QvwMgr from '../qvw/QvwMgr';
import HtmHelper from '../../utils/HtmHelper';
import Cke5Reg from '../../widgets/cke5/Cke5Reg';
import DomEventHelper from '../../utils/DomEventHelper';
import MenuHandler from './MenuHandler';

/** default menu item height in pixels */
const DEF_ITM_HGT = 40;
/** default rectangle size - see de.pisa.webcli.gui.dyn.men.MnuUtl.DEF_RCT_SIZ */
const DEF_RCT_SIZ = 14;

/**
 * menu manager class
 */
export default class MnuMgr {

	/**
	 * constructs a new instance
	 * @param {PSA} psa the PSA instance
	 * @param {CliCbkWdg} cbw callback widget
	 */
	constructor( psa, cbw ) {
		this._psa = psa;
		this.cbkWdg = cbw;
		this.menuHost = null;
		this.mnuFnt = null;
		this.mnuBgc = null;
		this.mnuTxc = null;
		this.mnuDtc = null;
		this.mnuHlc = null;
		this.itmHgt = DEF_ITM_HGT;
		this.curPos = new JsPoint( 0, 0 );
		this.clickLsr = null;
		this.cmdLsr = null;
		this.mmvLsr = Utils.bind( this, this._onMouseMove );
		document.body.addEventListener( 'mousemove', this.mmvLsr, false );
		this.curMnu = null;
		this.curHdl = null;
		this.qvwMgr = null;
	}

	/**
	 * destructor method
	 */
	destroy() {
		delete this.curMnu;
		delete this.curHdl;
		delete this.qvwMgr;
		if ( this.cmdLsr ) {
			document.removeEventListener( 'mousedown', this.cmdLsr );
		}
		delete this.cmdLsr;
		document.body.removeEventListener( 'mousemove', this.mmvLsr );
		delete this.mmvLsr;
		this._dropElms();
		delete this.mnuFnt;
		delete this.mnuBgc;
		delete this.mnuTxc;
		delete this.mnuDtc;
		delete this.mnuHlc;
		delete this.itmHgt;
		delete this.menuHost;
		delete this.cbkWdg;
		delete this.clickLsr;
		delete this.mmvLsr;
		delete this.curPos;
	}

	/**
	 * returns {PSA} the PSA instance
	 */
	get psa() {
		return this._psa;
	}

	/**
	 * returns the menu host element
	 * @returns {HTMLElement} the menu host element
	 */
	getHostElm() {
		return this.menuHost;
	}

	/**
	 * initializes the menu manager
	 * @param {Object} args arguments
	 */
	iniMnuMgr( args ) {
		if ( this._isRdy() ) {
			// drop old element
			this._dropElms();
		}
		const body = document.body;
		if ( body ) {
			const mnh = document.createElement( 'div' );
			mnh.id = '_psa_menu_host';
			mnh.style.position = 'absolute';
			mnh.style.top = '0px';
			mnh.style.left = '0px';
			mnh.style.width = '100%';
			mnh.style.height = '0px';
			mnh.style.background = 'transparent';
			mnh.style.cursor = 'default';
			mnh.style.zIndex = '1000000';
			mnh.style.display = 'none';
			if ( !this.cmdLsr ) {
				this.cmdLsr = Utils.bind( this, this._onCaptureMouseDown );
				document.addEventListener( 'mousedown', this.cmdLsr, true );
			}
			if ( !this.clickLsr ) {
				this.clickLsr = Utils.bind(this, this._onClick);
			}
			mnh.addEventListener('click', this.clickLsr);
			body.appendChild( mnh );
			// store DOM element
			this.menuHost = mnh;
			// read GUI properties
			this.mnuFnt = args.fnt || null;
			this.mnuBgc = args.bgc || null;
			this.mnuTxc = args.txc || null;
			this.mnuStc = args.stc || null;
			this.mnuDtc = args.dtc || null;
			this.mnuHlc = args.hlc || null;
			this.itmHgt = args.mih || DEF_ITM_HGT;
			// store reference of QuickView manager
			this.qvwMgr = QvwMgr.getInstance();
		}
	}

	/**
	 * return the current mouse cursor position
	 */
	getCurPos() {
		return new JsPoint( this.curPos.x, this.curPos.y );
	}

	/**
	 * indicates whether a menu is active
	 * @returns {Boolean} true if a menu is visible; false otherwise
	 */
	hasMnu() {
		return !!this.curMnu;
	}

	/**
	 * closes all currently open menus, if any
	 * @param {Boolean} rap flag whether to close all RAP menus
	 */
	closeAllMenus( rap ) {
		if ( this.menuHost ) {
			this.menuHost.style.display = 'none';
		}
		if ( this.curMnu ) {
			const mnu = this.curMnu;
			const hdl = this.curHdl;
			this.curMnu = null;
			this.curHdl = null;
			if ( mnu.alive ) {
				mnu.hide();
			}
			if ( hdl ) {
				hdl.onMenuClose( mnu );
			}
		}
		if ( rap && pisasales.ScrMen ) {
			pisasales.ScrMen.static.closeRapMenus();
		}
	}

	/**
	 * creates a popup menu
	 * @param {Number} idm menu ID
	 * @param {Array} items the menu items
	 * @returns {MnuObj} the popup menu
	 */
	createMenu( idm, items ) {
		return new MnuObj( this, null, idm, items );
	}

	/**
	 * shows a pop-up menu
	 * @param {MnuObj} menu the menu to be shown
	 * @param {Object} origin origin; provides the original HTML element and an optional area
	 * @param {MenuHandler} handler the menu event handler; handler's "onMenuItem(id)" method is called if a menu item is selected
	 * @param {Boolean} coverFullBodyArea flag whether the menu host should cover the full body area
	 * @param {Boolean} forceRightAlignment "force right alignment" flag
	 */
	showMenu( menu, origin, handler, coverFullBodyArea, forceRightAlignment ) {
		this._blurAllEditors();
		this.closeAllMenus( true );
		if ( this.qvwMgr ) {
			this.qvwMgr.frcQvwHid();
		}
		if ( [ menu, origin, handler ].some(
				object => !Validator.isObject( object ) ) || [
				this.menuHost, origin.element
			].some( element => !( element instanceof HTMLElement ) ) ) {
			return;
		}
		this.originElement = origin.element; // do not remove this line
		try {
			const bodyRect = document.body.getBoundingClientRect();
			const exp = !!( origin.rect instanceof DOMRectReadOnly ); // no clue
			const originRect = exp ? origin.rect : origin.element.getBoundingClientRect();
			const outside = !!origin.outside;
			const menuElement = menu.getElm();
			// show the menu host
			const menuHost = this.menuHost;
			const menuHostRect = new JsRect( 0,
											( coverFullBodyArea ? bodyRect.top : Math.max( originRect.bottom - 1, 0 ) ),
											bodyRect.width,
											( coverFullBodyArea ? bodyRect.height : ( bodyRect.height - ( originRect.bottom - 1 ) ) )
											);
			menuHost.style.left = '' + menuHostRect.left + 'px';
			menuHost.style.width = '' + menuHostRect.width + 'px';
			menuHost.style.top = '' + menuHostRect.top + 'px';
			menuHost.style.height = '' + menuHostRect.height + 'px';
			menuHost.style.display = '';
			// show the menu (and then we can deal with placement and size limits)
			menu.show();
			const menuRect = menuElement.getBoundingClientRect();
			// check horizontal edges
			let left = exp ? originRect.right : originRect.left;
			if ( forceRightAlignment || ((left + menuRect.width) > bodyRect.width) ) {
				left = Math.max( ( exp ? originRect.left : originRect.right ) - menuRect.width, 0 );
				menu.setPfrLft( true );
			} else {
				menu.setPfrLft( false );
			}
			// deal with height limit
			const rquMenuHgt = menu.getMnuHgt();
			let top = 0;
			let visMenuHgt = Math.min( rquMenuHgt, menuHostRect.height );
			if ( coverFullBodyArea ) {
				top = Math.max( originRect.bottom - bodyRect.top + 1, 0 );
				if ( (menuHostRect.bottom - top) < visMenuHgt ) {
					top = Math.max( menuHostRect.bottom - visMenuHgt - 8, 0 );
					if ( outside && ((menuHostRect.bottom - visMenuHgt) < originRect.bottom) && ((originRect.top - menuHostRect.top) < (menuHostRect.bottom - originRect.bottom)) ) {
						// there's more space below
						top = originRect.bottom + 1;
						visMenuHgt = Math.max( menuHostRect.bottom - top, 0 );
					}
				}
			} else {
				visMenuHgt = bodyRect.height - originRect.bottom;
			}
			if ( outside && (top < originRect.bottom) && ((top + visMenuHgt) > originRect.top) ) {
				// the menu would cover the original element, but it must stay outside
				if ( (bodyRect.right - originRect.right) > (originRect.left - bodyRect.left) ) {
					left = originRect.right + Math.max( Math.min( bodyRect.right - originRect.right - menuRect.width, 4 ), 0 );
				} else {
					left = originRect.left - menuRect.width - Math.max( Math.min( originRect.left - bodyRect.left - menuRect.width, 4 ), 0 );
				}
			}
			menu.setVisHgt( visMenuHgt );
			// move the menu into the visible area
			menuElement.style.left = '' + left + 'px';
			menuElement.style.top = '' + top + 'px';
			// store current menu and handler
			this.curMnu = menu;
			this.curHdl = handler;
		} catch ( e ) {
			console.error( "Error in menu handling!", e );
			this.closeAllMenus( false );
		}
	}

	/**
	 * hides a menu
	 * @param {MnuObj} menu the menu to be hidden
	 */
	hideMenu( menu ) {
		if ( menu ) {
			if ( menu === this.curMnu ) {
				this.closeAllMenus( false );
			} else if ( menu.alive ) {
				menu.hide();
			}
		}
	}

	/**
	 * called by a menu item if it was clicked
	 * @param {MnuItm} mni the menu item
	 * @param {Boolean} right "right click" flag
     * @param {Number} what an indicator which part was clicked:
     *                      0 - unspecified;
     *                      1 - the icon was clicked;
     *                      2 - the text was clicked
	 */
	onItemClick( mni, right, what ) {
		if ( this.curHdl ) {
			this.curHdl.onMenuItem( mni.getId(), this.curMnu, what );
		}
		if ( !right ) {
			this.closeAllMenus( false );
		}
	}

	/**
	 * @returns {Boolean} true if the instance is fully initialized
	 */
	_isRdy() {
		return !!this.menuHost;
	}

	/**
	 * drops the menu host element
	 */
	_dropElms() {
		if ( this.menuHost ) {
			this.menuHost.removeEventListener('click', this.clickLsr);
			HtmHelper.rmvDomElm(this.menuHost);
		}
		this.menuHost = null;
	}

	/**
	 * 'click' listener
	 * @param {MouseEvent} e the 'click' event
	 */
	_onClick( e ) {
		if ( e.target === this.menuHost ) {
			DomEventHelper.stopEvent(e);
			this.closeAllMenus( false );
		}
	}

	/**
	 * 'mouse down' listener in the capturing phase
	 * @param {MouseEvent} e the 'mouse down' event
	 */
	_onCaptureMouseDown( e ) {
		if ( this.menuHost && this.hasMnu() ) {
			const rct = this.menuHost.getBoundingClientRect();
			const x = e.clientX;
			const y = e.clientY;
			const out = ( x < rct.left ) || ( x > rct.right ) || ( y < rct.top ) || ( y > rct.bottom );
			if ( out ) {
				// close *our* menus, not the RAP menus
				this.closeAllMenus( false );
			}
		}
	}

	/**
	 * 'mouse move' listener
	 * @param {MouseEvent} e the 'mouse move' event
	 */
	_onMouseMove( e ) {
		this.curPos.x = e.clientX;
		this.curPos.y = e.clientY;
	}

	/**
	 * removes focus from all ck editor 5 instances (blurs them)
	 * @return <true> if the action was successful, <false> otherwise
	 * @see /webCli/src/de/pisa/webcli/cstwdg/dtrpck/js/DtrPck.js~blurAllEditors
	 */
	_blurAllEditors() {
		// TODO this is a function that copies exactly the behavior in
		// /webCli/src/de/pisa/webcli/cstwdg/dtrpck/js/DtrPck.js~blurAllEditors
		return  Cke5Reg.getInstance().blurAllCke5Editors();
	}
}
