import LoggingBase from "../base/loggingbase";

/**
 * generic object that can hold the DOM focus
 */
export default class FocusHolder extends LoggingBase {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'gui.FocusHolder')
        this._locked = 0;
    }

    /**
     * @returns {Boolean} true if the focus is locked by this element; false otherwise
     */
    get locked() {
        return this._locked > 0;
    }

    /**
     * locks the focus by this element
     */
    lock() {
        ++this._locked;
    }

    /**
     * releases the focus by this element
     */
    release() {
        --this._locked;
        if ( this._locked < 0 ) {
            throw new Error('Mismatch in lock() / release() calls!');
        }
    }

    /**
     * @returns {Number} the current lock counter value
     */
    get lockCount() {
        return this._locked;
    }

    /**
     * restores a specific lock counter
     * @param {Number} lc the lock counter value to set
     */
    restoreLock(lc) {
        this._locked = lc;
    }

    /**
     * sets the focus to this element
     * @abstract
     */
    setFocus() { /* abstract */ }

    /**
     * notifies this instance about forced blur
     * @param {Boolean} unlock flag whether to for a locked focus holder to be unlocked
     * @abstract
     */
    forceBlur(unlock = false) { /* abstract */ }

    /**
     * applies all pending data changes if required
     */
    applyChanges() { /* abstract */}

    /**
     * @returns {Boolean} true if this instance should be kept alive and focused on any data operation; false for common behavior
     */
    get isKeepForced() {
        return false;
    }
}