import Validator from '../Validator';
import ConditionalItem from './ConditionalItem';
import ConditionInterpreter from './ConditionInterpreter';

export default class Condition extends ConditionalItem {

	constructor( {
		operands = null,
		callback = null,
		expression = null,
		operator = null
	} ) {
		super( {
			operands: operands,
			callback: callback,
			expression: expression,
			operator: operator
		} );
	}

	static from( condition ) {
		if ( !( condition instanceof Condition ) ) return void 0;
		return new Condition( {
			operands: condition.expressionOperands,
			callback: condition.callback,
			expression: String( condition.expression ),
			operator: String( condition.operator )
		} );
	}

	evaluate() {
		if ( Validator.isBoolean( this.result ) ) return this.result;
		let operandsResults = [];
		this.operands.forEach( operand => {
			if ( Validator.isBoolean( operand.result ) )
				return operandsResults.push( operand.result );
			operand.evaluate();
			operandsResults.push( operand.result );
		} );
		this.result = Validator.isFunction( this.callback ) ?
			this.callback( operandsResults ) : this._defaultCallback();
		return this.result;
	}

	forceEvaluate() {
		let operandsResults = [];
		this.operands.forEach( operand => {
			operand.forceEvaluate();
			operandsResults.push( operand.result );
		} );
		this.result = this.callback( operandsResults );
		return this.result;
	}

	_conditionChildren() {
		for ( let index = 0; index < this.operands.length; index++ ) {
			this.operands[ index ] = ConditionInterpreter
				.toCondition( this.operands[ index ] );
		}
	}

	get expressionOperands() {
		let expressionOperands = [];
		for ( let operand of this.operands )
			expressionOperands =
			expressionOperands.concat( operand.expressionOperands );
		return expressionOperands;
	}

	get uniqueExpressionOperands() {
		return Array.from( new Set( this.expressionOperands ) );
	}

	modifyExpressionOperands( changeCallback ) {
		if ( !Validator.isFunction( changeCallback ) ) return;
		this._modifyExpressionOperands( changeCallback );
	}

	_modifyExpressionOperands( changeCallback ) {
		for ( let operand of this.operands )
			operand._modifyExpressionOperands( changeCallback );
	}

	stringify() {
		if ( !Validator.isArray( this.operands, true ) ) return "";
		if ( this.operands.length == 1 )
			return `${ this.operator }( ${ this.operands[ 0 ].stringify() } )`;
		return `( ${ this.operands[ 0 ].stringify() } ) ${ this.operator }` +
			` ( ${ this.operands[ 1 ].stringify() } )`;
	}

}

console.debug( 'utils/condition/Condition.js loaded.' );
