// a collection of constants used by XtwBody

const DEF_HDR_HGT = 22;
const DEF_ROW_HGT = 22;

const TMO_FETCH = 100;

const ROW_TEMPLATE_BODY_CLASS = "row-template";
const TABLE_BODY_CLASS = "table";

const ROW_HOVERED_BACKGROUND_COLOR = "--rtp-background-hovered";
const ROW_HOVERED_TEXT_COLOR = "--rtp-text-hovered";
const ROW_SELECTED_BACKGROUND_COLOR = "--rtp-background-selected";
const ROW_SELECTED_TEXT_COLOR = "--rtp-text-selected";

const UI_REFRESH_REQUEST = '# xtwBodyUIRefresh #';
const UI_ROWUPDATE_REQUEST = '## xtwBodyRowItemsRefresh ##';
const UI_SCROLL_REQUEST = '### xtwBodyScrolling ###';
const UI_STATUS_REQUEST = '#### xtwBodyScrolling ####';

const MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY = 5000;		// a pretty large limit :-)

/** "no focus" notification flag - prevents focus notifications */
const NO_NFY_FOCUS = 0x01;
/** "no selection" notification flag - prevents selection notifications */
const NO_NFY_SELECTION = 0x02;

const AFTER_SCROLLING_CALLBACKS = '_afterScrollViewUpdateCallbacks';
const AFTER_MODELDATA_CALLBACKS = '_afterModelDataCallbacks';

export {
    DEF_HDR_HGT,
    DEF_ROW_HGT,
    TMO_FETCH,
    ROW_TEMPLATE_BODY_CLASS,
    TABLE_BODY_CLASS,
    ROW_HOVERED_BACKGROUND_COLOR,
    ROW_HOVERED_TEXT_COLOR,
    ROW_SELECTED_BACKGROUND_COLOR,
    ROW_SELECTED_TEXT_COLOR,
    UI_REFRESH_REQUEST,
    UI_ROWUPDATE_REQUEST,
    UI_SCROLL_REQUEST,
    UI_STATUS_REQUEST,
    MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY,
    NO_NFY_FOCUS,
    NO_NFY_SELECTION,
    AFTER_SCROLLING_CALLBACKS,
    AFTER_MODELDATA_CALLBACKS
};