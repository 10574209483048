import LoggingBase from "../../base/loggingbase";
import ObjReg from "../../utils/ObjReg";
import Validator from "../../utils/Validator";
import Utils from "../../utils/Utils";

/**
 * class Cke5Reg - a registry for CKE5 editor instances
 */
export default class Cke5Reg extends LoggingBase {
    
    /**
     * constructs a new instance
     */
    constructor() {
        super('widgets.cke5.Cke5Reg');
        this._cke5Reg = new ObjReg();
		this._boundPsaObjClicked = Utils.bind( this, this._psaObjClicked ); // this function must be bound!
    }

    /**
     * @returns {Cke5Reg} the singleton instance
     */
    static getInstance() {
        return singleton;
    }

    /**
     * destructor method
     */
    doDestroy() {
        this._cke5Reg.clear();
        super.doDestroy();
    }
    
    /**
     * @returns {Function} the object link handling method
     */
    get psaObjClicked() {
        return this._boundPsaObjClicked;
    }

	/**
	 * returns the common register for CKEditor 5 instances
	 * @returns {ObjReg} the common register for CKEditor 5 instances
	 */
    getCke5ObjReg() {
		return this._cke5Reg;
	}

	/**
	 * global editor retrieval function
	 * @param {String} idw widget ID
	 * @param {Boolean} getWatchdog flag whether to get the watchdog
	 */
    getCke5Editor( idw, getWatchdog = false ) {
		const cke5 = this._cke5Reg.getObj( idw );
		if ( cke5 ) {
			// CkEdt5 instance found; return instance's editor or watchdog
			return ( getWatchdog ? cke5.watchdog : cke5.editor ) || null;
		} else {
			return null;
		}
	}

    /**
     * blurs all known CKE5 editor instances
     * @returns always true :-)
     */
	blurAllCke5Editors() {
		const ckEdt5Values = this._cke5Reg.getValues();
		for ( let ckEdt5 of ckEdt5Values ) {
			if ( !Validator.isObject( ckEdt5 ) ) {
				continue;
			}
			const watchdog = ckEdt5.watchdog;
			if ( !Validator.isObject( watchdog ) || !Validator.isFunction( watchdog.blur ) ) {
				continue;
			}
			watchdog.blur();
		}
		window.document.body.focus();
		return true;
	}

	/**
	 * PiSA sales object hyperlink handler
	 * @param {String} idw widget ID
	 * @param {Boolean} href hyperlink flag
	 */
    _psaObjClicked( idw, href ) {
		const cke5 = this._cke5Reg.getObj( idw );
		if ( cke5 ) {
			cke5.nfyPsaObjLink( href );
		} else {
			this.warn( 'No editor instance found for ID "' + idw + '"!' );
		}
	}


}

const singleton = new Cke5Reg();