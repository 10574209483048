import Base from "../base/base";
import Validator from "./Validator";

/**
 * a helper class providing a RAP widget
 */
export default class RapWidget extends Base {

    /**
     * constructs a new instance
     * @param {String} idw widget ID
     * @param {*} wdg RAP widget object
     */
    constructor(idw, wdg) {
        super();
        if ( !Validator.isString(idw) || !Validator.isObject(wdg) ) {
            throw new Error('Invalid arguments specified!');
        }
        this._idw = idw;
        this._wdg = wdg;
        this._element = null;
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        delete this._idw;
        delete this._wdg;
        delete this._element;
        super.doDestroy();
    }

    /**
     * @returns {String} the widget ID
     */
    get idw() {
        return this._idw;
    }

    /**
     * @returns {*} the RAP widget object
     */
    get wdg() {
        return this._wdg;
    }

    /**
     * @returns {HTMLElement} the DOM element, if present
     */
    get element() {
        if ( !this.alive ) {
            return null;
        }
        if ( !this._element ) {
            this._element = this._getDomElement();
        }
        return this._element;
    }

    /**
     * retrieves the DOM element
     * @returns {HTMLElement} the DOM element or null
     */
    _getDomElement() {
        if ( this.alive ) {
            const wdg = this._wdg
            if ( wdg._element instanceof HTMLElement ) {
                return wdg._element;
            } else if ( wdg.$el ) {
                const e = wdg.$el.get(0);
                if ( e instanceof HTMLElement ) {
                    return e;
                }
            }
        }
        return null;
    }
}