import Validator from '../../../utils/Validator';
import XtwRowTemplateCellContentItem from './XtwRowTemplateCellContentItem';
import XtwRowTemplateExtendedItem from './XtwRowTemplateExtendedItem';

export default class XtwRowTemplateCellContentContainer extends XtwRowTemplateExtendedItem {

	create() {
		super.create();
		this._defineTrueGetterProperty( "isCellContentContainer" );
		this.renderBlocked = false;
	}

	render() {
		if ( !this.isVisible || this.renderBlocked ) return;
		super.render();
		if ( this.alignment == "left" ) this.addClasses( "left" );
	}

	updateContent( args ) {
		if ( !Validator.isObjectPath( args, "args.ctt" ) ) return;
		this.setContent( args.ctt.text, args.ctt.prop );
	}

	onClick( evt, parameters = void 0 ) {
		// should we check creation parameters if this is a link?
		if ( !Validator.isObject( parameters ) ) parameters = {};
		parameters.idc = Number( this.itemId );
		if ( !Validator.isString( parameters.fromItem ) )
			parameters.fromItem = Validator.getClassName( this );
		super.onClick( evt, parameters );
	}

	setContent( content, formatting ) {
		if ( !Validator.isString( content ) ) return;
		if ( !this.isRendered ) {
			this.render();
			if ( !this.isRendered ) return;
		}
		this.removeChildren();
		this._addContentChild( content, formatting );
	}

	_addContentChild( content, formatting ) {
		let parameters = Validator.isObjectPath( this,
				"this._creationParameters.parameters" ) ?
			this._creationParameters.parameters : {};
		let contentChild = new XtwRowTemplateCellContentItem( this,
			Object.assign( parameters, {
				initialContent: content,
				initialFormatting: formatting,
				itemId: Validator.generateRandomString( "cell-content-item-" )
			} ) );
		contentChild.renderBlocked = false;
		contentChild.setContent( content, formatting );
		this.addChild( contentChild );
	}

}
