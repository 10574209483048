import DndWtc from './DndWtc';
import PSA from '../psa';
import EscHandler from './EscHandler';
import Utils from '../utils/Utils';
import Validator from '../utils/Validator';
import BscMgr from '../gui/BscMgr';
import KeyBlocker from './KeyBlocker';

const DRG_BEG_EVT = [ 'dragenter', 'dragover', 'dragstart' ];
const DRG_END_EVT = [ 'dragend', 'dragexit', 'dragleave', 'drop' ];


/**
 * global key handler class
 */
export default class KeyHdl extends KeyBlocker {

	/**
	 * @returns {KeyHdl} the singleton instance
	 */
	static getInstance() {
		return KeyHdl.instance;
	}

	/**
	 * constructs a new instance
	 * @param {PSA} psa the global PSA instance
	 */
	constructor( psa ) {
		super('key.KeyHdl');
		KeyHdl.instance = this;
		this._psa = psa;
		const txf = {};
		txf[ "rwt.widgets.base.BasicText" ] = true;
		txf[ "rwt.widgets.Text" ] = true;
		txf[ "rwt.widgets.Combo" ] = true;
		this.txtFld = txf;
		// "frame" widgets that indicate that the shell has the focus; and the RAP guys will hopefully never change this :-)
		const frm = {};
		frm[ 'rwt.widgets.base.HorizontalSpacer' ] = true;
		this.frmWdg = frm;
		this.hdlSpc = false;
		this.capKhd = Utils.bind( this, this._onCaptureKeyDown );
		this.dndWtc = new DndWtc( this, false ); // no DOM events so far, since RAP "eats" all mouse events and synthesizes drag'n'drop handling
		this.escHandlers = new Array();
	}

	/**
	 * destructor method
	 */
	doDestroy() {
		this.escHandlers.length = 0;
		delete this.escHandlers;
		this.dndWtc.destroy();
		if ( this.hdlSpc ) {
			document.removeEventListener( 'keydown', this.capKhd );
		}
		delete this.dndWtc;
		delete this.capKhd;
		super.doDestroy();
	}

	/**
	 * creates a key descriptor
	 * @param {String} key the key identifier
	 * @param {Boolean} ctr "Ctrl" flag
	 * @param {Boolean} alt "Alt" flag
	 * @param {Boolean} sft "Shift" flag
	 * @param {Number} cod the key code
	 * @return {String} the key descriptor
	 */
	getKeyDsc( key, ctr, alt, sft, cod ) {
		let dsc = "";
		let flg = 0;
		if ( sft ) {
			flg |= 0x01;
		}
		if ( ctr ) {
			flg |= 0x02;
		}
		if ( alt ) {
			flg |= 0x04;
		}
		dsc = dsc + flg;
		switch ( key ) {
			case "Enter":
				dsc = dsc + "\\RET";
				break;
			case "Escape":
				dsc = dsc + "\\ESC";
				break;
			case "Up":
				dsc = dsc + "\\CUP";
				break;
			case "Down":
				dsc = dsc + "\\CDN";
				break;
			case "Left":
				dsc = dsc + "\\CLF";
				break;
			case "Right":
				dsc = dsc + "\\CRT";
				break;
			case "Delete":
				dsc = dsc + "\\DEL";
				break;
			case "Insert":
				dsc = dsc + "\\INS";
				break;
			case "Home":
				dsc = dsc + "\\HOM";
				break;
			case "End":
				dsc = dsc + "\\END";
				break;
			case "PageUp":
				dsc = dsc + "\\PUP";
				break;
			case "PageDown":
				dsc = dsc + "\\PDN";
				break;
			case "Tab":
				dsc = dsc + "\\TAB";
				break;
			case "F1":
			case "F2":
			case "F3":
			case "F4":
			case "F5":
			case "F6":
			case "F7":
			case "F8":
			case "F9":
			case "F10":
			case "F11":
			case "F12":
			case "F13":
			case "F14":
			case "F15":
			case "F16":
			case "F17":
			case "F18":
			case "F19":
			case "F20":
			case "F21":
			case "F22":
			case "F23":
			case "F24":
				dsc = dsc + "\\";
				if ( key.length < 3 ) {
					dsc = dsc + "F0" + key.charAt( 1 );
				} else {
					dsc = dsc + key;
				}
				break;
			case "Control":
			case "Shift":
			case "Alt":
			case "Meta":
			case "CapsLock":
			case "Space":
			case "Backspace":
			case "Print":
			case "PrintScreen":
			case "Win":
				// not supported, not handled,...
				dsc = "";
				break;
			case "Unidentified":
				if ( cod === 190 ) {
					dsc = dsc + ".";
				} else {
					dsc = "";
				}
				break;
			default:
				if ( Validator.isString( key ) ) {
					dsc = dsc + key;
				} else {
					dsc = '';
				}
				break;
		}
		return dsc;
	}

	/**
	 * creates a key descriptor from a key event
	 * @param {Event} evt key event
	 * @param {Boolean} mac flag whether we're on a Mac
	 * @return {String} the key descriptor
	 */
	getEvtKeyDsc( evt, mac ) {
		return this.getKeyDsc( evt.getKeyIdentifier(), evt.isCtrlPressed() || ( mac && evt.isMetaPressed() ), evt.isAltPressed(), evt.isShiftPressed(), evt.getKeyCode() );
	}

	/**
	 * activates or deactivates the special key handling
	 * @param {Boolean} spc "special key handling" flag
	 */
	setSpcHdl( spc ) {
		this.hdlSpc = !!spc;
		const domdoc = window.document;
		if ( this.hdlSpc ) {
			this.log( '+++ Special key handling activated.' );
			domdoc.addEventListener( 'keydown', this.capKhd, true );
			domdoc.body.focus();
		} else {
			domdoc.removeEventListener( 'keydown', this.capKhd );
			this.log( '--- Special key handling deactivated.' );
		}
	}

	/**
	 * adds an [Esc] handler
	 * @param {EscHandler} h the [Esc] handler to be added
	 */
	addEscHandler(h) {
		if ( !(h instanceof EscHandler) ) {
			throw new Error('Invalid [Esc] handler given!');
		}
		this.escHandlers.push(h);
	}

	/**
	 * removed an [Esc] handler
	 * @param {EscHandler} h the [Esc] handler to be removed
	 */
	 rmvEscHandler(h) {
		if ( !(h instanceof EscHandler) ) {
			throw new Error('Invalid [Esc] handler given!');
		}
		const eh = this.escHandlers;
		if ( eh.length > 0 ) {
			if ( eh[eh.length-1] === h ) {
				eh.pop();
			}
			else {
				let idx = eh.indexOf(h);
				if ( idx > -1 ) {
					eh.splice(idx, 1);
				}
			}
		}
	}

	/**
	 * checks whether a key combination should be sent to the application server
	 */
	getHdlFlg( ctr, sft, alt ) {
		// possible hotkeys are <Crl>[<Shift>][<Alt>]<key>, <Shift><Alt><key> but not <Ctrl><Alt><key !== 'R'> or any other <Shift><key> or <Alt><key> combination
		const hdl = ( ctr || ( sft && alt ) ) && ( !( ctr && alt && !sft ) );
		return hdl;
	}

	/**
	 * handles a key stroke event (usually "keydown")
	 * @param {Event} evt RAP keyboard event (rwt.event.KeyEvent)
	 * @param {Boolean} dlg true to handle "dialog" key strokes ("Escape" and "Enter"); false to not handle these key strokes
	 * @return {Boolean} true if the key stroke was handled; false otherwise
	 */
	hdlKey( evt, dlg ) {
		try {
			const dbg = this.isDebugEnabled();
			const dom_evt = evt.getDomEvent();
			if ( this.isTraceEnabled() ) {
				this.trace('Handling keyboard event: ', dom_evt);
			}
			if ( this.isBlocked() ) {
				if ( this.isTraceEnabled() ) {
					this.trace('Checking keyboard event in "blocked" state.', evt);
				}
				return this._checkBlockedEvent(dom_evt);
			}
			const bsc = BscMgr.getInstance();
			if ( (bsc instanceof BscMgr) && bsc.isSrvBlk() ) {
				this.stopKeyEvt( evt );
				return true;
			}
			const wdg = evt.getTarget();
			const dt = ( wdg ? ( wdg._element || null ) : null ) || evt.getDomTarget();
			if ( this.isTraceEnabled() ) {
				this.trace('Target RAP widget:', wdg);
				this.trace('DOM target:', dt);
			}
			if ( dt && dt._keyEvtHdl ) {
				// the DOM element has a key event handler
				this.log('Forwarding keyboard event to target widget', dt);
				if ( dt._keyEvtHdl.hdlKeyEvt( dom_evt, dt ) ) {
					// handled by the key event handler that is connected to the DOM element
					this.log('Keyboard event handled by target widget.')
					this.stopKeyEvt( evt );
					return true;
				}
			}

			const dom_key = dom_evt.key || '';
			const mac = this._psa.Info.isMac();
			const dsc = this.getEvtKeyDsc( evt, mac );
			const key = evt.getKeyIdentifier();
			const sft = evt.isShiftPressed();
			const ctr = evt.isCtrlPressed() || ( mac && evt.isMetaPressed() );
			const alt = evt.isAltPressed(); // Mac: that's the option key
			const cls = wdg ? wdg.classname : null;

			if ( dbg ) {
				this.log( 'DOM event key      = "' + dom_key + '"' );
				this.log( 'KEY identifier     = "' + key + '"' );
				this.log( 'PSA key descriptor = "' + dsc + '"' );
			}

			if ( dom_key.length === 1 ) {
				// the key stroke was translated into a single character
				if ( !ctr || !dsc.length ) {
					// that is a single character input
					if ( dbg ) {
						this.log( ' +---> never a hotkey, no special handling.' );
					}
					return false;
				}
			}

			let hdl = false;
			let stop = true;
			let flush = true;
			switch ( key ) {
				case "Enter":
					if ( this.isNativeEditableField( wdg, cls ) ) {
						// stop handling the key
						let usd = wdg.getUserData( 'org.eclipse.swt.widgets.Widget#data' );
						let fwd = !!( usd && usd[ 'pisasales.CSTPRP.RET' ] );
						if ( fwd ) {
							// do *not* forward it to the widget (endless recursion!) but send it to the web server instead
							hdl = dlg && !sft && !ctr && !alt;
						}
						if ( hdl ) {
							stop = false;
						} else {
							return false;
						}
					}
					// fall-through
					case "Escape":
						if ( this.hdlSpc && key === 'Escape' ) {
							this.sndSpcKey( evt, dsc );
							return true;
						}
						hdl = dlg && !sft && !ctr && !alt;
						if ( hdl ) {
							// dirty hack - check for focus on the dialog shell itself; in that case we should *NOT* send this keystroke
							if ( key === 'Escape' && !!this.frmWdg[ cls ] ) {
								// that indicates that the shell has the focus; and the RAP guys will hopefully never change this :-)
								hdl = false;
							}
						}
						break;
					case "Down":
					case "Up":
						this.handleNavigationKey( evt, dt );
						// We need the alt+up combination
						hdl = alt && !ctr && !sft;
						break;
					case "A":
						// allow Ctrl+A to be handled by text field
						if ( ( ctr && !sft && !alt ) && cls && this.isNativeEditableField( wdg, cls ) ) {
							hdl = false;
						} else if ( ctr || ( sft && alt ) ) {
							hdl = true;
						}
						break;
					case "C":
					case "V":
					case "X":
						// plain keystrokes or Ctrl+C|V|X should *not* be forwarded to the web server
						hdl = ctr && sft && alt;
						if ( !hdl && ctr && alt && ( key === "X" ) ) {
							// block screen rescue key <Ctrl><Alt><X> :-)
							this._psa.frcBlkScr( false );
						}
						break;
					case "I":
						if ( !this._psa.Info.isMS() && sft && ctr && !alt || mac && ctr && alt && !sft ) {
							// <Shift><Ctrl><I> --> developer tools
							return false;
						} else if ( ctr || ( sft && alt ) ) {
							hdl = true;
						}
						break;
					case "L":
						if ( ctr && alt && ( !cls || !this.isNativeEditableField( wdg, cls ) ) ) {
							// <Ctrl><Alt><L> --> toggle logging
							this._psa.Log.setLogAct( !this._psa.Log.isLogAct() );
							hdl = false;
						} else {
							hdl = this.getHdlFlg( ctr, sft, alt );
						}
						break;
					case 'Q':
						hdl = this.getHdlFlg( ctr, sft, alt );
						flush = !hdl;		// <Ctrl><Q> must not flush current editor!
						break;
					case 'Y':
					case 'Z':
						hdl = ( ( ctr && !this.isHtmlEditor( wdg, cls ) ) || ( sft && alt ) );
						break;
					case "F1":
					case "F2":
					case "F3":
					case "F4":
					case "F5":
					case "F6":
					case "F7":
					case "F8":
					case "F9":
					case "F10":
					case "F13":
					case "F14":
					case "F15":
					case "F16":
					case "F17":
					case "F18":
					case "F19":
					case "F20":
					case "F21":
					case "F22":
					case "F23":
					case "F24":
						hdl = true;
						break;
					case "F11":
						if ( !sft && !ctr && !alt ) {
							// toggle full screen
							return false;
						}
						hdl = true;
						break;
					case "F12":
						if ( this._psa.Info.isMS() && !sft && !ctr && !alt ) {
							// IE/Edge: <F12> --> developer tools
							return false;
						}
						hdl = true;
						break;
					case "Backspace":
						if ( cls ) {
							if ( !this.isNativeEditableField( wdg, cls ) && !this.eventComesFromRtpInput( evt ) ) {
								// class *not* a text field, stop backspace from navigating back
								this.stopKeyEvt( evt );
							}
							// no server-side backspace handling
							return false;
						}
						break;
					case "Home":
					case "End":
					case "Left":
					case "Right":
					case "PageUp":
					case "PageDown":
					case "Tab":
						if ( bsc instanceof BscMgr ) {
							bsc.flushFocusHolder();
						}
						this.handleNavigationKey( evt, dt );
						// never handled otherwise!!
						break;
					case "+":
					case "-":
					case "Delete":
					case "Insert":
					case "Control":
					case "Shift":
					case "Alt":
					case "CapsLock":
					case "Space":
					case "Win":
					case "PrintScreen":
					case "Print":
					case "Meta":
					case "Apps":
						// never handled!
						break;
					case "Unidentified":
						if ( dsc && ( dsc.length > 0 ) ) {
							// that's a dot ('.')
							if ( ( !ctr && !sft && !alt ) || ( cls && ( this.isNativeEditableField( wdg, cls ) ) ) ) {
								// no modifier (plain '.') and/or we're in a text field
								hdl = false;
							} else {
								// let the server handle this
								hdl = true;
							}
						}
						break;
					default:
						// check for possible hotkey
						if ( ctr && alt && !sft && ( key === 'R' ) ) {
							// <Ctrl><Alt><R> is the only <Ctrl><Alt> combination that's handled by the web server
							hdl = true;
						} else {
							// possible hotkeys are <Crl>[<Shift>][<Alt>]<key>, <Shift><Alt><key> but not <Ctrl><Alt><key !== 'R'> or any other <Shift><key> or <Alt><key> combination
							hdl = this.getHdlFlg( ctr, sft, alt );
						}
						break;
			}
			if ( hdl ) {
				// stop propagation and handle it here (send to application server)
				if ( dsc.length > 1 ) {
					if ( flush && (bsc instanceof BscMgr) ) {
						bsc.flushFocusHolder();
					}
					hdl = this.sndKeyStroke( evt, dsc, stop );
				}
			}
			// done
			return hdl;
		} catch ( err ) {
			this._psa.Log.logErr( err );
			if ( this._psa.cliCbkWdg ) {
				this._psa.cliCbkWdg.nfyErr( "pisasales.KeyHdl.hdlKey", err, null );
			}
			return false;
		}
	}

	handleNavigationKey( rapEvent, domTarget ) {
		if ( !( domTarget instanceof HTMLElement ) ||
			typeof domTarget._transferDomEvent != "function" ||
			!rapEvent || typeof rapEvent != "object" ) {
			return false;
		}
		return domTarget._transferDomEvent( rapEvent._valueDomEvent );
	}

	/**
	 * sends a "key stroke" request to the web server
	 * @param {Event} evt keyboard event (rwt.event.KeyEvent)
	 * @param {String} dsc hotkey descriptor
	 * @param {Boolean} stop flag whether to stop further processing of the keyboard event
	 * @returns {Boolean} true if the notification was sent; false otherwise
	 */
	sndKeyStroke( evt, dsc, stop ) {
		if ( stop ) {
			// stop this event
			this.stopKeyEvt( evt );
		}
		if ( this._psa.cliCbkWdg ) {
			// send key stroke request to the web server
			const param = {};
			param.keyCod = dsc;
			this._psa.cliCbkWdg.nfyGlb( 'keyEvt', param, true );
			return true;
		} else {
			return false;
		}
	}

	/**
	 * sends a "special" key stroke event to the web server
	 * @param {Event} evt keyboard event (rwt.event.KeyEvent)
	 * @param {String} dsc hotkey descriptor
	 */
	sndSpcKey( evt, dsc ) {
		// always stop this event
		this.stopKeyEvt( evt );
		if ( this._psa.cliCbkWdg ) {
			const par = {};
			par.keyCod = dsc;
			this._psa.cliCbkWdg.nfyGlb( 'keySpecial', par, true );
		}
	}

	/**
	 * stops the propagation of the specified event
	 * @param {Event} evt the key event
	 */
	stopKeyEvt( evt ) {
		evt.stopPropagation();
		evt.preventDefault();
	}

	/**
	 * Checks whether the widget is the HTML editor. Keys in HTML mode are handled by the internal iframe, but in text-mode
	 * they land here. Some specific keys such as Enter and Backspace need to be handled by the editor and not this key listener.
	 * @param {Widget} widget the widget
	 * @param {String} className the name of the server-side class of the widget
	 * @return {Boolean} true if the widget is an HTML editor; false otherwise
	 */
	isHtmlEditor( widget, className ) {
		if ( className === 'rwt.widgets.Composite' ) {
			let wm = rwt.remote.WidgetManager.getInstance();
			let wid = wm.findIdByWidget( widget );
			let wob = rap.getObject( wid );
			if ( wob ) {
				let cwd = wob.getData( 'pisasales.CSTPRP.CWD' );
				if ( cwd ) {
					return cwd.editor || ( cwd.className === 'de.pisa.webcli.cstwdg.htmedr.HtmEdr' );
				}
			}
		}
		return false;
	}

	/**
	 * Checks whether the widget is a natively editable field such an input field or a textarea.
	 * @param {rwt.widgets.base.Widget} widget the widget
	 * @param {String} className the class name of the widget
	 * @return {Boolean} true if the widget is a natively editable field; false otherwise
	 */
	isNativeEditableField( widget, className ) {
		return ( this.txtFld[ className ] || this.isHtmlEditor( widget, className ) );
	}

	eventComesFromRtpInput( rapEvent ) {
		if ( !rapEvent || typeof rapEvent != "object" ||
			!( rapEvent._valueDomEvent instanceof KeyboardEvent ) ) {
			return false;
		}
		const domEventInputId = rapEvent._valueDomEvent.inputId;
		return typeof domEventInputId == "string" && domEventInputId.length >= 0;
	}

	/**
	 * converts a DOM event into a RAP event
	 * @param {Event} domKeyEvent the DOM event
	 * @param {String} eventType the event name of the RAP event
	 * @return {Event} the correspodning RAP event
	 */
	domKeyEventToRap( domKeyEvent, eventType ) {
		let EventHandlerUtil = rwt.event.EventHandlerUtil;
		let keyCode = EventHandlerUtil.getKeyCode( domKeyEvent );
		let charCode = EventHandlerUtil.getCharCode( domKeyEvent );
		let keyIdentifier;
		if ( !isNaN( keyCode ) && ( keyCode !== 0 ) ) {
			keyIdentifier = EventHandlerUtil.keyCodeToIdentifier( keyCode );
		} else {
			keyIdentifier = EventHandlerUtil.charCodeToIdentifier( charCode );
		}
		let domTarget = EventHandlerUtil.getDomTarget( domKeyEvent );
		let keyEvent = new rwt.event.KeyEvent( eventType, domKeyEvent, domTarget, null, null, keyCode, charCode, keyIdentifier );
		return keyEvent;
	}

	/**
	 * handles keyboard events in the capturing phase
	 * @param {KeyboardEvent} evt the DOM keyboard event
	 */
	_onCaptureKeyDown( evt ) {
		if ( this.isBlocked() ) {
			if ( this.isTraceEnabled() ) {
				this.trace('Key handling blocked. Ignoring keyboard event: ', evt);
			}
			return;
		}
		if ( this.isTraceEnabled() ) {
			this.trace('Capture key down: ', evt);
		}
		switch ( evt.key ) {
			case 'Escape':
				this._hdlEscape( evt );
				break;
			default:
				break;
		}
	}

	/**
	 * handles [Esc] keystrokes
	 * @param {KeyboardEvent} evt the DOM keyboard event
	 */
	_hdlEscape( evt ) {
		// check [Esc] handlers first
		let handled = false;
		const eh = this.escHandlers;
		if ( eh.length > 0 ) {
			// use the "top" handler only!
			const h = eh[eh.length - 1];
			handled = h.handleEsc(evt);
		}
		if ( !handled ) {
			// we "eat" each and every [Esc] keystroke
			const dsc = this.getKeyDsc( evt.key, evt.ctrlKey || evt.metaKey, evt.altKey, evt.shiftKey, 0 );
			this.sndSpcKey( evt, dsc );
		} else {
			// processed by [Esc] handler
			evt.preventDefault();
			evt.stopPropagation();
		}
	}

	/**
	 * checks a keyboard event inf "blocked" mode
	 * @param {KeyboardEvent} evt the DOM keyboard event
	 * @returns {Boolean} true if the keyboard event has been stopped; false otherwise
	 */
	_checkBlockedEvent(evt) {
		const key = evt.key || '';
		let stop = false;
		switch ( key ) {
			case 'F5':
				stop = true;
				break;
			default:
				break;
		}
		if ( stop ) {
			evt.preventDefault();
			evt.stopPropagation();
		}
		return stop;
	}
}
