import Validator from '../Validator';
import ConditionalItem from './ConditionalItem';

export default class Expression extends ConditionalItem {

	constructor( {
		operands = null,
		callback = null,
		expression = null,
		operator = null
	} ) {
		super( {
			operands: operands,
			callback: callback,
			expression: expression,
			operator: operator
		} );
	}

	static from( expression ) {
		if ( !( expression instanceof Expression ) ) return void 0;
		return new Expression( {
			operands: expression.expressionOperands,
			callback: expression.callback,
			expression: String( expression.expression ),
			operator: String( expression.operator )
		} );
	}

	evaluate() {
		if ( Validator.isBoolean( this.result ) ) return this.result;
		return this.forceEvaluate();
	}

	forceEvaluate() {
		this.result = Validator.isFunction( this.callback ) ?
			this.callback( this.operands ) : this._defaultCallback();
		return this.result;
	}

	get expressionOperands() {
		return [ ...this.operands ];
	}

	get uniqueExpressionOperands() {
		return Array.from( new Set( this.expressionOperands ) );

	}

	modifyExpressionOperands( changeCallback ) {
		if ( !Validator.isFunction( changeCallback ) ) return;
		this._modifyExpressionOperands( changeCallback );
	}

	_modifyExpressionOperands( changeCallback ) {
		for ( let index = 0; index < this.operands.length; index++ )
			this.operands[ index ] = changeCallback( this.operands[ index ], index );
	}

	stringify() {
		if ( !Validator.isArray( this.operands, true ) ) return "";
		if ( this.operands.length == 1 )
			return `${ this.operator }( ${ this.operands[ 0 ] })`;
		return `${ this.operands[ 0 ] } ${ this.operator }` +
			` ${ this.operands[ 1 ] }`;
	}

}

console.debug( 'utils/condition/Expression.js loaded.' );
