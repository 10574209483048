import Validator from '../../../../utils/Validator';

export default class AutoFitModeExtension {

	constructor( hostObject, isAutoFitModeOn = false ) {
		if ( !this._addSelf( hostObject ) ) {
			return Warner._trace( `An auto-fit mode extension could not be` +
				` attached to the host object.` );
		}
		// the "auto-fit mode" is "final"; in the future this might change
		Object.defineProperty( this, "isAutoFitModeOn", {
			value: !!isAutoFitModeOn,
			writable: false,
			configurable: false
		} );
	}

	get shouldAutoFit() {
		if ( !this.isAutoFitModeOn || !Validator.isObject( this.hostObject ) ||
			this.hostObject.isRowTpl || !this.hostObject.ready ||
			!this.hostObject.alive ) {
			return false;
		}
		return true;
	}

	get orderedColumns() {
		const hostObject = this.hostObject;
		if ( !Validator.isObject( hostObject ) ) {
			return void 0;
		}
		return Validator.isObject( hostObject.wdgHead ) &&
			"orderedColumns" in hostObject.wdgHead ?
			hostObject.wdgHead.orderedColumns : void 0;
	}

	get visibleColumns() {
		const orderedColumns = this.orderedColumns;
		if ( !Validator.isArray( orderedColumns, true ) ) {
			return void 0;
		}
		const visibleColumns = [ ...orderedColumns ].filter( column =>
			Validator.isObject( column ) && column.visible &&
			Validator.isPositiveNumber( column.defWidth, false ) );
		return visibleColumns.length < 1 ? void 0 : visibleColumns;
	}

	get columnsAutoFitProperties() {
		const visibleColumns = this.visibleColumns;
		if ( !Validator.isArray( visibleColumns, true ) ) {
			return void 0;
		}
		const columnsAutoFitProperties = new Map();
		let theoreticalWidthTotal = 0;
		for ( let column of visibleColumns ) {
			const properties = Object.defineProperty( {}, "definedWidth", {
				value: column.defWidth,
				configurable: false,
				writable: false
			} );
			theoreticalWidthTotal += column.defWidth;
			columnsAutoFitProperties.set( column.id, properties );
		}
		for ( let columnId of [ ...columnsAutoFitProperties.keys() ] ) {
			const properties = columnsAutoFitProperties.get( columnId );
			Object.defineProperties( properties, {
				quota: {
					value: properties.definedWidth / theoreticalWidthTotal,
					configurable: false,
					writable: false
				},
				// TODO should this part also be defined, or is it excessive/redundant?
				theoreticalWidthTotal: {
					value: theoreticalWidthTotal,
					configurable: false,
					writable: false
				}
			} );
			columnsAutoFitProperties.set( columnId, properties );
		}
		return columnsAutoFitProperties;
	}

	get selectionColumn() {
		const hostObject = this.hostObject;
		if ( !Validator.isObject( hostObject ) ) {
			return void 0;
		}
		if ( !Validator.isObject( hostObject.wdgHead ) ||
			!Validator.is( hostObject.wdgHead.columns, "ObjReg" ) ) {
			return void 0;
		}
		let selectionColumn = hostObject.wdgHead.columns.getItem( "o-1" );
		if ( Validator.is( selectionColumn, "XtwCol" ) ) {
			return selectionColumn;
		}
		selectionColumn = hostObject.wdgHead.columns.getObj( "o-1" );
		if ( Validator.is( selectionColumn, "XtwCol" ) ) {
			return selectionColumn;
		}
		selectionColumn = hostObject.wdgHead.columns.getFirst();
		return Validator.is( selectionColumn, "XtwCol" ) ?
			selectionColumn : void 0;
	}

	get selectionColumnWidth() {
		const selectionColumn = this.selectionColumn;
		if ( !Validator.isObject( selectionColumn ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( selectionColumn.width ) ?
			selectionColumn.width : void 0;
	}

	get verticalSliderWidth() {
		const hostObject = this.hostObject;
		if ( !Validator.isObject( hostObject ) ) {
			return void 0;
		}
		if ( !Validator.isObject( hostObject.wdgSlv ) ) {
			return hostObject.verticalSliderOriginalWidth;
		}
		const widthProperty = [ "_computedWidthValue", "_cachedBoxWidth",
			"_cachedInnerWidth", "__user$width"
		].find( property =>
			Validator.isPositiveNumber( hostObject.wdgSlv[ property ], false ) );
		return Validator.isString( widthProperty ) ?
			hostObject.wdgSlv[ widthProperty ] : void 0;
	}

	getNeighbourColumn( column, toTheRight = true ) {
		if ( !Validator.isObject( column ) ) {
			return void 0;
		}
		const visibleColumns = this.visibleColumns;
		if ( !Validator.isArray( visibleColumns, true ) ) {
			return void 0;
		}
		if ( visibleColumns.length == 1 ) {
			return void 0;
		}
		const columnOrderIndex = Validator.getIndexInArray( visibleColumns, column );
		if ( !Validator.isPositiveInteger( columnOrderIndex ) ) {
			return void 0;
		}
		const neighbourColumnIndex = columnOrderIndex == 0 ? 1 :
			columnOrderIndex == visibleColumns.length - 1 ? visibleColumns.length - 2 :
			!!toTheRight ? columnOrderIndex + 1 : columnOrderIndex - 1;
		const neighbourColumn = visibleColumns[ neighbourColumnIndex ];
		return Validator.isObject( neighbourColumn ) ? neighbourColumn : void 0;
	}

	_addSelf( hostObject ) {
		if ( !this._addSelfAs( hostObject, "autoFitModeExtension" ) ) {
			return false;
		}
		delete this._addSelf;
		return true;
	}

	_addSelfAs( hostObject, ownNameAsAProperty ) {
		if ( !Validator.isObject( hostObject ) ) {
			return false;
		}
		if ( !Validator.isString( ownNameAsAProperty ) ) {
			return false;
		}
		if ( ownNameAsAProperty in hostObject ) {
			if ( Validator.is( hostObject[ ownNameAsAProperty ], "AutoFitModeExtension" ) ) {
				return false;
			}
			const oldAutoFitModeExtensionDescriptor = Validator
				.getDescriptor( hostObject[ ownNameAsAProperty ] );
			if ( !Validator.isObject( oldAutoFitModeExtensionDescriptor ) ) {
				return false;
			}
			if ( oldAutoFitModeExtensionDescriptor.configurable ) {
				delete hostObject[ ownNameAsAProperty ];
			} else if ( !oldAutoFitModeExtensionDescriptor.writable ) {
				return false;
			}
			hostObject[ ownNameAsAProperty ] = void 0;
		}
		Object.defineProperty( hostObject, ownNameAsAProperty, {
			value: this,
			writable: false,
			configurable: false
		} );
		Object.defineProperty( this, "hostObject", {
			value: hostObject,
			writable: false,
			configurable: false
		} );
		delete this._addSelfAs;
		return true;
	}

}
