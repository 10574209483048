import Validator from "./Validator";

/**
 * a text builder class
 */
export default class TextBuilder {

    /**
     * constructs a new instance
     * @param {String} sep separator character or string; required
     * @param {String|null} initial optional initial value
     */
    constructor(sep, initial = null) {
        if ( !Validator.isString(sep) ) {
            throw new Error('Separator is mandatory!');
        }
        this._sep = sep;
        this._text = '';
        if ( Validator.isString(initial) ) {
            this.append(initial);
        }
    }

    /**
     * @returns {String} the separator character or string
     */
    get sep() {
        return this._sep;
    }

    /**
     * @returns {String} the current text
     */
    get text() {
        return this._text;
    }

    /**
     * @returns {String} the current text
     */
    toString() {
        return this.text;
    }

    /**
     * appends a string
     * @param {String} str string to be appended
     * @returns {TextBuilder} this instance
     */
    append(str) {
        if ( this._text.length > 0 ) {
            this._text += this._sep;
        }
        if ( Validator.isString(str) ) {
            this._text += str;
        }
        return this;
    }
}