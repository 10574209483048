import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class FrmRct - client side part of custom rectangular frame widget
 */
export default class FrmRct {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		this.element = null;
		this.parent.addListener("Resize", this.layout);
		this.ready = false;
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			this.element = ItmMgr.getInst().creCmnDiv(this.parent, null); 
			this.setFgrClr(cwd);
			this.setBrdWdt(cwd);
			this.setBrdSty(cwd);
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	onReady() {
		this.ready = true;
		// update the layout
		this.layout();
	}
	
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
		}
	}
	
	onSend() {
		// do nothing so far...
	}
	
	destroy() {
		this._psa.rmvDomElm(this.element);
	}
	
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const frm = this.element;
			frm.style.left = '0px';
			frm.style.top = '0px';
			frm.style.width = area[2] + 'px';
			frm.style.height = area[3] + 'px';
		}
	}
	
	setFgrClr(args) {
		if ( this.element ) {
			const rgb = ItmMgr.getInst().getRgb(args.fgrClr);
			if ( rgb ) {
				this.element.style.borderColor = rgb;
			}
		}
	}
	
	setBrdWdt(args) {
		if ( this.element ) {
			let wdt = 1;
			if ( Number.isInteger(args.brdWdt) ) {
				wdt = args.brdWdt;
			}
			else {
				wdt = 1;
			}
			this.element.style.borderWidth = '' + wdt + 'px';
		}
	}
	
	setBrdSty(args) {
		if ( this.element ) {
			const frm = this.element;
			const sty = args.brdSty;
			let brd = null;
			if ( this._psa.isStr(sty) ) {
				switch ( sty ) {
				case 'B':
					brd = 'outset';
					break;
				case 'D':
					brd = 'groove';
					break;
				case 'L':
					brd = 'dotted';
					break;
				case 'H':
					brd = 'inset';
					break;
				case 'R':
					brd = 'ridge';
					break;
				case 'S':
					brd = 'solid';
					break;
				default:
					brd = null;
					break;
				}
			}
			if ( brd !== null ) {
				frm.style.borderStyle = brd;
			} else {
				frm.style.borderStyle = 'none';
			}
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget FrmRct.');
		rap.registerTypeHandler("psawidget.FrmRct", {
			factory : function(properties) {
				return new FrmRct(properties);
			},
			destructor : "destroy",
			methods : [ "setFgrClr", "setBrdWdt", "setBrdSty" ],
			events : [ "FRMRCT_NFY" ]
		} );
	}
}

console.debug('widgets/csswdg/FrmRct.js loaded.');