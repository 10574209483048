import LoggingBase from "../base/loggingbase";

/**
 * an abstract listener manager interface class
 */
export default class IListenerMgr extends LoggingBase {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'utils.IListenerMgr');
    }

    /**
     * checks whether a listener is present
     * @param {String} eventName the event name; required
     * @param {String} prefix callback prefix
     * @returns {Boolean} true if a matching listener is registered; false otherwise
     */
    hasListener(eventName, prefix) {
        // abstract
        return false;
    }

    /**
     * registers an added listener
	 * @param {Object} instance the object providing the listener callback method
     * @param {String} eventName the event name to add a listener for; required
     * @param {String} prefix callback prefix
     * @param {HTMLElement} element optional target DOM element
     */
    registerListener(instance, eventName, prefix, element = null) {
        // abstract
    }

    /**
     * un-registers a removed listener
     * @param {String} eventName the event name to remove the listener from; required
     * @param {String} prefix callback prefix
     */
    unregisterListener(eventName, prefix) {
        // abstract
    }

    /**
     * removes all registered listeners
     */
    removeAllListeners() {
        // abstract
    }
}