import TimObj from './TimObj';
import ObjReg from '../utils/ObjReg';
import Validator from '../utils/Validator';
import LoggingBase from '../base/loggingbase';

/**
 * timer manager class
 */
export default class TimMgr extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {pisasales.CliCbkWdg} cbw callback widget
	 */
	constructor(cbw) {
		super('ssn.TimMgr');
		this.cbkWdg = cbw;
		this.conMgr = cbw.conMgr;
		this.timMap = new ObjReg();
	}

	/**
	 * destroys this instance
	 */
	doDestroy() {
		this.timMap.destroy();
		this.cbkWdg = null;
		delete this.cbkWdg;
		delete this.timMap;
		super.doDestroy();
	}

	/**
	 * creates a timer object
	 */
	creTim(args) {
		const obj = args.tim;
		if ( obj ) {
			const nam = obj.nam;
			const tmo = obj.tmo;
			const sng = obj.sng ? true : false;
			const bsc = obj.bsc ? true : false;
			if ( Validator.isString(nam) && Validator.isPositiveNumber(tmo, false) ) {
				this.timMap.addObj(nam, new TimObj(this, nam, tmo, sng, bsc));
			}
		}
	}

	/**
	 * stops and deletes a timer
	 */
	stpTim(args) {
		this._killTimer(args.tim);
	}
	
	/**
	 * really destroys a timer
	 */
	_killTimer(obj) {
		if ( obj && Validator.isString(obj.nam) ) {
			const nam = obj.nam;
			const tim = this.timMap.rmvObj(nam);
			if ( tim ) {
				tim.destroy();
			}
		}
	}
	
	/**
	 * logs a timer "hit"
	 * @param {TimObj} tim the timer
	 */
	_logHit(tim) {
		if ( this.isTraceEnabled() ) {
			const now = new Date();
			this.trace(now.toISOString() + ' - TIMEVT: ' + tim.timNam + '(' + (tim.bsyScr ? 'BSY' : '-') + ')');
		}
	}
	
	/**
	 * notifies the web server
	 * @param {TimObj} tim the timer
	 */
	_nfySrv(tim) {
		this._logHit(tim);
		if ( this.conMgr ) {
			this.conMgr.sendTimerHit(tim.timNam);
		} else if ( this.cbkWdg ) {
			this.cbkWdg.nfyGlb("timEvt", tim.timNam, !!tim.bsyScr);
		}			
	}
}
