import LoggingBase from "../../base/loggingbase";
import MnuObj from "./MnuObj";

/**
 * an unspecified menu item click
 */
const MI_ANY_CLICKED = 0;

/**
 * a click on menu item's main icon
 */
const MI_ICON_CLICKED = 1;

/**
 * a click on menu item's text
 */
const MI_TEXT_CLICKED = 2;

/**
 * abstract menu handler class (interface)
 */
export default class MenuHandler extends LoggingBase {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'gui.menu.MenuHandler');
    }

    /**
     * called if a menu item was clicked
     * @param {Number} id the ID of the menu item that was clicked
     * @param {MnuObj} menu the menu that contains this item
     * @param {Number} what an indicator which part of the menu item was clicked:
     *                      0 - unspecified;
     *                      1 - the icon was clicked;
     *                      2 - the text was clicked
     * @abstract
     */
    onMenuItem(id, menu, what) {
        // abstract
    }

    /**
     * called if a menu was closed
     * @param {MnuObj} menu the menu that was closed
     * @abstract
     */
    onMenuClose(menu) {
        // abstract
    }
}

export { MI_ANY_CLICKED, MI_ICON_CLICKED, MI_TEXT_CLICKED };
