export default class BaseSize {
    
	/**
	 * construcs a new instance
	 * @param {*} args arguments
	 */
     constructor(args) {
		let cx = 0;
		let cy = 0;
		if ( args.length == 2 ) {
			cx = args[0];
			cy = args[1];
		} else {
			cx = args.cx;
			cy = args.cy;
		}
		// check types
		if ( (typeof cx !== "number") || (typeof cy !== "number") ) {
			throw new Error("Invalid arguments");
		}
		// initialize
		this.cx = cx;
		this.cy = cy;
	}

}