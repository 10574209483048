import LoggingBase from "../base/loggingbase";

/**
 * abstract "key handling blocker" class
 */
export default class KeyBlocker extends LoggingBase {

    constructor(ln) {
        super(ln || 'key.KeyBlocker');
        this._blocked = false;
    }

    /**
     * @returns {Boolean} the current "blocked" state
     */
    get blocked() {
        return this._blocked;
    }

	/**
	 * indicates whether global key handling is blocked; blocked key handling is an indicator of a maximized input element
	 * @returns {Boolean} true if global key handling is currently blocked; false otherwise
	 */
    isBlocked() {
		return this._blocked;
	}

	/**
	 * sets or clears the "key handling blocked" flag
	 * @param {Boolean} b the blocked flag
	 */
     blockGlobalKeyHandling( b ) {
		this._blocked = b;
	}

}