import LoggingBase from "../../../base/loggingbase";

/** a new model was committed */
const CODE_MODEL = 1;
/** one or more rows were inserted */
const CODE_INSERT = 2;
/** one or more rows were deleted */
const CODE_DELETE = 3;

/**
 * pending operation descriptor
 */
export default class PendingOp extends LoggingBase {

    /**
     * constructs a new instance
     * @param {Number} code the operation code
     * @param {Object} args the argument object
     */
    constructor(code, args) {
        super('widgets.xtw.util.PendingOp');
        this._code = code;
        this._args = args;
    }

    /**
     * creates a new instance
     * @param {Number} code the operation code
     * @param {Object} args the argument object
     * @returns {PendingOp} the created instance
     */
    static newInstance(code, args) {
        return new PendingOp(code, args);
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        delete this._code;
        delete this._args;
        super.doDestroy();
    }

    /**
     * @returns {Number} the operation code
     */
    get code() {
        return this._code;
    }

    /**
     * @returns {Object} the argument object
     */
    get args() {
        return this._args;
    }
}

export { CODE_MODEL, CODE_INSERT, CODE_DELETE };
