import LoggingBase from "../../../base/loggingbase";
import SelectionLsr from "./SelectionLsr";
import RowFocusLsr from "./RowFocusLsr";
import XtwModel from "./XtwModel";
import { EMPTY_ARR } from '../../../base/base';
import ColumnFocusLsr from "./ColumnFocusLsr";
import MRowItem from "./MRowItem";


/** the "dummy row" pseudo ID - see de.pisa.webcli.bas.ROWID.ROWID_DUMMY; *MUST* be kept in sync. */
const ROWID_DUMMY = 0x7fffffff;
/** the "no row" pseudo ID - see de.pisa.webcli.bas.ROWID.ROWID_NONE; *MUST* be kept in sync. */
const ROWID_NONE = -1;
/** the "no column" ID */
const COLID_NONE = -1;

/**
 * a selection listener that wraps a callback function
 */
class XSelectionWrapper extends SelectionLsr {
    constructor(f) {
        super();
        if ( typeof f !== 'function' ) {
            throw new Error('Invalid function specified!');
        }
        this._function = f;
    }

    /**
     * @override
     */
    onChanged(model, selection, options) {
        this._function(model, selection, options);
    }
}


/**
 * a row focus listener that wraps a callback function
 */
class XRowFocusWrapper extends RowFocusLsr {
    constructor(f) {
        super();
        if ( typeof f !== 'function' ) {
            throw new Error('Invalid function specified!');
        }
        this._function = f;
    }

    /**
     * @override
     */
     onRowFocus(model, focus, prev, options) {
        this._function(model, focus, prev, options);
    }
}


/**
 * a column focus listener that wraps a callback function
 */
 class XColumnFocusWrapper extends ColumnFocusLsr {
    constructor(f) {
        super();
        if ( typeof f !== 'function' ) {
            throw new Error('Invalid function specified!');
        }
        this._function = f;
    }

    /**
     * @inheritdoc
     * @override
     */
    onColumnFocus(model, idc, prev) {
        this._function(model, idc, prev);
    }
}


/**
 * selection manager class for XTW data models
 */
export default class SelectionMgr extends LoggingBase {

    /**
     * constructs a new instance
     * @param {XtwModel} model the data model instance
     */
    constructor(model) {
        super('widgets.xtw.model.SelectionMgr');
        if ( !(model instanceof XtwModel) ) {
            throw new Error('Invalid data model!');
        }
        this._model = model;
        this._focusedRow = ROWID_NONE;
        this._focusedColumn = COLID_NONE;
        this._selection = new Set();
        this._selListeners = new Set();
        this._rowFocusListeners = new Set();
        this._colFocusListeners = new Set();
    }

    /**
     * @override
     */
    doDestroy() {
        this._selection.clear();
        this._selListeners.clear();
        this._rowFocusListeners.clear();
        this._colFocusListeners.clear();
        delete this._focusedRow;
        delete this._focusedColumn;
        delete this._selection;
        delete this._selListeners;
        delete this._rowFocusListeners;
        delete this._colFocusListeners;
		super.doDestroy();
    }

    /**
     * clears all selections and resets the focused row
     */
    clearAll() {
        const prev = this._focusedRow;
        this._selection.clear();
        this._focusedRow = ROWID_NONE;
        this._focusedColumn = COLID_NONE;
        const opt = this._options();
        this._callSelLsr(EMPTY_ARR, opt);
        this._callRowFocusLsr(ROWID_NONE, prev, opt);
    }

    /**
     * @returns {XtwModel} the data model
     */
    get model() {
        return this._model;
    }

    /**
     * @returns {Number} the ID of the currently focused row
     */
    get focusedRow() {
        return this._focusedRow;
    }

    /**
     * @returns {Number} the ID of the currently focused column
     */
    get focusedColumn() {
        return this._focusedColumn;
    }

    /**
     * @returns {Boolean} true if there's at least one row selected; false otherwise
     */
    get hasSelection() {
        return this._selection.size > 0;
    }

    /**
     * @returns {Number} the number of currently selected items
     */
    get selectedCount() {
        return this._selection.size;
    }

    /**
     * checks whether the specified row is selected
     * @param {Number} idr row ID
     * @returns {Boolean} true if the specified row is selected; false otherwise
     */
    isSelected(idr) {
        return this._selection.has(idr);
    }

    /**
     * checks whether the specified row is focused
     * @param {Number} idr  row ID
     * @returns {Boolean} true if the specified row is focused; false otherwise
     */
    isFocused(idr) {
        return (idr !== ROWID_NONE) && (this._focusedRow === idr);
    }

    /**
     * adds a new selection listener
     * @param {SelectionLsr | Function} listener the listener to be added
     */
    addSelectionListener(listener) {
        if ( listener instanceof SelectionLsr ) {
            this._selListeners.add(listener);
        }
        else if ( typeof listener === 'function' ) {
            this._selListeners.add(new XSelectionWrapper(listener));
        }
    }

    /**
     * adds a new row focus listener
     * @param {RowFocusLsr | Function} listener the listener to be added
     */
    addRowFocusListener(listener) {
        if ( listener instanceof RowFocusLsr ) {
            this._rowFocusListeners.add(listener);
        } else if ( typeof listener === 'function' ) {
            this._rowFocusListeners.add(new XRowFocusWrapper(listener));
        }
    }

    /**
     * adds a new column focus listener
     * @param {ColumnFocusLsr | Function} listener the listener to be added
     */
    addColumnFocusListener(listener) {
        if ( listener instanceof ColumnFocusLsr ) {
            this._colFocusListeners.add(listener);
        } else if ( typeof listener === 'function' ) {
            this._colFocusListeners.add(new XColumnFocusWrapper(listener));
        }
    }

    /**
     * sets the row focus on the specified row
     * @param {Number} idr ID of row to become focused
     */
    focusRow(idr) {
        if ( idr !== ROWID_NONE && this._focusedRow !== idr ) {
            const prev = this._focusedRow;
            this._focusedRow = idr;
            this._callRowFocusLsr(this._focusedRow, prev, this._options());
        }
    }

    /**
     * unfocuses the specified row if it is currently the focused row
     * @param {Number} idr ID of row that's unfocused
     */
    unfocusRow(idr) {
        if ( idr !== ROWID_NONE && this._focusedRow === idr ) {
            this.unfocusAll();
        }
    }

    /**
     * forces no row to be focused
     */
    unfocusAll() {
        if ( this._focusedRow !== ROWID_NONE ) {
            const prev = this._focusedRow;
            this._focusedRow = ROWID_NONE;
            this._callRowFocusLsr(ROWID_NONE, prev, this._options());
        }
    }

    /**
     * helper method;
     * sets the focus to the first selected row if there's no focused row yet
     */
    focusFirstSelected() {
        if ( (this._focusedRow === ROWID_NONE) && this.hasSelection ) {
            const it = this._selection.values();
            this.focusRow(it.next().value);
        }
    }

    /**
     * causes a single row to be selected
     * @param {Number} idr ID of currently selected row
	 * @param {Boolean} notify flag whether to notify the web server
     */
    selectSingle(idr, notify) {
        if ( this._canSelect(idr) ) {
            this._selection.clear();
            this._selection.add(idr);
            const selected = this._snapshot();
            this._callSelLsr(selected, this._options(notify));
        }
        if ( (this._focusedRow === ROWID_NONE) && (ROWID_NONE !== idr) ) {
            this.focusRow(idr);
        }
    }

    /**
     * selects a row
     * @param {Number} idr ID of row that's selected
     * @param {Boolean} notify "notify web server" flag
     */
    selectRow(idr, notify) {
        if ( this._canSelect(idr) && !this.isSelected(idr) ) {
            this._selection.add(idr);
            this._callSelLsr(this._snapshot(), this._options(notify));
        }
        if ( (this._focusedRow === ROWID_NONE) && (ROWID_NONE !== idr) ) {
            this.focusRow(idr);
        }
    }

    /**
     * selects one or more rows at once
     * @param {Array<Number>} rows IDs of rows to be selected
     * @param {Boolean} notify "notify web server" flag
     */
    selectRows(rows, notify) {
        let hit = false;
        const self = this;
        rows.forEach((r) => {
            if ( this._canSelect(r) && !self.isSelected(r) ) {
                self._selection.add(r);
                hit = true;
            }
        });
        if ( hit ) {
            this._callSelLsr(this._snapshot(), this._options(notify));
        }
        if ( hit && (this._focusedRow === ROWID_NONE) ) {
            this.focusRow(rows[0]);
        }
    }

    /**
     * causes a single row to be unselected
     * @param {Number} idr ID of currently unselected row
	 * @param {Boolean} notify flag whether to notify the web server
     */
    unselectSingle(idr, notify) {
        if ( this.isSelected(idr) ) {
            const unselected = [ idr ];
            this._selection.delete(idr);
            const selected = this._snapshot();
            this._callSelLsr(selected, unselected, this._options(notify));
        }
    }

    /**
     * unselects all currently selected rows
	 * @param {Boolean} notify flag whether to notify the web server
     */
    unselectAll(notify) {
        this.clearSelection(!!notify);
    }

    /**
     * helper method;
     * forces the selection listeners to be called
     */
    callSelectLsr() {
        if ( this.hasSelection ) {
            this._callSelLsr(this._snapshot(), this._options(true));
        }
    }

    /**
     * helper method;
     * clears the current selection
     * @param {Boolean} notify flag whether to notify selection listeners
     */
    clearSelection(notify) {
        if ( this.hasSelection ) {
            this._selection.clear();
            if ( notify ) {
                this._callSelLsr(EMPTY_ARR, this._options(notify));
            }
        }
    }

    /**
     * helper method; silently selects a row, does not call select listeners
     * @param {Number} idr ID of row to be selected
     */
    selectSilent(idr) {
        if ( this._canSelect(idr) ) {
            this._selection.add(idr);
        }
    }

    /**
     * stores the ID of the currently focused column
     * @param {Number} idc ID of focused column
     */
    setFocusColumn(idc) {
        const prev = this._focusedColumn;
        this._focusedColumn = idc;
        if ( idc !== prev ) {
            this._callColFocusLsr(idc, prev);
        }
    }

    /**
     * returns the IDs of the currently selection rows
     * @returns {Number[]} an array containing the IDs of all currently selected rows
     */
    getSelection() {
        return this._snapshot();
    }

    /**
     * checks whether a row ID refers to a selectable row
     * @param {Number} idr row ID
     * @returns {Boolean} true if the row ID refers to a selectable row; false otherwise
     */
    _canSelect(idr) {
        return (idr !== ROWID_NONE);
    }

    /**
     * creates a snapshot
     * @returns {Number[]} an array containing the IDs of all currently selected rows
     */
    _snapshot() {
        const selection = this._selection;
        if ( selection.size > 1 ) {
            // get currently visible order!
            const model = this.model;
            const ordered = [];
            selection.forEach((idr) => {
                const mi = model.getDataRowModelItem(idr);
                if ( mi instanceof MRowItem ) {
                    ordered.push({idr: idr, fix: mi.flatIndex});
                }
            });
            if ( ordered.length > 1 ) {
                ordered.sort((a,b) => a.fix - b.fix);
                const snapshot = [];
                ordered.forEach(o => snapshot.push(o.idr));
                return snapshot;
            }
        }
        // fallback (not sorted) -> use selection as is
        return Array.from(selection);
    }

    /**
     * @param {Boolean} notify "send notification" flag
     * @returns an options object passed to the listeners
     */
    _options(notify) {
        const fr = this._focusedRow !== ROWID_NONE ? this.model.getDataRowModelItem(this._focusedRow) : null;
        const do_notify = (typeof notify === 'boolean') ? !!notify : true;
        return { fix: ((fr != null) ? fr.flatIndex : -1), notify: do_notify };
    }

    /**
     * calls the selection listeners
     * @param {Number[]} selected IDs of rows that were selected
     * @param {Object} options more options
     */
    _callSelLsr(selected, options) {
       const model = this.model;
       this._selListeners.forEach((l) => l.onChanged(model, selected, options));
    }

    /**
     * calls the row focus listeners
     * @param {Number} focus ID of currently focused row
     * @param {Number} prev ID of previously focused row
     * @param {Object} options more options
     */
    _callRowFocusLsr(focus, prev, options) {
        const model = this.model;
        this._rowFocusListeners.forEach((l) => l.onRowFocus(model, focus, prev, options));
    }

    /**
     * calls the column focus listeners
     * @param {Number} idc ID of currently focused column
     * @param {Number} prev ID of previously focused column
     */
    _callColFocusLsr(idc, prev) {
        const model = this.model;
        this._colFocusListeners.forEach((l) => l.onColumnFocus(model, idc, prev));
    }
}

export { ROWID_DUMMY, ROWID_NONE, COLID_NONE };