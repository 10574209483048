import PSA from '../../psa';
import Base from '../../base/base';

// CSS class allowing elements to use this customization
const CSS_BLOCKER_CLASS = 'circumvent-rap-dnd-blocker';

// See CKEDITOR. Regular expressions (taken from pisautil module of ckeditor)
const HTTP_REGEX = /^https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]$/gim;

/** 
 * See CKEDITOR. Checks whether the provided string is an HTTP(S) url.
 * @param {String} url the url
 * @return {Boolean} true if the provided string is an HTTP(S) url 
 */
function isHttpUrl( url ) {
	if ( url ) {
		const tUrl = url.trim();
		const tlcUrl = tUrl.toLowerCase();
		// help IE recognize the string
		HTTP_REGEX.lastIndex = 0;
		return HTTP_REGEX.test( tlcUrl );
	}
	return false;
}

/**
 * See CKEDITOR. Parses the HTML string and returns a document.
 * @param {String} htmlString the HTML string
 * @return {Document} returns a document containing the HTML string 
 */
function parseHtml( htmlString ) {
	const parser = new DOMParser();
	const htmlDocument = parser.parseFromString( htmlString, 'text/html' );
	return htmlDocument;
}

/**
 * See CKEDITOR. Returns the source type of an unresolved image.
 * @param {String} resource the source reference of an unresolved image tag
 * @return {String} the resource type
 */
function getResourceType( resource ) {
	if ( !resource ) {
		return '';
	}
	if ( resource.indexOf( 'http' ) == 0 ) {
		return 'web-uri';
	} else if ( resource.indexOf( 'file' ) == 0 ) {
		return 'fs-uri';
	} else if ( /^data:image\/(gif|png|jpeg|bmp|tiff);base64/.test( resource ) ) {
		return 'base64';
	}
	return '';
}

/**
 * Takes the resource, attempts to determine its type and if succcessful, packages the data into an
 * object and returns it. If it doesn't work out, this function return null.
 * @param {String} resournce the image URL
 */
function toSingleImageResource( resource ) {
	const type = getResourceType( resource );
	if ( resource && type ) {
		return {
			url: resource,
			type: type
		}
	}
	return null;
}

/**
 * Attempts to extract an image resource from an HTML string.
 * @param {String} htmlString the HTML string
 * @return {Object} image resource or null if image resource cannot be extracted
 */
function extractSingleImageFromHtml( htmlString ) {
	try {
		const fragment = parseHtml( htmlString );
		const images = fragment.getElementsByTagName( 'img' );
		let res = null;
		let i = 0;
		for ( ; i < images.length; i++ ) {
			res = toSingleImageResource( images[i].getAttribute( 'src' ) );
			if ( res ) {
				return res;
			}
		}
		const anchors = fragment.getElementsByTag( 'a' );
		for ( i = 0; i < anchors.length; i++ ) {
			res = toSingleImageResource( anchors[i].getAttribute( 'href' ) );
			if ( res ) {
				return res;
			}
		}
		const text = fragment.body.textContent.trim();
		if ( isHttpUrl( text ) ) {
			res = toSingleImageResource( text );
			if ( res ) {
				return res;
			}
		}
	} catch ( err ) {
		// void
	}
	return null;
}

/**
 * Checks whether we are dealing with the expected event. Only drop and dragover are handled for now.
 * @param {DragEvent} event the event
 * @return {Boolean} true if the event is either drop or dragOver
 */
	function isExpectedEvent( event ) {
	return ( event.type === 'drop' || event.type === 'dragover' );
}

/**
 * Checks whether the target element of the event contains the class required to avoid the RAP DND block.
 * @param {Element} element the target element
 * @return {Boolean} true if the element has the circumvent class
 */
function hasCircumventClass( element ) {
	return ( element && element.classList.contains( CSS_BLOCKER_CLASS ) );
}

/**
 * Files are handled directly by RAP, don't handle them here.
 * @param {DataTransfer} data the data transfer object
 * @return {Boolean} true if the data transfer object contains no files 
 */
	function hasNoFiles( data ) {
	return ( data && data.files && ( data.files.length <= 0 ) );
}

/**
 * Creates a wrapper function for drag and drop handling.
 * @param {rwt.event.EventHandler} self the event handler module
 * @param {Function} originalFn the original function
 * @return {Function} the derivative function which delegates to the original function
 */
function createWrapperFunction( self, originalFn ) {
	return function ( event ) {
		const type = event.type || '';
		if ( !event ) {
			event = window.event;
		}
		if ( isExpectedEvent( event ) && hasCircumventClass( event.target ) && hasNoFiles( event.dataTransfer ) ) {
			// we are not blocking this, we can do this, go go go go ...
			return;
		}
		originalFn.apply( self, [event] );
	};
}

/**
 * Initializes the customization.
 */
function init() {
	// safe API access 
	if ( rwt && rwt.event && rwt.event.EventHandler && ( rwt.event.EventHandler._ondragevent || rwt.event.EventHandler.__ondragevent ) ) {
		const that = rwt.event.EventHandler;
		const sng = !!rwt.event.EventHandler._ondragevent;
		const original = sng ? rwt.event.EventHandler._ondragevent : rwt.event.EventHandler.__ondragevent;
		that.detachEventTypes( that._dragEventTypes, original );
		if ( sng ) {
			that._ondragevent = rwt.util.Functions.bind( createWrapperFunction( that, original ) );
			that.attachEventTypes( that._dragEventTypes, that._ondragevent );
		} else {
			that.__ondragevent = rwt.util.Functions.bind( createWrapperFunction( that, original ) );
			that.attachEventTypes( that._dragEventTypes, that.__ondragevent );
		}
	}
}

let _initalized = false;

/**
 * Drag and drop customization.
 */
export default class DndCso extends Base {

	/**
	 * constructs a new instance
	 */
	constructor() {
		super();
		this._psa = PSA.getInst();
		if ( !_initalized ) {
			init();
			_initalized = true;
		}
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
	}

	get CSS_CLASS() {
		return CSS_BLOCKER_CLASS;
	}

	static getCssClass() {
		return CSS_BLOCKER_CLASS;
	}

	/**
	 * Extracts image data for a single image from the data transfer object.
	 * @param {DataTransfer} data the data transfer object
	 * @return {Object} the object containing url and type of the resource or null if no 
	 * resource was found 
	 */
	extractSingleImageData( data ) {
		// no surprises policy
		try {
			const htmlData = data.getData( 'text/html' );
			let res = null;
			if ( htmlData ) {
				res = extractSingleImageFromHtml( htmlData );
			}
			if ( res ) {
				return res;
			}
			const uriData = data.getData( 'text/uri-list' );
			if ( uriData && isHttpUrl( uriData ) ) {
				res = toSingleImageResource( uriData );
			}
			if ( res ) {
				return res;
			}
			const textData = data.getData( 'text/plain' );
			if ( textData && isHttpUrl( textData ) ) {
				res = toSingleImageResource( textData );
			}
			if ( res ) {
				return res;
			}
		} catch ( err ) {
			// void
		}
		return null;
	}
}

console.debug('gui/misc/DndCso.js loaded');
