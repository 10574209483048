import Validator from '../utils/Validator';

import PgiWdg from './pgiwdg/PgiWdg';
import XWdg from './xwdg/XWdg';
import SimpleLabel from './csswdg/SimpleLabel';
import SelBtnGrp from './selbtn/SelBtnGrp';
import SelBtn from './selbtn/SelBtn';
import MnuPopWdg from './menu/MnuPopWdg';
import DragWdg from './dragwdg/DragWdg';
import PsaImg from './psaimg/PsaImg';
import ImgWdg from './psaimg/ImgWdg';
import JsClock from './jsclock/JsClock';
import PsaBtnBar from './psabtn/PsaBtnBar';
import PsaBtn2 from './psabtn/PsaBtn2';
import MsgCtl from './msgctl/MsgCtl';
import FntIco from './csswdg/FntIco';
import FrmRct from './csswdg/FrmRct';
import SepDiv from './csswdg/SepDiv';
import TxtDiv from './csswdg/TxtDiv';
import BorderCmp from './csswdg/BorderCmp';
import SepLine from './csswdg/SepLine';
import DtrPck from './dtrpck/DtrPck';
import RchTree from './rchtree/RchTree';
import TblMgr from './rchtable/TblMgr';
import RtpTblMgr from './rchtable/RtpTblMgr';

import XtwTbl from './xtw/XtwTbl';
import XtwHead from './xtw/XtwHead';
import XtwBody from './xtw/XtwBody';

import HtmVwr from './htmvwr/HtmVwr';
import CkEdt5 from './cke5/CkEdt5';
import CodEdt from './codedt/CodEdt';

import JsChart from './jschart/JsChart';

/**
 * the custom widget register
 */
class WdgRegister {

	/**
	 * constructs an instance
	 */
	constructor() {
		this._wdginit = [];
		this._init();
	}

	_init() {
		const wi = this._wdginit;
		wi.push( { register: PgiWdg.register } );
		wi.push( { register: XWdg.register } );
		wi.push( { register: SimpleLabel.register } );
		wi.push( { register: SelBtnGrp.register } );
		wi.push( { register: SelBtn.register } );
		wi.push( { register: MnuPopWdg.register } );
		wi.push( { register: DragWdg.register } );
		wi.push( { register: PsaImg.register } );
		wi.push( { register: ImgWdg.register } );
		wi.push( { register: JsClock.register });
		wi.push( { register: PsaBtnBar.register });
		wi.push( { register: PsaBtn2.register });
		wi.push( { register: MsgCtl.register });
		wi.push( { register: FntIco.register });
		wi.push( { register: FrmRct.register });
		wi.push( { register: SepDiv.register });
		wi.push( { register: TxtDiv.register });
		wi.push( { register: BorderCmp.register });
		wi.push( { register: SepLine.register });
		wi.push( { register: DtrPck.register });
		wi.push( { register: RchTree.register });
		wi.push( { register: TblMgr.register });
		wi.push( { register: RtpTblMgr.register });

		wi.push( { register: XtwTbl.register } );
		wi.push( { register: XtwHead.register } );
		wi.push( { register: XtwBody.register } );

		wi.push( { register: HtmVwr.register });
		wi.push( { register: CkEdt5.register });
		wi.push( { register: CodEdt.register });

		wi.push( { register: JsChart.register });
	}

	/**
	 * registers all known custom widgets
	 */
	registerAll() {
		console.debug( 'Registering all custom widgets...' )
		try {
			this._wdginit.forEach( ( wi ) => {
				if ( Validator.isFunction( wi.register ) ) {
					try {
						wi.register();
					} catch ( e ) {
						console.error( "Widget registration failed!", e );
						console.error( wi );
					}
				} else {
					console.warn( 'Invalid widget register item.' );
					console.warn( wi );
				}
			} );
		} finally {
			// this is a single shot method!
			this._wdginit = [];
			delete this._wdginit;
			console.debug( 'Done.' );
		}
	}
};

/** the one and only instance */
const singleton = new WdgRegister();

/** global access function */
export default function globalWdgRegister() {
	return singleton;
}

console.debug( 'widgets/wdgregister.js loaded.' );
