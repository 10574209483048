import XtwModel from "./XtwModel";

/**
 * selection listener interface
 */
export default class SelectionLsr {

    /**
     * called if the the selection has changed
     * @param {XtwModel} model the data model
     * @param {Number[]} selected IDs of rows that are selected
     * @param {Object} options more options
     */
    onChanged(model, selected, options) {
        // abstract
    }
}