import LoggingBase from "../base/loggingbase";
import PSA from "../psa";
import ClipboardDataHdl from "./ClipboardDataHdl";
import NotificationHdl from "./NotificationHdl";

/**
 * a wrapper class for browser's Clipboard API
 */
export default class ClipboardMgr extends LoggingBase {

    /**
     * constructs a new instance
     */
    constructor() {
        super('utils.ClipboardMgr');
        this._available = (typeof navigator.clipboard !== 'undefined') && (typeof window.ClipboardItem !== 'undefined');
        Object.freeze(this);
    }

    /**
     * @returns {ClipboardMgr} the singleton instance
     */
    static getInstance() {
        return instance;
    }

    /**
     * indicates whether the Clipboard API is available in this browser
     * @returns {Boolean} true if the Clipboard API is available; false otherwise
     */
    get available() {
        return this._available;
    }

    /**
     * copies data to the clipboard
     * @param {NotificationHdl} nh notification handler
     * @param {ClipboardItem[]} data array of clipboard items to be copied to the clipboard
     */
    copyToClipboard(nh, data) {
        navigator.clipboard.write(data).then( () => {
            nh.notify(true, null);
        }).catch( (err) => {
            nh.notify(false, err);
        });
    }

    /**
     * reads data from clipboard and passes them to the clipboard data handler
     * @param {ClipboardDataHdl} ch clipboard data handler
     */
    pasteFromClipboard(ch) {
        const self = this;
        const info = PSA.getInst().Info;
        const rqa = !info.isFF() && !info.isSafari();
        if ( rqa ) {
            // request access
            navigator.permissions.query({ name: "clipboard-read" }).then( (result) => {
                if ( result.state !== 'denied' ) {
                    // we assume "yes" :-)
                    self._readClipboard(ch);
                } else {
                    // ok, it's forbidden :-(
                    self.log('Clipboard access:', result);
                    ch.notify(false, 'FORBIDDEN');
                }
            }).catch( (err) => {
                ch.notify(false, err);
            });
        } else {
            // Firefox and Safari do not support permission query for clipboard access - just go on
            self._readClipboard(ch);
        }
    }

    /**
     * reads data from clipboard and passes them to the clipboard data handler
     * @param {ClipboardDataHdl} ch clipboard data handler
     */
    _readClipboard(ch) {
        navigator.clipboard.read().then( (items) => {
            ch.onClipboardData(items);
        }).catch ( (err) => {
            ch.notify(false, err);
        });
    }
}

// the one and only instance
const instance = new ClipboardMgr();