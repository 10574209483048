import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';

export default class XtwRowItemInsertionDummyExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get insertionDummy() {
		return this.modelItemIsValid && this.item.insertionDummy;
	}

	displayAsInsertionDummy() {
		this.addInsertionStar();
		const element = this.element;
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		element.classList.add( "rtp-insertion-dummy" );
		return true;
	}

	displayAsNotInsertionDummy() {
		this._removeInsertionStar();
		const element = this.element;
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		element.classList.remove( "rtp-insertion-dummy" );
		return true;
	}

	addInsertionStar() {
		return this.isFocused ? this._addFocusedInsertionStar() : this._addInsertionStar();
	}

	_addFocusedInsertionStar() {
		this._removeSelectionArrow();
		const selectionCell = this.selectionCell;
		if ( !Validator.is( selectionCell, "XCellItem" ) ) {
			return false;
		}
		return selectionCell.addFocusedInsertionStar();
	}

	_addInsertionStar() {
		this._removeSelectionArrow();
		const selectionCell = this.selectionCell;
		if ( !Validator.is( selectionCell, "XCellItem" ) ) {
			return false;
		}
		return selectionCell.addInsertionStar();
	}

	_removeInsertionStar() {
		const selectionCell = this.selectionCell;
		if ( !Validator.is( selectionCell, "XCellItem" ) ) {
			return false;
		}
		return selectionCell.removeInsertionStar();
	}

	_updateInsertionDummyFocusedUI( setToFocused ) {
		if ( !this.insertionDummy || !this.isRendered ) {
			return false;
		}
		this.element.classList.add( "rtp-insertion-dummy" );
		if ( !!setToFocused ) {
			this.element.classList.add( "rtp-focused" );
			return this._addFocusedInsertionStar();
		}
		this.element.classList.remove( "rtp-focused" );
		return this._addInsertionStar();
	}

}
