import LoggingBase from "../base/loggingbase";

/**
 * Notification handler interface
 */
export default class NotificationHdl extends LoggingBase {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'utils.NotificationHdl');
    }

    /**
     * called by an operation to issue a notification
     * @param {Boolean} success success flag
     * @param {Object} info optional info
     */
    notify(success, info) {
        // abstract
    }
}