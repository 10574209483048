import GlobalCounter from "../utils/GlobalCounter";
import Warner from "../utils/Warner";

/**
 * common base class
 */
export default class Base {

	/**
	 * constructs a new instance
	 */
	constructor() {
        Object.defineProperty(this, '_iid', {
            value: GlobalCounter.getInst().nextValue(),
            writable: false,
            configurable: false,
			enumerable: false
        });
		this._dying = false;
		this._alive = true;
	}

    /**
     * @returns {Number} the instance ID
     */
	get iid() {
        return this._iid;
    }

	/**
	 * destructor method
	 */
	destroy() {
		if ( this._alive ) {
			this._dying = true;
			this.doDestroy();
			this._alive = false;
		}
	}

	/**
	 * destructor method - derived classes should override this
	 */
	doDestroy() {
		// do nothing here
	}

	/**
	 * marks this instance as "dying" and "dead"
	 */
	assumeDead() {
		this._alive = false;
		this._dying = true;
	}

	/**
	 * instance's "alive" flag
	 * @returns {Boolean} true if this instance is alive; false otherwise
	 */
	get alive() {
		return !!this._alive;
	}

	/**
	 * instance's "in destruction" flag
	 * @returns {boolean} true if this instance is in its destruction phase or already dead; false otherwise
	 */
	get dying() {
		return !!this._dying;
	}

	/**
	 * issues an error message if this instance is not longer alive while it should be
	 */
	checkAlive() {
		if ( !this.alive ) {
			console.warn(`{${this.iid}}`, 'Instance not longer alive!', this, `\n    ${Warner.getStack(2)}`);
		}
	}
}

/** an empty object */
const EMPTY_OBJ = Object.freeze({});
/** an empty array */
const EMPTY_ARR = Object.freeze([]);
export { EMPTY_OBJ, EMPTY_ARR };

console.debug('base/base.js loaded.');