import LoggingBase from "../base/loggingbase";

/**
 * a file drop handler class;
 * this is just an example showing the expected interface
 */
export default class FileDropHandler extends LoggingBase {

	/**
	 * constructs an instance
	 * @param {String} ln logger name
	 */
	constructor(ln) {
		super(ln || 'dnd.FileDropHandler');
	}

	/**
	 * handles a file drop
	 * @param {File[]} files array of dropped files
	 */
	handleDrop(files) {
	}

	/**
	 * shows a (warning) message
	 * @param {String} msg message text
	 */
	showMessage(msg) {
	}
}
