import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import ConditionInterpreter from '../../../utils/condition/ConditionInterpreter';

export default class XtwRtpIdManager {

	constructor( hostObject, extern = false ) {
		if ( !extern && !this._addSelf( hostObject ) ) {
			return Warner._trace( `An id manager could not be attached to the` +
				` host object.` );
		}
		this.hostObject = hostObject;
		this._addMaps();
		this.relevantOperands = new Set();
		this.descriptionNames = [];
		this.contentGroupsCounter = 0;
		this.invisibleLineIdsAndGroupNames = new Set();
		this.maxRowsProContentGroup = Number.MAX_SAFE_INTEGER;
		this.fullWidth = 0;
		this.internalGroupNameSpans = new Map();

		this.sortedColumns = [];
		this.rcells = new Map();
		this.fixedContentLineGroups = new Set();
		this.gridTemplateColumns = "repeat(auto-fit, minmax(0px, 1fr))";
		this._addUnmodifiableGetters();
	}

	initialize() {
		if ( !Validator.isObjectPath( this.hostObject,
				"hostObject.xtdTbl.rowTpl" ) ) return false;

		this.setLineCountProGroupContainer( this.hostObject.xtdTbl.rowTpl.rtpLines );
		this.setSortedColumns( this.hostObject.xtdTbl.rowTpl.columns );
		this.extendBreakDefinition();
		this.registerSortedColumns();
		this.organizeDescriptionNames();
		this.postRegisterSortedColumns();
		this.calcGroupsWidths();
		this.calcGroupsHeights();
		// this.calcGroupsSpansFromWidths();
		this.organizeContentCellsByPosition();

		delete this.initialize; // TODO maybe instead of delete replace with empty function?
		return true;
	}

	setIf( map, key, value, isValueOfTypeSet = false ) {
		if ( !Validator.isString( key ) ) return false;
		if ( !isValueOfTypeSet && !Validator.isString( value ) ) return false;
		if ( isValueOfTypeSet && !Validator.isSet( value ) ) return false;
		map.set( key, value );
		return true;
	}

	_addSelf( hostObject ) {
		if ( !this._addSelfAs( hostObject, "idManager" ) ) return false;
		delete this._addSelf;
		return true;
	}

	_addSelfAs( hostObject, ownNameAsAProperty ) {
		if ( !Validator.isObject( hostObject ) ) return false;
		if ( !Validator.isString( ownNameAsAProperty ) ) return false;
		if ( ownNameAsAProperty in hostObject ) {
			if ( Validator.is( hostObject[ ownNameAsAProperty ], "XtwRtpIdManager" ) )
				return false;
			const oldIdManagerDescriptor = Validator
				.getDescriptor( hostObject[ ownNameAsAProperty ] );
			if ( !Validator.isObject( oldIdManagerDescriptor ) ) return false;
			if ( oldIdManagerDescriptor.configurable ) {
				delete hostObject[ ownNameAsAProperty ];
			} else if ( !oldIdManagerDescriptor.writable )
				return false;
			hostObject[ ownNameAsAProperty ] = void 0;
		}
		Object.defineProperty( hostObject, ownNameAsAProperty, {
			value: this,
			writable: false,
			configurable: false
		} );
		delete this._addSelfAs;
		return true;
	}

	_addMaps() {
		[ "groupIdToInternalGroupNameId", "cellIdToGroupId",
			"cellIdToInternalGroupNameId", "cellIdToDescriptionName",
			"descriptionNameToCellId", "descriptionNameToGroupId",
			"descriptionNameToAdress", "descriptionNameToInternalGroupNameId",
			"internalGroupNameToGroupId", "internalGroupNameWidths", "groupIdWidths",
			"internalGroupNameHeights", "groupIdHeights", "lineContainerWidths"
		].forEach( newMapName => {
			if ( this._defineAsMap( newMapName ) ) return;
			Warner._trace( `A new map with the name "${ newMapName }" could not` +
				` be added to the id manager.` );
		} );
		delete this._defineAsMap;
		delete this._addMaps;
	}

	_defineAsMap( mapName, writable = false, configurable = false ) {
		if ( !Validator.isString( mapName ) ) return false;
		if ( mapName in this ) return false;
		Object.defineProperty( this, mapName, {
			value: new Map(),
			writable: !!writable,
			configurable: !!configurable
		} );
		return true;
	}

	_addUnmodifiableGetters() {
		Validator.unmodifiableGetter( {
			hostObject: this.hostObject,
			getterName: "rcells",
			getCallback: () => {
				return this.rcells
			},
			configurable: true
		} );
		Validator.unmodifiableGetter( {
			hostObject: this.hostObject,
			getterName: "sortedColumns",
			getCallback: () => {
				return this.sortedColumns
			},
			configurable: true
		} );
		delete this._addUnmodifiableGetters;
		return true;
	}

	setLineCountProGroupContainer( lineCountNewValue ) {
		if ( Validator.isString( lineCountNewValue ) )
			lineCountNewValue = Number( lineCountNewValue );
		if ( !Validator.isPositiveInteger( lineCountNewValue ) ) return false;
		Object.defineProperty( this, "maxRowsProContentGroup", {
			value: lineCountNewValue,
			writable: false,
			configurable: false
		} );
		delete this.setLineCountProGroupContainer;
		return true;
	}

	calcGroupsSpansFromWidths() {
		if ( !Validator.isMap( this.internalGroupNameWidths ) ) return false;
		this.fullWidth = 0;

		[ ...this.internalGroupNameWidths.values() ]
		.forEach( groupWidth => this.fullWidth += groupWidth );

		if ( this.fullWidth <= 0 ) return false;

		this.internalGroupNameSpans = new Map();

		[ ...this.internalGroupNameWidths.entries() ].forEach( entry => {
			const groupName = entry[ 0 ];
			const groupNameWidth = entry[ 1 ];
			const span = Math.round( 100 * groupNameWidth / this.fullWidth );
			this.internalGroupNameSpans.set( groupName, span );
		} );

		delete this.calcGroupsSpansFromWidths;
		return true;
	}

	calcGroupsWidths() {
		if ( !Validator.isMap( this.internalGroupNameWidths ) ||
			!Validator.isSet( this.fixedContentLineGroups ) ) return false;
		const flexibleContentLineGroups = new Map();

		[ ...this.internalGroupNameWidths.entries() ].forEach( entry => {
			const groupName = entry[ 0 ];
			const groupNameWidth = entry[ 1 ];
			const isFixed = this.fixedContentLineGroups.has( groupName );
			// we have to set the value even for groups with "flexible" (not fixed)
			// widths, even if at this stage these are undefined (void 0), so that
			// the order in the map does not get messed up and so that we do not
			// have to rearrange the groups again in the future
			this.lineContainerWidths.set( groupName,
				( !isFixed ? void 0 : String( groupNameWidth ) + "px" ) );
			if ( !isFixed )
				flexibleContentLineGroups.set( groupName, groupNameWidth );
		} );

		const greatestCommonDivisor = Validator
			.getGreatestCommonDivisor( [ ...flexibleContentLineGroups.values() ] );

		[ ...flexibleContentLineGroups.entries() ].forEach( entry => {
			const groupName = entry[ 0 ];
			const groupNameWidth = entry[ 1 ];
			let fractionalUnits = groupNameWidth / greatestCommonDivisor;
			if ( !Validator.isValidNumber( fractionalUnits ) || fractionalUnits < 0 )
				fractionalUnits = 1;
			// if the process of finding the greatest common denominator is right,
			// it should always be an Integer
			if ( !Validator.isInteger( fractionalUnits ) )
				fractionalUnits = Math.round( fractionalUnits );
			this.lineContainerWidths.set( groupName, String( fractionalUnits ) + "fr" );
		} );

		this.gridTemplateColumns = "";
		[ ...this.lineContainerWidths.values() ].forEach( lineContainerWidth => {
			if ( !Validator.isString( lineContainerWidth ) ) return;
			this.gridTemplateColumns += lineContainerWidth + " ";
		} );
		this.gridTemplateColumns = this.gridTemplateColumns.trim();

		delete this.calcGroupsWidths;
		return true;
	}

	calcGroupsHeights() {
		// Warner.traceIf( true );
		if ( !Validator.isMap( this.groupIdHeights ) ||
			!Validator.isMap( this.internalGroupNameToGroupId ) ||
			!Validator.isMap( this.internalGroupNameHeights ) ) return false;
		let rowHeight = 0;
		[ ...this.internalGroupNameToGroupId.entries() ].forEach( entry => {
			const groupName = entry[ 0 ];
			if ( !Validator.isString( groupName ) ||
				groupName === "invisible-lines" ) return;
			const groupIds = entry[ 1 ];
			if ( !Validator.isSet( groupIds ) ) return;
			let totalHeight = 0;
			for ( let groupId of [ ...groupIds ] ) {
				const groupIdHeight = this.groupIdHeights.get( groupId );
				if ( !Validator.isPositiveNumber( groupIdHeight ) ) continue;
				totalHeight += groupIdHeight;
			}
			if ( totalHeight > rowHeight ) rowHeight = totalHeight;
			this.internalGroupNameHeights.set( groupName, totalHeight );
		} );
		Object.defineProperty( this, "rowHeight", {
			value: rowHeight,
			configurable: false,
			writable: false
		} );
		const definedRowHeight = this.tableRowHeight;
		if ( Validator.isPositiveNumber( definedRowHeight ) &&
			definedRowHeight != rowHeight ) {
			Warner.traceIf( true, `The row template row height estimated` +
				` (calculated) by the id manager does not match with the row` +
				` template row height defined in the table object. Estimated` +
				` height is "${ rowHeight }", whilst defined height is` +
				` "${ definedRowHeight }".` );
		}
		delete this.calcGroupsHeights;
		return true;
	}

	get tableRowHeight() {
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.is( this.hostObject.xtdTbl, "XtwTbl" ) ||
			!Validator.isObject( this.hostObject.xtdTbl.rowTpl ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( this.hostObject.xtdTbl.rowTpl.height, false ) ?
			this.hostObject.xtdTbl.rowTpl.height : void 0;
	}

	organizeDescriptionNames() {
		this.descriptionNames = this.deduplicate( this.descriptionNames );
		delete this.organizeDescriptionNames;
	}

	deduplicate( array ) {
		if ( !Validator.isArray( array, true ) ) return array;
		array = Array.from( new Set( array ) );
		return array;
	}

	setSortedColumns( rowTemplateCells ) {
		if ( !Validator.isArray( rowTemplateCells, true ) ) return rowTemplateCells;
		const sortedCells = this.sortCellsByGroup( rowTemplateCells );
		Object.defineProperty( this, "sortedColumns", {
			value: sortedCells,
			writable: false,
			configurable: false
		} );
		delete this.setSortedColumns;
		return true;
	}

	extendBreakDefinition() {
		if ( !this._extendBreakDefinition() ) return false;
		delete this._extendBreakDefinition;
		delete this.extendBreakDefinition;
		return true;
	}

	_extendBreakDefinition() {
		if ( !Validator.isArray( this.sortedColumns, true ) ) {
			return false;
		}
		let groupsHavingBreaks = [];
		for ( let column of this.sortedColumns ) {
			if ( !Validator.isObjectPath( column, "column.rtpCol" ) ) {
				continue;
			}
			if ( [ "true", true ].indexOf( column.rtpCol.rtpDscBreak ) < 0 ) {
				continue;
			}
			const groupName = Number( column.rtpCol.rtpDscGroup );
			if ( !Validator.isValidNumber( groupName ) ||
				groupsHavingBreaks.indexOf( groupName ) >= 0 ) {
				continue;
			}
			groupsHavingBreaks.push( groupName );
		}
		groupsHavingBreaks = Array.from( new Set( groupsHavingBreaks ) );
		for ( let column of this.sortedColumns ) {
			if ( !Validator.isObjectPath( column, "column.rtpCol" ) ) {
				continue;
			}
			const groupName = Number( column.rtpCol.rtpDscGroup );
			if ( groupsHavingBreaks.indexOf( groupName ) < 0 ) {
				continue;
			}
			column.rtpCol.rtpDscBreak = true;
		}
		return true;
	}

	registerSortedColumns() {
		if ( !this._registerCellArray( this.sortedColumns ) ) return false;
		delete this.registerSortedColumns;
		return true;
	}

	_registerCellArray( rcells ) {
		if ( !Validator.isArray( rcells, true ) ) return false;
		for ( let cellObject of rcells ) this.registerCell( cellObject );
		delete this.registerAndPassGroupNameId;
		delete this.getGroupNameId;
		delete this.setIf;
		delete this._registerCellIds;
		delete this.registerCell;
		delete this._registerCellArray;
		return true;
	}

	registerCell( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) ) return false;
		const cellId = this.getCellId( cellObject );
		const groupId = this.getGroupId( cellObject );
		const descriptionName = this.getDescriptionName( cellObject );

		const internalGroupNameId = this.getGroupNameId( {
			groupId: groupId,
			isInNewLineContainer: cellObject.rtpCol.rtpDscBreak == true ||
				cellObject.rtpCol.rtpDscBreak == "true",
			groupIdShouldBeInvisible: groupId.startsWith( "line-group-" ) &&
				!cellObject.rtpCol.rtpDscVis
		} );

		this._registerCellIds( {
			cellId: cellId,
			groupId: groupId,
			internalGroupNameId: internalGroupNameId,
			descriptionName: descriptionName
		} );

		return true;
	}

	getGroupNameId( {
		groupId,
		isInNewLineContainer = false,
		groupIdShouldBeInvisible = false
	} ) {
		if ( !Validator.isString( groupId ) ) return void 0;
		if ( !Validator.isBoolean( isInNewLineContainer ) ) isInNewLineContainer = false;

		if ( !!groupIdShouldBeInvisible ||
			this.invisibleLineIdsAndGroupNames.has( groupId ) )
			return this.registerAndPassGroupNameId( groupId, "invisible-lines" );

		let internalGroupNameId = this.groupIdToInternalGroupNameId.get( groupId );

		if ( Validator.isString( internalGroupNameId ) )
			return this.registerAndPassGroupNameId( groupId, internalGroupNameId );

		const lineGroupEntries = [ ...this.internalGroupNameToGroupId.entries() ];
		const lastEntry = lineGroupEntries.length <= 0 ? void 0 :
			lineGroupEntries[ lineGroupEntries.length - 1 ];

		if ( !isInNewLineContainer && Validator.isArray( lastEntry ) &&
			lastEntry.length == 2 && Validator.isSet( lastEntry[ 1 ] ) &&
			lastEntry[ 1 ].size < this.maxRowsProContentGroup )
			internalGroupNameId = lastEntry[ 0 ];
		else internalGroupNameId = Validator.generateRandomString(
			`content-group-${ this.contentGroupsCounter++ }-` +
			`${ ( !!isInNewLineContainer ? "newline-" : "" ) }` );

		return this.registerAndPassGroupNameId( groupId, internalGroupNameId );
	}

	registerAndPassGroupNameId( groupId, internalGroupNameId ) {
		if ( !Validator.isString( groupId ) ||
			!Validator.isString( internalGroupNameId ) ) return internalGroupNameId;
		let internalGroupEntry = this.internalGroupNameToGroupId
			.get( internalGroupNameId );
		if ( !Validator.isSet( internalGroupEntry ) )
			internalGroupEntry = new Set();
		internalGroupEntry.add( groupId );
		this.internalGroupNameToGroupId
			.set( internalGroupNameId, internalGroupEntry );
		return internalGroupNameId;
	}

	_registerCellIds( {
		cellId,
		groupId,
		internalGroupNameId,
		descriptionName
	} ) {
		this.setIf( this.groupIdToInternalGroupNameId,
			groupId, internalGroupNameId );
		this.setIf( this.cellIdToGroupId, cellId, groupId );
		this.setIf( this.cellIdToInternalGroupNameId, cellId, internalGroupNameId );
		this.setIf( this.cellIdToDescriptionName, cellId, descriptionName );
		this.setIf( this.descriptionNameToCellId, descriptionName, cellId );
		this.setIf( this.descriptionNameToGroupId, descriptionName, groupId );
		this.setIf( this.descriptionNameToInternalGroupNameId,
			descriptionName, internalGroupNameId );

		if ( Validator.isString( descriptionName ) )
			this.descriptionNames.push( descriptionName );

		if ( Validator.isString( internalGroupNameId ) &&
			Validator.isString( groupId ) && Validator.isString( cellId ) )
			this.setIf( this.descriptionNameToAdress, descriptionName,
				new Set( [ internalGroupNameId, groupId, cellId ] ), true );
	}

	postRegisterSortedColumns() {
		if ( !this._registerCellObjectArray( this.sortedColumns ) ) return false;
		delete this.postRegisterSortedColumns;
		return true;
	}

	_registerCellObjectArray( rcells ) {
		if ( !Validator.isArray( rcells, true ) ) return false;
		for ( let cellObject of rcells ) this.registerCellObject( cellObject );
		this.relevantOperands.add = () => { return false; };
		this.relevantOperands.clear = () => { return false; };
		this.relevantOperands.delete = () => { return false; };
		Object.seal( this.relevantOperands );
		Object.freeze( this.relevantOperands );
		delete this.register;
		delete this.registerWidth;
		delete this.registerHeight
		delete this.setWidth;
		delete this.setLineHeight;
		delete this.setHeight;
		delete this.setCellPosition;
		delete this.analyzeCellConditionalExpression;
		delete this.setColumnProperties;
		delete this.getColumnObject;
		delete this.isCellContentBlob;
		delete this.isFixedWidth;
		delete this.isCellVisible;
		delete this.getCellId;
		delete this.registerCellObject;
		delete this._registerCellObjectArray;
		return true;
	}

	registerCellObject( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) ) return;

		const cellId = this.getCellId( cellObject );
		if ( !Validator.isString( cellId ) ) {
			return;
		}

		const groupId = this.cellIdToGroupId.get( cellId );
		if ( !Validator.isString( groupId ) ) {
			return;
		}

		const internalGroupNameId = this.cellIdToInternalGroupNameId.get( cellId );
		if ( !Validator.isString( internalGroupNameId ) ) {
			return;
		}

		const descriptionName = this.cellIdToDescriptionName.get( cellId );
		const isVisible = this.isCellVisible( cellObject );
		const isFixedWidth = this.isFixedWidth( cellObject );
		const isBlob = this.isCellContentBlob( cellObject );
		const columnObject = this.getColumnObject( cellId );
		const padding = this.getPadding( cellObject );
		const newCellObject = {
			internalGroupNameId: internalGroupNameId,
			descriptionName: descriptionName,
			groupId: groupId,
			cellId: cellId,
			visible: isVisible,
			fixedWidth: isFixedWidth,
			padding: padding,
			isBlob: isBlob,
			rtpCol: Object.assign( {}, cellObject.rtpCol ),
			rtpTtl: Object.assign( {}, cellObject.rtpTtl )
		};

		this.analyzeCellConditionalExpression( cellObject, newCellObject );
		this.setColumnProperties( newCellObject, columnObject );
		this.setCellPosition( cellObject, newCellObject );
		if ( !isVisible ) {
			return this.register( newCellObject ); // should it be happening earlier?
		}
		this.setWidth( newCellObject, cellObject );
		if ( isFixedWidth ) {
			this.fixedContentLineGroups.add( internalGroupNameId );
		}
		this.setHeight( newCellObject, cellObject );
		this.setLineHeight( newCellObject, cellObject );
		this.registerWidth( groupId, internalGroupNameId, newCellObject );
		this.registerHeight( groupId, internalGroupNameId, newCellObject );
		this.register( newCellObject );
	}

	setCellPosition( oldCellObject, newCellObject ) {
		if ( !Validator.isObject( newCellObject ) ||
			!Validator.isObjectPath( oldCellObject, "cellObject.rtpCol" ) )
			return newCellObject;
		if ( Validator.isPositiveNumber( oldCellObject.rtpCol.rtpDscPos ) )
			newCellObject.position = oldCellObject.rtpCol.rtpDscPos;
		else {
			let presumptivePosition = Number( oldCellObject.rtpCol.rtpDscPos );
			newCellObject.position = Validator.isValidNumber( presumptivePosition ) ?
				presumptivePosition : 99;
		}
		return newCellObject;
	}

	setColumnProperties( newCellObject, columnObject ) {
		if ( !Validator.isObject( columnObject ) ||
			!Validator.isObject( newCellObject ) ) {
			return newCellObject;
		}
		newCellObject.columnObject = columnObject; //Object.assign( {}, columnObject );
		if ( !Validator.isBoolean( columnObject.link ) ) {
			return newCellObject;
		}
		newCellObject.isLink = columnObject.link;
		return newCellObject;
	}

	isCellVisible( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) ) {
			return false;
		}
		if ( !cellObject.rtpCol.rtpDscVis ) {
			return false;
		}
		return cellObject.rtpCol.rtpDscStdMode !== "-";
	}

	isCellContentBlob( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) )
			return false;
		if ( !( "rtpDscIsBlob" in cellObject.rtpCol ) ) return false;
		return [ true, "true" ].indexOf( cellObject.rtpCol.rtpDscIsBlob ) >= 0;
	}

	getCellId( cellObject ) {
		if ( !Validator.isObject( cellObject ) ) return void 0;
		if ( Validator.isPositiveInteger( cellObject.idc ) )
			return String( cellObject.idc );
		if ( !Validator.isString( cellObject.idc ) ) return void 0;
		return cellObject.idc;
	}

	getGroupId( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) )
			return void 0;
		if ( Validator.isPositiveInteger( cellObject.rtpCol.rtpDscGroup ) )
			return String( cellObject.rtpCol.rtpDscGroup );
		if ( Validator.isString( cellObject.rtpCol.rtpDscGroup ) )
			return cellObject.rtpCol.rtpDscGroup;
		// return void 0;
		return Validator.generateRandomString( "line-group-" );
	}

	getDescriptionName( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) ||
			!Validator.isString( cellObject.rtpCol.rtpDscName ) )
			return void 0;
		return cellObject.rtpCol.rtpDscName;
	}

	getColumnObject( cellId ) {
		if ( !Validator.isObjectPath( this.hostObject, "hostObject.xtwHead" ) ||
			!Validator.isMap( this.hostObject.xtwHead.rtpColumns ) ) {
			return void 0;
		}
		return this.hostObject.xtwHead.rtpColumns.get( cellId );
	}

	getPadding( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol.rtpDscPadding" ) ) {
			return void 0;
		}
		const descriptorPadding = cellObject.rtpCol.rtpDscPadding;
		const paddingTop = Validator.isPositiveNumber( descriptorPadding.top ) ?
			descriptorPadding.top : 0;
		const paddingRight = Validator.isPositiveNumber( descriptorPadding.right ) ?
			descriptorPadding.right : 0;
		const paddingBottom = Validator.isPositiveNumber( descriptorPadding.bottom ) ?
			descriptorPadding.bottom : 0;
		const paddingLeft = Validator.isPositiveNumber( descriptorPadding.left ) ?
			descriptorPadding.left : 0;
		if ( paddingTop === paddingRight === paddingBottom === paddingLeft === 0 ) {
			return void 0;
		}
		return `${ paddingTop }px ${ paddingRight }px ${ paddingBottom }px` +
			` ${ paddingLeft }px`;
	}

	setWidth( cell, oldCellObject ) {
		if ( Validator.isPositiveNumber( cell.width, false ) ) return cell;
		// <cellObject.rtpCol.rtpDscWidth> (the column width) has priority over
		// <columnProperties.width> (which is the width for the excel/table view)
		// for obvious reasons (we are in the "row template", NOT in the "table" view )
		if ( Validator.isObjectPath( oldCellObject, "oldCellObject.rtpCol" ) &&
			Validator.isPositiveNumber( oldCellObject.rtpCol.rtpDscWidth, false ) ) {
			cell.width = oldCellObject.rtpCol.rtpDscWidth;
			return cell;
		}
		if ( !Validator.isObject( cell.columnObject ) ||
			!Validator.isPositiveNumber( cell.columnObject.width, false ) )
			return cell;
		cell.width = cell.columnObject.width;
		return cell;
	}

	setHeight( cell, oldCellObject ) {
		// Warner.traceIf( true );
		if ( Validator.isPositiveNumber( cell.height, false ) ) return cell;
		if ( Validator.isObjectPath( oldCellObject, "oldCellObject.rtpCol" ) &&
			Validator.isPositiveNumber( oldCellObject.rtpCol.rtpDscHeight, false ) ) {
			cell.height = oldCellObject.rtpCol.rtpDscHeight;
			return cell;
		}
		if ( !Validator.isObject( cell.rtpTtl ) ||
			!Validator.isPositiveNumber( cell.rtpTtl.rtpDscHeight, false ) )
			return cell;
		cell.height = cell.rtpTtl.rtpDscHeight;
		return cell;
	}

	setLineHeight( cell, oldCellObject ) {
		if ( !Validator.isPositiveNumber( cell.height, false ) ) return cell;
		if ( !Validator.isObjectPath( oldCellObject, "oldCellObject.rtpCol" ) ||
			!Validator.isString( oldCellObject.rtpCol.rtpDscStyle ) ||
			oldCellObject.rtpCol.rtpDscStyle.indexOf( "M" ) < 0 ) {
			cell.lineHeight = cell.height;
			return cell;
		}
		const multilineValue = Validator.firstMatchValue( oldCellObject.rtpCol.rtpDscStyle, /M\d*/g );
		if ( !Validator.isString( multilineValue ) ) return cell;
		let numberOfLines = Validator.isFunction( multilineValue.replaceAll ) ?
			Number( multilineValue.replaceAll( /\D+/g, "" ) ) : Number( multilineValue );
		if ( !Validator.isValidNumber( numberOfLines, false ) ) return cell;
		if ( numberOfLines < 0 ) numberOfLines = -1 * numberOfLines;
		numberOfLines = parseInt( numberOfLines, 10 );
		if ( !Validator.isInteger( numberOfLines ) ) return cell;
		const lineHeightValue = cell.height / numberOfLines;
		if ( !Validator.isValidNumber( lineHeightValue, false ) ) return cell;
		cell.lineHeight = lineHeightValue;
		return cell;
	}

	registerWidth( groupId, internalGroupNameId, cell ) {
		if ( !Validator.isString( groupId ) ||
			!Validator.isString( internalGroupNameId ) ||
			!Validator.isObject( cell ) ) {
			return false;
		}
		let currentGroupWidth = this.groupIdWidths.get( groupId );
		if ( !Validator.isPositiveNumber( currentGroupWidth, false ) ) {
			currentGroupWidth = 0;
		}
		currentGroupWidth += !Validator.isPositiveInteger( cell.width, true ) ?
			0 : Number( cell.width );
		this.groupIdWidths.set( groupId, currentGroupWidth );
		let currentInternalGroupNameWidth =
			this.internalGroupNameWidths.get( internalGroupNameId );
		if ( !Validator.isPositiveNumber( currentInternalGroupNameWidth, false ) ) {
			currentInternalGroupNameWidth = 0;
		}
		if ( currentGroupWidth > currentInternalGroupNameWidth ) {
			currentInternalGroupNameWidth = currentGroupWidth;
		}
		this.internalGroupNameWidths
			.set( internalGroupNameId, currentInternalGroupNameWidth );
		return true;
	}

	registerHeight( groupId, internalGroupNameId, cell ) {
		// Warner.traceIf( true );
		let currentGroupHeight = this.groupIdHeights.get( groupId );
		if ( !Validator.isPositiveNumber( currentGroupHeight, false ) )
			currentGroupHeight = 0;
		if ( Validator.isPositiveInteger( cell.height, true ) &&
			cell.height > currentGroupHeight )
			currentGroupHeight = Number( cell.height );
		this.groupIdHeights.set( groupId, currentGroupHeight );
		return true;
	}

	analyzeCellConditionalExpression( cellParameters, cell ) {
		if ( !Validator.isObject( cell ) ||
			!Validator.isObject( cellParameters ) ) return cell;
		if ( !cell.visible ) return cell;
		let expression = ConditionInterpreter.getFromParameters( cellParameters );
		if ( !Validator.isString( expression ) ) return cell;
		let interpretation = ConditionInterpreter.splitExpression( expression );
		if ( !Validator.isObjectPath( interpretation, "interpretation.condition" ) )
			return cell;
		cell.condition = interpretation;
		cell.operands = interpretation.condition.uniqueExpressionOperands.filter(
			operand => this.descriptionNames.indexOf( operand ) >= 0 );
		for ( let operand of cell.operands )
			this.relevantOperands.add( operand );
		return cell;
	}

	register( cell ) {
		let rowInternalCellGroupMap = this.rcells.get( cell.internalGroupNameId );
		if ( !Validator.isMap( rowInternalCellGroupMap ) )
			rowInternalCellGroupMap = new Map();
		let groupsMap = rowInternalCellGroupMap.get( cell.groupId );
		if ( !Validator.isMap( groupsMap ) ) groupsMap = new Map();
		groupsMap.set( cell.cellId, cell );
		rowInternalCellGroupMap.set( cell.groupId, groupsMap );
		this.rcells.set( cell.internalGroupNameId, rowInternalCellGroupMap );
	}

	_organizeByPosition( cellsMap ) {
		if ( !Validator.isMap( cellsMap ) ) return cellsMap;
		let newCells = new Map();
		for ( let contentGroupId of [ ...cellsMap.keys() ] ) {
			let newContentGroup = new Map();
			let oldContentGroup = cellsMap.get( contentGroupId );
			for ( let contentRowId of [ ...oldContentGroup.keys() ] ) {
				let oldContentRow = oldContentGroup.get( contentRowId );
				let newContentRow = this
					.getChildMapOrganizedByPosition( oldContentRow );
				newContentGroup.set( contentRowId, newContentRow );
			}
			newCells.set( contentGroupId, newContentGroup );
		}
		delete this._organizeByPosition;
		return newCells;
	}

	isFixedWidth( cellObject ) {
		if ( !Validator.isObjectPath( cellObject, "cellObject.rtpCol" ) ||
			!Validator.isString( cellObject.rtpCol.rtpDscAnc ) ) return false;
		return [ 0, 2 ].indexOf(
			cellObject.rtpCol.rtpDscAnc.toUpperCase().indexOf( "N" ) ) >= 0;
	}

	organizeContentCellsByPosition() {
		if ( !this._organizeByPosition( this.rcells ) ) return false;
		delete this.organizeContentCellsByPosition;
		return true;
	}

	getChildMapOrganizedByPosition( childMap ) {
		if ( !Validator.isMap( childMap ) ) return childMap;
		const positionToCellId = new Map();
		const cellIdsWithNoPosition = new Set();
		let positions = new Set();
		for ( let contentCellId of [ ...childMap.keys() ] ) {
			let contentCellDescription = childMap.get( contentCellId );
			if ( !Validator.isObject( contentCellDescription ) ||
				!Validator.isPositiveNumber( contentCellDescription.position ) ) {
				cellIdsWithNoPosition.add( contentCellId );
				continue;
			}
			const position = Number( contentCellDescription.position );
			let cellIdList = positionToCellId.get( position );
			if ( !Validator.isSet( cellIdList ) ) cellIdList = new Set();
			cellIdList.add( contentCellId );
			positionToCellId.set( position, cellIdList );
			positions.add( position );
		}
		positions = new Set( Array.from( positions ).sort( ( position, nextPosition ) => {
			return position > nextPosition ? 1 : nextPosition > position ? -1 : 0;
		} ) );
		const finalMap = new Map();
		for ( let position of Array.from( positions ) ) {
			const cellIdList = positionToCellId.get( position );
			if ( !Validator.isSet( cellIdList ) ) continue;
			for ( let cellId of Array.from( cellIdList ) ) {
				finalMap.set( cellId, childMap.get( cellId ) );
			}
		}
		for ( let cellId of Array.from( cellIdsWithNoPosition ) ) {
			finalMap.set( cellId, childMap.get( cellId ) );
		}
		delete this.getChildMapOrganizedByPosition;
		return finalMap;
	}

	sortCellsByGroup( rowTemplateCells ) {
		if ( !Validator.isArray( rowTemplateCells, true ) ) return rowTemplateCells;
		const unsortedCellsWithNoGroupName = [];
		const unsortedCellsWithGroupName = new Map();
		let groupNamesWithNumericValue = new Set();
		const lineIdToCells = new Map();
		for ( let contentCell of rowTemplateCells ) {
			if ( !Validator.isObjectPath( contentCell, "contentCell.rtpCol" ) ||
				!( "rtpDscGroup" in contentCell.rtpCol ) ) {
				unsortedCellsWithNoGroupName.push( contentCell );
				continue;
			}
			let groupNameAndLineId = Number( contentCell.rtpCol.rtpDscGroup );
			if ( !Validator.isValidNumber( groupNameAndLineId ) ) {
				let cellsByGroupName = unsortedCellsWithGroupName.get( contentCell.rtpCol.rtpDscGroup );
				if ( !Validator.isArray( cellsByGroupName ) ) cellsByGroupName = [];
				cellsByGroupName.push( contentCell );
				unsortedCellsWithGroupName.set( contentCell.rtpCol.rtpDscGroup, cellsByGroupName );
				continue;
			}
			let cellsByGroupName = lineIdToCells.get( groupNameAndLineId );
			if ( !Validator.isArray( cellsByGroupName ) ) cellsByGroupName = [];
			cellsByGroupName.push( contentCell );
			lineIdToCells.set( groupNameAndLineId, cellsByGroupName );
			groupNamesWithNumericValue.add( groupNameAndLineId );
		}
		groupNamesWithNumericValue = new Set(
			Array.from( groupNamesWithNumericValue )
			.sort( ( lineId, nextLineId ) => {
				return lineId > nextLineId ? 1 : nextLineId > lineId ? -1 : 0;
			} )
		);
		const invisibleLineGroups = new Map();
		if ( !Validator.isSet( this.invisibleLineIdsAndGroupNames ) )
			this.invisibleLineIdsAndGroupNames = new Set();

		lineIdToCells.forEach( ( cellList, lineIdAndGroupName ) => {
			const allCellsAreInvisible = cellList.every( cell => (
				!Validator.isObjectPath( cell, "cell.rtpCol" ) ||
				!( "rtpDscVis" in cell.rtpCol ) || cell.rtpCol.rtpDscVis === false ||
				cell.rtpCol.rtpDscVis === "false" ) );
			if ( !allCellsAreInvisible ) return;
			// this.invisibleLineIdsAndGroupNames.add( lineIdAndGroupName );
			// if ( !Validator.isString( lineIdAndGroupName ) )
			this.invisibleLineIdsAndGroupNames.add( String( lineIdAndGroupName ) );
			invisibleLineGroups.set( lineIdAndGroupName, cellList );
			lineIdToCells.set( lineIdAndGroupName, void 0 );
			lineIdToCells.delete( lineIdAndGroupName );
		} );

		unsortedCellsWithGroupName.forEach( ( cellList, lineIdAndGroupName ) => {
			const allCellsAreInvisible = cellList.every( cell => (
				!Validator.isObjectPath( cell, "cell.rtpCol" ) ||
				!( "rtpDscVis" in cell.rtpCol ) || cell.rtpCol.rtpDscVis === false ||
				cell.rtpCol.rtpDscVis === "false" ) );
			if ( !allCellsAreInvisible ) return;
			// this.invisibleLineIdsAndGroupNames.add( lineIdAndGroupName );
			// if ( !Validator.isString( lineIdAndGroupName ) )
			this.invisibleLineIdsAndGroupNames.add( String( lineIdAndGroupName ) );
			invisibleLineGroups.set( lineIdAndGroupName, cellList );
			unsortedCellsWithGroupName.set( lineIdAndGroupName, void 0 );
			unsortedCellsWithGroupName.delete( lineIdAndGroupName );
		} );

		// a short-hand function to compare two cells (of the same group...) by position
		const compare_by_pos = ( c1, c2 ) => {
			const p1 = Number( c1.rtpCol.rtpDscPos );
			const p2 = Number( c2.rtpCol.rtpDscPos );
			return Validator.numericCompare( p1, p2 );
		};

		const sortedCells = [];
		for ( let groupNameAndLineId of Array.from( groupNamesWithNumericValue ) ) {
			const cellList = lineIdToCells.get( groupNameAndLineId );
			if ( !Validator.isArray( cellList ) ) continue;
			cellList.sort( compare_by_pos );
			for ( let cell of cellList ) {
				sortedCells.push( cell );
			}
		}
		const groupedCells = [];
		for ( let cellList of [ ...unsortedCellsWithGroupName.values() ] ) {
			if ( !Validator.isArray( cellList ) ) continue;
			cellList.sort( compare_by_pos );
			for ( let cell of cellList ) {
				groupedCells.push( cell );
			}
		}
		const invisibleCells = [];
		for ( let cellList of [ ...invisibleLineGroups.values() ] ) {
			if ( !Validator.isArray( cellList ) ) continue;
			for ( let cell of cellList ) {
				invisibleCells.push( cell );
			}
		}
		for ( let cellIndex = 0; cellIndex < unsortedCellsWithNoGroupName.length; cellIndex++ ) {
			let cell = unsortedCellsWithNoGroupName[ cellIndex ];
			if ( Validator.isObjectPath( cell, "cell.rtpCol" ) &&
				"rtpDscVis" in cell.rtpCol && cell.rtpCol.rtpDscVis !== false &&
				cell.rtpCol.rtpDscVis !== "false" ) continue;
			invisibleCells.push( cell );
			unsortedCellsWithNoGroupName.splice( cellIndex, 1 );
			cellIndex--;
		}
		delete this.sortCellsByGroup;
		return Array.from( new Set(
			sortedCells.concat( groupedCells )
			.concat( unsortedCellsWithNoGroupName ).concat( invisibleCells )
		) );
	}
}

console.debug( 'widgets/xtw/rtp/XtwRtpIdManager.js loaded.' );
