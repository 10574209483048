/**
 * a global counter class
 */
export default class GlobalCounter {

    /**
     * constructs the instance
     */
    constructor() {
        this._counter = 0;
    }

    /**
     * 
     * @returns {GlobalCounter} the global counter instance 
     */
    static getInst() {
        return singleton;
    }

    /**
     * increments the counter and returns its value
     * @returns {Number} the next counter value
     */
    nextValue() {
        return ++this._counter;
    }
}

// create the singleton
const singleton = new GlobalCounter();