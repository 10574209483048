import Color from '../../../../utils/Color';
import Validator from '../../../../utils/Validator';

export default class ColumnDataColorManager {

	constructor() {
		this._mainFontColor = void 0;
		this._alternativeFontColor = void 0;
		this._mainBackgroundColor = void 0;
		this._alternativeBackgroundColor = void 0;
	}

	set mainFontColor( color ) {
		this._setColorProperty( "_mainFontColor", color );
	}

	get mainFontColor() {
		return this._mainFontColor instanceof Color ? this._mainFontColor : void 0;
	}

	set alternativeFontColor( color ) {
		this._setColorProperty( "_alternativeFontColor", color );
	}

	get alternativeFontColor() {
		return this._alternativeFontColor instanceof Color ?
			this._alternativeFontColor : void 0;
	}

	set mainBackgroundColor( color ) {
		this._setColorProperty( "_mainBackgroundColor", color );
	}

	get mainBackgroundColor() {
		return this._mainBackgroundColor instanceof Color ?
			this._mainBackgroundColor : void 0;
	}

	set alternativeBackgroundColor( color ) {
		this._setColorProperty( "_alternativeBackgroundColor", color );
	}

	get alternativeBackgroundColor() {
		return this._alternativeBackgroundColor instanceof Color ?
			this._alternativeBackgroundColor : void 0;
	}

	_setColorProperty( propertyName, value, voidIfInvalidValue = true ) {
		if ( !Validator.isString( propertyName ) || !( propertyName in this ) ) {
			return false;
		}
		if ( value instanceof Color ) {
			this[ propertyName ] = value;
			return true;
		}
		if ( Validator.isArray( value, 4 ) ) {
			this[ propertyName ] = Color.fromRgba( value );
			return true;
		}
		if ( Validator.isString( value ) ) {
			this[ propertyName ] = Color.fromHex( value, 1 );
			return true;
		}
		if ( !!voidIfInvalidValue ) {
			this[ propertyName ] = void 0;
		}
		return false;
	}
}
