/**
 * a simple logging helper class
 */

import LoggingBase from "../base/loggingbase";

/** dummy console */
class DmyCon {
	log(out) {
		// do nothing
	}

	info(out) {
		// do nothing
	}

	warn(out) {
		// do nothing
	}

	error(out) {
		// do nothing
	}
}

/** internal logger class */
export default class InternalLogger extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {Boolean} debug debug mode flag
	 */
	constructor(debug) {
		super('internal');
		this._active = this._checkConsole(debug) || !!debug;
		this._dmycon = new DmyCon();
	}

	hasConsole() {
		return !!(console && console.log);
	}

	isLogAct() {
		return this._active && this.alive;
	}

	setLogAct(act) {
		this._active = act;
	}

	getConsole(frc) {
		return ((frc || this._active) && this.hasConsole()) ? console : this._dmycon;
	}

	logTxt(txt, frc) {
		this.log(txt);
	}

	logErr(err) {
		this.error(err);
	}

	logObj(obj, idw, fnc, par) {
		if ( this._active && this.hasConsole() ) {
			let txt = "";
			if ( obj ) {
				if ( obj.classname ) {
					txt += obj.classname;
				} else {
					txt += Object.prototype.toString.call(obj);
				}
				if ( idw ) {
					txt += "(" + idw + ")";
				}
			}
			txt += ": " + fnc;
			if ( par ) {
				txt += "(" + par + ")";
			}
			this.logTxt(txt, true);
		}
	}

	_checkConsole(debug) {
		let con_open = !!debug;
		if ( !debug && this.hasConsole() ) {
			const threshold = 160;
			const dy = window.outerHeight - window.innerHeight;
			if ( dy > threshold ) {
				con_open = true;
			}
		}
		return con_open;
	}
}
