import AttachmentObject from '../../../../utils/AttachmentObject';
import DomEventHelper from '../../../../utils/DomEventHelper';
import EventListenerManager from '../../../../utils/EventListenerManager';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class SelectionCellRowHeightExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;

		parentObject.addHeightAdjustmentDiv();
	}

	reappendHeightAdjustmentDiv() {
		if ( ![ this.element, this.heightAdjustmentDiv ]
			.every( element => element instanceof HTMLElement ) ) {
			return false;
		}
		this.element.appendChild( this.heightAdjustmentDiv );
		return true;
	}

	addHeightAdjustmentDiv() {
		if ( !( this.element instanceof HTMLElement ) ) {
			return false;
		}
		this.heightAdjustmentDiv = this.newHeightAdjustmentDiv;
		this.element.appendChild( this.heightAdjustmentDiv );
		return true;
	}

	get newHeightAdjustmentDiv() {
		const heightAdjustmentDiv = window.document.createElement( "div" );
		heightAdjustmentDiv.classList.add( "row-height-adjustment" );
		this.addHeightAdjustmentDivBasicListeners( heightAdjustmentDiv );
		return heightAdjustmentDiv;
	}

	onHeightAdjustmentDivMouseDown( domEvent ) {
		DomEventHelper.stopIf( domEvent );
		const xRowItem = this.row;
		if ( !Validator.isObject( xRowItem ) ) {
			return false;
		}
		const xtwBody = this.xtwBody;
		if ( !Validator.isObjectPath( xtwBody, "xtwBody.xtdTbl" ) ||
			!Validator.isFunction( xtwBody.xtdTbl.onHeightAdjustmentDivMouseDown ) ) {
			return false;
		}
		return xtwBody.xtdTbl.onHeightAdjustmentDivMouseDown( domEvent, xRowItem );
	}

	onHeightAdjustmentDivMouseUp( domEvent ) {
		// DomEventHelper.stopIf( domEvent );
	}

	onHeightAdjustmentDivClick( domEvent ) {
		// DomEventHelper.stopIf( domEvent );
	}

	addHeightAdjustmentDivBasicListeners( heightAdjustmentDiv ) {
		const mousedownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousedown",
			functionName: "onHeightAdjustmentDivMouseDown",
			callBackPrefix: "HeightAdjustmentDiv",
			element: heightAdjustmentDiv,
			useCapture: false
		} );
		const mouseupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "onHeightAdjustmentDivMouseUp",
			callBackPrefix: "HeightAdjustmentDiv",
			element: heightAdjustmentDiv,
			useCapture: false
		} );
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onHeightAdjustmentDivClick",
			callBackPrefix: "HeightAdjustmentDiv",
			element: heightAdjustmentDiv,
			useCapture: false
		} );
		return mousedownListenerSuccessfullyAdded &&
			mouseupListenerSuccessfullyAdded && clickListenerSuccessfullyAdded;
	}

	destroyHeightAdjustmentDiv() {
		[ "mousedown", "mouseup", "click" ].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName,
				this.heightAdjustmentDiv, "HeightAdjustmentDiv" );
		} );
		return HtmHelper.discardElementProperty( this, "heightAdjustmentDiv" );
	}

}
