import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';

export default class MaximizeButtonExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get newMaximizeButton() {
		return this._getNewExpandBtn( true );
	}

	get newMinimizeButton() {
		return this._getNewExpandBtn( false );
	}

	_getNewExpandBtn( expandIcon = true ) {
		const btnContainer = window.document.createElement( "div" );
		btnContainer.classList.add( "rtp-maximize" );
		const btnIcon = window.document.createElement( "i" );
		// note: the CSS class contains a rotation by 45deg, so we need upright symbols
		btnIcon.classList.add( "fal", ( !!expandIcon ? "fa-long-arrow-up" : "fa-plus" ) );
		btnContainer.appendChild( btnIcon );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onButtonContainerClick",
			callBackPrefix: "ButtonContainer",
			element: btnContainer,
			useCapture: false
		} );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "mousedown",
			functionName: "onButtonContainerMousedown",
			callBackPrefix: "ButtonContainer",
			element: btnContainer,
			useCapture: false
		} );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "onButtonContainerMouseup",
			callBackPrefix: "ButtonContainer",
			element: btnContainer,
			useCapture: false
		} );
		return btnContainer;
	}

	onButtonContainerMousedown( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onButtonContainerMouseup( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

}
