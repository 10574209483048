import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class RchTree - client side implementation of tree widget
 */
export default class RchTree {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, ["onSend", "onRender", "onReady"]);
		this.classname = "psawidget.RchTree";
		this.treeCtl = null;
		this.tmr = 1;
		this.ready = false;
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		this.itmMgr = ItmMgr.getInst();
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			const idt = cwd["tcl"];
			if ( idt ) {
				const tcl = rwt.remote.ObjectRegistry.getObject(idt);
				if ( tcl ) {
					this.treeCtl = tcl;
				}
			}
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	destroy() {
		this.treeCtl.removeEventListener("mousedown", this._onMouseDown, this);
		this.treeCtl.removeEventListener("mouseup", this._onMouseUp, this);
		this.ready = false;
		this.itmMgr = null;
		this.treeCtl = null;
	}

	onSend() {
		// do nothing so far...
	}

	onReady() {
		this.treeCtl.addEventListener("mousedown", this._onMouseDown, this);
		this.treeCtl.addEventListener("mouseup", this._onMouseUp, this);
		this.ready = true;
	}

	onRender(){
		rap.off("render", this.onRender);
		this.onReady();
	}

	setHdrClr( args ) {
		const color = args["bgc"];
		if ( color ) {
			this.itmMgr.setQxBgkClr(this.treeCtl._header._dummyColumn, color);
		}
	}

	/**
	 * sets a HTML/CSS based header icon for single a tree column.
	 */
	setHdrIco(args) {
		const	idw	= args.idw;
		const wdg = rwt.remote.ObjectRegistry.getObject(idw);
		if ( wdg ) {
			const dsc = args.ico;
			if ( dsc ) {
				const cls = dsc.cssCls;
				const icn = dsc.icoNam;
				const siz = dsc.icoSiz;
				const clr = dsc.icoClr;
				const fnm = dsc.fntNam;
				if ( (typeof cls === "string") && (typeof icn === "string") && (typeof siz === "number") ) {
					const pos = [];
					pos.x = 3; pos.y = Math.round((wdg._grid._headerHeight-siz)/2);
					const div = this.itmMgr._creDiv('center', siz, pos);
					const ico = this.itmMgr._creDscIco(cls, icn, siz, clr, fnm, null, false);
					div.appendChild(ico);
					const mulcel = wdg._grid._header._getLabelByColumn(wdg);
					mulcel.$el.append(div);
				}
			}
		}
	}

	_onKeyDown(event) {
		// nothing so far
	}
	
	_onMouseDown(evt) {
		if ( this.ready ) {
			if ( evt.isRightButtonPressed() ) {
				// evt.stopPropagation();
				// evt.preventDefault();
				// console.log('Caught right button DOWN on tree!');
			}
		}
	}

	_onMouseUp(evt) {
		if ( this.ready ) {
			if ( evt.isRightButtonPressed() ) {
				// evt.stopPropagation();
				// evt.preventDefault();
				// console.log('Caught right button UP on tree!');
			}
		}
	}
	
	_onScroll() {
		// nothing so far
	}
	
	_nfySrv(code, par) {
		if ( this.ready ) {
			// nothing yet
		}
	}

	_callSrv(code, par) {
		const param = {};
		param["cod"] = code;
		param["par"] = par;
		rap.getRemoteObject(this).call("TRE_CAL_SRV", param);
	}
	
	_sndRes(par) {
		this._log("sndRes", par);
		this._callSrv("result", par);
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget RchTree.');
		rap.registerTypeHandler("psawidget.RchTree", {
			factory : function(properties) {
				return new RchTree(properties);
			},				
			destructor : "destroy",
			methods : [ "setHdrClr", "setHdrIco", "_sndRes" ],
			events : ["TRE_NFY_SRV", "TRE_CAL_SRV"]
		} );				
	}
}

function overwriteTreeDNDRowFeedback() {
	if ( rwt ) {
		console.debug('RCHTREE: overriding default DND feddback function.');
		rwt.remote.DNDSupport.prototype._configureTreeRowFeedback = function(row) {}
	}
}

overwriteTreeDNDRowFeedback();
console.debug('widgets/rchtree/RchTree.js loaded.');