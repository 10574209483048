import AttachmentObject from '../../../../utils/AttachmentObject';

const FA_CLASS_REGULAR ='fal';
const FA_SYMBOL_REGULAR = 'fa-asterisk';
const FA_CLASS_FOCUS ='fal';
const FA_SYMBOL_FOCUS = 'fa-star-christmas';
const FONT_SIZE = '12px';
const SYMBOL_COLOR = '#717171';

export default class XtwSelectionCellInsertionDummyExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	_addInsertionStar( focused = false ) {
		if ( !this.isRendered ) {
			return false;
		}
		this.removeSelectionArrow();
		const element = this.element;
		element.style.alignItems = "center";
		const insertionStar = this._createStar(!!focused);
		element.appendChild( insertionStar );
		return true;
	}

	addInsertionStar() {
		return this._addInsertionStar( false );
	}

	removeInsertionStar() {
		return this.removeSelectionArrow();
	}

	addFocusedInsertionStar() {
		return this._addInsertionStar( true );
	}

	_createStar(focused) {
		const starSpan = window.document.createElement( "span" );
		starSpan.style.marginLeft = "auto";
		starSpan.style.marginRight = "auto";
		starSpan.style.fontSize = FONT_SIZE;
		starSpan.style.color = SYMBOL_COLOR;
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( (focused ? FA_CLASS_FOCUS : FA_CLASS_REGULAR), (focused ? FA_SYMBOL_FOCUS : FA_SYMBOL_REGULAR) );
		starSpan.appendChild( italicIconPlaceholder );
		return starSpan;
	}

	get insertionStar() {
		return this._createStar(false);
	}

	get focusedInsertionStar() {
		return this._createStar(true);
	}

}
