import Validator from './Validator';

const TAG_CONTENT = "[^\\<\\>]";
const ATTRIBUTE_VALUE_CONTENT = '[^\\"\\<\\>]*';
const ATTRIBUTE_VALUE = `\\=\\"${ ATTRIBUTE_VALUE_CONTENT }\\"`;

export default class HtmParsingUtils {

	// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	// Solution#4 (chosen because of small amount of code)
	static encode64( unicodeString ) {
		return btoa( encodeURIComponent( unicodeString ).replace( /%([0-9A-F]{2})/g,
			( match, firstPosition ) => {
				return String.fromCharCode( '0x' + firstPosition );
			} ) );
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	// Solution#4 (chosen because of small amount of code)
	static decode64( encodedString ) {
		return decodeURIComponent( atob( encodedString ).split( "" ).map(
			( character ) => {
				return '%' + ( '00' + character.charCodeAt( 0 ).toString( 16 ) ).slice( -2 );
			} ).join( "" ) );
	}

	static isEncoded64( encodedString ) {
		if ( typeof encodedString != "string" ) return false;
		if ( encodedString.match( /(\s)/g ) &&
			encodedString.match( /(\s)/g ).length > 0 ) return false;
		let isEncoded = false;
		try {
			HtmParsingUtils.decode64( encodedString );
			isEncoded = true;
		} catch ( err ) {};
		return isEncoded;
	}

	static matchTag( {
		source,
		tagName,
		attribute
	} ) {
		if ( !Validator.isString( tagName ) || !Validator.isString( source ) ) {
			return void 0;
		}
		const tagRegExp = Validator.isString( attribute ) ?
			HtmParsingUtils.createRegexForChildlessTagWithAttribute( {
				tagName: tagName,
				attribute: attribute
			} ) :
			HtmParsingUtils.createRegexForChildlessTag( { tagName: tagName } );
		if ( !( tagRegExp instanceof RegExp ) ) {
			return void 0;
		}
		return [ ...source.matchAll( tagRegExp ) ];
	}

	static createRegexForChildlessTag( {
		tagName,
		hasClosingTag = true,
		flags = "gi"
	} ) {
		if ( !Validator.isString( tagName ) ) {
			return void 0;
		}
		if ( !Validator.isString( flags ) ) {
			flags = "gi";
		}
		const regExpString =
			`\\<${ tagName.toLowerCase() }(?:\\s+${ TAG_CONTENT }*)*\\>` +
			( !hasClosingTag ? "" : `${ TAG_CONTENT }*<\\/${ tagName.toLowerCase() }>` );
		return new RegExp( regExpString, flags );
	}

	static createRegexForChildlessTagWithAttribute( {
		tagName,
		attribute = "",
		hasClosingTag = true,
		flags = "gi"
	} ) {
		if ( !Validator.isString( tagName ) ) {
			return void 0;
		}
		if ( !Validator.isString( flags ) ) {
			flags = "gi";
		}
		if ( !Validator.isString( attribute ) ) {
			attribute = "";
		} else {
			attribute = attribute.toLowerCase().replaceAll( /\-/gi, "\\-" ) +
				ATTRIBUTE_VALUE;
		}
		const regExpString =
			`\\<${ tagName.toLowerCase() }\\s+${ TAG_CONTENT }*${ attribute }` +
			`${ TAG_CONTENT }*\\>` + ( !hasClosingTag ? "" :
				`${ TAG_CONTENT }*<\\/${ tagName.toLowerCase() }>` );
		return new RegExp( regExpString, flags );
	}

	static getTagAttributeValue( tagString, tagName, attributeName ) {
		if ( ![ tagString, tagName, attributeName ]
			.every( component => Validator.isString( component ) ) ) {
			return void 0;
		}
		tagString = tagString.replace( /\s+/g, " " );
		tagName = tagName.replace( /\s+/g, "" );
		attributeName = attributeName.replace( /\s+/g, "" );
		if ( ![ tagString, tagName, attributeName ]
			.every( component => component.length >= 1 ) ||
			!tagString.startsWith( "<" + tagName + " " ) ||
			tagString.charAt( tagString.length - 1 ) != ">" ||
			tagString.indexOf( attributeName + '=' ) < 0 ) {
			return void 0;
		}
		let result = tagString.slice( tagString.indexOf( attributeName + '=' ) +
			attributeName.length + '='.length );
		if ( result.startsWith( '"' ) ) {
			result = result.slice( 1 );
			return result.indexOf( '"' ) < 0 ? result :
				result.slice( 0, result.indexOf( '"' ) );
		}
		return result.indexOf( " " ) >= 0 ?
			result.slice( 0, result.indexOf( ' ' ) ) :
			result.indexOf( ">" ) >= 0 ?
			result.slice( 0, result.indexOf( '>' ) ) : result;
	}

}
