import MRowItem from './MRowItem';
import ObjReg from '../../../utils/ObjReg';
import CellCtt from './CellCtt';
import MDataRow from './MDataRow';
import XtwModel from './XtwModel';
import { EMPTY_CTT } from './CellCtt';

/** the built-in identifier for the default group - keep in sync. with "XtdModel.java"! */
const DEFAULT_DSC = 'XTDGRP_DEFAULT_79B944AA3F7844D29F717D18D00FA7AD';

/**
 * group head item
 */
export default class MGroup extends MRowItem {

	/**
	 * constructs a new instance
	 * @param {XtwModel} model the data model
	 * @param {Object} JSON data sent by the web server
	 * @param {Number} dgh default group height
	 * @param {Number} drh default row height
	 */
	constructor(model, json, dgh, drh ) {
		super(model, json );
		this.idg = json.idg || 0;
		this.dsc = json.dsc || '';
		this._height = json.height || dgh;
		this.defRwh = drh;
		// title, font, colors are provided by a content object
		this.ctt = new CellCtt( json.ctt || EMPTY_CTT );
		this.defgrp = ( DEFAULT_DSC === this.dsc );
		this.collapsed = !this.defgrp && !!json.collapsed;
		this.rows = new ObjReg();
		// we're visible and present!
		this._present = true;
	}

	/**
	 * @override
	 */
	doDestroy() {
		this.clear();
		delete this.defRwh;
		delete this.rows;
		delete this.idg;
		delete this.dsc;
		delete this.collapsed;
		delete this.defgrp;
		if ( this.ctt ) {
			this.ctt.destroy();
		}
		delete this.ctt;
		super.doDestroy();
	}

	/**
	 * @returns {Number} the group ID of the target group to which this item belongs
	 * @override
	 */
	getTgtID() {
		// no nested groups so far
		return 0;
	}

	/**
	 * @returns {Number} the group ID
	 */
	getGrpID() {
		return this.idg;
	}

	/**
	 * @returns {String} the group descriptor
	 */
	getGrpDsc() {
		return this.dsc;
	}

	/**
	 * @override
	 */
	isGroupHead() {
		return true;
	}

	/**
	 * @override
	 */
	hasChildren() {
		return this.rows && !this.rows.isEmpty();
	}

	/**
	 * @returns {Boolean} true if this is the default group; false otherwise
	 */
	isDefault() {
		return this.defgrp;
	}

	/**
	 * @override
	 */
	isPresent() {
		return true;
	}

	/**
	 * @returns {Boolean} true if this is group is currently collapsed (no rows visible); false otherwise
	 */
	isCollapsed() {
		return !this.defgrp && this.collapsed;
	}

	/**
	 * changes the "collapsed" status; has no effect for the default group
	 * @param {Boolean} c new "collapsed" status
	 */
	setCollapsed( c ) {
		if ( !this.defgrp ) {
			this.collapsed = !!c;
		}
	}

	/**
	 * clears & destroys all rows
	 */
	clear() {
		if ( this.rows ) {
			// drop all rows
			this.rows.dstChl();
		}
	}

	/**
	 * removes all rows from this group but does NOT destroy the row items
	 */
	makeEmpty() {
		if ( this.rows ) {
			this.rows.clear();
		}
	}

	/**
	 * inserts a new row
	 * @param {MDataRow} row the ror to be inserted
	 * @param {MDataRow} tr the insertion target
	 */
	_insertRow(row, tr) {
		const map = this.rows;
		const rows = map.getValues();
		const idx = rows.findIndex((r) => r.idr === tr.idr);
		if ( idx !== -1 ) {
			// we must re-crate the row map
			rows.splice(idx, 0, row);
			map.clear();
			rows.forEach(r => map.addObj(r.idr, r));
		} else {
			// target not found - just append the new row
			this.rows.addObj(row.idr, row);
		}
	}

	/**
	 * adds a model data row to this group
	 * @param {MDataRow} md the model data row to be added
	 */
	addRowItem(md) {
		if ( md instanceof MDataRow ) {
			this.rows.addObj(md.idr, md);
		}
	}

	/**
	 * adds a new row
	 * @param {Object} md the model item data to be processed
	 * @param {Boolean} insert flag whether to insert the model item at a specific location append the model item
	 * @param {Number} target ID of insert target
	 * @returns {MDataRow} the new data row
	 */
	addRow( md, insert, target ) {
		const idr = md.idr || 0;
		if ( idr <= 0 ) {
			throw new Error(`Invalid row ID: ${idr}!`);
		}
		if ( this.rows.hasObj( idr ) ) {
			if ( insert ) {
				throw new Error(`Duplicate row ID: ${idr}!`);
			} else {
				// just do nothing!
				return null;
			}
		}
		const row = new MDataRow(this.model, md, this.defRwh, this );
		const tr = insert || (target > 0) ? this.rows.getObj(target) : null;
		if ( tr instanceof MDataRow ) {
			// insert before the target row
			this._insertRow(row, tr);
		} else {
			// just add at the end
			this.addRowItem(row);
		}
		return row;
	}

	/**
	 * deletes a row from this group
	 * @param {MDataRow} row the row to be deleted
	 */
	deleteRow(row) {
		if ( row.group !== this ) {
			throw new Error('Cannot delete a row that does not belong to this group!');
		}
		this.rows.rmvObj(row.idr);
		row.destroy();
	}

	/**
	 * adds this group to the flat model
	 * @param {Array<MRowItem>} fm flat model
	 * @param {Number} top current top coordinate
	 * @param {Number} ovh the overridden height of data row items
	 * @param {Number} vpad vertical padding
	 * @returns {Number} the total height required by this group
	 */
	addToFlat( fm, top, ovh, vp ) {
		let hgt = 0;
		if ( !this.defgrp ) {
			this._flatIndex = fm.length;
			this.setTop( top );
			fm.push( this );
			hgt += this.getHeight();
		}
		if ( this.rows && !this.rows.isEmpty() ) {
			if ( !this.isCollapsed() ) {
				this.rows.forEach( ( r ) => {
					r.setTop( top + hgt );
					r.setOvrHeight( ovh, vp );
					r._flatIndex = fm.length;
					fm.push( r );
					hgt += r.getHeight() + vp;
				} );
			} else {
				this.rows.forEach( ( r ) => {
					// not part of the flat model!
					r._flatIndex = -1;
				} );
			}
		}
		return hgt;
	}

	/**
	 * @override
	 */
	setData( data ) {
		super.setData( data );
	}

	/**
	 * @override
	 */
	setOvrHeight( ovh, vpad ) {
		super.setOvrHeight( null, 0 );
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	invalidate() {
		this._present = true; // --- !!! ---
	}
}
