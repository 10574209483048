import Validator from './Validator';
import Warner from './Warner';

export default class CallbackManager {

	static _unused_addCallbackMethods( {
		instance,
		callbackMapName,
		setupMethodName,
		executionMethodName
	} ) {
		if ( !Validator.isObject( instance ) ||
			![ callbackMapName, setupMethodName, executionMethodName ].every( name =>
				Validator.isString( name ) ) ||
			![ setupMethodName, executionMethodName ].every( name =>
				!( name in instance ) ) ) {
			return false;
		}
		if ( !Validator.isMap( instance[ callbackMapName ] ) ) {
			instance[ callbackMapName ] = new Map();
		}
		instance[ setupMethodName ] = ( prefix, callback,
			deleteRightAfterExecution = true, deleteOthersWithSamePrefix = true,
			canBeDeletedByOthers = true ) => {
			return CallbackManager.setupCallback( {
				instance: instance,
				callbackMapName: callbackMapName,
				prefix: prefix,
				callback: callback,
				deleteRightAfterExecution: deleteRightAfterExecution,
				deleteOthersWithSamePrefix: deleteOthersWithSamePrefix,
				canBeDeletedByOthers: canBeDeletedByOthers
			} );
		};
		instance[ executionMethodName ] = () => {
			return CallbackManager.executeCallbacks( {
				instance: instance,
				callbackMapName: callbackMapName
			} );
		};
		return true;
	}

	/**
	 * checks if there are registered callbacks
	 * @param {*} instance the host instance
	 * @param {String} callbackMapName the name of the callback map
	 * @returns {Boolean} true if there are pending callbacks; false otherwise
	 */
	static hasCallBacks(instance, callbackMapName) {
		if ( !Validator.isObject(instance) || !Validator.isString(callbackMapName) ) {
			return false;
		}
		if ( !Validator.isMap( instance[ callbackMapName ] ) ) {
			return false;
		}
		return instance[callbackMapName].size > 0;
	}

	/**
	 * 
	 * @param {*} param0 parameter object
	 * @param {Object} param0.instance the host instance
	 * @param {String} param0.callbackMapName name of callback map
	 * @param {String} param0.prefix prefix
	 * @param {Function} param0.callback the callback function
	 * @returns {Boolean} true if successful; false otherwise
	 */
	static setupCallback( {
		instance,
		callbackMapName,
		prefix,
		callback,
		deleteRightAfterExecution = true,
		deleteOthersWithSamePrefix = true,
		canBeDeletedByOthers = true
	} ) {
		if ( !Validator.isObject( instance ) || !Validator.isString( callbackMapName ) || !Validator.isString( prefix ) || !Validator.isFunction( callback ) ) {
			return false;
		}
		if ( !Validator.isMap( instance[ callbackMapName ] ) ) {
			instance[ callbackMapName ] = new Map();
		}
		const callBackId = Validator.generateRandomString( prefix );
		// due to the "registration" process this callback could be "called" when
		// the instance does not exist anymore
		const instanceHolder = instance;
		const callbackFunction = () => {
			callback();
			if ( !deleteRightAfterExecution || !Validator.isObject( instanceHolder ) || !Validator.isMap( instanceHolder[ callbackMapName ], true ) ) {
				return;
			}
			instanceHolder[ callbackMapName ].delete( callBackId );
		}
		Object.defineProperties(callbackFunction, {
			prefix: {
				value: prefix,
				writable: false,
				configurable: false
			},
			callBackId: {
				value: callBackId,
				writable: false,
				configurable: false
			},
			deleteRightAfterExecution: {
				value: !!deleteRightAfterExecution,
				writable: false,
				configurable: false
			},
			deleteOthersWithSamePrefix: {
				value: !!deleteOthersWithSamePrefix,
				writable: false,
				configurable: false
			},
			canBeDeletedByOthers: {
				value: !!canBeDeletedByOthers,
				writable: false,
				configurable: false
			}
		});
		if ( deleteOthersWithSamePrefix ) {
			CallbackManager.deleteCallbacksWithPrefix( {
				instance: instance,
				callbackMapName: callbackMapName,
				prefix: prefix,
				forceDeleteAll: false
			} );
		}
		instance[ callbackMapName ].set( callBackId, callbackFunction );
		return true;
	}

	/**
	 * transfers registered callbacks
	 * @param {*} instance the host instance
	 * @param {String} fromMap name of the map that provides the currently registered callbacks
	 * @param {String} toMap name of map that receives the callbacks
	 */
	static transferCallbacks(instance, fromMap, toMap) {
		if ( !Validator.isObject(instance) || !Validator.isString(fromMap) || !Validator.isString(toMap) ) {
			return false;
		}
		if ( !Validator.isMap(instance[fromMap], true) ) {
			// source map does not exist or is empty - nothing to do
			return true;
		}
		const source = instance[fromMap];
		try {
			source.forEach( (cf) => {
				const prefix = Validator.ensureString(cf.prefix);
				const deleteRightAfterExecution = !!cf.deleteRightAfterExecution;
				const deleteOthersWithSamePrefix = !!cf.deleteOthersWithSamePrefix;
				const canBeDeletedByOthers = !!cf.canBeDeletedByOthers;
				CallbackManager.setupCallback({
					instance: instance,
					callbackMapName: toMap,
					prefix: prefix,
					callback: cf,
					deleteRightAfterExecution: deleteRightAfterExecution,
					deleteOthersWithSamePrefix: deleteOthersWithSamePrefix,
					canBeDeletedByOthers: canBeDeletedByOthers
				});
			});
		} finally {
			source.clear();
		}
	}

	static deleteCallbacksWithPrefix( {
		instance,
		callbackMapName,
		prefix,
		forceDeleteAll = false
	} ) {
		if ( !Validator.isObject( instance ) || !Validator.isString( callbackMapName ) || !Validator.isString( prefix ) || !Validator.isMap( instance[ callbackMapName ] ) ) {
			return false;
		}
		const callbackKeysWithPrefix = [ ...instance[ callbackMapName ].keys() ].filter( key => key.startsWith( prefix ) );
		for ( let callbackKey of callbackKeysWithPrefix ) {
			const otherCallback = instance[ callbackMapName ].get( callbackKey );
			if ( (!forceDeleteAll && !otherCallback.canBeDeletedByOthers) || !Validator.isFunction( otherCallback ) ) {
				continue;
			}
			instance[ callbackMapName ].delete( callbackKey );
		}
		return true;
	}

	static executeCallbacks( {
		instance,
		callbackMapName
	} ) {
		if ( !Validator.isObject( instance ) || !Validator.isString( callbackMapName ) || !Validator.isMap( instance[ callbackMapName ], true ) ) {
			return false;
		}
		[ ...instance[ callbackMapName ].values() ].forEach( callback => {
			if ( !Validator.isFunction( callback ) ) {
				return;
			}
			callback();
		} );
		return true;
	}

	static executeAsync( callback ) {
		if ( !Validator.isFunction( callback ) ) {
			return void 0;
		}
		let returnValue;
		window.setTimeout( () => {
			returnValue = callback();
		}, 0 );
		return returnValue;
	}

}
