import AbstractEditable from "./AbstractEditable";
import Validator from "../../../../utils/Validator";
import XCellItem from "../../parts/XCellItem";

/**
 * a class that holds all the information to identify an editable data cell
 */
export default class EditTarget extends AbstractEditable {

    /**
     * constructs a new instance
     * @param {AbstractEditable} _ee the target editable element
     * @param {Number} _cid the column ID
     * @param {Number} _rid the row ID
     * @param {String} _idi the input ID
     */
    constructor(_ee, _cid, _rid, _idi) {
        super(_cid, _rid, 'widgets.xtw.editing.EditTarget');
        this._editable = _ee;
        this._idi = _idi;
    }

    /**
     * creates a new instance
     * @param {AbstractEditable} ee the target editable element
     * @param {Number} cid the column ID
     * @param {Number} rid the row ID
     * @param {String} iid the input ID
     * @returns {EditTarget | null} the created instance of null if an invalid editable element was given
     */
    static createInstance(ee, cid, rid, iid) {
        if ( !(ee instanceof AbstractEditable) ) {
            return null;
        }
        if ( !Validator.isStringOrNumber(cid) || !Validator.isStringOrNumber(rid) || !Validator.isString(iid) ) {
            return null;
        }
        return new EditTarget(ee, cid, rid, iid);
    }

    /**
     * destroys this instance
     * @override
     */
    doDestroy() {
        delete this._idi;
        delete this._editable;
        super.doDestroy();
    }

    /**
     * @inheritdoc
     * @override
     * @returns {Boolean}
     */
    get alive() {
        return super.alive && this.editable.alive;
    }

    /**
     * @returns {AbstractEditable} the target editable
     */
    get editable() {
        return this._editable;
    }

    /**
     * @returns {String} iid the input ID
     */
    get inputId() {
        return this._idi;
    }

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
    get insertMode() {
        return this.editable.insertMode;
    }

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
    get droppedDown() {
        return this.editable.droppedDown;
    }

    /**
     * compares two instances for equality
     * @param {EditTarget} et the instance to compare with
     * @returns {Boolean} true if this instance equals to the given one; false otherwise
     */
    equals(et) {
        if ( et instanceof EditTarget ) {
            return (this.columnId === et.columnId) && (this.rowId === et.rowId) && (this.inputId === et.inputId);
        }
        return false;
    }

    /**
     * @inheritdoc
     * @override
     */
    get isOnDummyRow() {
        return this.editable.isOnDummyRow;
    }

    /**
     * @inheritdoc
     * @override
     */
    get isKeepForced() {
        return this.editable.isKeepForced;
    }

    /**
     * @inheritdoc
     * @override
     * @param {Boolean} keep 
     */
    setKeepForced(keep) {
        this.editable.setKeepForced(keep);
    }

    /**
     * @inheritdoc
     * @override
     */
    get locked() {
        return this.editable.locked;
    }

    /**
     * @inheritdoc
     * @override
     */
    get lockCount() {
        return this.editable.lockCount;
    }

    /**
     * @inheritdoc
     * @override
     */
    lock() {
        this.editable.lock();
    }

    /**
     * @inheritdoc
     * @override
     */
    release() {
        this.editable.release();
    }

    /**
     * @inheritdoc
     * @override
     * @param {Number} lc 
     */
    restoreLock(lc) {
        this.editable.restoreLock(lc);
    }

    /**
     * @override
     */
    setFocus() {
        this.trace('Forwarding focus to editable.');
        this.editable.setFocus();
    }

    /**
     * @inheritdoc
     * @override
     */
    forceBlur(unlock = false) {
        this.editable.forceBlur(!!unlock);
    }

    /**
     * @inheritdoc
     * @override
     */
    applyChanges() {
        this.editable.applyChanges();
    }

    /**
     * @override
     */
    getType() {
        return this.editable.getType();
    }

    /**
     * @override
     * @returns {Boolean}
     */
    isCheckbox() {
        return this.editable.isCheckbox();
    }

    /**
     * @override
     */
    isEditCtrl() {
        return this.editable.isEditCtrl();
    }

    /**
     * @override
     */
    register() {
        return this.editable.register();
    }

    /**
     * @override
     */
    render() {
        this.editable.render();
    }

    /**
     * @override
     */
    destroySelfAndRestoreCell() {
        return this.editable.destroySelfAndRestoreCell();
    }

    /**
     * @inheritdoc
     * @override
     * @param {Boolean} horz 
     * @param {Boolean} upleft 
     * @param {KeyboardEvent} ke
     * @returns {Boolean}
     */
    needsArrowKey(horz, upleft, ke) {
        return this.editable.needsArrowKey(horz, upleft, ke);
    }

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} editingAllowed 
	 * @returns {Boolean}
	 */
    setEditingPermission( editingAllowed ) {
        return this.editable.setEditingPermission(editingAllowed);
    }

    /**
     * @inheritdoc
     * @override
     * @param {String} ovr 
     */
    setOvrOrgValue(ovr) {
        this.editable.setOvrOrgValue(ovr);
    }

    /**
     * @inheritdoc
     * @override
     */
    triggerDropdown() {
        this.editable.triggerDropdown();
    }

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} open 
	 * @param {String} wdgId 
	 */
    setDropdownOpenState(open, wdgId) {
        this.editable.setDropdownOpenState(open, wdgId);
    }

    /**
     * @inheritdoc
     * @override
     * @param {*} args 
     */
    syncInputContentWithDropdown(args) {
        this.editable.syncInputContentWithDropdown(args);
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutContentChange() {
        return this.editable.informAboutContentChange();
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutCancel(parameters = {}, blockScreenRequest = false) {
        return this.editable.informAboutCancel(parameters, blockScreenRequest);
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutSave( parameters = {}, blockScreenRequest = false ) {
        return this.editable.informAboutSave(parameters, blockScreenRequest);
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutFullSave( parameters = {}, blockScreenRequest = false ) {
        return this.editable.informAboutFullSave(parameters, blockScreenRequest);
    }

    /**
     * @inheritdoc
     * @override
     */
    createEditRequest() {
		return this.editable.createEditRequest();
	}

    /**
     * @inheritdoc
     * @override
     * @param {XCellItem} cell 
     */
    rebindToCell(cell) {
        super.rebindToCell(cell);
        this.editable.rebindToCell(cell);
    }
}