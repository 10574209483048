import Validator from './Validator';

export default class AttachmentObject {

	constructor( hostObject ) {
		this.attachSelf( hostObject );
	}

	attachSelf( hostObject ) {
		this.assignFunctionsTo( this, hostObject );
	}

	assignFunctionsTo( sourceObject, hostObject ) {
		const gettersAndSettersPropertyNames = this.filterGettersAndSetters( sourceObject );
		const propertiesToMirror = Object.getOwnPropertyNames(
				Object.getPrototypeOf( sourceObject ) )
			.filter( propertyName =>
				gettersAndSettersPropertyNames.indexOf( propertyName ) < 0 &&
				Validator.isFunction( sourceObject[ propertyName ] ) );
		this._mirrorAll( hostObject, propertiesToMirror );
	}

	_mirror( hostPlugin, propertyName ) {
		hostPlugin[ propertyName ] = this[ propertyName ];
	}

	_mirrorAll( hostPlugin, propertiesToMirror ) {
		if ( !Validator.isArray( propertiesToMirror ) ) return;
		for ( const propertyName of propertiesToMirror ) {
			this._mirror( hostPlugin, propertyName );
		}
	}

	filterGettersAndSetters( sourceObject ) {
		const sourceObjectPrototype = Object.getPrototypeOf( sourceObject );
		const gettersAndSettersPropertyNames =
			Object.getOwnPropertyNames( sourceObjectPrototype )
			.filter( propertyName => {
				const propertyDescriptor =
					Object.getOwnPropertyDescriptor( sourceObjectPrototype, propertyName );
				return [ "get", "set" ].some( descriptorPart =>
					Validator.isFunction( propertyDescriptor[ descriptorPart ] ) );
			} );
		return gettersAndSettersPropertyNames;
	}

	assignGettersAndSettersTo( hostObject ) {
		const propertiesToMirror = this.filterGettersAndSetters( this );
		this._mirrorAllGettersAndSetters( hostObject, propertiesToMirror );
	}

	_mirrorAllGettersAndSetters( hostPlugin, propertiesToMirror ) {
		if ( !Validator.isArray( propertiesToMirror ) ) return;
		for ( const propertyName of propertiesToMirror ) {
			this._mirrorGetterSetter( hostPlugin, propertyName );
		}
	}

	_mirrorGetterSetter( hostPlugin, propertyName ) {
		const sourceObjectPrototype = Object.getPrototypeOf( this );
		const propertyDescriptor =
			Object.getOwnPropertyDescriptor( sourceObjectPrototype, propertyName );
		Object.defineProperty( hostPlugin, propertyName, {
			get: propertyDescriptor.get,
			set: propertyDescriptor.set
		} );
	}

}
