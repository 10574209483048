import ItmMgr from '../../../gui/ItmMgr';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import MRowItem from '../model/MRowItem';
import XCellItem from './XCellItem';
import XItem from './XItem';
import ObjReg from '../../../utils/ObjReg';
import XtwRowTemplateRow from '../rtp/XtwRowTemplateRow';
import XtwUtils from '../util/XtwUtils';
import XRowItemEditingExtension from '../impl/editing/XRowItemEditingExtension';
import XtwRowItemInsertionDummyExtension from '../impl/editing/XtwRowItemInsertionDummyExtension';
import XRowItemHeightAdjustmentExtension from '../impl/rowheight/XRowItemHeightAdjustmentExtension';
import XRowItemGroupHeaderExtension from './XRowItemGroupHeaderExtension';
import { GROUP_HEADER_CLASS } from './XRowItemGroupHeaderExtension';
import MDataRow from '../model/MDataRow';
import CellCtt from '../model/CellCtt';
import DomEventHelper from '../../../utils/DomEventHelper';
import GlobalCounter from '../../../utils/GlobalCounter';
import XtwModel from '../model/XtwModel';
import HtmHelper from '../../../utils/HtmHelper';
import XtwHead from '../XtwHead';
import { ROWID_NONE } from '../model/SelectionMgr';
import { ITEM_SPECIFIC_TEXT_COLOR, ITEM_SPECIFIC_BACKGROUND_COLOR, ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR } from './XCellItem';
import Color from '../../../utils/Color';
export const OWN_TEXT_COLOR = "--rtp-own-text-color";

/**
 * a row item in the UI
 */
export default class XRowItem extends XItem {

	/**
	 * constructs a new UI row item
	 * @param {XtwBody} bdy the table body instance
	 * @param {Number} idx item index
	 * @param {Number} rh initial row height
	 * @param {Boolean} rtp row template flag
	 * @param {Number} rth height in pixels of a row template row
	 */
	constructor( bdy, idx, rh, rtp, rth ) {
		super('widgets.xtw.XRowItem');
		Object.defineProperty(this, '_xRowID', {
			value: 'XROW_' + GlobalCounter.getInst().nextValue(),
			configurable: false,
			writable: false,
			enumerable: false
		});
		this.tblBody = bdy;
		this.idx = idx;
		this.defHgt = rh;
		this.rtpMode = rtp;
		this._rtpRwh = rth;
		this.rtpRow = null;
		this._uiSelected = null;
		this._uiFocused = null;
		this._uiEdited = null;
		// create main DOM element
		const re = this.getDomElement();
		re.className = 'xtwrowitem';
		// create helpers
		new XRowItemEditingExtension( this );
		new XtwRowItemInsertionDummyExtension( this );
		new XRowItemHeightAdjustmentExtension( this );
		new XRowItemGroupHeaderExtension( this );
		this.addClickListener();
		this.addDblclickListener();
		// cell containers
		this.wdtFix = 0;
		this.wdtDyn = 0;
		this.ccnFix = null;
		this.scrDyn = null;
		this.ccnDyn = null;
		this.btnGcl = null;
		// model item
		this.item = null;
		// cell container
		this.cells = new ObjReg();
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	doDestroy() {
		this.removeAllListeners();
		this._dropCont( true, true );
		if ( this.rtpRow ) {
			this.rtpRow.destroy();
			delete this.rtpRow;
		}
		this.cells.dstChl();
		delete this.cells;
		delete this.btnGcl;
		delete this.ccnDyn;
		delete this.ccnFix;
		delete this.item;
		delete this.defHgt;
		delete this.idx;
		delete this.tblBody
		super.doDestroy();
	}

	get isRowTpl() {
		return Validator.isBoolean( this.rtpMode ) && this.rtpMode;
	}

	get getRowTpl() {
		if ( !Validator.isObject( this.tblBody ) ) {
			return null;
		}
		return this.isRowTpl ? this.tblBody.getRowTpl : null;
	}

	get idManager() {
		return this.getRowTpl && Validator.isObject( this.tblBody.idManager ) ?
			this.tblBody.idManager : null;
	}

	get rcells() {
		const mgr = this.idManager;
		return mgr ? mgr.rcells : null;
	}

	get unorderedCells() {
		if ( !Validator.isObject( this.cells ) ||
			!Validator.isMap( this.cells._objReg ) ) {
			return void 0;
		}
		return [ ...this.cells._objReg.values() ];
	}

	get orderedColumns() {
		if ( !Validator.isObjectPath( this.tblBody, "tblBody.xtwHead" ) ||
			!( "orderedColumns" in this.tblBody.xtwHead ) ) {
			return void 0;
		}
		const orderedColumns = this.tblBody.xtwHead.orderedColumns;
		return Validator.isArray( orderedColumns ) ? orderedColumns : void 0;
	}

	get orderedColumnIds() {
		const orderedColumns = this.orderedColumns;
		if ( !Validator.isArray( orderedColumns ) ) {
			return void 0;
		}
		const columnIds = [];
		for ( let column of orderedColumns ) {
			if ( !Validator.isObject( column ) ||
				!Validator.isPositiveInteger( column.id ) ) {
				continue;
			}
			columnIds.push( Number( column.id ) );
		}
		return columnIds.length > 0 ? columnIds : void 0;
	}

	get rtpRowHeight() {
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( idManager.rowHeight ) ?
			idManager.rowHeight : void 0;
	}

	get rtpRwh() {
		if ( !this.isRowTpl ) {
			return this._rtpRwh;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ?
			rtpRowHeight : this._rtpRwh;
	}

	set rtpRwh( newValue ) {
		this._rtpRwh = newValue;
	}

	/**
	 * gets & returns the row ID of this item, which corresponds to the xid of
	 * the model data row (MDataRow) assigned to this item and also to the
	 * itemId of the row template row (XtwRowTemplateRow) assigned to this item
	 * @return {Number} the row ID
	 * @see XtwModel.js~MDataRow
	 * @see XtwRtp.js~XtwRowTemplateRow
	 */
	get rowId() {
		return this.xid;
	}

	/**
	 * gets/returns the idr of this item, which corresponds with the idr of the
	 * model data row (MDataRow) corresponding/attached to this item
	 * @return {Number} idr this item's idr
	 * @see XtwModel.js~MDataRow
	 */
	get idr() {
		return [ "MDataRow", "MGroup", "MRowItem" ]
			.some( className => Validator.is( this.item, className ) ) ?
			Number( this.item.idr ) : void 0;
	}

	/**
	 * gets/returns the idt of this item, which corresponds with the idt of the
	 * model data row (MDataRow) corresponding/attached to this item
	 * @return {Number} idt this item's idt
	 * @see XtwModel.js~MDataRow
	 */
	get idt() {
		return [ "MDataRow", "MGroup", "MRowItem" ]
			.some( className => Validator.is( this.item, className ) ) ?
			Number( this.item.idt ) : void 0;
	}

	/**
	 * gets/returns the xid of this item, which corresponds with the xid of the
	 * model data row (MDataRow) corresponding/attached to this item
	 * @return {Number} xid this item's xid
	 * @see XtwModel.js~MDataRow
	 */
	get xid() {
		const item = this.item;
		if ( item instanceof MRowItem ) {
			if ( item.alive ) {
				return item.xid;
			}
		}
		return -1;
	}

	get flatIndex() {
		const item = this.item;
		if ( item instanceof MRowItem ) {
			return item.flatIndex;
		} else {
			return -1;
		}
	}

	/**
	 * gets & returns the selection manager of the table body, if present and
	 * valid
	 * @return {SelectionManager} the selection manager
	 * @see XtwRtpItm.js~SelectionManager
	 * @see XtwBody.js~XtwBody (the table body)
	 */
	get selectionManager() {
		this.warn('Deprecated property "selectionManager" accessed!', Warner.getStack());
		return null;
	}

	get modelItemIsValid() {
		return this.isValidModelItem( this.item );
	}

	/**
	 * checks if the given model item is valid
	 * @param {MRowItem} mi the model item to be checked
	 * @returns {Boolean} true if the model item is valid; false otherwise
	 */
	isValidModelItem( mi ) {
		return (mi instanceof MRowItem) && mi.alive;
	}

	/**
	 * returns the index of this item
	 * @returns {Number} the item index
	 */
	getIdx() {
		return this.idx;
	}

	/**
	 * @returns {Number} the width in pixels of the fixed part
	 */
	getWdtFix() {
		return this.wdtFix;
	}

	/**
	 * @returns {Number} the width in pixels of the dynamic part
	 */
	getWdtDyn() {
		return this.wdtDyn;
	}

	/**
	 * retrieves a data cell
	 * @param {String | Number} idc column ID
	 * @returns {XCellItem} the data cell or null if no matching cell is found
	 */
	getCell(idc) {
		return this.cells.getObj(idc);
	}

	get clientRect() {
		if ( !this.isRendered ) {
			return void 0;
		}
		return this.element.getBoundingClientRect();
	}

	get fixedContainerClientRect() {
		if ( !( this.ccnFix instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.ccnFix.getBoundingClientRect();
	}

	get clientHeight() {
		const clientRect = this.clientRect;
		if ( !( clientRect instanceof DOMRect ) ) {
			return void 0;
		}
		const height = clientRect.height;
		return Validator.isValidNumber( height ) ? height : void 0;
	}

	get clientY() {
		const clientRect = this.clientRect;
		if ( !( clientRect instanceof DOMRect ) ) {
			return void 0;
		}
		const y = clientRect.y;
		return Validator.isValidNumber( y ) ? y : void 0;
	}

	/**
	 * sends a notification to the web server through the table body (XtwBody)
	 * corresponding/hosting this item, if the table body (XtwBody) exists, is
	 * valid and is able to send notifications
	 * @param {String} notificationCode notification code
	 * @param {Object} parameters notification parameters
	 * @param {Boolean} blockScreenRequest flag whether to force a block screen
	 * request
	 * @return {Boolean} the success of the operation; true if the operation
	 * was successful and "_nfySrv" could be invoked, false otherwise
	 * @see XtwBody.js~XtwBody~_nfySrv
	 */
	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const tableBody = this.tblBody;
		if ( !Validator.is( tableBody, "XtwBody" ) ||
			!Validator.isFunction( tableBody._nfySrv ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( "idr" in parameters ) {
			delete parameters.idr;
		}
		Object.assign( parameters, {
			idr: this.idr,
			idt: this.idt,
			xid: this.xid,
			rowId: this.rowId,
			idx: this.idx
		} );
		tableBody._nfySrv( notificationCode, parameters, !!blockScreenRequest );
		return true;
	}

	/**
	 * notifies the web server about the current selection & focus through the
	 * selection manager (SelectionManager)
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	notifySelection() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.informAboutRowSelection ) ) {
			return false;
		}
		selectionManager.informAboutRowSelection();
		return true;
	}

	/**
	 * selects this item
	 */
	select() {
		this.isSelected = true;
	}

	/**
	 * removes the selection from this item
	 */
	deselect() {
		this.isSelected = false;
	}

	/**
	 * selects this row through the selection manager (SelectionManager),
	 * so that the information about current focus and selection is in sync with
	 * the states of this (and other) item(s); selecting the row through the
	 * selection manager automatically focuses the row;
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the selection change
	 * @param {Boolean} controlPressed whether or not the selection manager
	 * should act as if the control key is pressed
	 * @param {Boolean} shiftPressed whether or not the selection manager should
	 * act as if the shift key is pressed
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncSelect( notify = false, controlPressed = false, shiftPressed = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.select ) ) {
			return false;
		}
		selectionManager.select( {
			row: this,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * deselects this row through the selection manager (SelectionManager),
	 * so that the information about current focus and selection is in sync with
	 * the states of this (and other) item(s); deselecting the row through the
	 * selection manager automatically focuses the row;
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the selection change
	 * @param {Boolean} controlPressed whether or not the selection manager
	 * should act as if the control key is pressed
	 * @param {Boolean} shiftPressed whether or not the selection manager should
	 * act as if the shift key is pressed
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncDeselect( notify = false, controlPressed = false, shiftPressed = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.deselect ) ) {
			return false;
		}
		selectionManager.deselect( {
			row: this,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * gets the status of the "_isSelected" property of the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * is valid
	 * @see XtwModel.js~MDataRow
	 * @return {Boolean} true if item selected, false if the model data row
	 * (MDataRow) is invalid or if the item is not selected
	 */
	get isSelected() {
		const mi = this.item;
		return (mi instanceof MDataRow) && mi.alive ? mi.isSelected : false;
	}

	/**
	 * sets the status of the "_isSelected" property on the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow) is valid and if the updating of the "_isSelected" state on
	 * the corresponding model data row (MDataRow) is not frozen/blocked;
	 * also updates this item element's UI in case the element is rendered
	 * @see XtwModel.js~MDataRow
	 */
	set isSelected( newValue ) {
		throw new Error('Forbidden');
	}

	/**
	 * resets the cached UI status
	 * @param {Boolean} clear flag whether to clear the "dirty" status of all cells
	 */
	resetUIStatus(clear) {
		this._uiEdited = null;
		this._uiFocused = null;
		this._uiSelected = null;
		if ( clear ) {
			// -- not required so far? -- this.cells.forEach((c) => c.resetDirty());
		}
	}

	/**
	 * updates the UI status
	 * @param {Number} hsp horizontal scrolling position
	 */
	updateUIStatus(hsp) {
		const edited = this.modelItemIsValid ? this.item.edited : false;
		if ( edited !== this._uiEdited ) {
			this._uiEdited = edited;
			if ( !edited ) {
				// make sure that the "edit pen" is removed
				this.displayAsUnedited();
				this._uiFocused = null;		// force an update!
			}
		}
		this._updateSelectedUI(this.isSelected);
		this._updateFocusedUI(this.isFocused);
		if ( Validator.isNumber(hsp) ) {
			this.onHScroll(hsp);
		}
	}

	/**
	 * updates the UI of this item's HTML element (if such exists a.k.a. is
	 * rendered) to match it's selected/unselected state
	 * @param {Boolean} setToFocused decides whether the UI should be updated
	 * for a selected element or for an unselected element; true if the UI needs
	 * to be updated to selected, false otherwise; if the value of this parameter
	 * is invalid, it will be replaced with the actual value of the "isSelected"
	 * state/flag of this item;
	 * @return {Boolean} the success of the operation; true if the update of the
	 * UI was successful, false otherwise
	 */
	_updateSelectedUI( setToSelected ) {
		if ( !this.isRendered ) {
			return false;
		}
		const eff_selected = !!setToSelected;
		if ( eff_selected !== this._uiSelected ) {
			this._uiSelected = eff_selected;
			if ( !!eff_selected ) {
				this.element.classList.add( "rtp-selected" );
			} else {
				this.element.classList.remove( "rtp-selected" );
			}
		}
		return true;
	}

	/**
	 * updates the UI of this item's HTML element (if such exists a.k.a. is
	 * rendered) to match it's focused/unfocused state
	 * @param {Boolean} setToFocused decides whether the UI should be updated
	 * for a focused element or for an unfocused element; true if the UI needs
	 * to be updated to focused, false otherwise; if the value of this parameter
	 * is invalid, it will be replaced with the actual value of the "isFocused"
	 * state/flag of this item;
	 * @return {Boolean} the success of the operation; true if the update of the
	 * UI was successful, false otherwise
	 */
	 _updateFocusedUI( setToFocused ) {
		if ( !this.isRendered ) {
			 return false;
		}
		const row = this.modelItemIsValid ? this.item : null;
		const eff_focused = !!setToFocused && (row instanceof MRowItem) && !row.readOnlyDummy;
		const edited = !!this._uiEdited;
		const dummy = this.insertionDummy;
		// always update cell focus
		const fcc = this.tblBody.model.focusedColumn;
		this.cells.forEach((cell) => cell.setCellFocusStyle(fcc, eff_focused));
		// update row focus if required
		if ( (eff_focused !== this._uiFocused) || edited || dummy ) {
			this._uiFocused = eff_focused;
			this._removeSelectionArrow();
			if ( !this.hasChildren ) {
				this.element.classList.remove( "rtp-focused" );
				return false;
			}
			if ( edited ) {
				return this._updateEditedFocusedUI( setToFocused );
			}
			if ( dummy ) {
				return this._updateInsertionDummyFocusedUI( setToFocused );
			}
			if ( eff_focused ) {
				this.element.classList.add( "rtp-focused" );
				this._addSelectionArrow();
			} else {
				this.element.classList.remove( "rtp-focused" );
			}
		}
		return true;
	}


	get selectionCell() {
		if ( !this.alive ) {
			return null;
		}
		let xCellItem = this.cells.getObj( "o-1" );
		if ( xCellItem instanceof XCellItem ) {
			return xCellItem;
		}
		xCellItem = this.cells.getFirst();
		return xCellItem instanceof XCellItem ? xCellItem : null;
	}

	_addSelectionArrow() {
		const selectionCell = this.selectionCell;
		if ( !(selectionCell instanceof XCellItem) ) {
			return false;
		}
		return selectionCell.addSelectionArrow();
	}

	_removeSelectionArrow() {
		const selectionCell = this.selectionCell;
		if ( !(selectionCell instanceof XCellItem) ) {
			return false;
		}
		return selectionCell.removeSelectionArrow();
	}

	/**
	 * focuses this item
	 */
	focus() {
		this.isFocused = true;
	}

	/**
	 * removes the focus from this item
	 */
	unfocus() {
		this.isFocused = false;
	}

	/**
	 * focuses this row through the selection manager (SelectionManager),
	 * so that the information about current focus is in sync with the states of
	 * this (and other) item(s)
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the focus change
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncFocus( notify = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.focus ) ) {
			return false;
		}
		selectionManager.focus( this );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * unfocuses this row through the selection manager (SelectionManager),
	 * so that the information about current focus is in sync with the states of
	 * this (and other) item(s)
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the focus change
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncUnfocus( notify = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.unfocus ) ) {
			return false;
		}
		selectionManager.unfocus( this );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * gets the status of the "_isFocused" property of the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * is valid
	 * @see XtwModel.js~MDataRow
	 * @return {Boolean} true if item focused, false if the model data row
	 * (MDataRow) is invalid or if the item is not focused
	 */
	get isFocused() {
		const mi = this.item;
		return (mi instanceof MDataRow) && mi.alive ? mi.isFocused : false;
	}

	/**
	 * sets the status of the "_isFocused" property on the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow) is valid and if the updating of the "_isFocused" state on
	 * the corresponding model data row (MDataRow) is not frozen/blocked;
	 * also updates this item element's UI in case the element is rendered
	 * @see XtwModel.js~MDataRow
	 */
	set isFocused( newValue ) {
		throw new Error('Forbidden');
	}

	get hasChildren() {
		if ( !this.isRendered ) {
			return false;
		}
		return this.element.childElementCount > 0;
	}

	selectAndFocusRow( evt ) {
		if ( this.alive ) {
			const model = this.tblBody.model;
			if ( model instanceof XtwModel ) {
				const idr = this.idr;
				model.focusDataRow(idr);
				if ( !model.isRowSelected(idr) ) {
					model.selectSingleRow(idr, true);
				}
			}
		}
	}

	/**
	 * @override
	 * handles selection & focus when this item's element is "clicked"
	 * @param {MouseEvent} evt the click event
	 */
	onClick( evt ) {
		const rowId = this.rowId;
		const colId = this._getColumnIdFromEvent( evt );
		if ( !Validator.isValidNumber( rowId ) ) {
			return;
		}
		DomEventHelper.stopIf(evt);
		const domEventIsValid = evt instanceof MouseEvent || evt instanceof KeyboardEvent;
		const controlPressed = domEventIsValid && !!XtwUtils.isCommandKeyPressed( evt );
		const shiftPressed = !domEventIsValid ? false : !!evt.shiftKey;
		const detail = {
			colId: colId,
			rowId: rowId,
			controlPressed: controlPressed,
			shiftPressed: shiftPressed,
			domEvent: domEventIsValid ? evt : null
		};
		const event = new CustomEvent('xcellclicked', { bubbles: true, detail: detail });
		this.element.dispatchEvent(event);
	}

	nfyCtxMenu(parameters) {
		// just forward this to the body
		const args = parameters || { idr: this.rowId };
		this.tblBody.onCellContextMenu(args);
	}

	_getColumnIdFromEvent( evt ) {
		let idc = -1000;
		if ( evt instanceof MouseEvent ) {
			const re = this.getDomElement();
			let tgt = evt.target;
			while ( ( idc === -1000 ) && tgt && HtmHelper.isChildOf( tgt, re ) ) {
				if ( typeof tgt.__psaidc === 'number' ) {
					idc = tgt.__psaidc;
					tgt = null;
				} else {
					tgt = tgt.parentElement;
				}
			}
		}
		if ( idc < 0 ) {
			idc = -1;
		}
		return idc;
	}

	/**
	 * called after a horizontal scroll operation was performed by the user
	 * @param {Number} hsp new horizontal scrolling position
	 */
	onHScroll( hsp ) {
		if ( this.ccnDyn ) {
			const left = -hsp;
			this.ccnDyn.style.left = '' + left + 'px';
		}
	}

	/**
	 * called after the user has changed a column width by dragging the column
	 * border in the table/excel view/display
	 * @param {XtwCol} column the affected column
	 * @param {Number} newWidth new column width
	 * @param {Number} widthDifference the difference of the width
	 * @return {Boolean} whether or not the process was carried out successfully
	 * (as requested/intended)
	 */
	onColumnWidth( column, newWidth, widthDifference ) {
		if ( !this.isRendered || this.isRowTpl ) {
			return false;
		}
		if ( !Validator.is( column, "XtwCol" ) ||
			!Validator.isPositiveNumber( newWidth ) ||
			!Validator.isValidNumber( widthDifference, false ) ||
			!Validator.is( this.cells, "ObjReg" ) ) {
			return false;
		}
		const xCellItem = this.cells.getObj( column.id );
		if ( !Validator.is( xCellItem, "XCellItem" ) ) {
			return false;
		}
		xCellItem.setWidth( newWidth );
		const im = ItmMgr.getInst();
		if ( !!column.fix ) {
			this.wdtFix += widthDifference;
			im.setFlexWdt( this.ccnFix, this.wdtFix, true );
			XtwUtils.syncZeroWidthClass( this.ccnFix, this.wdtFix );
		} else {
			this.wdtDyn += widthDifference;
			im.setFlexWdt( this.scrDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.scrDyn, this.wdtDyn );
			im.setFlexWdt( this.ccnDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.ccnDyn, this.wdtDyn );
		}
		return true;
	}

	getSiblingElement( previous = false ) {
		if ( !this.isRendered ) {
			return void 0;
		}
		const siblingProperty = !!previous ? "previousElementSibling" : "nextElementSibling";
		let sibling = this.element[ siblingProperty ];
		while ( sibling instanceof HTMLElement && sibling.classList.contains( GROUP_HEADER_CLASS ) ) {
			sibling = sibling[ siblingProperty ];
		}
		return sibling instanceof HTMLElement ? sibling : void 0;
	}

	onSelectAll( evt ) {
		if ( evt instanceof KeyboardEvent ) {
			evt.stopPropagation();
			evt.preventDefault();
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.selectAllModelItems ) ) {
			return false;
		}
		return selectionManager.selectAllModelItems( this, true );
	}

	get widgetVerticalSelection() {
		if ( !Validator.is( this.tblBody, "XtwBody" ) ||
			!Validator.is( this.tblBody.xtdTbl, "XtwTbl" ) ||
			!Validator.isObject( this.tblBody.xtdTbl.wdgSlv ) ) {
			return void 0;
		}
		return this.tblBody.xtdTbl.wdgSlv;
	}

	/**
	 * moves the element of the first cell before or after the element of the second cell
	 * and changes/adjusts the fixed and dynamic cell containers' widths in the
	 * case/scenario when the cell element is moved from a fixed container to a
	 * dynamic one or vice versa
	 * @param {Number} firstCellId the ID of the first cell (the one that should be moved and should change its place), as a natural number (positive, whole)
	 * @param {Number} secondCellId the ID of the second cell (the one that keeps its place), as a natural number (positive, whole)
	 * @param {Number} fixedWidthDifference the amount (positive or negative) the fixed container should increase (or decrease) its width by; will not be
	 * taken into consideration if "ignoreFixedFlag" has the value "true"
	 * @param {Number} dynamicWidthDifference the amount (positive or negative) the dynamic container should increase (or decrease) its width by; will not
	 * be taken into consideration if "ignoreFixedFlag" has the value "true"
	 * @param {Boolean} ignoreFixedFlag whether or not the "fix" (fixed) property
	 * of the columns the cells are attached/correspond to should be ignored, so
	 * that the fixed and dynamic container do not change their widths; true if
	 * the fixed property should be ignored, false if it should be taken into
	 * consideration; logically it should be set to true if and only if both
	 * cells come from the same type of container (both come from a fixed
	 * container or both come from a dynamic container), so the "fix" property
	 * has the same value for both cells, which means the containers' widths do
	 * not need to be adjusted
	 * @param {Boolean} before indicates whether the moved cell should be inserted before the second cell
	 * @return {Boolean} true if the movement was successful, false otherwise
	 */
	moveFirstCellToSecond(firstCellId, secondCellId, fixedWidthDifference = 0, dynamicWidthDifference = 0, ignoreFixedFlag = false, before = true) {
		if ( !this.alive || [ firstCellId, secondCellId ].some( cellId => !Validator.isPositiveInteger( cellId ) ) || (firstCellId === secondCellId) ) {
			return false;
		}
		const firstCell = this.cells.getObj( firstCellId );
		if ( !(firstCell instanceof XCellItem) || !firstCell.isRendered ) {
			return false;
		}
		const secondCell = this.cells.getObj( secondCellId );
		if ( !(secondCell instanceof XCellItem) || !secondCell.isRendered || !( secondCell.element.parentElement instanceof HTMLElement ) ) {
			return false;
		}
		if ( before ) {
			secondCell.element.parentElement.insertBefore( firstCell.element, secondCell.element );
		} else {
			const sibling = secondCell.element.nextElementSibling;
			if ( sibling instanceof HTMLElement ) {
				secondCell.element.parentElement.insertBefore(firstCell.element, sibling);
			} else {
				secondCell.element.parentElement.appendChild(firstCell.element);
			}
		}
		if ( !!ignoreFixedFlag ) {
			return true;
		}
		const im = ItmMgr.getInst();
		if ( Validator.isValidNumber( fixedWidthDifference ) ) {
			this.wdtFix += fixedWidthDifference;
			im.setFlexWdt( this.ccnFix, this.wdtFix, true );
			XtwUtils.syncZeroWidthClass( this.ccnFix, this.wdtFix );
		}
		if ( Validator.isValidNumber( dynamicWidthDifference ) ) {
			this.wdtDyn += dynamicWidthDifference;
			im.setFlexWdt( this.scrDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.scrDyn, this.wdtDyn );
			im.setFlexWdt( this.ccnDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.ccnDyn, this.wdtDyn );
		}
		return true;
	}

	/**
	 * sets a new model item
	 * @param {XtwHead} tableHeaderWidget the table header widget
	 * @param {MRowItem} modelItem the new model item
	 */
	setModelItem( tableHeaderWidget, modelItem, updateSelectionUi = true ) {
		if ( this.alive ) {
			const oldModelItem = this.item;
			const xRowItemElement = this.getDomElement();
			xRowItemElement.classList.remove( 'rtp-selected' );
			const modelItemIsValid = this.isValidModelItem( modelItem );
			if ( !modelItemIsValid || ( this.rtpMode && modelItem.isDummyRow() ) ) {
				return this._setInvalidModelItem( false );
			}
			if ( Validator.isObject( oldModelItem ) && ( oldModelItem.isDataRow() !== modelItem.isDataRow() ) ) {
				// force full re-create
				this._dropCont( true, true );
			}
			xRowItemElement.__mi = modelItem;
			this.item = modelItem;
			this._uiFocused = null;
			this._uiSelected = null;
			this._uiEdited = null;
			if ( xRowItemElement.dataset instanceof DOMStringMap ) {
				xRowItemElement.dataset.xrowinfo = 'xrowinfo-r' + modelItem.getRowID() + '-x' + modelItem.getExtraID();
			}
			if ( modelItem.isDataRow() ) {
				return this._setDataRowModelItem( tableHeaderWidget, modelItem, updateSelectionUi );
			}
			if ( modelItem.isGroupHead() ) {
				return this._setGroupHeaderModelItem( tableHeaderWidget, modelItem, updateSelectionUi );
			}
			this._setUnknownModelItem();
		}
	}

	/**
	 * re-builds the row item due to changes in the table (columns etc.)
	 * @param {XtwHead} tableHeaderWidget the table header widget
	 * @param {Number} hsp current horizontal scrolling position
	 */
	rebuild( tableHeaderWidget, hsp ) {
		const mi = this.isValidModelItem(this.item) ? this.item : null;
		this._dropCont( true, true );
		this.item = null;
		this.setModelItem( tableHeaderWidget, mi );
		this.resetUIStatus();
		this.updateUIStatus(hsp);
	}

	_setInvalidModelItem( warn ) {
		const xRowItemElement = this.getDomElement();
		this.item = null;
		this._dropCont( true, true );
		if ( xRowItemElement instanceof HTMLElement ) {
			xRowItemElement.__mi = null;
			xRowItemElement.innerHTML = '';
			xRowItemElement.dataset.xrowinfo = '';
		}
		if ( warn ) {
			this.log(`The row item with the idx ${ this.idx } has an invalid model item.` );
		}
		this.ccnFix = null;
		this.ccnDyn = null;
		this._updateFocusedUI( false );
		this._updateSelectedUI( false );
		return;
	}

	_setDataRowModelItem( tableHeaderWidget, modelItem, updateSelectionUi = true ) {
		const re = this.getDomElement();
		if ( re instanceof HTMLElement ) {
			re.classList.remove( GROUP_HEADER_CLASS );
			if ( this.rtpMode ) {
				this._adjustRowItemHeight( re, modelItem );
				re.classList.add( 'xtwrtprowitem' );
			} else {
				re.classList.remove( 'xtwrtprowitem' );
			}
		}
		this._setupDataRow( tableHeaderWidget );
		if ( (re instanceof HTMLElement) && (modelItem instanceof MDataRow) ) {
			const props = modelItem.props;
			const style = re.style;
			if ( Validator.isArray(props.txc, 4) ) {
				style.setProperty(ITEM_SPECIFIC_TEXT_COLOR, Color.stringify(props.txc));
			} else {
				style.removeProperty(ITEM_SPECIFIC_TEXT_COLOR);
			}
			if ( Validator.isArray(props.bgc, 4) ) {
				const color = Color.stringify(props.bgc);
				style.setProperty(ITEM_SPECIFIC_BACKGROUND_COLOR, color);
				style.setProperty(ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR, color);
			} else {
				style.removeProperty(ITEM_SPECIFIC_BACKGROUND_COLOR);
				style.removeProperty(ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR);
			}
		}
	}

	_adjustRowItemHeight( xRowItemElement, modelItem ) {
		if ( !this.rtpMode || !( xRowItemElement instanceof HTMLElement ) ||
			!Validator.isObject( modelItem ) ||
			!Validator.isFunction( modelItem.getHeight ) ||
			!Validator.isFunction( modelItem.getVPadding ) ) {
			return false;
		}
		const newHeight = modelItem.getHeight() + modelItem.getVPadding();
		if ( !Validator.isValidNumber( newHeight ) ) {
			return false;
		}
		const cssHeight = '' + newHeight + 'px';
		xRowItemElement.style.height = cssHeight;
		xRowItemElement.style.maxHeight = cssHeight;
		xRowItemElement.style.minHeight = cssHeight;
		return true;
	}

	_setUnknownModelItem() {
		// ?! - what ever it is, we'll ignore it :-)
		console.warn( '#' + this.idx + ': Got an UNKNOWN model item!' );
		console.warn( this.item );
		this._updateFocusedUI( false );
		this._updateSelectedUI( false );
	}

	/**
	 * creates / updates a data row
	 * @param {XtwHead} xth the table header widget
	 */
	_setupDataRow( xth ) {
		// make sure that the required containers exist
		const rtp = this.rtpMode;
		this._creCont( xth, true, !rtp );
		if ( rtp ) {
			// create / update row template data row
			this._setupRtpRow( xth );
		} else {
			// create / update common data row
			this._setupCommonRow( xth );
		}
	}

	/**
	 * creates / updates a common data row
	 * @param {XtwHead} xth the table header widget
	 */
	_setupCommonRow( xth ) {
		const mi = this.item;
		if ( this.cells.isEmpty() ) {
			// create UI cells first
			if ( (xth instanceof XtwHead ) && xth.alive ) {
				const cols = xth.getColumns( true );
				this._setupCommonCells( cols );
			}
		} else {
			const idr = (mi instanceof MRowItem) && mi.alive ? mi.idr : ROWID_NONE;
			const clear = (idr === ROWID_NONE) || mi.edited;
			this.cells.forEach( (xc) => {
				xc.onNewModelItem(idr, clear);
			});
		}
		if ( mi.isPresent() ) {
			// the model item has data!
			this.cells.forEach( ( xc ) => {
				if ( xc.idc !== -1 ) {
					const mc = mi.getCell( xc.idc );
					xc.setCtt( ( mc != null ? mc.getCtt() : null ) || CellCtt.EMPTY_CONTENT );
				}
			} );
		}
		if ( this.insertionDummy ) {
			this.displayAsInsertionDummy();
		} else {
			this.displayAsNotInsertionDummy();
		}
		this._setDebugInfo();
	}

	_setDebugInfo() {
		if ( this.alive && this.isDebugEnabled() ) {
			const idx = this.idx;
			const sc = this.selectionCell;
			if ( sc instanceof XCellItem ) {
				const ce = sc.element;
				if ( ce instanceof HTMLElement ) {
					const mi = this.item;
					const ttp = `(${idx}) IDR: ${(mi instanceof MRowItem) && mi.alive ? mi.idr : 'invalid'}`;
					ce.setAttribute('title', ttp);
				}
			}
		}
	}

	/**
	 * creates a common data row
	 * @param {Array<XtwCol>} cols array of table columns
	 */
	_setupCommonCells( cols ) {
		const brc = this.tblBody.clrVtg || null;
		let fxw = 0;
		let dnw = 0;
		const self = this;
		Object.values( cols ).forEach( ( cc ) => {
			cc.forEach( ( col ) => {
				if ( col.available ) {
					const cell = new XCellItem( col, self, brc );
					self.cells.addObj( cell.idc, cell );
					const width = col.visible ? cell.getWidth() : 0;
					if ( col.fix ) {
						self.ccnFix.appendChild( cell.getDomElement() );
						fxw += width;
					} else {
						self.ccnDyn.appendChild( cell.getDomElement() );
						dnw += width;
					}
				}
			} );
		} );
		this.wdtFix = fxw;
		this.wdtDyn = dnw;
		const im = ItmMgr.getInst();
		im.setFlexWdt( this.ccnFix, fxw, true );
		XtwUtils.syncZeroWidthClass( this.ccnFix, fxw );
		im.setFlexWdt( this.scrDyn, dnw, true );
		XtwUtils.syncZeroWidthClass( this.scrDyn, dnw );
		im.setFlexWdt( this.ccnDyn, dnw, true );
		XtwUtils.syncZeroWidthClass( this.ccnDyn, dnw );
	}

	/**
	 * creates / updates a row template row
	 * @param {XtwHead} xth the table header widget
	 */
	_setupRtpRow( xth ) {
		if ( (xth instanceof XtwHead ) && xth.alive ) {
			const tpl = this.getRowTpl;
			if ( tpl ) {
				if ( !this.rtpRow ) {
					const cols = xth.getColumns( true );
					this._setupRtpCells( tpl, cols );
				}
				if ( this.item.isPresent() ) {
					const new_rtp = !!tpl.rtpNew;
					const row = this.rtpRow;
					row.reset();
					const modelItem = this.item;
					const cells = modelItem.getCells();
					cells.forEach( ( mc ) => {
						row.updateCellContent( mc, new_rtp );
					} );
				}
			}
		}
	}

	/**
	 * creates / updates a row template row
	 * @param {Object} tpl thr row template descriptor
	 * @param {Array<XtwCol>} cols array of table columns
	 */
	_setupRtpCells( tpl, cols ) {
		const rcols = new ObjReg();
		Object.values( cols ).forEach( ( cc ) => {
			cc.forEach( ( col ) => {
				if ( !col.select ) {
					rcols.addObj( col.id, col );
				}
			} );
		} );
		const args = {};
		args.rtp = tpl;
		args.cols = rcols;
		args.item = this.item;
		const row = new XtwRowTemplateRow( this, args );
		this.rtpRow = row;
		row.render();
	}

	/**
	 * creates the cell container elements if required
	 * @param {XtwHead} xth the table header widget
	 * @param {Boolean} fix true to create the fixed cell container
	 * @param {Boolean} dyn true to create the dynamic cell container
	 */
	_creCont( xth, fix, dyn ) {
		if ( fix && !this.ccnFix ) {
			this.ccnFix = this._creContElm();
			this.getDomElement().appendChild( this.ccnFix );
		}
		if ( dyn && !this.ccnDyn ) {
			this.scrDyn = document.createElement( 'div' );
			this.scrDyn.className = 'xtwrowscroll';
			this.ccnDyn = this._creContElm();
			this.scrDyn.appendChild( this.ccnDyn );
			this.getDomElement().appendChild( this.scrDyn );
		}
	}

	/**
	 * creates a cell container element
	 * @returns {HTMLElement} the container element
	 */
	_creContElm() {
		const ce = document.createElement( 'div' );
		ce.className = 'xtwcellcont';
		return ce;
	}

	/**
	 * drops cell containers
	 * @param {Boolean} fix true to drop the fixed cell container
	 * @param {Boolean} dyn true to drop the dynamic cell container
	 */
	_dropCont( fix, dyn ) {
		if ( this.btnGcl ) {
			HtmHelper.rmvDomElm( this.btnGcl );
			this.btnGcl = null;
		}
		if ( fix || dyn ) {
			if ( this.rtpRow ) {
				this.rtpRow.destroy();
				this.rtpRow = null;
			}
			this.cells.dstChl();
			this.wdtFix = 0;
			this.wdtDyn = 0;
		}
		if ( fix && this.ccnFix ) {
			HtmHelper.rmvDomElm( this.ccnFix );
			this.ccnFix = null;
		}
		if ( dyn && this.ccnDyn ) {
			HtmHelper.rmvDomElm( this.ccnDyn );
			HtmHelper.rmvDomElm( this.scrDyn );
			this.scrDyn = null;
			this.ccnDyn = null;
		}
	}

}
