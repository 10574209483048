import PSA from '../../psa';
import ItmMgr from '../ItmMgr';
import Utils from '../../utils/Utils';
import Validator from '../../utils/Validator';

/**
 * CllRnd.js - custom tree/table cell renderer
 */

const Variant = rwt.util.Variant;
const Encoding = rwt.util.Encoding;

const PSA_IMG_STR = "PSA_CUS_IMG_CLL";

const psaPfx = '<psajson data="';
const psaSfx = '" end psajson />';
const htmPfx = '<psahtm text="';
const htmSfx = '" end psahtm />';
const hrefPfx = '<a class="pisa" target="_rwt" href="';
const hrefCls = '">';
const hrefSfx = '</a>';
const prePfx = '<pre>';
const preSfx = '</pre>';
const imgPlc = '{.#.}';	// keep in sync. with de.pisa.webcli.cstwdg.utl.TblCll.IMG_PLC!

const itmMgr = ItmMgr.getInst();

const escapeText = function ( text, removeNewLines ) {
	let result = Encoding.escapeText( text, false );
	result = Encoding.replaceNewLines( result, removeNewLines ? "" : "<br/>" );
	result = Encoding.replaceWhiteSpaces( result );
	return result;
};

const setPsaStyle = function ( element, text, cellData ) {
	let res = text;
	let ovl = 'hidden';
	let zix;
	let tdc = 'none';
	if ( text.length > ( psaPfx.length + psaSfx.length ) ) {
		if ( text.substring( 0, psaPfx.length ) === psaPfx ) {
			let pos = text.indexOf( psaSfx, psaPfx.length );
			if ( pos > 0 ) {
				res = Utils.escHtml( text.substring( pos + psaSfx.length ) );
				let nfo = atob( text.substring( psaPfx.length, pos ) );
				if ( nfo ) {
					let data = JSON.parse( nfo );
					if ( data ) {
						if ( data.tdc ) {
							tdc = data.tdc;
						}
						if ( data.join ) {
							ovl = 'visible';
							zix = '4';
						}
						if ( data.pre ) {
							res = prePfx + res + preSfx;
						}
						let img = null;
						if ( data.psahtm ) {
							let posBgnHtm = data.psahtm.indexOf( htmPfx ) + htmPfx.length;
							let posEndHtm = data.psahtm.indexOf( htmSfx );
							let clnPsaHtm = data.psahtm.substring( posBgnHtm, posEndHtm );
							// { typ : DSC, cls : .. },* TextBlah,* { typ : img, cls : ...}
							let splStr = clnPsaHtm.split( ",*" );
							// [ JSONobj1 , Textblah , JSONobj2 ]
							if ( splStr && splStr.length > 0 ) {
								img = "";
								let subCllArr = [];
								let txtCllCnt = 0;
								let cllWdt = cellData.width ? cellData.width : element.style.width.match( /[\d]+/g );
								// pre-rendering loop
								for ( let i = 0; i < splStr.length; i++ ) {
									let curStr = splStr[i].trim();
									if ( curStr.indexOf( '[' ) == 0 ) {
										curStr = curStr.substring( 1, curStr.length );
									}
									if ( curStr.indexOf( ']' ) == curStr.length - 1 ) {
										curStr = curStr.substring( 0, curStr.length - 1 );
									}
									if ( curStr.length > 0 ) {
										let cllSubObj = {};
										let cpos = curStr.indexOf( '{' );
										if ( cpos > -1 ) {
											let aJsn = {};
											let jsonStr = '';
											if ( cpos > 0 ) {
												// text before image
												let plnTxt = curStr.substring( 0, cpos );
												jsonStr = curStr.substring( cpos );
												let txtCll = {};
												txtCll.txt = plnTxt;
												subCllArr.push( txtCll );
												txtCllCnt++;
											} else {
												// we're starting with the image
												jsonStr = curStr;
											}
											// this is a JSON object containing an image!
											try {
												aJsn = JSON.parse( jsonStr );
											} catch ( err ) {
												console.warn( "Parsing Error: row template pattern contained invalid characters: " + err );
											}
											cllSubObj.isImg = true;
											cllSubObj.psaHtmJsn = aJsn;
											if ( aJsn.img && ( aJsn.img.icoSiz || aJsn.img[1] ) ) {
												cllSubObj.wdt = aJsn.img.icoSiz ? parseInt( aJsn.img.icoSiz ) : aJsn.img[1];
												cllWdt -= cllSubObj.wdt;
											}
										} else {
											// its just text
											cllSubObj.txt = curStr;
											txtCllCnt++;
										}
										subCllArr.push( cllSubObj );
									}
								}
								// distribute the remaining width for text cells
								let txtWdt = cllWdt / txtCllCnt;
								let padTop = ( element.style.height.match( /[\d]+/g ) - 16 ) / 2;
								// rendering loop
								let plc = rndTxtSpn( '$plc$', txtWdt, padTop )
								let cnt = '';
								for ( let j = 0; j < subCllArr.length; j++ ) {
									let rndObj = subCllArr[j];
									if ( cnt.length > 0 ) {
										cnt += ' ';
									}
									if ( rndObj.isImg ) {
										let psaHtmJsn = rndObj.psaHtmJsn;
										let chldEle = document.createElement( "div" );
										// create a copy of cell data for value manipulation without affecting rendering of other cells
										// seems quite like an effort, but it is necessary
										let cllDatCpy = _.clone( cellData );
										if ( psaHtmJsn.img && psaHtmJsn.img.icoSiz ) {
											let chlWdt = parseInt( psaHtmJsn.img.icoSiz );
											chldEle.style.width = chlWdt + "px";
											cllDatCpy.height = chlWdt;
										}
										if ( j > 0 && psaHtmJsn.img ) {
											psaHtmJsn.img.padLft = "padding-left: 4px;";
										}
										chldEle.style.height = element.style.height;
										cnt += rndImgDat( psaHtmJsn, chldEle, cllDatCpy );
									} else if ( rndObj.txt ) {
										cnt += rndObj.txt;
									}
								}
								img = plc.replace( '$plc$', cnt );
							}
						}
						if ( data.img ) {
							img = rndImgDat( data.img, element, cellData );
						}
						if ( !data.img && !data.psahtm && cellData && cellData.name && cellData.name.includes( PSA_IMG_STR ) ) {
							// combined title cells can be only text and in this case we have to adapt the rendering so it
							// will look like the other combined titles
							let pTop = ( element.style.height.match( /[\d]+/g ) - 16 ) / 2;
							let aWdt = cellData.width ? cellData.width : element.style.width.match( /[\d]+/g );
							res = rndTxtSpn( res, aWdt, pTop );
						}
						let tms = '';
						if ( Validator.isString( data.tms ) ) {
							tms = '<span class="inipos timestamp">' + data.tms + '</span>&nbsp;';
							ovl = 'visible';
							if ( Validator.isString( img ) ) {
								img = '<span class="inipos">' + img + '</span>';
							}
							if ( Validator.isString( res ) ) {
								res = '<span class="inipos">' + res + '</span>';
							}
						}
						if ( data.href ) {
							let cnt = tms;
							if ( data.href === 'tree' ) {
								if ( img && img.length > 0 ) {
									cnt = img + ' ' + tms;
								}
								cnt += hrefPfx + data.href + hrefCls;
							} else {
								cnt = hrefPfx + data.href + hrefCls;
								if ( img && img.length > 0 ) {
									cnt += img + ' ' + tms;
								}
							}
							if ( res && res.length > 0 ) {
								cnt += res;
							}
							res = cnt + hrefSfx;
						} else if ( img && img.length > 0 ) {
							res = img + ' ' + tms + res;
						} else if ( Validator.isString( tms ) ) {
							res = tms + res;
						}
					}
				}
			}
		}
	}
	element.style.textDecoration = tdc;
	element.style.overflow = ovl;
	if ( zix ) {
		element.style.zIndex = zix;
		if ( element.parentNode ) {
			element.parentNode.style.zIndex = zix;
		}
	}
	if ( res && (res.length > 0) ) {
		const pos = res.indexOf(imgPlc);
		if ( pos !== -1 ) {
			let str = res.substring(0, pos);
			if ( (pos + imgPlc.length) < res.length ) {
				str += res.substring(pos + imgPlc.length);
			}
			res = str;
		}
	}
	return res;
};

/**
 * Render a text span with a certain width.
 * @param  {[String]} text   [text]
 * @param  {[Integer]} wdt    [width of the span]
 * @param  {[Integer]} padTop [padding top]
 * @return {[String]}        [returns the html-node as a string]
 */
let rndTxtSpn = function ( text, wdt, padTop ) {
	let aSpn = "";
	if ( text && wdt && padTop ) {
		let ptp = Math.max( padTop, 0 );
		aSpn = "<span style=' width:" + wdt + "px; padding-top:" + ptp + "px; padding-right:2px; padding-left:2px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;'>" + text + "</span>";
	}
	return aSpn;
};

/**
 * [Render the image from the image data object]
 * @param  {[Object]} imagedata     [the image data]
 * @param  {[Node]} parDomElement   [the parent dom element]
 * @param  {[Object]} cellDataObj   [the cell data object]
 * @param  {[Boolean]} cellDataObj  [the cell data object]
 * @return {[String]} imgEle        [return the HTML-Element]
 */
const rndImgDat = function ( imagedata, parDomElement, cellDataObj ) {
	let imgEle = null;
	if ( imagedata ) {
		let typ = imagedata.typ;
		let imd = imagedata.img;
		// set padding top to zero since the cells are getting recycled!
		parDomElement.style.paddingTop = "";
		if ( typ === "IMG" ) {
			let src = '';
			let wdt = 0;
			let hgt = 0;
			if ( imagedata.svg ) {
				// a SVG image
				const url = imd.url || false;
				const pfx = url ? '' : 'data:image/svg+xml;base64,';
				const svg = imd.svg || '';
				src = pfx + svg;
				wdt = imd.wdt || 0;
				hgt = imd.hgt || 0;
				const ovs = imd.ovs || null;
				if ( ovs ) {
					wdt = ovs.cx || wdt;
					hgt = ovs.cy || hgt;
				}
			} else {
				// a bitmap (PNG, JPG,...)
				src = imd[0] || '';
				wdt = imd[1] || 0;
				hgt = imd[2] || 0;
			}
			let padLftStr = '';
			if ( imd.padLft ) {
				padLftStr = imd.padLft;
			}
			if ( wdt > hgt ) {
				// landscape mode
				if ( cellDataObj && cellDataObj.name && cellDataObj.name.includes( "*R*" ) ) {
					parDomElement.style.borderRadius = "50%";
					imgEle = '<img src="' + src + '" style="width:auto; height:100%; position:absolute;' + padLftStr + ' left:50%; top:50%; margin-right:-50%; transform: translate(-50%,-50%);">';
				} else {
					imgEle = '<img src="' + src + '" style="max-width:100%;' + padLftStr + '" width="auto" height="auto ">';
				}
			} else {
				// portrait mode
				if ( cellDataObj && cellDataObj.name && cellDataObj.name.includes( "*R*" ) ) {
					parDomElement.style.borderRadius = "50%";
					imgEle = '<img src="' + src + '" style="width:102%; position:absolute;' + padLftStr + ' left:50%; margin-right:-50%; transform: translate(-50%, 0%);">';
				} else {
					const cll_hgt = ( cellDataObj ? cellDataObj.height : 0 ) || 0;
					const aImgHgt = cll_hgt > 0 ? Math.min( cll_hgt, hgt ) : hgt;
					imgEle = '<img src="' + src + '" style="max-width:100%;' + padLftStr + '" width="auto" height="' + aImgHgt + 'px">';
				}
			}
		} else if ( typ === "DSC" ) {
			imd.cusHgt = parDomElement.style.height.match( /[\d]+/g );
			imd.cusWdt = parDomElement.style.width.match( /[\d]+/g );
			if ( imd.cusHgt && imd.cusWdt ) {
				imd.cusIcoSiz = Math.min( imd.cusHgt, imd.cusWdt ) - 4;
			}
			imgEle = renderImageDescriptor( imd );
		}
	}
	return imgEle;
};

const renderImageDescriptor = function ( imageDescriptor ) {
	let image = '<i class="' + imageDescriptor.cssCls + ' ' + imageDescriptor.icoNam + '"';
	const siz = imageDescriptor.icoSiz;
	const fnt = imageDescriptor.fntNam;
	const padLft = imageDescriptor.padLft;
	const cusIcoSiz = imageDescriptor.cusIcoSiz;

	const rgb = imageDescriptor.icoClr ? itmMgr.getRgb( imageDescriptor.icoClr ) : null;
	const fsz = siz && ( typeof siz === "number" );
	const cusFsz = cusIcoSiz && ( typeof cusIcoSiz === "number" );
	const ffm = fnt && ( typeof fnt === "string" );
	const stl = fsz || ffm || rgb || padLft;
	if ( stl ) {
		image += ' style="';
	}
	if ( fsz ) {
		let aFsz = itmMgr.getFntSiz( siz );
		if ( cusFsz && aFsz < cusIcoSiz && cusIcoSiz > 20 ) {
			aFsz = cusIcoSiz;
		}
		let padTop = ( imageDescriptor.cusHgt - aFsz ) / 2;
		if ( padTop > 0 ) {
			image += 'padding-top:' + padTop + 'px !important; ';
		}
		image += 'font-size:' + aFsz + 'px; ';
	}
	if ( ffm ) {
		image += 'font-family:' + fnt + '; ';
	}
	if ( rgb ) {
		image += 'color:' + rgb + ';';
	}
	if ( padLft ) {
		image += padLft;
	}
	if ( stl ) {
		image += '"';
	}
	image += '></i>';
	return image;
};

const alignmentStyleToCss = {
	"LEFT": "left",
	"CENTER": "center",
	"RIGHT": "right",
	"TOP": "top",
	"BOTTOM": "bottom"
};

const hookLinkLsr = function ( element ) {
	if ( element && ( element.childElementCount > 0 ) ) {
		for ( let i = 0; i < element.children.length; ++i ) {
			let chl = element.children[i];
			if ( "A" === chl.tagName ) {
				chl.addEventListener( "mousedown", function ( evt ) {
					if ( evt.button !== 0 ) {
						if ( Validator.isString( chl.href ) && PSA.getInst().cliCbkWdg ) {
							let href = chl.href;
							let pos = href.lastIndexOf( '/' );
							if ( pos !== -1 ) {
								href = href.substr( pos + 1 );
							}
							let par = {};
							par.href = href;
							par.btn = evt.button;
							PSA.getInst().cliCbkWdg.nfyGlb( "tblHlkSel", par, true );
						}
					}
				} );
			}
		}
	}
};

const psaTxtRenderer = {
	"cellType": "text",
	"contentType": "text",
	"createElement": function ( cellData ) {
		let result = document.createElement( "div" );
		result.style.textAlign = alignmentStyleToCss[cellData.horizontalAlignment] || "left";
		// do not set padding for image cells.
		if ( cellData.font && cellData.font.match && !( cellData.name && cellData.name.includes( PSA_IMG_STR ) ) ) {
			let pad = ( cellData.height - ( cellData.font.match( /[\d]+/g ) ) ) / 2;
			if ( cellData.text && cellData.text.includes( "</i>" ) ) {
				let fnz = cellData.text.match( /[font]+\-[size]+\:\s?[\d]*[\d]+\p\x/g );
				if ( fnz ) {
					fnz = String( fnz ).match( /[\d]*[\d]/g );
					if ( fnz ) {
						pad = ( cellData.height - fnz.valueOf() ) / 2;
					}
				}
			}
			if ( cellData.verticalAlignment == "CENTER" ) {
				result.style.paddingTop = pad + "px";
				result.style.paddingBottom = pad + "px";
			} else if ( cellData.verticalAlignment == "BOTTOM" ) {
				result.style.paddingTop = pad * 2 + "px";
				result.style.paddingBottom = "";
			}
			if ( cellData.wrap ) {
				cellData.name = "rtpLinHgt";
			}
		}
		result.style.whiteSpace = cellData.wrap ? "" : "nowrap";
		result.style.textOverflow = "ellipsis";
		return result;
	},
	"renderContent": function ( element, content, cellData, options ) {
		let text = setPsaStyle( element, content || "", cellData );
		if ( options.markupEnabled ) {
			if ( text !== element.rap_Markup ) {
				element.innerHTML = text;
				element.rap_Markup = text;
				hookLinkLsr( element );
			}
		} else {
			element.innerHTML = escapeText( text, options.removeNewLines );
		}
	}
};

const psaImgRenderer = {
	"cellType": "image",
	"contentType": "image",
	"renderContent": function ( element, content, cellData, options ) {
		let opacity = options.enabled ? 1 : 0.3;
		let src = content ? content[0] : null;
		rwt.html.Style.setBackgroundImage( element, src );
		element.style.opacity = opacity;
	},
	"createElement": function ( cellData ) {
		let result = document.createElement( "div" );
		rwt.html.Style.setBackgroundRepeat( result, "no-repeat" );
		let position = ["center", "center"];
		if ( cellData.scaleMode === "FIT" ) {
			rwt.html.Style.setBackgroundSize( result, "contain" );
		} else if ( cellData.scaleMode === "FILL" ) {
			rwt.html.Style.setBackgroundSize( result, "cover" );
		} else if ( cellData.scaleMode === "STRETCH" ) {
			rwt.html.Style.setBackgroundSize( result, "100% 100%" );
		} else {
			position[0] = alignmentStyleToCss[cellData.horizontalAlignment] || "center";
			position[1] = alignmentStyleToCss[cellData.verticalAlignment] || "center";
		}
		rwt.html.Style.setBackgroundPosition( result, position.join( " " ) );
		return result;
	}
};

/**
 * registers cell renderers
 */
export default function registerCellRenderers() {
	const registry = rwt.widgets.util.CellRendererRegistry.getInstance();
	const rnd = [psaTxtRenderer, psaImgRenderer];
	for ( let i = 0; i < rnd.length; ++i ) {
		const r = rnd[i];
		if ( registry.getRendererFor( r.cellType ) ) {
			registry.removeRendererFor( r.cellType );
		}
		registry.add( r );
	}
}

