/**
 * WakeLock.js - tries to keep the session running
 * see https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API
 */

import LoggingBase from "../base/loggingbase";

/**
 * internal WakeLock API wrapper class
 */
export default class WakeLock extends LoggingBase {

	/**
	 * constructs a new instance
	 */
	constructor() {
		super('ssn.WakeLock');
		this._wakeLock = null;
		this._supported = WakeLock._checkAPI();
		if ( this.supported ) {
			this.log('WakeLock API available.');
			this._initWakeLock();
		} else {
			this.log('WakeLock API not available in this browser!');
		}
	}

	/**
	 * returns the singleton instance
	 * @returns {WakeLock} the singleton instance
	 */
	static getInstance() {
		return instance;
	}

	/**
	 * @returns {Boolean} true if the WakeLock API is supported by this browser; false otherwise
	 */
	get supported() {
		return this._supported;
	}

	/**
	 * @returns {WakeLockSentinel|null} the wake lock or null if no wake lock is available
	 */
	get wakeLock() {
		return this._wakeLock;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	doDestroy() {
		if ( this.handleVisibilityChange ) {
			document.removeEventListener('visibilitychange', this.handleVisibilityChange);
		}
		this.releaseWakeLock();
		delete this._wakeLock;
		delete this.requestWakeLock;
		delete this.handleVisibilityChange;
		super.doDestroy();
	}

	/**
	 * requests a wake lock, if possible
	 */
	setWakeLock() {
		if ( this.supported ) {
			this.releaseWakeLock();
			if ( this.requestWakeLock ) {
				this.requestWakeLock();
			} else {
				this.log('WakeLock API not available.');
			}
		}
		else {
			this.log('WakeLock API not supported by this browser.');
		}
	}

	/**
	 * releases the wait lock, if present
	 */
	releaseWakeLock() {
		if ( this.supported ) {
			const wl = this.wakeLock;
			this._wakeLock = null;
			if ( wl instanceof WakeLockSentinel ) {
				this.log('Releasing current wake lock.');
				wl.release();
			}
		}
	}

	/**
	 * checks whether the browser supports the WakeLock API
	 * @returns {Boolean} true if the browser supports the WakeLock API; false otherwise
	 */
	static _checkAPI() {
		return ('wakeLock' in navigator);
	}

	/**
	 * re-requests a wake lock, called after document activation
	 */
	_reRequest() {
		if ( this.wakeLock && (document.visibilityState === 'visible') ) {
			this.log('Re-requesting a wake lock after document activation...');
			this.requestWakeLock();
		}
	}

	/**
	 * initializes the 
	 */
	_initWakeLock() {
		if ( this.supported ) {
			const self = this;
			const rwl = async () => {
				self.log('Requesting wait lock.');
				try {
					const wl = await navigator.wakeLock.request('screen');
					self._wakeLock = wl;
					self.log('Successfully requested a wake lock.');
				} catch ( err ) {
					self.warn(`${err.name}, ${err.message}`);
				}
			};
			const hvc = () => {
				self._reRequest();
			};
			this.requestWakeLock = rwl;
			this.handleVisibilityChange = hvc;
			document.addEventListener('visibilitychange', this.handleVisibilityChange);
		}
		else {
			this.warn('No WakeLock support in this browser!');
		}
	}
};

const instance = new WakeLock();
