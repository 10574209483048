import NotificationHdl from "./NotificationHdl";

/**
 * Clipboard data handler interface
 */
export default class ClipboardDataHdl extends NotificationHdl {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'utils.ClipboardDataHdl');
    }

    /**
     * called to handle clipboard items as read from the clipboard
     * @param {ClipboardItem[]} items array of clipboard items
     */
    onClipboardData(items) {
        // abstract
    }
}