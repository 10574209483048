import PSA from '../../psa';

/**
 * class MsgCtl - client side implementation of message control
 */
export default class MsgCtl {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		this.element = document.createElement("div");
		this.parent.append(this.element);
		this.parent.addListener("Resize", this.layout);
		this.ready = false;
		this.treCtl = null;
		this.edtCtl = null;
		this.hitCnt = 0;
		this.ttpItm = null;
		const self = this;
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			const idt = cwd["tree"];
			if ( idt ) {
				const tree = rwt.remote.ObjectRegistry.getObject(idt);
				if ( tree ) {
					this.treCtl = tree;
					this.idwTre = idt;
					tree.addEventListener("keydown", (event) => {
						self.onKeyDown(event);
					}, false);
				}
			}
			const ide = cwd["edit"];
			if ( ide ) {
				const edit = rwt.remote.ObjectRegistry.getObject(ide);
				if ( edit ) {
					this.edtCtl = edit;
					this.idwEdt = ide;
					edit.addEventListener("keydown", (event) => {
						self.onKeyDown(event);
					}, false);
				}
			}
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	onReady() {
		this.ready = true;
		this.layout();
	}
	
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
		}
	}
	
	onSend() {
		// do nothing so far...
	}
	
	destroy() {
		if ( this.treCtl ) {
			this.treCtl = null;
			this.idwTre = null;
		}
		if ( this.edtCtl ) {
			this.edtCtl = null;
			this.idwEdt = null;
		}
		if ( this.element && this.element.parentNode ) {
			this.element.parentNode.removeChild(this.element);
		}
	}
	
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			this.element.style.left = area[0] + "px";
			this.element.style.top = area[1] + "px";
			this.element.style.width = area[2] + "px";
			this.element.style.height = area[3] + "px";
		}
	}
	
	onKeyDown(evt) {
		if ( this.treCtl && this.edtCtl ) {
			if ( !evt.isShiftPressed() && !evt.isCtrlPressed() && !evt.isMetaPressed() && !evt.isAltPressed() ) {
				let hdl = false;
				let hid = null;
				let shw = null;
				switch ( evt.getKeyIdentifier() ) {
				case "F2":
					hdl = true;
					hid = this.idwTre;
					shw = this.idwEdt;
					break;
				case "Escape":
					hdl = true;
					hid = this.idwEdt;
					shw = this.idwTre;
					break;
				default:
					hdl = false;
					break;
				}
				if ( hdl ) {
					if ( this._psa.keyHdl ) {
						this._psa.keyHdl.stopKeyEvt(evt);
					}
					const param = {};
					param["hide"] = hid;
					param["show"] = shw;
					this._nfySrv("layout", param);
				}
			}
		}
	}
	
	_nfySrv(code, par) {
		if ( this.ready ) {
			const param = {};
			param["cod"] = code;
			param["par"] = par;
			rap.getRemoteObject(this).notify("MSG_NFY_SRV", param);
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget MsgCtl.');
		rap.registerTypeHandler("psawidget.MsgCtl", {
			factory : function(properties) {
				return new MsgCtl(properties);
			},
			destructor : "destroy",
			methods : [ ],
			events : [ "MSG_NFY_SRV" ]
		} );
	}	
}	

console.debug('widgets/msgctl/MsgCtl.js loaded.');
