import Utils from '../../utils/Utils';
import LoggingBase from '../../base/loggingbase';

// a little timeout
const TMO_WAIT = 42;

/**
 * window activation tracker
 */
export default class WndAcv extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {CliCbkWdg} cbw callback widget
	 */
	constructor(cbw) {
		super('gui.WndAcv');
		this.cbkWdg = cbw;
		this.lisBlr = Utils.bind(this, this._onBlur);
		this.lisFoc = Utils.bind(this, this._onFocus);
		this.isAcv = false;
		this.tmoHdl = null;
		window.focus();
		window.addEventListener('blur', this.lisBlr);
		window.addEventListener('focus', this.lisFoc);
	}

	/**
	 * destroys this instance
	 */
	doDestroy() {
		this._clrTmo();
		if ( this.lisBlr ) {
			const lis = this.lisBlr;
			this.lisBlr = null;
			window.removeEventListener('blur', lis);
		}
		if ( this.lisFoc ) {
			const lis = this.lisFoc;
			this.lisFoc = null;
			window.removeEventListener('focus', lis);
		}
		this.cbkWdg = null;
		super.doDestroy();
	}

	_clrTmo() {
		if ( this.tmoHdl ) {
			var tmh = this.tmoHdl;
			this.tmoHdl = null;
			clearTimeout(tmh);
		}
	}

	_nfyAcv() {
		this._clrTmo();
		if ( this.cbkWdg ) {
			this.log('WNDNFY: ' + (this.isAcv ? ' active' : 'inactive'));
			this.cbkWdg.nfyWndAcv(this.isAcv);
		}
	}
	
	_iniNfy() {
		if ( !this.tmoHdl ) {
			const self = this;
			self.tmoHdl = setTimeout(() => {
				self._nfyAcv();
			}, TMO_WAIT);
		}
	}
	
	_onBlur() {
		this.log('blur');
		this.isAcv = false;
		this._iniNfy();
	}
	
	_onFocus() {
		this.log('focus');
		this.isAcv = true;
		this._iniNfy();
	}

}
