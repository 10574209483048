import LoggingBase from "../base/loggingbase";
import Validator from "./Validator";
import PSA from "../psa";

/**
 * UI refresh handler class
 */
export default class UIRefresh extends LoggingBase {

    constructor() {
        super('utils.UIRefresh');
        this._requests = new Map();
        this._pending = false;
        this._running = false;
        this._queue = new Map();
        this._callback = PSA.getInst().bind(this, this._runPendingRequests);
    }

    static getInstance() {
        return singleton;
    }

    /**
     * adds a named request
     * @param {String} name request name
     * @param {Function} func the request function
     * @param {*} context the calling context (i.e. 'this')
     * @param {*} args argument object passed to the request function upon execution
     * @returns {Boolean} true if the request was added; false otherwise
     */
    addRequest(name, func, context, args) {
        if ( this.alive && Validator.isString(name) && Validator.isFunction(func) ) {
            if ( !this.hasRequest(name) ) {
                this.log(`Registering UI refresh request "${name}".`);
                const rqu = Object.freeze({ func: func, context: context, args: args });
                if ( !this._running ) {
                    this._requests.set(name, rqu);
                    if ( !this._pending ) {
                        this._pending = true;
                        window.requestAnimationFrame(this._callback);
                    }
                } else {
                    this.log(`Request "${name}" queued due to previously registered requests are currently processed.`);
                    this._queue.set(name, rqu);
                }
                return true;
            } else {
                this.warn(`UI refresh request "${name}" already registered or queued!`);
            }
        }
        return false;
    }

    /**
     * checks, whether is request with a specific name
     * @param {String} name request name
     * @returns {Boolean} true if there's a request with the specified name; false otherwise
     */
    hasRequest(name) {
        if ( !this.alive ) {
            return false;
        }
        return this._requests.has(name) || this._queue.has(name);
    }

    /**
     * runs all pending requests in an "animation frame"
     */
    _runPendingRequests() {
        this._running = true;
        const self = this;
        const current = new Map(this._requests.entries());
        this._requests.clear();
        this._pending = false;
        try {
            current.forEach((rqu, name) => {
                self._runRequest(rqu, name);
            });
        } finally {
            current.clear();
            this._running = false;
        }
        if ( this._queue.size > 0 ) {
            // we got new requests while the previously registered requests were processed
            const queue = new Map(this._queue.entries());
            this.log(`Registering ${queue.size} queued requests...`);
            const requests = this._requests;
            this._queue.clear();
            queue.forEach((rqu, name) => {
                requests.set(name, rqu);
            });
            this._pending = true;
            window.requestAnimationFrame(this._callback);
        }
    }

    /**
     * runs a single UI refresh request
     * @param {*} func the refresh request
     * @param {string} name request name
     */
    _runRequest(rqu, name) {
        // just call the request function
        this.log(`Running UI refresh request "${name}".`);
        rqu.func.call(rqu.context, rqu.args);
    }
}

const singleton = new UIRefresh();