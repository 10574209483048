import BasePoint from './BasePoint';
import JsSize from './JsSize';
import BaseRect from './BaseRect';

export default class JsPoint extends BasePoint {

	/**
	 * constructs a new instance
	 * @param {*} args arguments
	 */
	 constructor(args) {
		super(typeof args === 'object' ? args : arguments);
	}

	/**
	 * creates new point that has an offset to this instance
	 * @param {Number} dx x-offset
	 * @param {Number} dy y-offset
	 * @returns the new point
	 * @override
	 */
	move(dx, dy) {
		return new JsPoint(this.x+dx, this.y+dy);
	}
	
	dist(pt) {
		return Math.abs(this.x-pt.x) + Math.abs(this.y-pt.y);
	}
	
	offs(pt) {
		return new JsSize(this.x-pt.x, this.y-pt.y);
	}
	
	/**
	 * tests whether this point is within a rectangle
	 * @param {BaseRect} rect the rectangle
	 * @returns {Boolean} true if this point lies in the specified rectangle; false otherwise
	 */
	inRect(rect) {
		return rect.contains(this.x, this.y);
	}
	
	toString() {
		return "{JsPoint (" + this.x + "," + this.y + ")}";
	}

}

console.debug('utils/JsPoint.js loaded.');