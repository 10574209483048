import EditableElement from './EditableElement';
import EventListenerManager from '../../../../utils/EventListenerManager';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';
import ListenerMgr from '../../../../utils/ListenerMgr';
import XCellItem from '../../parts/XCellItem';
import XRowItem from '../../parts/XRowItem';
import CellCtt from '../../model/CellCtt';

const CALLBACK_PREFIX = 'Input';

export default class Checkbox extends EditableElement {

	constructor( cellObject, ln ) {
		super( cellObject, ln || 'widgets.xtw.editing.Checkbox' );
		cellObject.checkbox = this;
		this.nextValue = null;
		this._checkedState = null;
		this.lsrManager = new ListenerMgr('widgets.xtw.editing.Checkbox');
	}

	get isTripleState() {
		return false;
	}

	render() {
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			this.warn('Invalid cell element: ', cellElement);
			return false;
		}
		this.discardUi();
		cellElement.innerHTML = "";
		this._setInput(this.newInput);
		if ( this.isOriginallyChecked ) {
			this.input.appendChild( this.newCheckMark );
			this._checkedState = true;
		} else {
			this._checkedState = false;
		}
		this.container = this.newContainer;
		this.container.style.justifyContent = "center";
		this.container.appendChild( this.input );
		cellElement.appendChild( this.container );
		return true;
	}

	/**
	 * @override
	 */
	getType() {
		return 'checkbox';
	}

	/**
	 * @override
	 * @returns {Boolean}
	 */
	isCheckbox() {
		return true;
	}

	/**
	 * @returns {Boolean} true if the checkbox has a hyperlink; false otherwise
	 */
	get isLink() {
		const cell = this.cell;
		return (cell instanceof XCellItem) && cell.link;
	}

	/**
	 * @returns {Boolean} true if the checkbox is checked; false otherwise
	 */
	get isChecked() {
		return Validator.true(this._checkedState);
	}

	get isOriginallyChecked() {
		return Validator.true(this.originalValue);
	}

	get userValue() {
		return this.isChecked;
	}

	get initialValue() {
		return this.isOriginallyChecked;
	}

	get changed() {
		return this.userValue !== this.initialValue;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {String}
	 */
	setOvrOrgValue(ovr) {
		// we do exactly NOTHING!
	}

	/**
	 * sets new content
	 * @param {CellCtt | null} ctt new content
	 * @param {Number} idr current row ID
	 * @returns {Boolean} true if the content was set; false otherwise
	 */
	setCtt(ctt, idr) {
		const input = this.input;
		if ( !(input instanceof HTMLElement) ) {
			this.render();
		}
		this.updateIdr(idr);
		const text = (ctt instanceof CellCtt) ? ctt.text : '';
		this.applyCtt(text);
		return true;
	}

	/**
	 * applies the new content
	 * @param {String} text new content
	 */
	applyCtt(text) {
		if ( Validator.isTrue(text) ) {
			this.check();
		} else {
			this.uncheck();
		}
	}


	setEditingPermission( editingAllowed ) {
		this.editingAllowed = !!editingAllowed;
		this.dirty = this.editingAllowed;
		try {
			if ( this.editingAllowed ) {
				if ( this.nextValue !== null ) {
					if ( this.nextValue ) {
						this.check();
					} else {
						this.uncheck();
					}
				}
			} else {
				this.resetInput();
			}
		} finally {
			this.nextValue = null;
		}
		if ( !this.editingAllowed ) {
			return true;
		}
		this.rowEdited = true;
		if ( (this.input instanceof HTMLElement) && (window.document.activeElement !== this.input) ) {
			this.input.focus( { preventScroll: true } );
		}
		const blurListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "blur",
			functionName: "onInputBlur",
			callBackPrefix: CALLBACK_PREFIX,
			element: this.input,
			useCapture: false,
			manager: this.lsrManager
		} );
		return true;
	}

	setCheckedStatus( newCellContent ) {
		return newCellContent == "true" ? this.check() : this.uncheck();
	}

	toggleInput() {
		return this.isChecked ? this.uncheck() : this.check();
	}

	check() {
		this._checkedState = true;
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.innerHTML = "";
		this.input.appendChild( this.newCheckMark );
		return true;
	}

	uncheck() {
		this._checkedState = false;
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.innerHTML = "";
		return true;
	}

	/**
	 * causes the checkbox to focus itself
	 * @returns {Boolean} true if successful; false otherwise
	 */
	focusSelf() {
		if ( this.alive ) {
			const input = this.input;
			const cell = this.cell;
			if ( (input instanceof HTMLElement) || ((cell instanceof XCellItem) && (cell.element instanceof HTMLElement)) ) {
				this.trace('CHECKBOX: registering as current edit target.');
				// note: do *NEVER EVER* set the focus to a DOM element here! it would destroy the table's layout
				this.register();
				return true;
			}
		}
		return false;
	}

	destroySelfAndRestoreCell() {
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) && (xtwBody.inputField === this) ) {
			xtwBody.inputField = void 0;
			delete xtwBody.inputField;
		}
		return this.resetInput();
	}

	resetInput() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		if ( this.lsrManager.hasListener("blur", CALLBACK_PREFIX) ) {
			EventListenerManager.removeListener( this, "blur", this.input, CALLBACK_PREFIX, this.lsrManager );
		}
		this.isOriginallyChecked ? this.check() : this.uncheck();
		this.dirty = false;
		return true;
	}

	discardInput() {
		if ( this.isRendered ) {
			const manager = this.lsrManager;
			[ "keydown", "keyup", "keypress", "click" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName, this.input, CALLBACK_PREFIX, manager );
			} );
		}
		return super.discardInput();
	}

	/**
	 * @override
	 */
	destroySelf() {
		if ( this.alive ) {
			try {
				this.discardUi();
				const xtwBody = this.xtwBody;
				if ( Validator.isObject( xtwBody ) && (xtwBody.inputField === this) ) {
					xtwBody.inputField = void 0;
					delete xtwBody.inputField;
				}
				const cellObject = this.cell;
				if ( cellObject instanceof XCellItem  ) {
					cellObject.checkbox = void 0;
					delete cellObject.checkbox;
				}
				const propertyNames = Object.getOwnPropertyNames(Object.getPrototypeOf( this ) );
				for ( let propertyName of propertyNames ) {
					Object.defineProperty( this, propertyName, {
						value: void 0,
						writable: true,
						configurable: true
					} );
					this[ propertyName ] = void 0;
					delete this[ propertyName ];
				}
			} finally {
				super.destroySelf();
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	discardUi() {
		return super.discardUi();
	}

	get newCheckMark() {
		const cm = window.document.createElement( "i" );
		cm.classList.add( "far", "fa-check" );
		return cm;
	}

	get newInput() {
		const checkboxDiv = window.document.createElement( "div" );
		checkboxDiv.classList.add( "rtp-checkbox" );
		checkboxDiv.id = this.inputId;
		checkboxDiv.tabIndex = 1;
		if ( this.isLink ) {
			checkboxDiv.style.cursor = 'pointer';
		}
		const manager = this.lsrManager;
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onInputKeyDown",
			callBackPrefix: CALLBACK_PREFIX,
			element: checkboxDiv,
			useCapture: false,
			manager: manager
		} );
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onInputKeyUp",
			callBackPrefix: CALLBACK_PREFIX,
			element: checkboxDiv,
			useCapture: false,
			manager: manager
		} );
		const keypressListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keypress",
			functionName: "onInputKeyPress",
			callBackPrefix: CALLBACK_PREFIX,
			element: checkboxDiv,
			useCapture: false,
			manager: manager
		} );
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onInputClick",
			callBackPrefix: CALLBACK_PREFIX,
			element: checkboxDiv,
			useCapture: false,
			manager: manager
		} );
		return checkboxDiv;
	}

	isHyperlinkClick( domEvent ) {
		return (this.cell instanceof XCellItem) ? this.cell.isHyperlinkClick( domEvent ) : false;;
	}

	onInputClick( domEvent ) {
		if ( this.isHyperlinkClick( domEvent ) ) {
			return this.cell.onCellContainerClick( domEvent );
		}
		if ( !this.canBeEdited ) {
			return false;
		}
		this.register();
		if ( this.dirty ) {
			if ( domEvent instanceof Event ) {
				DomEventHelper.stopEvent(domEvent);
			}
			return this.toggleInput();
		} else {
			this.nextValue = !this.isChecked;
			this.toggleInput();
			this.dirty = true;
			this.informAboutEditing();
			this.informAboutContentChange();
			return true;
		}
	}

	onInputBlur( domEvent ) {
		if ( (this.cell instanceof XCellItem) && (window.document.activeElement === this.cell.element) ) {
			return true;
		}
		this.informAboutContentChange();
		return this.destroySelfAndRestoreCell();
	}

	onInputKeyDown( domEvent ) {
		if ( DomEventHelper.isSpaceKey(domEvent) ) {
			return this.onCheckboxSpace( domEvent );
		}
		if ( DomEventHelper.keyIs( domEvent, "Enter" ) ) {
			return this.onCheckboxEnter( domEvent );
		}
		if ( DomEventHelper.isTabKey(domEvent) ) {
			return this.onCheckboxTab( domEvent );
		}
		if ( DomEventHelper.isSaveEvent( domEvent ) ) {
			return this.onCheckboxSave( domEvent );
		}
		if ( DomEventHelper.isArrowLeft( domEvent ) ) {
			return this.onCheckboxArrowLeft( domEvent );
		}
		if ( DomEventHelper.isArrowRight( domEvent ) ) {
			return this.onCheckboxArrowRight( domEvent );
		}
		if ( DomEventHelper.isArrowUp( domEvent ) ) {
			return this.onCheckboxArrowUp( domEvent );
		}
		if ( DomEventHelper.isArrowDown( domEvent ) ) {
			return this.onCheckboxArrowDown( domEvent );
		}
		if ( DomEventHelper.isPageUp( domEvent ) ) {
			return this.onCheckboxPageUp( domEvent );
		}
		if ( DomEventHelper.isPageDown( domEvent ) ) {
			return this.onCheckboxPageDown( domEvent );
		}
		if ( domEvent instanceof KeyboardEvent ) {
			DomEventHelper.stopEvent(domEvent);
		}
	}

	onInputKeyPress( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.input.id;
		}
	}

	onInputKeyUp( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			DomEventHelper.stopEvent(domEvent);
		}
		if ( DomEventHelper.keyIs( domEvent, "Escape" ) ) {
			return this.onCheckboxEscape( domEvent );
		}
	}

	onCheckboxSpace( domEvent ) {
		if ( domEvent instanceof Event ) {
			DomEventHelper.stopEvent(domEvent);
		}
		return this.onInputClick( domEvent );
	}

	onCheckboxEnter( domEvent ) {
		this.onInputBlur( domEvent );
		const cell = this.cell;
		if ( !(cell instanceof XCellItem) ) {
			return false;
		}
		return cell.onInputEnter( domEvent );
	}

	onCheckboxTab( domEvent ) {
		this.onInputBlur( domEvent );
		const cell = this.cell;
		if ( cell instanceof XCellItem ) {
			return cell.onInputTab( domEvent );
		}
		return false;
	}

	onCheckboxSave( domEvent ) {
		if ( !this.dirty || !this.changed || !this.editingAllowed ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		this.informAboutFullSave();
		this.destroySelfAndRestoreCell();
		this.trace(`A keyboard save operation was performed on a ${ this.isTripleState ? "triple state" : "" } checkbox.` );
		return true;
	}

	onCheckboxEscape( domEvent ) {
		this.destroySelfAndRestoreCell();
		this.informAboutCancel();
		const cell = this.cell;
		if ( !(cell instanceof XCellItem) ) {
			return false;
		}
		return cell.onInputEscape( domEvent );
	}

	onCheckboxArrowLeft( domEvent ) {}

	onCheckboxArrowRight( domEvent ) {}

	onCheckboxArrowUp( domEvent ) {}

	onCheckboxArrowDown( domEvent ) {}

	onCheckboxPageUp( domEvent ) {}

	onCheckboxPageDown( domEvent ) {}

	informAboutContentChange() {
		if ( this.editingAllowed && (this.isOriginallyChecked !== this.isChecked) ) {
			this.informAboutSave();
			return true;
		} else {
			return false;
		}
	}

	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const row = this.row;
		if ( !(row instanceof XRowItem) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		const isOriginallyChecked = this.isOriginallyChecked;
		Object.assign( parameters, {
			idc: this.columnId,
			originalValue: isOriginallyChecked ? 'y' : 'n',
			userValue: this.isChecked ? 'y' : 'n',
			inputId: this.inputId
		} );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

}
