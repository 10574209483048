import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class EditingElementSelectionManagerExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get selectionStart() {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return 0;
		}
		const selectionStart = contentEditableElement.selectionStart;
		return Validator.isPositiveInteger( selectionStart ) ? selectionStart : 0;
	}

	set selectionStart( startValue ) {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return false;
		}
		const currentContentLength = this.currentContentLength;
		if ( !Validator.isPositiveInteger( currentContentLength, false ) ) {
			return false;
		}
		if ( !Validator.isPositiveInteger( startValue ) ||
			startValue > currentContentLength ) {
			return false;
		}
		contentEditableElement.selectionStart = startValue;
		return true;
	}

	get selectionEnd() {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return 0;
		}
		const selectionEnd = contentEditableElement.selectionEnd;
		return Validator.isPositiveInteger( selectionEnd ) ? selectionEnd : 0;
	}

	set selectionEnd( endValue ) {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return false;
		}
		const currentContentLength = this.currentContentLength;
		if ( !Validator.isPositiveInteger( currentContentLength, false ) ) {
			return false;
		}
		if ( !Validator.isPositiveInteger( endValue ) ||
			endValue > currentContentLength ) {
			return false;
		}
		contentEditableElement.selectionEnd = endValue;
		return true;
	}

	get selectionDirection() {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return "forward";
		}
		const selectionDirection = contentEditableElement.selectionDirection;
		return [ "forward", "backward", "none" ].indexOf( selectionDirection ) < 0 ?
			"forward" : selectionDirection;
	}

	set selectionDirection( direction ) {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return false;
		}
		if ( [ "forward", "backward", "none" ].indexOf( direction ) < 0 ) {
			return false;
		}
		contentEditableElement.selectionDirection = direction;
		return true;
	}

	setSelection( selectionStart = 0, selectionEnd = 0, selectionDirection = "forward" ) {
		this.selectionStart = selectionStart;
		this.selectionEnd = selectionEnd;
		this.selectionDirection = selectionDirection;
	}

}
