import XtwModel from "./XtwModel";

/**
 * row focus listener interface
 */
export default class RowFocusLsr {

    /**
     * called if the the row focus has changed
     * @param {XtwModel} model the data model
     * @param {Number} focus ID of currently focused row
     * @param {Number} prev ID of previously focused row
     * @param {Object} options more options
     */
    onRowFocus(model, focus, prev, options) {
        // abstract
    }
}