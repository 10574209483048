import Validator from '../Validator';

export default class ConditionalItem {

	constructor( {
		operands = null,
		callback = null,
		expression = null,
		operator = null
	} ) {
		this.operands = Validator.isArray( operands ) ? operands : [];

		if ( Validator.isFunction( callback ) )
			this.callback = callback;

		this._defaultCallback = () => {
			if ( !Validator.isArray( this.operands, true ) ) return false;
			if ( !Validator.isFunction( this.operands[ 0 ].evaluate ) )
				return !!this.operands[ 0 ];
			return this.operands[ 0 ].evaluate();
		}

		if ( Validator.isString( expression ) )
			this.expression = expression.trimStart().trimEnd();

		if ( Validator.isString( operator ) )
			this.operator = operator.trimStart().trimEnd();
	}

	unshift( operand ) {
		this.operands.unshift( operand );
	}

	push( operand ) {
		this.operands.push( operand );
	}

	replace( oldOperand, newOperand ) {
		let index = this.operands.indexOf( oldOperand );
		if ( !Validator.isInteger( index ) ) return;
		this.operands[ index ] = newOperand;
	}

	stringify() {}

	evaluate() {}

	forceEvaluate() {}

	modifyExpressionOperands() {}

	_modifyExpressionOperands() {}

}

console.debug( 'utils/condition/ConditionalItem.js loaded.' );
